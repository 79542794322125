import { createNamespacedHelpers, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNameSelect from './ClassNameSelect.vue';

import expressionsList from './ExpressionsList/ExpressionsList.vue';
import intentActions from './Actions/Actions.vue';
import intentChannelActions from './ChannelActions/ChannelActions.vue';
import entitiesList from './EntitiesList/EntitiesList.vue';
import { SelectPanel } from '@/components';
import { basicInputForm, buildingKitAiTesting } from '../Partials';
import middlePaneHeader from '../Partials/MiddlePaneHeader/MiddlePaneHeader.vue';
import {
  appendUnitType,
  confirmUnsavedWarning,
} from '@/libs';
import { STATUS_CODES } from '@/constants';

const { mapGetters, mapMutations } = createNamespacedHelpers('intents/formIntent');
const LIMIT = 30;

export default {
  name: 'intents',
  components: {
    classNameSelect,
    entitiesList,
    expressionsList,
    intentActions,
    intentChannelActions,
    basicInputForm,
    buildingKitAiTesting,
    SelectPanel,
    middlePaneHeader,
  },
  data() {
    return {
      limit: LIMIT,
      offset: 0,
      search: '',
      loadedFlag: true,
      identifier: 0,
      newIntentName: '',
    };
  },
  created() {
    this.$_showChannelActions = process.env.FEATURE_FLAGS.CHANNEL_ACTIONS;
  },
  mounted() {
    if (!this.loaded) {
      this.loadIntents().then(() => {
        const id = parseInt(this.$route.params.id, 10);
        const intentId = id || get(this, 'items[0].id');

        if (intentId) { this.selectIntent(intentId) }
      });
    }
  },
  beforeUnmount() {
    this.$store.commit('intents/CLEAR_INTENTS');
    this.$store.commit('intents/CLEAR_SELECTED');
    this.$store.dispatch('intents/resetForm');
  },
  computed: {
    ...mapState({
      items: state => state.intents.items,
      entities: state => state.entities.entities,
      selected: state => state.intents.selected,
      formIntent: state => state.intents.formIntent,
      loaded: state => state.intents.intentsLoaded,
    }),
    ...mapGetters([
      'pristine',
    ]),
    formActionList() {
      return this.formIntent.actions;
    },
    middleColumnsClassObj() {
      return {
        pristine: this.pristine,
      };
    },
  },
  methods: {
    ...mapMutations([
      'UPDATE_ACTIONS',
      'UPDATE_PAYLOAD',
      'DELETE_ACTION',
      'RESET_FORM',
    ]),
    // Methods - CRUD
    createIntent(name) {
      const tempIntent = { name };

      this.$store.dispatch('intents/addIntent', tempIntent)
        .then(data => {
          this.selectIntent(data.data.id);
          this.$aiq.notify.success(
            `${data.data.name} has been added to intents.`,
          );
        }, error => {
          this.handleErrorMessage(error, tempIntent);
        });
    },
    deleteIntent(id) {
      const { name } = this.items.find(e => e.id === id);
      this.$aiq.confirm(
        'Delete Intent',
        `Are you sure you want to delete <b>${name}</b>?`,
      ).then(() => {
        this.$store.dispatch('intents/deleteIntent', id)
          .then(() => { this.$aiq.notify.success(`${name} has been deleted from intents.`) });
      });
    },
    onEditSave() {
      return this.$store.dispatch('intents/updateIntent', this.formIntent)
        .then(data => {
          this.selectIntent(data.data.id, true);
          this.$aiq.notify.success(`${this.selected.name} has been saved.`);

          return data;
        });
    },
    selectIntent(id, disableSaveWarning) {
      if (!this.pristine && this.selected && !disableSaveWarning) {
        const { title, content } = confirmUnsavedWarning;
        return this.$aiq.confirm(title, content)
          .then(() => this.$store.dispatch('intents/selectIntent', id)).catch(() => {});
      }
      return this.$store.dispatch('intents/selectIntent', id)
        .catch(() => this.$aiq.notify.error('Intent not found'));
    },
    updateClass(newClass) {
      const payload = cloneDeep(this.formIntent.payload);
      payload.class = newClass;
      this.UPDATE_PAYLOAD(payload);
    },
    // Methods - Other
    handleErrorMessage(err, tempIntent) {
      if (err.response.status === STATUS_CODES.CONFLICT && tempIntent) {
        this.$aiq.notify.error(`${this.newIntentName} already exists.`);
        this.search = tempIntent.name;
        return this.$store.dispatch('intents/getAll', { q: tempIntent.name, limit: 0, offset: 0 })
          .then(() => {
            this.$store.dispatch('intents/upIntent', tempIntent.name);
          });
      } if (err.response.data.name === 'INVALID_STRING_PATTERN') {
        this.$aiq.notify.error(`Invalid name given. ${err.response.data.reason}`);
      } else {
        this.$aiq.notify.error('Unable to save intent.');
      }
    },
    saveIntentName(newName) {
      if (this.formIntent.name === newName) {
        return;
      }

      const intent = cloneDeep(this.formIntent);
      intent.name = newName;

      this.$store.dispatch('intents/updateIntent', intent)
        .then(data => {
          this.selectIntent(data.data.id)
            .then(() => {
              this.$aiq.notify.success(`${get(data, 'data.name', newName)} has been saved.`);
            });
        }).catch(err => {
          this.handleErrorMessage(err);
        });
    },
    deleteAction(index) {
      this.DELETE_ACTION(index);
    },
    loadIntents(searchTerms) {
      return this.$store.dispatch('intents/getAll', { q: searchTerms, limit: this.limit, offset: this.offset }).then(data => {
        this.$store.dispatch('intents/markAsLoaded', data.data.pagination.rowCount < (this.offset + this.limit));
        return data;
      });
    },
    onEditCancel() {
      this.$store.dispatch('intents/resetForm');
    },
    onScrollLoad($state, searchTerms) {
      if (!this.loaded) {
        this.loadIntents(searchTerms).then(data => {
          this.offset += this.limit;
          const dataItems = get(data, 'data.data', []);
          if (isEmpty(dataItems) || this.loaded) {
            $state.complete();
          } else {
            $state.loaded();
          }
        });
      } else {
        $state.complete();
      }
    },
    searchIntents(searchTerms) {
      this.search = searchTerms;
      this.identifier++;
      this.$store.dispatch('intents/markAsLoaded', false);
      this.offset = 0;

      this.loadIntents(searchTerms);
    },
    updateActions(evt) {
      const actions = cloneDeep(this.formActionList);

      if (evt.added) {
        // Putting in array to conform to appendUnitType
        const addedAction = actions[evt.added.newIndex];
        const action = [addedAction];
        appendUnitType(action);
      }

      this.UPDATE_ACTIONS(actions);
    },
    onActionItemClick(item) {
      this.$store.dispatch('routing/routeIqtoolActionItem', item);
    },
  },
};

