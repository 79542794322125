<template lang="pug">
.webchat-link-configuration(v-if="config")
  aiq-row
    .insert-new
      i.el-icon-plus(@click="onNewItem")
  aiq-row
    aiq-table(:data="linkBehaviors")
      aiq-table-column(:label="$t('settings_tab.channels_tab.webchat_configuration.link_config.table.column.domain')"
                       align="left"
                       prop="domain"
                       :sortable="false")
        template(v-slot:default="scope")
          .config-value(v-if="isEditing && isEditing.line === scope.$index && isEditing.field === 'domain'")
            aiq-input(v-model="tempText"
                      size="small"
                      @change="onEditingDone")
          .config-value.click-space(v-else
                                    @click="onEdit(scope.row, 'domain')") {{ scope.row.domain }}
      aiq-table-column(:label="$t('settings_tab.channels_tab.webchat_configuration.link_config.table.column.description')"
                       align="left"
                       prop="description"
                       :sortable="false")
        template(v-slot:default="scope")
          .config-value(v-if="isEditing && isEditing.line === scope.$index && isEditing.field === 'description'")
            aiq-input(v-model="tempText"
                      size="small"
                      @change="onEditingDone")
          .config-value.click-space(v-else @click="onEdit(scope.row, 'description')") {{ scope.row.description }}
      aiq-table-column(:label="$t('settings_tab.channels_tab.webchat_configuration.link_config.table.column.behavior')"
                       align="left"
                       prop="launch_behavior"
                       :sortable="false")
        template(v-slot:default="scope")
          aiq-select(v-model="scope.row.launch_behavior"
                     size="small"
                     @change="onLaunchBehaviorChange(scope.row, $event)"
                     :reserve-keyword="false")
            aiq-option(v-for="option in launchOptions"
                      :key="option.key"
                      :label="option.label"
                      :value="option.key")
      aiq-table-column(label=""
                       align="left"
                       width="80"
                       :sortable="false")
        template(v-slot:default="scope")
          a.pointer(@click="onDelete(scope.row)") {{ $t('settings_tab.channels_tab.webchat_configuration.link_config.table.archive') }}
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import * as log from 'loglevel';


export default {
  name: 'WebchatLinkConfiguration',
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEditing: null, // indicate a location that is currently editing
      tempText: '', // a place holding editing value
      hasNewItem: false,
    };
  },
  computed: {
    launchOptions() {
      return [{
        key: 'new_tab',
        label: this.$t('settings_tab.channels_tab.webchat_configuration.link_config.table.behavior.new_tab'),
      },
      {
        key: 'current_tab',
        label: this.$t('settings_tab.channels_tab.webchat_configuration.link_config.table.behavior.current_tab'),
      },
      {
        key: 'event_only',
        label: this.$t('settings_tab.channels_tab.webchat_configuration.link_config.table.behavior.event_only'),
      }];
    },
    newPayload() {
      return {
        domain: '',
        description: '',
        launch_behavior: 'new_tab',
      };
    },
    linkBehaviors() {
      if (this.hasNewItem) {
        return [this.newPayload, ...get(this.config, 'link_behaviors', [])];
      } else {
        return get(this.config, 'link_behaviors', []);
      }
    },
  },
  methods: {
    onLaunchBehaviorChange(behavior, value) {
      log.debug('value updated', behavior, value);
      const behaviors = cloneDeep(this.linkBehaviors);
      const foundBehavior = behaviors.find(b => b.domain === behavior.domain);
      set(foundBehavior, 'launch_behavior', value);
      this.updateConfig({ link_behaviors: behaviors });
    },
    onEdit(behavior, field) {
      this.isEditing = {
        line: this.linkBehaviors.findIndex(b => b.domain === behavior.domain),
        field,
      };
      this.tempText = behavior[field];
      log.debug('onEdit', behavior, field, this.isEditing);
    },
    onEditingDone(value) {
      value = value.trim();
      const behaviors = cloneDeep(this.linkBehaviors);
      behaviors[this.isEditing.line][this.isEditing.field] = value;
      this.isEditing = null;
      this.hasNewItem = false; // reconsider this location
      this.updateConfig({ link_behaviors: behaviors });
    },
    onNewItem() {
      this.hasNewItem = true;
    },
    validatePayload(payload) {
      for (const behavior of payload.link_behaviors) {
        const domain = behavior.domain;
        if (isEmpty(domain)) {
          return { validated: false, error: 'Domain cannot be empty' };
        }

        if (domain.includes(' ')) {
          return { validated: false, error: 'Domain cannot include a space' };
        }

        // We may want to add more validations here.
      }
      return { validated: true };
    },
    onDelete(behavior) {
      let behaviors = cloneDeep(this.linkBehaviors);
      behaviors = behaviors.filter(b => b.domain !== behavior.domain);
      this.updateConfig({ link_behaviors: behaviors });
    },
    updateConfig(payload) {
      const ret = this.validatePayload(payload);
      if (ret.validated) {
        this.$emit('update', payload);
      } else {
        this.$aiq.notify.error(ret.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";
@import "./../../../../../styles/aiq-mixins.scss";

.webchat-link-configuration {
  padding-bottom: 25px;
}

.click-space {
  width: 100%;
  height: 14px;
}

.insert-new {
  display: flex;
  padding: 10px;
  width: 100%;
  justify-content: end;
}

.pointer {
  cursor: pointer;
}
</style>
