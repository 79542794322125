<template lang="pug">
.conversation-escalation-configuration(v-if="localConversationEscalationConfig")
  .enabled-toggle
    aiq-row.padding-5
      aiq-col(:span="11")
        label {{ $t(`${baseTranslationPath}.config.no_agent_response`) }}
          aiq-tooltip(placement="top")
            template(v-slot:content)
              div(v-html="$t(`${baseTranslationPath}.config.no_agent_response_tooltip`)")
            fa-icon.question-mark(:icon="['fas', 'circle-question']")
      aiq-col(:span="13")
        aiq-switch(v-model="localConversationEscalationConfig.enabled"
                  style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                  @change="enabledChanged($event)")

  .configuration(v-if="localConversationEscalationConfig.enabled")
    aiq-form
      aiq-row.padding-5
        aiq-col(:span="12").is-required
          aiq-form-item(:class="{'is-error': v$.localConversationEscalationConfig.configuration.escalate_after.$error}")
            label {{ $t(`${baseTranslationPath}.config.no_agent_response_time`) }}
              span.text-muted {{ $t(`${baseTranslationPath}.config.no_agent_response_time_measure`) }}
            aiq-input(type="number"
                      size="small"
                      v-model.number="localConversationEscalationConfig.configuration.escalate_after")
            span.text-danger(v-if="v$.localConversationEscalationConfig.configuration.escalate_after.$error") {{ v$.localConversationEscalationConfig.configuration.escalate_after.$errors[0]?.$message }}

      aiq-row.padding-5
        aiq-col(:span="12")
          aiq-form-item(:class="{'is-error': v$.localConversationEscalationConfig.configuration.workflow.$error}")
            label {{ $t(`${baseTranslationPath}.config.no_agent_response_workflow`) }}
            aiq-select(v-model="localConversationEscalationConfig.configuration.workflow"
                      :placeholder="$t('global.select')"
                      size="small"
                      name="configuration.workflow"
                      :reserve-keyword="false"
                      clearable)
              aiq-option(v-for="m in workflows"
                        :key="m"
                        :label="m"
                        :value="m")
            span.text-danger(v-if="v$.localConversationEscalationConfig.configuration.workflow.$error") {{ v$.localConversationEscalationConfig.configuration.workflow.$errors[0]?.$message }}

      aiq-row.padding-5
        aiq-col(:span="12")
          aiq-form-item(:class="{'is-error': v$.localConversationEscalationConfig.configuration.segments.$error}")
            label {{ $t(`${baseTranslationPath}.config.segments`) }}
            aiq-select(v-model="localConversationEscalationConfig.configuration.segments"
                      :placeholder="$t('global.select')"
                      size="small"
                      multiple
                      name="segments"
                      :reserve-keyword="false")
              aiq-option(v-for="m in segments"
                        :key="m"
                        :label="m"
                        :value="m")
            span.text-danger(v-if="v$.localConversationEscalationConfig.configuration.segments.$error") {{ v$.localConversationEscalationConfig.configuration.segments.$errors[0]?.$message }}

    .settings-container
      .settings__item
        span.text-required.is-required
          label Required
        .save-buttons__container.settings__item
          aiq-button(:disabled="!isConversationEscalationConfigChanged"
                    size="small"
                    @click="resetConfig") {{$t('global.cancel')}}
          aiq-button.save__button(type="primary"
                                  :disabled="!isConversationEscalationConfigChanged || v$.localConversationEscalationConfig.$invalid"
                                  size="small"
                                  @click="saveConfig") {{$t('global.save')}}
</template>

<script>
import set from 'lodash/set';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

const BASE_TRANSLATION_PATH = 'settings_tab.channels_tab.webchat_configuration.conversation_escalation';

export default {
  name: 'conversation-escalation-configuration',
  props: {
    conversationEscalationConfig: {
      type: Object,
      default: () => ({}),
    },
    workflows: {
      type: Array,
      default: () => [],
    },
    segments: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      localConversationEscalationConfig: {
        enabled: { $autoDirty: true },
        configuration: {
          escalate_after: {
            requiredIf: requiredIf(this.localConversationEscalationConfig?.enabled ?? false),
            $autoDirty: true,
          },
          workflow: {
            $autoDirty: true,
          },
          segments: {
            $autoDirty: true,
          },
        },
      },
    };
  },
  data() {
    return {
      localConversationEscalationConfig: null,
    };
  },
  watch: {
    conversationEscalationConfig: {
      handler(newVal) {
        this.localConversationEscalationConfig = cloneDeep(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    baseTranslationPath() {
      return BASE_TRANSLATION_PATH;
    },
    isConversationEscalationConfigChanged() {
      return !isEqual(this.localConversationEscalationConfig, this.conversationEscalationConfig);
    },
  },
  methods: {
    enabledChanged(val) {
      // when enabling feature first time we need to default escalate_after to blank instead of 0
      if (val && get(this.localConversationEscalationConfig, 'configuration.escalate_after', 0) === 0) {
        set(this.localConversationEscalationConfig, 'configuration.escalate_after', null);
        this.v$.localConversationEscalationConfig.configuration.escalate_after.$reset();
      } else if (!val && get(this.localConversationEscalationConfig, 'configuration.escalate_after', null) === null) {
        set(this.localConversationEscalationConfig, 'configuration.escalate_after', 0);
      }
      set(this.localConversationEscalationConfig, 'configuration.enabled_at', val ? new Date().toISOString() : '');
      if (!val) {
        this.saveConfig();
      }
    },
    resetConfig() {
      this.localConversationEscalationConfig = cloneDeep(this.conversationEscalationConfig);
    },
    async saveConfig() {
      if (await this.v$.$validate()) {
        this.$emit('update', this.localConversationEscalationConfig);
        return;
      }
      this.$aiq.notify.error(this.$t('settings_tab.channels_tab.webchat_configuration.unable_save_error'));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";
@import "./../../../../../styles/aiq-mixins.scss";
.configuration {
  margin-left: 10px;;
}
.padding-5 {
  padding: 5px;
}
.text-muted {
  color: #6c757d;
}
.text-danger {
  height: 0;
  margin-top: -5px;
  color: #F56C6C;
  font-size: 12px;
  display: block;
}
svg.question-mark {
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  &:hover {
    color: $aiq-bgc-header;
  }
  &:focus {
    outline: none;
  }
}
.is-required {
  label::before {
    margin-right: 5px;
    content: "*";
    color: red;
  }
}
.text-required {
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
  color: #F56C6C;
}
.settings-container {
  display: flex;

  .settings__item {
    align-self: flex-start;
    flex-grow: 1;
    min-width: 589px;
  }

  .save-buttons__container {
    background-color: #fff;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    margin-bottom: 0px;
    padding: 5px 8px 0px 0px;
    width: calc(100% - 4px);

    * {
      margin: 0px 5px;
    }

    .save__button {
      margin-right: 0px;
    }
  }
}
.el-form-item {
  margin-bottom: 5px;
  &.is-error {
    :deep(.el-form-item__content) {
      .el-select__wrapper {
        box-shadow: 0 0 0 1px #f56c6c !important;
      }
    }
  }
  :deep(.el-form-item__content) {
    line-height: 24px !important;
    display: block;
    label {
      font-weight: 300;
    }
    span.text-danger {
      margin-top: 0;
      color: #F56C6C;
      font-size: 12px;
      display: block;
    }
    .inline-item {
      display: inline-flex;
      width: 300px !important;
    }
    .el-input__icon {
      position: unset;
      width: unset;
      margin-right: unset;
    }
    .el-input__wrapper {
      box-shadow: unset;
    }
  }
}
</style>
