<template lang="pug">
.today-availability-container
  .select-container
    aiq-select(placeholder="Select Teams"
              v-model="selectedTeams"
              @change="handleTeamsChange"
              value-key='id'
              multiple
              size="small"
              popper-class="custom-header"
              :reserve-keyword="false")
      aiq-option(v-for="team in allTeams"
                :key="team.id"
                :label="team.name"
                :value="team")
    aiq-select(placeholder="Select Agents"
              v-model="selectedAgents"
              @change="handleAgentsChange"
              value-key='id'
              multiple
              size="small"
              popper-class="custom-header"
              :reserve-keyword="false"
              :loading="agentsFilterLoading")
      aiq-option(v-for="agent in teamAgents"
                :key="agent.id"
                :label="agent.fullName"
                :value="agent")
  .no-chart(v-if="agentsFilterLoading")
    font-awesome-icon(size="3x"
                      icon="spinner"
                      spin)
  .timelines(v-else-if="selectedTeams.length")
    overview-chart(:keepChartOnly="true"
                  :chartConfig="chartConfig"
                  :preLoadedchartData="chartData"
                  visualization='aiq-timeline-chart')
  .no-chart(v-else)
    span {{ $t('pulse_tab.today_tab.availability_tab.no_selections') }}
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { AGENT_LIMIT, TEAM_LIMIT } from '@/constants/pagination';
import OverviewChart from '@/components/Chart.vue';
import { FIXED_AGENT_STATUS } from '@/constants';
import { increaseOpacity } from '@/libs';

const DEBOUNCE_TIME = 1000;

export default {
  name: 'PulseTodayAvailability',
  components: { OverviewChart },
  computed: {
    ...mapGetters({
      allTeams: 'teams/sortedTeams',
      teamAgents: 'agentsDashboard/agents',
    }),
    ...mapState({
      agentStatuses: state => state.settings.agentStatuses,
    }),
    displayedAgents() {
      return this.selectedAgents.length ? this.selectedAgents : this.teamAgents;
    },
    scaleMapping() {
      const domain = [];
      const range = [];
      for (const agentStatus of this.agentStatuses.statuses) {
        const statusColor = FIXED_AGENT_STATUS[agentStatus.status].color;
        const subStatusesLength = agentStatus.subStatuses.length;
        const opacityStep = subStatusesLength ? 1 / (subStatusesLength + 1) : 0;
        domain.push(agentStatus.label);
        range.push(statusColor);
        agentStatus.subStatuses.forEach((subStatus, idx) => {
          domain.push(`${agentStatus.label} - ${subStatus.label}`);
          range.push(increaseOpacity(statusColor, opacityStep * (idx + 1)));
        });
      }
      return { domain, range };
    },
    chartConfig() {
      return {
        title: 'Agent Status | Sub-status by Hour',
        name: 'agent_status',
        metric: 'timeslice',
        graphParams: {
          domain: this.scaleMapping.domain,
          range: this.scaleMapping.range,
          colorProperty: 'fullStatus',
          timeRange: {
            beginning: new Date().setHours(0, 0, 0, 0),
            ending: new Date().setHours(23, 59, 0, 0), // eslint-disable-line
          },
          layout: {
            row: {
              height: 20,
              margin: 5,
            },
            axis: {
              height: 20,
            },
            chartMargins: {
              top: 30,
              bottom: 30,
              right: 30,
              left: 100,
            },
          },
        },
      };
    },
  },
  props: {
    metrics: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedTeams: [],
      selectedAgents: [],
      agentsFilterLoading: false,
      getTeamAgents: null,
      chartData: [],
    };
  },
  created() {
    // Initialize debounced function with Promise support
    this.getTeamAgents = debounce((teams, resolve, reject) => {
      this.getAgents({
        params: {
          teams: teams.map(t => t.id),
          limit: AGENT_LIMIT,
        },
        update: true,
      })
        .then(resolve)
        .catch(reject);
    }, DEBOUNCE_TIME, { leading: false, trailing: true });
  },
  async mounted() {
    try {
      await this.getTeams([{ limit: TEAM_LIMIT }]);
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  },
  methods: {
    ...mapActions({
      getTeams: 'teams/getTeamsList',
      getAgents: 'agentsDashboard/getAgents',
    }),
    async handleTeamsChange(teams) {
      this.selectedTeams = teams;

      // Cancel previous debounce call
      if (this.getTeamAgents) {
        this.getTeamAgents.cancel();
      }

      this.selectedAgents = [];
      this.agentsFilterLoading = true;

      try {
        await new Promise((resolve, reject) => {
          // Pass resolve/reject to debounced function
          this.getTeamAgents(teams, resolve, reject);
        });
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      } finally {
        this.agentsFilterLoading = false;
        this.prepareChartData();
      }
    },
    handleAgentsChange(agents) {
      this.selectedAgents = agents;
      this.$nextTick(() => {
        this.prepareChartData();
      });
    },
    getAgentStatusDataset(metrics) {
      const metricsDataset = get(metrics, 'data.dataset', []);
      const agentStatusMetrics = metricsDataset.find(d => d.metric === 'agent_status');
      return get(agentStatusMetrics, 'dataset', []);
    },
    getSelectedAgentDataPoints(allAgentsDataset) {
      if (this.selectedAgents.length) {
        const selectedAgentIds = new Set(this.selectedAgents.map(agent => agent.id));
        return allAgentsDataset.filter(dataPoint => selectedAgentIds.has(dataPoint.agent_id));
      }
      const selectedTeamNames = new Set(this.selectedTeams.map(team => team.name));
      return allAgentsDataset.filter(dataPoint => dataPoint.teams.some(teamName => selectedTeamNames.has(teamName)));
    },
    getInflatedDataPoints(selectedAgentDataPoints) {
      const selectedAgentDPMap = new Map();
      selectedAgentDataPoints.forEach(dp => {
        selectedAgentDPMap.set(dp.agent_id, dp);
      });
      const inflatedDataPoints = [];
      const visibleAgents = this.selectedAgents.length ? this.selectedAgents : this.teamAgents;
      for (const agent of visibleAgents) {
        if (selectedAgentDPMap.has(agent.id)) {
          inflatedDataPoints.push(selectedAgentDPMap.get(agent.id));
        } else {
          inflatedDataPoints.push({ label: agent.fullName, agent_id: agent.id, data_points: [] });
        }
      }
      return inflatedDataPoints;
    },
    getFullStatus(status, subStatus) {
      let fullStatus = '';
      for (const agentStatus of this.agentStatuses.statuses) {
        if (status === agentStatus.status) {
          fullStatus = agentStatus.label;
          if (subStatus) {
            const agentSubstatus = agentStatus.subStatuses.find(s => s.status === subStatus);
            fullStatus += ` - ${agentSubstatus.label}`;
          }
        }
      }
      return fullStatus;
    },
    prepareChartData() {
      if (!this.metrics.lastFetchedAt || !this.selectedTeams.length || this.agentsFilterLoading) {
        return;
      }
      const allAgentsDataset = this.getAgentStatusDataset(this.metrics);
      const selectedAgentDataPoints = this.getSelectedAgentDataPoints(allAgentsDataset);
      const inflatedDataPoints = this.getInflatedDataPoints(selectedAgentDataPoints);
      this.chartData = inflatedDataPoints.map(agent => {
        const times = agent.data_points.map((dataPoint, i, allDataPoints) => ({
          starting_time: new Date(dataPoint.ts),
          ending_time: allDataPoints[i + 1] ? new Date(allDataPoints[i + 1].ts) : new Date(),
          status: dataPoint.status,
          substatus: dataPoint.substatus,
          fullStatus: this.getFullStatus(dataPoint.status, dataPoint.substatus),
        }));

        return {
          label: agent.label,
          times,
          tooltipData: this.getTooltipData(times),
        };
      });
    },
    getTooltipData(times) {
      const tooltipData = { items: [], total: {}};
      const timeAggregates = {};
      let totalTime = 0;
      const { domain, range } = this.scaleMapping;
      for (const slot of times) {
        const { fullStatus, starting_time, ending_time } = slot;
        const durationSeconds = (ending_time - starting_time) / 1000; // eslint-disable-line
        timeAggregates[fullStatus] = timeAggregates[fullStatus] ? timeAggregates[fullStatus] + durationSeconds : durationSeconds;
      }
      for (let i = 0 ; i < domain.length ; i++) {
        const durationSeconds = timeAggregates[domain[i]];
        totalTime += durationSeconds;
        tooltipData.items.push({
          status: domain[i],
          color: range[i],
          duration: this.$aiq.formatters.timeDelta(durationSeconds),
        });
      }
      tooltipData.total = { label: 'Total', totalTime };
      return tooltipData;
    },
  },
  beforeUnmount() {
    if (this.getTeamAgents) {
      this.getTeamAgents.cancel();
    }
  },
  watch: {
    metrics: {
      handler() {
        this.prepareChartData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/aiq-mixins.scss";
@import "@/styles/aiq-variables.scss";

.today-availability-container {
  .select-container {
    display: flex;
    column-gap: 20px;
  }

  .timelines {
    @include scrollable(calc(100vh - 270px));
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
  }

  .no-chart {
    height: calc(100vh - 270px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>