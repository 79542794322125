import axios from 'axios';
import get from 'lodash/get';
import { Events } from './events';
import { alphabetSort, isCaseInsensitiveEqual } from './shared';
import { isPublicItem } from './commands';
import {
  AGENT_STATUS,
  FIXED_AGENT_STATUS,
} from '@/constants';
import { mapformToTriggerEndpoint } from '.';
import { getUrl } from '@/config/api.routes';
import { CMD_ARG_TYPE } from '@/constants/iqtool';

const mapper = e => {
  const { id, name } = e;
  return { id, name };
};

const argHandlers = {
  [CMD_ARG_TYPE.MULTI_SELECT]: {
    hasArguments: true,
    toEntry: params => {
      const args = params.map(id => ({ id }));

      return { arguments: args };
    },
    toComponentParams: params => {
      if (params.arguments) {
        return params.arguments.map(arg => arg.id);
      }

      return [];
    },
  },
  [CMD_ARG_TYPE.SINGLE_SELECT]: {
    hasArguments: true,
    toEntry: name => {
      // For single select, the field name is fixed as 'name' for now.
      // The desirable state is to make the field name flexible.
      return { arguments: { name } };
    },
    toComponentParams: params => {
      if (params.arguments) {
        return get(params, 'arguments.name', null);
      }

      return null;
    },
  },
  [CMD_ARG_TYPE.BOOL_SELECT]: {
    hasArguments: true,
    toEntry: bool => ({ arguments: bool }),
    toComponentParams: params => params.arguments,
  },
  [CMD_ARG_TYPE.NONE]: {
    hasArguments: false,
    toEntry: params => params,
    toComponentParams: params => params,
  },

  [CMD_ARG_TYPE.OBJ_CONSTRUCT]: {
    hasArguments: true,
    toEntry: obj => (obj),
    toComponentParams: params => params.id,
  },

  // Currently unused
  [CMD_ARG_TYPE.TEXT_INPUT]: {
    hasArguments: true,
    toEntry: params => params,
    toComponentParams: params => params,
  },

  [CMD_ARG_TYPE.MULTI_KEYWORD]: {
    hasArguments: true,
    toEntry: obj => obj,
    toComponentParams: params => params,
  },
};


const syncEntities = {
  systemName: 'conversations.entities.store',
  chatName: '/store_entities',
  iqtoolsName: '/store_entities',
  prompt: 'Store Entity',
  description: 'Store entities to customer profile',
  paramsType: CMD_ARG_TYPE.OBJ_CONSTRUCT,
  getParamsList: () => [],
  run: null,
  ...argHandlers[CMD_ARG_TYPE.OBJ_CONSTRUCT],
};

const addNote = {
  systemName: 'conversations.note.attach',
  chatName: '/note',
  iqtoolsName: null,

  prompt: 'Note',
  description: 'Adds note to conversation',
  paramsType: CMD_ARG_TYPE.TEXT_INPUT,

  getParamsList: () => [],
  run: (store, noteText) => {
    const { id } = store.getters['conversations/selected'];
    return store.dispatch('conversations/addNote', [id, noteText])
      .then(() => ({ response: `Note was added to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.TEXT_INPUT],
};

const assignAgent = {
  systemName: 'conversations.agents.attach',
  chatName: '/assign',
  iqtoolsName: '/assign_agent',

  prompt: 'Agents list',
  description: 'Adds agent to conversation',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => {
    const { agents = [] } = store.getters['conversations/selected'];

    return store.getters['agents/sortedList'].map(e => {
      const { id, fullName } = e;
      return { id, name: fullName };
    }).filter(e => !agents.find(j => e.id === j.id));
  },
  run: (store, agentName) => {
    const { id, agents } = store.getters['conversations/selected'];
    const allAgents = store.state.agents.list;
    const agent = allAgents.find(a => isCaseInsensitiveEqual(a.fullName, agentName));

    return store.dispatch('conversations/updateAgents',
      [id, [...agents.map(e => e.id), agent.id]])
      .then(() => ({ response: `${agent.fullName} was assigned to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const assignPrimaryAgent = {
  systemName: 'conversations.agents.attachprimary',
  chatName: '/assign_primary_agent',
  iqtoolsName: '/assign_primary_agent',

  prompt: 'Add Primary Agent',
  description: 'Adds primary agent to conversation',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => {
    const { id } = store.getters['conversations/selected'];
    const payload = { conversation: { id } };
    return store.dispatch('conversations/assignPrimaryAgent', [id, payload])
      .then(() => ({ response: 'Primary agent was assigned to conversation.' }));
  },
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const lockToPrimaryAgent = {
  systemName: 'conversations.agents.lockprimary',
  chatName: '/lock_primary_agent',
  iqtoolsName: '/lock_primary_agent',

  prompt: 'Lock Conversation To Primary Agent',
  description: 'Locks primary agent to conversation',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => {
    const { id } = store.getters['conversations/selected'];
    const payload = { conversation: { id } };
    return store.dispatch('conversations/lockToPrimaryAgent', [id, payload])
      .then(() => ({ response: 'Conversation locked to Primary Agent' }));
  },
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const assignTeam = {
  systemName: 'conversations.teams.attach',
  chatName: '/team',
  iqtoolsName: '/assign_team',

  prompt: 'Teams list',
  description: 'Assign team to conversation',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList(store) {
    const { teams = [] } = store.getters['conversations/selected'];

    return store.getters['teams/sortedTeams']
      .map(mapper)
      .filter(e => !teams.find(j => j.id === e.id));
  },
  run(store, teamName) {
    const { id, teams } = store.getters['conversations/selected'];
    const team = store.state.teams.teams.find(t => isCaseInsensitiveEqual(t.name, teamName));

    return store.dispatch('conversations/updateTeams',
      [id, [...teams.map(e => e.id), team.id]])
      .then(() => ({ response: `${team.name} was assigned to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const unassignTeam = {
  systemName: 'conversations.teams.detach',
  chatName: '/unteam',
  iqtoolsName: '/unassign_team',

  prompt: 'Teams list',
  description: 'Assign team to conversation',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList(store) {
    const { teams = [] } = store.getters['conversations/selected'];

    return store.getters['teams/sortedTeams']
      .map(mapper)
      .filter(e => !teams.find(j => j.id === e.id));
  },
  run(store, teamName) {
    const { id, teams } = store.getters['conversations/selected'];
    const team = store.state.teams.teams.find(t => isCaseInsensitiveEqual(t.name, teamName));

    return store.dispatch('conversations/updateTeams',
      [id, [...teams.map(e => e.id), team.id]])
      .then(() => ({ response: `${team.name} was unassigned to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const attachTeamAgent = {
  systemName: 'conversations.teams.attachagent',
  chatName: '/team_agent',
  iqtoolsName: '/assign_team_agent',

  prompt: 'Teams list',
  description: 'Assign team agent to conversation',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList(store) {
    const { teams = [] } = store.getters['conversations/selected'];

    return store.getters['teams/sortedTeams']
      .map(mapper)
      .filter(e => !teams.find(j => j.id === e.id));
  },
  run(store, teamName) {
    const { id, teams } = store.getters['conversations/selected'];
    const team = store.state.teams.teams.find(t => isCaseInsensitiveEqual(t.name, teamName));

    return store.dispatch('conversations/updateTeams',
      [id, [...teams.map(e => e.id), team.id]])
      .then(() => ({ response: `${team.name} was unassigned to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const bot = {
  systemName: 'bot.pass',
  chatName: '/bot',
  iqtoolsName: '/bot',

  prompt: 'Bot',
  description: 'Activates/Deactivates bot',
  paramsType: CMD_ARG_TYPE.SINGLE_SELECT,
  getParamsList: () => [{ id: false, name: 'Off' }],

  run: store => store.dispatch('conversations/botOn', store.getters['conversations/selected'].id)
    .then(() => ({ response: 'Bot was turned on.' })),
  ...argHandlers[CMD_ARG_TYPE.SINGLE_SELECT],
  toEntry: () => ({ arguments: false }),
  toComponentParams: () => ({id: false, name: 'Off' }),
};

const categorize = {
  systemName: 'conversations.categories.attach',
  chatName: '/category',
  iqtoolsName: '/categorize',

  description: 'Assign category to conversation',
  prompt: 'Categories list',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => {
    const { categories = [] } = store.getters['conversations/selected'];
    return store.getters['categories/categories']
      .map(mapper)
      .filter(e => !categories.find(j => j.id === e.id))
      .sort(alphabetSort);
  },
  run(store, categoryName) {
    const { id, categories } = store.getters['conversations/selected'];
    const allCategories = store.state.categories.categories;
    const category = allCategories.find(c => isCaseInsensitiveEqual(c.name, categoryName));
    return store.dispatch('conversations/updateCategories',
      [id, [...categories.map(e => e.id), category.id]])
      .then(() => ({ response: `${category.name} category was added to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const complete = {
  systemName: 'conversations.close',
  chatName: '/complete',
  iqtoolsName: '/complete',

  prompt: 'Complete',
  description: 'Сomplete conversation',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => new Promise(resolve => {
    resolve(Events.emit('complete.conversation'));
  }),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const disableConversation = {
  systemName: 'conversations.disable',
  chatName: '/disable_input_box',
  iqtoolsName: '/disable_input_box',

  prompt: '',
  description: '',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: null,
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const enableConversation = {
  systemName: 'conversations.enable',
  chatName: '/enable_input_box',
  iqtoolsName: '/enable_input_box',

  prompt: '',
  description: '',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: null,
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

// The following command is deprecated, it does not have corresponding
// definition in Base-Api and hence cannot be executed as action for IQtools
// it has therefore been hidden in dashboard_configs
const form = {
  systemName: 'conversations.form.trigger',
  chatName: '/trigger_form',
  iqtoolsName: null,

  prompt: 'Send survey form to the customer',
  description: 'Send survey form to the customer',
  paramsType: CMD_ARG_TYPE.NONE,
  getParamsList: () => [],
  run: (store) => axios.post(mapformToTriggerEndpoint(store))
    .then(() => ({ response: 'feedback form was triggered.' })),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const lock = {
  systemName: 'conversations.lock',
  chatName: '/lock',
  iqtoolsName: null,

  prompt: 'Lock',
  description: 'Locks conversation',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => {
    const availability = store.getters['agent/available'];
    return store.dispatch('conversations/lockConversation', store.getters['conversations/selected'].id)
      .then(() => {
        if (availability === AGENT_STATUS.Away.field) {
          if (store.state.featureFlags.FEATURE_FLAGS.SETTINGS_AGENT_STATUS) {
            store.dispatch('agent/changeAgentStatus', {
              value: FIXED_AGENT_STATUS.available.value,
              status: FIXED_AGENT_STATUS.available.subStatus.none,
            });
          } else {
            store.dispatch('agent/changeAvailability', AGENT_STATUS.Available.field);
          }
        }

        return { response: 'Conversation was locked by you.' };
      });
  },
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const endDialog = {
  systemName: 'dialogs.end',
  chatName: null,
  iqtoolsName: '/end_dialog',

  prompt: 'End Dialog',
  description: 'Ends dialog',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const endWorkflow = {
  systemName: 'workflows.end',
  chatName: null,
  iqtoolsName: '/end_workflow',

  prompt: 'End workflow',
  description: 'Ends workflow',
  paramsType: CMD_ARG_TYPE.NONE,
  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const addBackupAgent = {
  systemName: 'conversations.queuing.backup',
  chatName: '/queue_conversation_backup',
  iqtoolsName: '/queue_conversation_backup',

  prompt: 'Add Backup Agent',
  description: 'Queue conversation for a backup agent to be added',
  paramsType: CMD_ARG_TYPE.NONE,
  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const queueConversation = {
  systemName: 'conversations.queue',
  chatName: '/queue_conversation',
  iqtoolsName: '/queue_conversation',

  prompt: 'Queue',
  description: 'Queue conversation',
  paramsType: CMD_ARG_TYPE.BOOL_SELECT,

  getParamsList: () => ['Queue', 'Dequeue'],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.BOOL_SELECT],
};

const runDialog = {
  systemName: 'bot.dialog.init',
  chatName: '/dialog',
  iqtoolsName: null,

  description: 'Run a selected dialogue in a conversation.',
  prompt: 'Dialogs list',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => store.state.dialogs.dialogs
    .map(mapper)
    .filter(isPublicItem)
    .sort(alphabetSort),
  run: (store, dialogName) => {
    const allDialogs = store.state.dialogs.dialogs;
    const dialog = allDialogs.find(d => isCaseInsensitiveEqual(d.name, dialogName));

    return store
      .dispatch('conversations/runDialog', [store.getters['conversations/selected'].id, dialog])
      .then(() => ({ response: `Running ${dialog.name} dialog.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const runWorkflow = {
  systemName: 'bot.workflow.init',
  chatName: '/workflow',
  iqtoolsName: null,

  description: 'Run a selected workflow on conversation.',
  prompt: 'Workflows',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => (store.state.workflows.workflows
    .filter(item => item.payload.triggerable_by_agent)
    .map(({ id, name, payload }) => ({
      id,
      name: payload.display_name || name,
      workflow_name: name,
      description: payload.description,
      button: {
        label: 'Example',
        event: 'simulate_workflow',
      },
    }))
    .sort(alphabetSort)),
  run: (store, workflowDisplayName) => {
    const allWorkflows = store.state.workflows.workflows;
    const workflow =
      allWorkflows.find(w => [w.payload.display_name, w.name].includes(workflowDisplayName));

    return store
      .dispatch('conversations/runWorkflow', [store.getters['conversations/selected'].id, workflow])
      .then(() => ({ response: `Running ${workflow.name} workflow.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const schedule = {
  // command name not set up with Base-API yet
  systemName: 'conversations.schedule',
  chatName: '/schedule',
  iqtoolsName: null,

  description: '',
  prompt: '',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => new Promise(resolve => {
    resolve(Events.emit('schedule.message.on.conversation'));
  }),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const sendAgentList = {
  systemName: 'conversations.messages.agentlist',
  chatName: '/send_agent_list',
  iqtoolsName: '/send_agent_list',

  prompt: 'Send Agent List',
  description: 'Send agent selection list',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => {
    const { id } = store.getters['conversations/selected'];
    return axios
      .get(`${getUrl('conversations.conversations', id)}/agentlist`)
      .then(() => ({ response: `Agent carousel was sent to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const sendAssets = {
  systemName: 'conversations.assets.send',
  chatName: null,
  iqtoolsName: '/send_assets',

  prompt: '',
  description: 'Send assets',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => {
    const { assets } = store.state.assets;

    return assets.map(a => ({ id: a.id, name: a.title }));
  },
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const sendDocuments = {
  systemName: 'conversations.documents.send',
  chatName: null,
  iqtoolsName: '/send_documents',

  prompt: '',
  description: 'Send documents',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => {
    const { documents } = store.state.documents;

    return documents.map(a => ({ id: a.id, name: a.name }));
  },
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const sendDmCardLink = {
  systemName: 'conversations.dmcard.send',
  chatName: '/send_dm_card',
  iqtoolsName: '/send_dm_card',

  prompt: 'Send DM',
  description: 'Send DM card',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => {
    if (!store.getters['configs/twitterInstalled']) {
      return;
    }
    const { id } = store.getters['conversations/selected'];
    return store.dispatch('conversations/sendDmDeepLink', [id])
      .then(() => ({ response: `DM Card was sent to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const setConversationResolved = {
  systemName: 'conversations.system.resolved.ai',
  chatName: '/set_conversation_resolved_by_ai',
  iqtoolsName: '/set_conversation_resolved_by_ai',

  prompt: 'Resolve Conversation',
  description: 'Set conversation as resolved by AI',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const setConversationUnresolved = {
  systemName: 'conversations.system.unresolved.ai',
  chatName: '/set_conversation_unresolved_by_ai',
  iqtoolsName: '/set_conversation_unresolved_by_ai',

  prompt: 'Unresolve Conversation',
  description: 'Set conversation as unresolved by AI',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const star = {
  // command name not set up with Base-API yet
  systemName: 'conversations.star',
  chatName: '/star',
  iqtoolsName: null,

  prompt: 'Star',
  description: 'Adds conversation to watchlist',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => store.dispatch('conversations/starConversation',
    [store.getters['conversations/selected'].id, true])
    .then(() => ({ response: 'Conversation was added to your watchlist.' })),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const tag = {
  systemName: 'conversations.tags.attach',
  chatName: '/tag',
  iqtoolsName: '/tag',

  prompt: 'Tags list',
  description: 'Assign tag to conversation',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => {
    const { tags = [] } = store.getters['conversations/selected'];

    return store.getters['tags/tags']
      .map(mapper)
      .filter(e => !tags.find(j => j.id === e.id))
      .sort(alphabetSort);
  },
  run: (store, tagName) => {
    const { id, tags } = store.getters['conversations/selected'];

    const allTags = store.state.tags.tags;
    const tagItem = allTags.find(t => isCaseInsensitiveEqual(t.name, tagName));

    return store.dispatch('conversations/updateTags',
      [id, [...tags.map(e => e.id), tagItem.id]])
      .then(() => ({ response: `Applied tag ${tagItem.name} to conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const triggerCsatForm = {
  systemName: 'conversations.form.csat',
  chatName: '/trigger_csat_form',
  iqtoolsName: '/trigger_csat_form',

  prompt: 'Trigger CSAT Form',
  description: 'Trigger CSAT form',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const triggerEmailForm = {
  systemName: 'conversations.form.email',
  chatName: '/trigger_email_form',
  iqtoolsName: '/trigger_email_form',

  prompt: 'Trigger Email Form',
  description: 'Trigger email form',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const triggerNPSForm = {
  systemName: 'conversations.form.nps',
  chatName: '/trigger_nps_form',
  iqtoolsName: '/trigger_nps_form',

  description: 'Trigger NPS Form',
  prompt: 'Trigger nps form',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: () => {},
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

// The following is used by BuildingKit for Commands and by Agents as slash commands
const triggerPostchatForm = {
  systemName: 'conversations.form.postchat',
  chatName: '/form',
  iqtoolsName: '/trigger_postchat_form',

  prompt: 'Send survey form to the customer',
  description: 'Trigger configured postchat form',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: (store) => axios.post(getUrl('postChatSurveyTrigger'),
    { conversation: { id: store.getters['conversations/selected'].id } })
    .then(() => ({ response: 'feedback form was triggered.' })),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const unassignAgent = {
  systemName: 'conversations.agents.detach',
  chatName: '/unassign',
  iqtoolsName: null,
  prompt: 'Assigned agents list',
  description: 'Removes agent from conversation',
  paramsType: CMD_ARG_TYPE.MULTI_SELECT,

  getParamsList: store => {
    const { agents = [] } = store.getters['conversations/selected'];

    return agents
      .map(e => {
        const { id, fullName } = e;
        return { id, name: fullName };
      }).sort(alphabetSort);
  },
  run: (store, agentName) => {
    const { id, agents } = store.getters['conversations/selected'];
    const allAgents = store.state.agents.list;
    const agent = allAgents.find(a => isCaseInsensitiveEqual(a.fullName, agentName));

    return store.dispatch('conversations/updateAgents',
      [id, agents.map(e => e.id).filter(e => e !== agent.id)])
      .then(() => ({ response: `${agent.fullName} was unassigned from conversation ${id}.` }));
  },
  ...argHandlers[CMD_ARG_TYPE.MULTI_SELECT],
};

const unlock = {
  systemName: 'conversations.unlock',
  chatName: '/unlock',
  iqtoolsName: null,

  prompt: 'Unlock',
  description: 'Unlocks conversation',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => store.dispatch('conversations/unlockConversation',
    store.getters['conversations/selected'].id)
    .then(() => ({ response: 'Conversation was unlocked.' })),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const unstar = {
  // command name not set up with Base-API yet
  systemName: 'conversations.unstar',
  chatName: '/unstar',
  iqtoolsName: null,

  prompt: 'Unstar',
  description: 'Removes conversation from watchlist',
  paramsType: CMD_ARG_TYPE.NONE,

  getParamsList: () => [],
  run: store => store.dispatch('conversations/starConversation',
    [store.getters['conversations/selected'].id, false])
    .then(() => ({ response: 'Conversation was removed from your watchlist.' })),
  ...argHandlers[CMD_ARG_TYPE.NONE],
};

const attachSegments = {
  systemName: 'customers.segments.attach',
  chatName: '/assign_segment',
  iqtoolsName: '/assign_segment',
  prompt: 'Assign Segment',
  description: 'Assign customer seggments',
  paramsType: CMD_ARG_TYPE.MULTI_KEYWORD,
  getParamsList: () => [],
  run: null,
  ...argHandlers[CMD_ARG_TYPE.MULTI_KEYWORD],
};

const detachSegments = {
  systemName: 'customers.segments.detach',
  chatName: '/unassign_segment',
  iqtoolsName: '/unassign_segment',
  prompt: 'Unassign Segment',
  description: 'Unassign customer seggments',
  paramsType: CMD_ARG_TYPE.MULTI_KEYWORD,
  getParamsList: () => [],
  run: null,
  ...argHandlers[CMD_ARG_TYPE.MULTI_KEYWORD],
};

const sendAgentEmail = {
  systemName: 'conversations.notification.email',
  chatName: '/email_notification',
  iqtoolsName: '/email_notification',
  prompt: 'Email Agent',
  description: 'Email agent',
  paramsType: CMD_ARG_TYPE.SINGLE_SELECT,
  getParamsList: store => {
    const val = store.getters['buildingKit/emailTemplateNames'].map(name => ({ id: name, name }));
    return val;
  },
  run: null,
  ...argHandlers[CMD_ARG_TYPE.SINGLE_SELECT],
};

const commandDefinitions = [
  addNote,
  assignAgent,
  assignPrimaryAgent,
  assignTeam,
  attachSegments,
  attachTeamAgent,
  bot,
  categorize,
  complete,
  detachSegments,
  disableConversation,
  enableConversation,
  endDialog,
  endWorkflow,
  form,
  lock,
  lockToPrimaryAgent,
  addBackupAgent,
  queueConversation,
  runDialog,
  runWorkflow,
  schedule,
  sendAgentList,
  sendAssets,
  sendDocuments,
  sendDmCardLink,
  setConversationResolved,
  setConversationUnresolved,
  star,
  syncEntities,
  tag,
  triggerCsatForm,
  triggerEmailForm,
  triggerNPSForm,
  triggerPostchatForm,
  unassignAgent,
  unassignTeam,
  unlock,
  unstar,
  sendAgentEmail,
];

const commandsBySystemName = Object.values(commandDefinitions).reduce((acc, c, i) => {
  acc[c.systemName] = c;
  acc[c.systemName].id = i;
  return acc;
}, {});


export default commandsBySystemName;
