<template lang="pug">
  .permission_item
    aiq-switch.permission_item-option(active-text="",
                                      inactive-text="",
                                      style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                                      @change="save",
                                      v-model="localConfig.allow")
</template>

<script>
import capitalize from 'lodash/capitalize';

export default {
  data() {
    return {
      localConfig: {},
    };
  },
  emits: ['request'],
  watch: {
    config() {
      this.localConfig = this.config;
    },
  },
  mounted() {
    this.localConfig = this.config;
  },
  props: {
    config: {
      required: true,
      type: Object,
    },
  },
  methods: {
    reset() {
      this.localConfig.allow = !this.localConfig.allow;
    },

    save() {
      const {
        allow, permission, resource, role,
      } = this.localConfig;
      const action = allow ? 'grant' : 'revoke';
      this.$aiq.confirm(
        'Save?',
        `Are you sure you want to ${action} permission to ${permission} <b>${resource}</b> for the ${capitalize(role)} role?`,
      ).then(confirm => {
        if (confirm) {
          const config = {
            resource_name: resource,
            permission,
            allow,
          };

          this.$emit('request', { role, config });
        }
      }, () => {
        this.reset();
      });
    },
  },
};
</script>
