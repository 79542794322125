<template lang="pug">
.content-inner
  .header
    h1.title {{$t('settings_tab.admin_tab.tab_nav.emails_tab')}}
  aiq-collapse.scrollbar__collapse(accordion @change="onCancel")
    aiq-collapse-item(:title="$t('settings_tab.admin_tab.email_tab.email_variables')")
      aiq-table(:data="localForm.template_variables")
        aiq-table-column(label="Name"
                        label-class-name="is-bordered"
                        min-width="50"
                        prop="key"
                        :sortable="false")
        aiq-table-column(label="Description"
                        label-class-name="is-bordered"
                        min-width="100"
                        prop="description"
                        :sortable="true")
        aiq-table-column(label="Value"
                        label-class-name="is-bordered"
                        min-width="50")
          template(v-slot:default="scope")
            aiq-input(:disabled="scope.row.type === 'reserved'",
                      v-model="scope.row.value")
        aiq-table-column(label="Usage"
                        label-class-name="is-bordered"
                        min-width="50")
          template(v-slot:default="scope")
            label(v-html="$filters.sanitize(`#{${scope.row.key}}`)")

      .content-footer
        aiq-button(@click="onCancel") Cancel
        aiq-button(type="primary" @click="onSave" :disabled="!isTemplateVariableChanged") Save
    aiq-collapse-item(v-for="item in localForm.notifications" :title="getLabel(item.type)" :key="item.type" :ref="item.type")
      .content-body-group
        div.notification-wrapper
          span(v-html="$t(`${BASE_TRANSLATION_PATH}.email_description`, { notificationTimeType : returnNotificationTypeLabel(item.type, false) })")
          div
            span {{ $t(`${BASE_TRANSLATION_PATH}.email_increment`) + " " + $t(`${BASE_TRANSLATION_PATH}.minutes`)  }}:
            aiq-input.margin-left.digit-input(:model-value="(item.interval/60)" @update:modelValue="updateNotificationInterval($event, item)")
          div
            span {{ returnNotificationTypeLabel(item.type, true) }}:
            aiq-input.digit-input(v-model.number="item.timeAfter")
        aiq-row
          label Enabled
          aiq-switch.left-margin(v-model="item.enabled")
        aiq-row(v-if="item.enabled")
          span Send
          aiq-select(v-model="item.send_when" :suffix-icon="Icon.CaretBottom")
            aiq-option(v-for="option in notificationOptions"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value")
        aiq-row(v-if="item.enabled && shouldShowField(item.type, [NOTIFICATION_TYPES.WAITING_MESSAGE_ALERT])")
          span Teams Assignment
          aiq-button(type="success" size="small" @click="item.segment_teams[''] = []")
            i.iq-ico-add
          aiq-table.teams-assigment(:data="Object.keys(item.segment_teams)")
            aiq-table-column(label="Segment")
              template(v-slot:default="scope")
                aiq-select(placeholder="Segment"
                        size="small"
                        :suffix-icon="Icon.CaretBottom"
                        :model-value="scope.row"
                        @change="onSegmentChange(item, scope.row, $event)")
                  aiq-option(v-for="item in segments", :key="item", :label="item", :value="item")
            aiq-table-column(label="Teams")
              template(v-slot:default="scope")
                aiq-select(placeholder="Teams"
                           size="small"
                           :suffix-icon="Icon.CaretBottom"
                           multiple
                           :reserve-keyword="false"
                           v-model="item.segment_teams[scope.row]")
                  aiq-option(v-for="item in sortedTeams", :key="item.id", :label="item.name", :value="item.id")
            aiq-table-column(label="Remove", align="center", width="80px")
              template(v-slot:default="scope")
                aiq-button(type="danger" size="small" @click="delete item.segment_teams[scope.row]")
                  i.iq-ico-close

        aiq-row
          .content(v-if="item.enabled")
            aiq-row
              label Title
              aiq-input(v-model="item.subject")
            aiq-row
              label Sender Email
              aiq-input(v-model="item.from")
            aiq-row
              .editor
                label Content
                vue-editor(v-model="item.html" :editorToolbar="toolbar")

      .content-footer
        aiq-button(@click="onCancel") Cancel
        aiq-button(type="primary" @click="onSave" :disabled="!isChanged(item)") Save

    aiq-collapse-item(title="Email Templates")
      EmailTemplates(:templates="agentNotification.email_templates"
                     @update="onSaveEmailTemplate")

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { cloneDeep, isEqual, isEmpty } from 'lodash';
import { VueEditor } from 'vue3-editor';
import { EMAIL_EDITOR_CONFIG } from '@/constants/settings';
import EmailTemplates from './EmailTemplates.vue';

const NOTIFICATION_TYPES = {
  MESSAGE_ALERT: 'message-alert',
  AGENT_ATTACHMENT: 'agent-attachment',
  WAITING_MESSAGE_ALERT: 'waiting-message-alert',
};

export default {
  name: 'notification',
  components: {
    VueEditor,
    EmailTemplates,
  },
  data() {
    return {
      BASE_TRANSLATION_PATH: 'settings_tab.email_tab',
      localForm: {
        template_variables: [],
        notifications: [],
        email_templates: [],
      },
      NOTIFICATION_TYPES,
      labelMapping: {
        [NOTIFICATION_TYPES.MESSAGE_ALERT]: 'Email - Message Received',
        [NOTIFICATION_TYPES.AGENT_ATTACHMENT]: 'Email - Agent Assignment',
        [NOTIFICATION_TYPES.WAITING_MESSAGE_ALERT]: 'Email - Waiting Response',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch('configs/getAgentNotificationConfig');
    await this.$store.dispatch('configs/getCustomerSegments');
  },
  computed: {
    ...mapState({
      agentNotification: state => state.configs.agentNotification,
    }),
    ...mapGetters({
      segments: 'configs/segmentNames',
      sortedTeams: 'teams/sortedTeams',
    }),
    avalaibleSegments() {
      return selectedSegments => this.segments.filter(s => !selectedSegments.includes(s));
    },
    isTemplateVariableChanged() {
      return !isEqual(this.localForm.template_variables, this.agentNotification.template_variables);
    },
    notificationOptions() {
      return [
        { label: 'when agent is away', value: 'agent_is_away' },
        { label: 'regardless of agent\'s availability', value: 'always' }];
    },
    toolbar() {
      return EMAIL_EDITOR_CONFIG;
    },
  },
  methods: {
    returnNotificationTypeLabel(type, shouldShowTime) {
      const messageType = {
        'waiting-message-alert': 'no_response',
        'agent-attachment': 'agent_assigned',
        'message-alert': 'message_received',
      };
      const label = [this.$t(`${this.BASE_TRANSLATION_PATH}.${messageType[type]}`)];
      if (shouldShowTime) label.push(this.$t(`${this.BASE_TRANSLATION_PATH}.seconds`));

      return label.join(' ');
    },
    cloneLocalForm(newVal) {
      this.localForm = cloneDeep(newVal);
      for (const item of this.localForm.notifications) {
        item.html = this.$filters.sanitize(item.html);
      }
    },
    async onSave() {
      try {
        this.runValidations();
        await this.$store.dispatch('configs/updateAgentNotificationConfig', this.localForm);
        this.$aiq.notify.success('Saved');
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },
    async onCancel() {
      this.cloneLocalForm(this.agentNotification);
    },
    updateNotificationInterval(value, notificationItem) {
      const updatedNotificationItemIndex = this.localForm.notifications.findIndex(item => item.type === notificationItem.type);
      // The endpoint expects the interval in seconds, but we display it to the user in minutes.
      // Therefore, convert minutes to seconds here.
      // eslint-disable-next-line no-magic-numbers
      this.localForm.notifications[updatedNotificationItemIndex].interval = value * 60;
    },
    getLabel(type) {
      return this.labelMapping[type];
    },
    shouldShowField(type, types) {
      return types.includes(type);
    },
    isChanged(item) {
      const orignal = this.agentNotification.notifications.find(n => n.type === item.type);
      return !isEqual(item, orignal);
    },
    runValidations() {
      if (this.$refs[NOTIFICATION_TYPES.WAITING_MESSAGE_ALERT][0].isActive) {
        const { enabled, segment_teams } =
          this.localForm.notifications.find(x => x.type === NOTIFICATION_TYPES.WAITING_MESSAGE_ALERT);
        const isEmptyEntry =
        Object.keys(segment_teams).some(key => !key || !segment_teams[key].length);
        if (enabled && (isEmpty(segment_teams) || isEmptyEntry)) {
          throw new Error('Segment or teams field should not be empty');
        }
      }
    },
    onSegmentChange(item, oldValue, newValue) {
      const new_segment_teams = Object.keys(item.segment_teams)
        .reduce((acc, key) => ({
          ...acc,
          [key === oldValue ? newValue : key]: cloneDeep(item.segment_teams[key]),
        }), {});
      item['segment_teams'] = new_segment_teams;
    },
    onSaveEmailTemplate(templates) {
      this.localForm.email_templates = templates;
      return this.onSave();
    },
  },
  watch: {
    agentNotification: {
      handler(newVal) {
        this.cloneLocalForm(newVal);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
@import "../../../../../styles/aiq-extensions.scss";

:deep(.teams-assigment) {
  .el-table__body-wrapper {
    height: auto !important;
    padding-bottom: 20px;
  }
}

.scrollbar__collapse {
  @include scrollable(calc(100vh - 204px));
}

.left-margin {
  margin-left: 30px;
}

.content-footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.digit-input {
  width: 60px;
  margin-left: 5px;
}

.sending-options {
  margin-left: 10px;
  display: inline-block;
}

.el-row {
  margin-bottom: 10px;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
}
.inline-intro{
  display: inline-block;
  margin-left:0.25rem;
  padding-bottom: 0rem;
}
.notification-wrapper{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 15px;
}
</style>
