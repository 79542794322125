<template lang="pug">
aiq-table.scrollbar(ref="complianceTable"
                  :data="records"
                  :default-sort="sortOrder")
  aiq-table-column(:label="$t(`auditing_tab.header.name`)", prop="name", :sortable="true", width="300" label-class-name="is-bordered")
    template(v-slot:default="scope")
      aiq-button(link type="primary" size="small", @click="openPopup(scope.row)")
        | {{ $t(`auditing_tab.table.${scope.row.name}.title`) }}
  aiq-table-column(:label="$t(`auditing_tab.header.description`)", :sortable="true", label-class-name="is-bordered")
    template(v-slot:default="scope")
      div {{ $t(`auditing_tab.table.${scope.row.name}.description`) }}

ExportPanel(v-if="isOpen"
            title="Download Audit"
            translation-alias="auditing_tab"
            :compliance="selectedCompliance"
            :file-extensions="allowedFileExtensions"
            :agents-related-report="[]"
            @close="onClosePopup"
            @commitAction="commitAction"
            )
</template>

<script>
import { mapState } from 'vuex';
import { ALLOWED_FILE_EXTENSIONS } from '@/constants';
import ComplianceMixin from './Compliance.mixin';

export default {
  name: 'auditing',
  mixins: [ComplianceMixin],
  data() {
    return {
      type: 'audit',
      namespace: 'auditing_tab',
    };
  },
  computed: {
    ...mapState({
      showCustomerAudit: state => state.featureFlags.FEATURE_FLAGS.AUDITING_TAB_CUSTOMERS,
    }),
    records() {
      return [
        {
          name: 'quick_responses',
          useTimeRange: true,
        },
        ...(this.showCustomerAudit ?
          [{
            name: 'customers',
            useTimeRange: true,
          }] : []),
        {
          name: 'agents',
          useTimeRange: true,
        },
        {
          name: 'dialogs',
          useTimeRange: true,
        },
        {
          name: 'workflows',
          useTimeRange: true,
        },
      ];
    },
    allowedFileExtensions() {
      return [ALLOWED_FILE_EXTENSIONS.CSV];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/aiq-extensions.scss";
.content-inner {
  @extend %settings-content-inner;
  .header {
    margin-bottom: 24px;
  }
}
*:focus {
  border-color: transparent;
  background: unset;
  color: #409eff;
}
</style>
