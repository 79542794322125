import { mapState } from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import { randomString } from '@/libs/stringUtils';


// Do we need to support mulitple types of blur filtering?
// Changing maskBlurRadius and blurFilterRadius value would
// change the degree of blur filter.
const DEFAULT_BLUR_CONFIG = {
  assetsPath: '',
  maskBlurRadius: 10, // eslint-disable-line
  blurFilterRadius: 15, // eslint-disable-line
};

const LOCAL_TRACK_KIND = {
  VIDEO: 'video',
  AUDIO: 'audio',
};

const localTracks = {
  video: null,
  audio: null,
};

async function getInputDevices(kind) {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(device => device.kind === `${kind}input`);
}

export default {
  name: 'VideoDialog',
  data() {
    return {
      STEP_INSPECT_VIDEO_SUPPORT: 'inspect_capability',
      STEP_AUDIO_ADJUSTMENT: 'audio_device',
      STEP_VIDEO_ADJUSTMENT: 'camera_device',
      STEP_VIDEO_NOT_SUPPORTED: 'video_not_supported',
      steps: [
        'inspect_capability',
        'camera_device',
      ],
      stepState: null,
      audioDevices: [],
      videoDevices: [],
      selectedAudioDeviceId: null,
      selectedVideoDeviceId: null,
      bgValue: localStorage.getItem('video-call-background', ''),
    };
  },
  props: {
    agent: {
      type: Object,
      default: null,
    },
    customer: {
      type: Object,
      default: null,
    },
  },
  emits: ['launch', 'cancel'],
  computed: {
    ...mapState({
      videoBackgrounds: state => state.configs.videoBackground.models,
    }),
    roomName() {
      const agentId = get(this.agent, 'id');
      const customerId = get(this.customer, 'id');

      if (!agentId || !customerId) {
        return '';
      }

      return ['agent', agentId, 'customer', customerId, randomString()].join('-');
    },
    currentState() {
      if (this.stepState === 'camera_device') return this.$t('conversation_tab.chat_panel.video.video_call_request');
      else return this.stepState ? startCase(this.stepState) : '';
    },
  },
  async mounted() {
    this.stepState = this.STEP_INSPECT_VIDEO_SUPPORT;
    await this.runState();

    try {
      const ret = await this.$store.dispatch('configs/getVideoCallBackgrounds');

      // Validate current bgValue
      if (![...ret.models.map(bg => bg.payload.key), 'blur'].includes(this.bgValue)) {
        this.bgValue = '';
      }
    } catch (err) {
      this.$aiq.notify.error('Unable to fetch video backgrounds');
      this.bgValue = '';
    }

    if (this.bgValue) {
      await this.onUpdateBackground('', this.bgValue, true);
    }
  },
  methods: {
    async checkCapability() {
      // check agent's video capability
      if (!this.$aiq.video.isVideoSupported) {
        this.stepState = this.STEP_VIDEO_NOT_SUPPORTED;
        return this.runState();
      }

      // check customer's video capability
      // TODO: Check customer device

      return this.moveToNextState();
    },
    async getMediaStreamFromVender(kind, deviceId) {
      // Stop the previous LocalTrack, if present.
      if (localTracks[kind]) {
        localTracks[kind].stop();
      }

      const { track } = await this.$aiq.video.getMediaStream(kind, deviceId);

      // Render the current LocalTrack.
      localTracks[kind] = track;

      return track;
    },
    async showVideoDevices() {
      this.videoDevices = await getInputDevices('video');
      this.selectedVideoDeviceId = this.videoDevices[0].deviceId;

      const track = await this.getMediaStreamFromVender('video', this.selectedVideoDeviceId);
      track.attach(this.$refs['preview-video']);
    },
    async onUpdateBackground(oldKey, key, value) {
      const track = localTracks[LOCAL_TRACK_KIND.VIDEO];

      if (oldKey) {
        // this api can also remove background
        await this.$aiq.video.unsetBlur(track);
      }

      try {
        if (value) {
          if (key === 'blur') {
            await this.$aiq.video.setBlur(track, DEFAULT_BLUR_CONFIG);
          } else {
            await this.$aiq.video.setBackground(track, {
              backgroundImageUrl: this.makeBackgroundUrl(key),
            });
          }
          this.bgValue = key;
        } else {
          this.bgValue = '';
        }
      } catch (err) {
        this.bgValue = '';
        this.$aiq.notify.error('Unable to fetch video backgrounds');
      }
      localStorage.setItem('video-call-background', this.bgValue);
    },
    makeBackgroundUrl(bgValue) {
      let origin = get(window, 'location.origin', '');

      if (origin.includes('localhost')) {
        // Note: Local development needs to replace localhost to actual location of background
        origin = `https://dashboard.${process.env.ENVIRONMENT}.agentiq.co`;
      }

      return `${origin}/${bgValue}`;
    },
    async moveToNextState() {
      const idx = this.steps.findIndex(step => step === this.stepState) + 1;
      if (idx === this.steps.length) {
        this.stepState = null;
        return false;
      }
      this.stepState = this.steps[idx];

      // Stop the previous LocalTrack, if present.
      for (const kind of ['video', 'audio']) {
        if (localTracks[kind]) {
          localTracks[kind].stop();
        }
      }

      await this.runState();
      return true;
    },
    async onClickNext() {
      if (await this.moveToNextState()) {
        return;
      }

      this.cleanLocalTrack();

      this.$emit('launch', {
        video: {
          deviceId: this.selectedVideoDeviceId,
          blur: this.bgValue === 'blur' ? cloneDeep(DEFAULT_BLUR_CONFIG) : null,
          backgroundImageUrl: (!isEmpty(this.bgValue) && this.bgValue !== 'blur') ? this.makeBackgroundUrl(this.bgValue) : null,
        },
        audio: true,
        room: {
          name: this.roomName,
        },
      });
    },
    async runState() {
      switch (this.stepState) {
        case this.STEP_INSPECT_VIDEO_SUPPORT:
          return this.checkCapability();
        case this.STEP_VIDEO_ADJUSTMENT:
          return this.showVideoDevices();
        case this.STEP_VIDEO_NOT_SUPPORTED:
        default:
          return;
      }
    },
    cleanLocalTrack() {
      if (localTracks.video) {
        localTracks.video.stop();
      }
      if (localTracks.audio) {
        localTracks.audio.stop();
      }
    },
    onCancel() {
      this.cleanLocalTrack();
      this.$emit('cancel');
    },
  },
};
