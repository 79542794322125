<template lang="pug">
  .title
      h3(class="left") {{$t('metrics_tab.agents_tab.platform_tab.comms_table_title')}}
      div(class="right")
          aiq-input(v-model="searchString" class="search-form" size="small" :placeholder="$t('metrics_tab.agents_tab.platform_tab.search_placeholder')")
          aiq-button.export-chart(:disabled="loading || !metricList.length" @click="exportChart")
              i.iq-ico-export
  aiq-table(:data="metricList" v-loading="loading")
    aiq-table-column(:label="$t('metrics_tab.agents_tab.platform_tab.lynq')", :sortable="true", prop="customer_id" label-class-name="is-bordered")
    aiq-table-column(:label="$t('metrics_tab.agents_tab.platform_tab.agent')", :sort-method="onSortName" :sortable="true", prop="agent" label-class-name="is-bordered")
      template(v-slot:default="scope")
        span {{ getAgentName(scope.row.profile) }} &nbsp;
    aiq-table-column(:label="$t('metrics_tab.agents_tab.platform_tab.session')", :sortable="true", prop="event_type" label-class-name="is-bordered")
    aiq-table-column(:label="$t('metrics_tab.agents_tab.platform_tab.start')", :sort-method="onSortStartTime" :sortable="true", prop="event_created_at" label-class-name="is-bordered")
      template(v-slot:default="scope")
        span {{ formatTime(scope.row.event_created_at) }}&nbsp;
    aiq-table-column(:label="$t('metrics_tab.agents_tab.platform_tab.end')", :sort-method="onSortEndTime" :sortable="true", prop="session_ended_at" label-class-name="is-bordered")
      template(v-slot:default="scope")
        span {{ formatTime(scope.row.session_ended_at) }}&nbsp;
    aiq-table-column(:label="$t('metrics_tab.agents_tab.platform_tab.duration')", :sort-method="onSortDuration" :sortable="true", prop="session_duration" label-class-name="is-bordered")
      template(v-slot:default="scope")
        span {{ $filters.duration(scope.row.session_duration) }}&nbsp;
</template>

<script>
import moment from 'moment-timezone';
import { debounce, get, isEmpty } from 'lodash';
import { downloadAsFile, useTimeZone, getTimeZoneAbbr, formatDateWithTimezone } from '@/libs';
import { dstShift } from '@/pages/Metrics/configs/dateRange';

const DEBOUNCE_VALUE = 1000;
export default {

  name: 'PlatformCommunicationsTable',
  props: {
    dateRangeTable: {
      required: true,
      type: Array,
    },
    breakdown: {
      required: true,
      type: String,
    },
    selectedFilterValue: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.fetchTableData();
  },
  data() {
    return {
      loading: false,
      searchString: '',
      metricList: [],
      tzAbbr: '',
      timezone: '',
    };
  },
  watch: {
    dateRangeTable: {
      handler: debounce(function onDateChange(newValue, _oldValue = []) {
        const [start, end] = newValue;
        if (!(start && end)) {
          return;
        }
        this.fetchTableData();
      }, DEBOUNCE_VALUE),
      deep: true,
    },
    selectedFilterValue: {
      handler: function onFilterChange(_newValue, _oldValue) {
        this.fetchTableData();

      },
      deep: true,
    },
    breakdown: {
      handler: function onBreakdownChange(newValue, _oldValue) {
        if (!isEmpty(newValue)) {
          this.fetchTableData();
        }
      },
    },
    searchString: debounce(function onDateChange() {
      this.fetchTableData();
    }, DEBOUNCE_VALUE),
  },
  methods: {
    exportChart() {
      downloadAsFile({
        data: this.metricList.map(data => ({
          agent: this.getAgentName(data.profile),
          lynq_id: data.customer_id,
          session_type: data.event_type,
          start_time: `${this.formatTime(data.event_created_at)} ${this.tzAbbr}`,
          end_time: `${this.formatTime(data.session_ended_at)} ${this.tzAbbr}`,
          duration: this.$filters.duration(data.session_duration),
        })),
        dataType: 'table',
        filename: `Communication Details ${moment(this.dateRangeTable[0]).format('MMM DD, YYYY')} to ${moment(this.dateRangeTable[1]).format('MMM DD, YYYY')}.csv`,
      });
      this.$aiq.notify.success(this.$t('metrics_tab.agents_tab.platform_tab.download_success'));
    },
    getAgentName(agent) {
      return `${agent.first_name} ${agent.last_name}`;
    },
    formatTime(date) {
      if (!date) {
        return '';
      }
      return formatDateWithTimezone(date, this.timezone, 'MMM DD, YYYY hh:mm A');
    },
    async fetchTableData() {
      this.loading = true;
      this.timezone = await useTimeZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        this.$store.dispatch,
        this.$store._state.data.configs,
      );

      this.tzAbbr = getTimeZoneAbbr(this.timezone);

      const period_start = moment.unix(dstShift(this.dateRangeTable[0])).format('YYYY-MM-DD HH:mm:ss');
      const period_end = moment.unix(dstShift(this.dateRangeTable[1])).format('YYYY-MM-DD HH:mm:ss');

      //apply timezone to unix start and end time
      const start = moment.tz(period_start, this.timezone).unix();
      const end = moment.tz(period_end, this.timezone).unix();

      const params = {
        period_start: start,
        period_end: end,
        filters: this.selectedFilterValue,
        bucket: this.breakdown,
        time_zone: this.timezone,
      };

      if (!isEmpty(this.searchString)) {
        params.query = this.searchString;
      }

      this.$store.dispatch('metrics/getPlatformCommsList',
        ['communication_sessions', params]).then(data => {
        this.metricList = get(data, 'dataset[0].data_points', []);
      }).catch((_err) => {
        this.$aiq.notify.error(this.$t('metrics_tab.agents_tab.platform_tab.errro_fetching'));
      }).finally(() => {
        this.loading = false;
      });


    },
    onSortName(a, b) {
      const valueA = this.getAgentName(a.profile);
      const valueB = this.getAgentName(b.profile);
      return this.onSort(valueA, valueB);
    },
    onSortDuration(a, b) {
      const valueA = get(a, 'session_duration', 0);
      const valueB = get(b, 'session_duration', 0);
      return this.onSort(valueA, valueB);
    },
    onSortEndTime(a, b) {
      const valueA = parseInt(get(a, 'session_ended_at', 0));
      const valueB = parseInt(get(b, 'session_ended_at', 0));
      return this.onSort(valueA, valueB);
    },
    onSortStartTime(a, b) {
      const valueA = parseInt(get(a, 'event_created_at', 0));
      const valueB = parseInt(get(b, 'event_created_at', 0));
      return this.onSort(valueA, valueB);
    },
    onSort(a, b) {
      if (a === b) {
        return 0;
      }
      return a < b ? 1 : -1;
    },
  },
};
</script>
<style scoped lang="scss">
.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .left{
      float: left;
  }
  .right {
      float: right;
      display: flex;
      align-items: center;
      .search-form {
          width: 200px;
          margin-right: 10px;
   }
  }

}
:deep(.el-button) {
padding: 5px 15px;
}
</style>
