<template lang="pug">
  aiq-select(
    v-bind="$attrs"
    v-model="selectedValues"
    :ref="selectRef"
    :collapse-tags="isCollapseTags"
    :max-collapse-tags="maxCollapseTags"
  )
    template(v-if="$slots.header" v-slot:header)
      slot(name="header")
    slot
</template>

<script>
export default {
  name: 'CollapsibleSelect',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selectRef: {
      type: String,
      default: 'collapsibleSelector',
    },
  },
  data() {
    return {
      selectedValues: this.value,
      isCollapseTags: false,
      maxCollapseTags: 1,
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.selectedValues = newVal;
        this.resetCollapseTagsProps();
        this.$nextTick(() => this.handleTagCollapse());
      },
      deep: true,
    },
  },
  methods: {
    handleTagCollapse() {
      const selectorRef = this.$refs?.[this.selectRef];
      if (!selectorRef) return;

      const teamTags = selectorRef.$el.getElementsByClassName(
        'el-select__selected-item',
      );
      for (let i = 0; i < teamTags.length - 1; i++) {
        const currentTop = teamTags[i].getBoundingClientRect().top;
        const nextTop = teamTags[i + 1].getBoundingClientRect().top;
        if (nextTop > currentTop) {
          this.isCollapseTags = true;
          this.maxCollapseTags = i;
          return;
        }
      }

      this.resetCollapseTagsProps();
    },
    resetCollapseTagsProps() {
      this.isCollapseTags = false;
      this.maxCollapseTags = 1;
    },
  },
  mounted() {
    setTimeout(() => {
      this.selectedValues = this.value;
      this.$nextTick(() => this.resetCollapseTagsProps());
      this.$nextTick(() => this.handleTagCollapse());
      // Using setTimeout with a delay of 0 to defer this code execution.
      // This ensures that these functions are called at the end of the task queue,
      // guaranteeing that they execute only after the component is fully rendered.
    }, 0);
  },
};
</script>
