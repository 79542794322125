<template lang="pug">
.today-agents-container
  collapsible-select(
            placeholder="Filter by Teams"
            v-model="selectedTeams"
            :value="selectedTeams"
            @change="handleSelectChange"
            value-key="id"
            multiple
            size="small"
            popper-class="custom-header"
            :reserve-keyword="false"
          )
    template(v-slot:header)
      aiq-checkbox(v-model="checkAll"
                  :indeterminate="indeterminate"
                  @change="confirmCheckAll") All
    aiq-option(v-for="team in allTeams"
              :key="team.id"
              :label="team.name"
              :value="team")
  today-heat-maps-list(:metrics="metrics"
                      :isLoading="isLoading"
                      :heat-maps-initial-confg="heatMapsInitialConfg"
                      :selected-options="selectedTeams")
</template>

<script>
import { mapGetters } from 'vuex';
import TodayHeatMapsList from './components/TodayHeatMapsList.vue';
import CollapsibleSelect from '@/components/CollapsibleSelect/CollapsibleSelect.vue';

const DEFAULT_TEAM_SELECTIONS = [];
const TEAM_SELECTIONS_CACHE_KEY = 'pulse/today/teams';

export default {
  name: 'PulseTodayAgents',
  components: {
    TodayHeatMapsList,
    CollapsibleSelect,
  },
  props: {
    metrics: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      allTeams: 'teams/sortedTeams',
    }),
    heatMapsInitialConfg() {
      return [
        {
          id: 'agent-count',
          title: 'Agent Count',
          titleTooltip: this.$t('pulse_tab.today_tab.agent_metrics_tab.agent_count_chart.title_tootlip'),
          responseDataKey: 'agent_count',
          tooltips: [
            { valueKey: 'max', label: 'Max Agent Count ', defaultMetricCategory: 'count' },
            { valueKey: 'min', label: 'Min Agent Count', defaultMetricCategory: 'count' },
            { valueKey: 'median', label: 'Median Agent Count', defaultMetricCategory: 'count' },
          ],
          tooltipsClassName: 'today-agents-container',
          chartConfig: {
            metric: 'agent_count',
            lines: {
              agent_count: {
                valueKey: 'median',
                xKey: 'hour',
                yKey: 'team',
              },
            },
          },
          colors: ['#E4EBF4', '#BDCDE5', '#91ABD3', '#6489C1', '#2257A7'],
        },
        {
          id: 'agent-available-capacity',
          title: 'Agent Available Capacity',
          titleTooltip: this.$t('pulse_tab.today_tab.agent_metrics_tab.agent_available_capacity_chart.title_tooltip'),
          responseDataKey: 'agent_availability',
          tooltips: [
            { valueKey: 'max', label: 'Max Agent Capacity ', defaultMetricCategory: 'count' },
            { valueKey: 'min', label: 'Min Agent Capacity', defaultMetricCategory: 'count' },
            { valueKey: 'median', label: 'Median Agent Capacity', defaultMetricCategory: 'count' },
          ],
          tooltipsClassName: 'today-agents-container',
          chartConfig: {
            metric: 'agent_availability',
            lines: {
              agent_availability: {
                valueKey: 'median',
                xKey: 'hour',
                yKey: 'team',
              },
            },
          },
          colors: ['#E2F1E6', '#B6DCC1', '#86C498', '#56AC6E', '#0B812B'],
        },
        {
          id: 'agent-capacity',
          title: 'Agent Capacity',
          titleTooltip: this.$t('pulse_tab.today_tab.agent_metrics_tab.agent_capacity_chart.title_tootlip'),
          responseDataKey: 'agent_capacity',
          tooltips: [
            { valueKey: 'max', label: 'Max Agent Capacity ', defaultMetricCategory: 'percent' },
            { valueKey: 'min', label: 'Min Agent Capacity', defaultMetricCategory: 'percent' },
            { valueKey: 'median', label: 'Median Agent Capacity', defaultMetricCategory: 'percent' },
          ],
          tooltipsClassName: 'today-agents-container',
          chartConfig: {
            metric: 'agent_capacity',
            lines: {
              agent_capacity: {
                valueKey: 'median',
                xKey: 'hour',
                yKey: 'team',
              },
            },
          },
          colors: ['#FFF2DB', '#FFE9C2', '#FFDB9C', '#FFCD75', '#FFBE50'],
        },
        {
          id: 'agent-concurrent-conversations',
          title: 'Agent Concurrent Conversations',
          titleTooltip: this.$t('pulse_tab.today_tab.agent_metrics_tab.agent_concurrent_conversations_chart.title_tootlip'),
          responseDataKey: 'agent_locks',
          tooltips: [
            { valueKey: 'max', label: 'Max Concurrent Conversations ', defaultMetricCategory: 'count' },
            { valueKey: 'min', label: 'Min Concurrent Conversations', defaultMetricCategory: 'count' },
            { valueKey: 'median', label: 'Median Concurrent Conversations', defaultMetricCategory: 'count' },
          ],
          tooltipsClassName: 'today-agents-container',
          chartConfig: {
            metric: 'agent_locks',
            lines: {
              agent_locks: {
                valueKey: 'median',
                xKey: 'hour',
                yKey: 'team',
              },
            },
          },
          colors: ['#F8E2E2', '#EEB6B6', '#E38686', '#D75555', '#C60C0C'],
        },
      ];
    },
  },
  data() {
    return {
      selectedTeams: [],
      initialTeams: [],
      isLoading: true,
      checkAll: false,
      indeterminate: true,
      isCollapseTags: false,
      maxCollapseTags: 1,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch('teams/getTeamsList', [{ limit: 1000 }]);
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }

    this.initializeOptionsSelections();
  },
  methods: {
    initializeOptionsSelections() {
      const cachedTeamNames = localStorage.getItem(TEAM_SELECTIONS_CACHE_KEY);
      const initialTeamNames = cachedTeamNames ? cachedTeamNames.split(',') : DEFAULT_TEAM_SELECTIONS;

      if (initialTeamNames.includes('all')) {
        this.handleCheckAll(true);
      } else {
        const initialTeams =
          this.allTeams.filter(team => initialTeamNames.includes(team.name));
        this.initialTeams = initialTeams;
        this.handleSelectChange(initialTeams);
      }

    },
    handleSelectChange(newSelections) {
      if (this.metrics.lastFetchedAt) {
        this.isLoading = false;
      }

      this.selectedTeams = newSelections;

      if (newSelections.length === 0) {
        this.checkAll = false;
        this.indeterminate = false;
        localStorage.setItem(TEAM_SELECTIONS_CACHE_KEY, '');
      } else if (newSelections.length === this.allTeams.length) {
        this.checkAll = true;
        this.indeterminate = false;
        localStorage.setItem(TEAM_SELECTIONS_CACHE_KEY, 'all');
      } else {
        this.checkAll = false;
        this.indeterminate = true;
        const teamNames = newSelections.map(team => team.name);
        localStorage.setItem(TEAM_SELECTIONS_CACHE_KEY, teamNames);
      }
    },
    async confirmCheckAll(checkAllValue) {
      if (checkAllValue) {
        try {
          const baseStringPath = 'pulse_tab.today_tab.agent_metrics_tab.select_all_confirmation';
          await this.$aiq.confirm(
            this.$t(`${baseStringPath}.title`),
            this.$t(`${baseStringPath}.body`),
            {
              confirmButtonText: this.$t(`${baseStringPath}.confirm_text`),
              cancelButtonText: this.$t(`${baseStringPath}.cancel_text`),
            },
          );
          this.handleCheckAll(true);
        } catch (_) {
          this.checkAll = false;
        }

      } else {
        this.handleCheckAll(false);
      }
    },
    handleCheckAll(checkAllValue) {
      this.indeterminate = false;
      if (checkAllValue) {
        this.handleSelectChange(this.allTeams);
      } else {
        this.handleSelectChange([]);
      }
    },
  },
  watch: {
    'metrics.lastFetchedAt': function watchMetricsLastFetchedAt() {
      if (this.selectedTeams.length) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.custom-header {
  .el-checkbox {
    display: flex;
    height: unset;
  }
}
</style>
