import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { mapState, mapGetters } from 'vuex';
import * as log from 'loglevel';

import { FeedbackForm, WebchatConfig, Onboarding, OnboardingJourney, LanguageSettings, QueueConfiguration } from '@/components';
import { getTimeZoneListWithAbbr } from '@/libs';
import { ASK_RESOLVE_EXAMPLE, POST_CHAT_DESCRIPTION } from './constants';
import { ONBOARD_SCREERNS } from '@/constants/settings';
import PrechatConfiguration from './prechatConfiguration.vue';

// TODO Fetch dynamically
const appId = process.env.APP_ID || '';
const CODE_SNIPPET = `<script src="https://chat.agentiq.co/webchat.js?app_id=${appId}></script>`;
const MAX_ITEM_LIMIT = 999999;

export default {
  name: 'webchat-tab',
  components: {
    FeedbackForm,
    WebchatConfig,
    Onboarding,
    OnboardingJourney,
    LanguageSettings,
    PrechatConfiguration,
    QueueConfiguration,
  },
  props: {
    webform: {
      type: Object,
      default: () => ({
        message: { header: {} },
        live_hours: { weekday: {} },
      }),
    },
  },
  data() {
    return {
      webchatCodeSnippet: CODE_SNIPPET,
      localPostChatSurvey: {
        ask_resolved: null,
        survey_type: 'nps',
        nps: {
          question: '',
          scores: [],
        },
        csat: {
          question: '',
          scores: [],
        },
      },
      localPostChatSurveyStrings: {
        nps: {
          question: '',
          scores: {},
        },
        csat: {
          question: '',
          scores: {},
        },
      },
      preOnboardingConfig: null,
      postOnboardingConfig: null,

      webchatOldFeatures: process.env.FEATURE_FLAGS.CHANNEL_WEBCHAT_OLD_FEATURES,
      showSurveySettings: process.env.FEATURE_FLAGS.SHOW_SURVEY_SETTINGS,

      loading: true,
    };
  },
  emits: ['update'],
  computed: {
    ...mapState({
      postChatSurvey: state => state.configs.channels.webchat.postChatSurvey,
      webchatConfigs: state => state.configs.channels.webchat.webchat_configs,
      onboarding: state => state.configs.channels.webchat.onboarding,
      webchatLanguageSettings: state => state.configs.language_setting,
      webchatLanguageStrings: state => state.configs.language_strings,
    }),
    ...mapGetters({
      segments: 'configs/segmentNames',
      workflowNames: 'workflows/workflowNames',
    }),
    postChatSurveyPreview() {
      return merge({}, this.localPostChatSurvey, this.localPostChatSurveyStrings);
    },
    currentLanguageView() {
      return this.webchatLanguageSettings.selected_language_view;
    },
    surveyItems() {
      return get(this.localPostChatSurveyStrings, `${this.localPostChatSurvey.survey_type}.scores`, []);
    },
    shouldShowCodeSnippet() {
      return get(this.webchatConfigs, 'configs.targets', []).includes('anonymous');
    },
    targets() {
      return get(this.webchatConfigs, 'configs.targets', []);
    },
    onboardingItems() {
      return ONBOARD_SCREERNS.map(item => item.value);
    },
    isPostChatSettingPristine() {
      return isEqual(this.localPostChatSurvey, this.postChatSurvey) &&
      isEqual(this.localPostChatSurveyStrings, get(this.webchatLanguageStrings, 'surveys', {}));
    },
    liveHoursText() {
      return this.localForm.message.live_hours;
    },
    pristine() {
      return isEqual(this.localForm, this.webform);
    },
    feedbackFormTitle() {
      return this.localForm.message.header.live_hours;
    },
    postChatPreviewProps() {
      if (!this.localPostChatSurvey.survey_type) {
        return {};
      }

      return {
        type: this.localPostChatSurvey.survey_type,
      };
    },
    timeZone: {
      get() {
        const zone = this.localForm.live_hours.time_zone;

        return `${zone} (${moment.tz(zone).zoneAbbr()})`;
      },
      set(zone) {
        const timeZoneWithoutAbbr = zone.match(/^(.*)\s\(.*\)$/)[1];

        this.localForm.live_hours.time_zone = timeZoneWithoutAbbr;
      },
    },
    weekdays: {
      get() {
        const { weekday } = this.localForm.live_hours;

        const weekdaysSelected = [];
        for (const day in weekday) {
          // Until we manually configure times for each day rely on day times to be in sync
          if (weekday[day].start_time && weekday[day].end_time) {
            weekdaysSelected.push(day[0].toUpperCase() + day.substr(1));
          }
        }

        return weekdaysSelected;
      },
      set(weekdays) {
        const weekdayObj = {};

        for (const weekday of this.$_allWeekdays) {
          weekdayObj[weekday.toLowerCase()] = {
            start_time: null,
            end_time: null,
          };
        }

        for (const weekday of weekdays) {
          const lowercaseWeekday = weekday[0].toLowerCase() + weekday.substr(1);
          const startTime = this.localForm.live_hours.start_time;
          const endTime = this.localForm.live_hours.end_time;

          weekdayObj[lowercaseWeekday] = {
            start_time: startTime,
            end_time: endTime,
          };
        }

        this.localForm.live_hours.weekday = weekdayObj;
      },
    },
  },
  created() {
    this.localForm = cloneDeep(this.webform);
    this.$_allWeekdays = moment.weekdays();
    this.$_allTimezones = getTimeZoneListWithAbbr();
    this.$_ASK_RESOLVE_EXAMPLE = ASK_RESOLVE_EXAMPLE;
    this.$_POST_CHAT_DESCRIPTION = POST_CHAT_DESCRIPTION;

    return Promise.all([
      this.$store.dispatch('configs/getPostChatSurvey'),
      this.$store.dispatch('configs/getWebchatConfigs'),
      this.$store.dispatch('configs/getCustomerSegments'),
      this.$store.dispatch('configs/loadOnboarding'),
      this.$store.dispatch('workflows/getWorkflowsList', [{ limit: MAX_ITEM_LIMIT }]),
      this.$store.dispatch('configs/getWebchatLanguageSettings')])
      .then(responses => {
        const postchatSurveyConfig = responses[0];
        if (postchatSurveyConfig) {
          this.localPostChatSurvey = cloneDeep(postchatSurveyConfig);
        }
        this.getWebchatLanguageStrings(this.webchatLanguageSettings.selected_language_view);
        this.loading = false;
      });
  },
  methods: {
    copySnippet() {
      const el = document.createElement('textarea');
      el.value = this.webchatCodeSnippet;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$aiq.notify.success('Code snippet copied to clipboard.');
    },

    async getWebchatLanguageStrings(languagekey) {
      try {
        await this.$store.dispatch('configs/getWebchatLanguageStrings', { languagekey });
      } catch (err) {
        log.error('Unable to load the languages strings.', err);
        this.$aiq.notify.error('Unable to load the languages strings.');
      }
    },

    resetForm() {
      this.localForm = cloneDeep(this.webform);
    },

    resetPostChat() {
      this.localPostChatSurvey = cloneDeep(this.postChatSurvey);
    },

    saveForm() {
      this.$emit('update', this.localForm);
    },
    setPostChatSurvey() {
      return Promise.all([
        this.$store.dispatch('configs/setPostChatSurvey', this.localPostChatSurvey),
        this.$store.dispatch('configs/setWebchatLanguageStrings', { surveys: this.localPostChatSurveyStrings })])
        .then(() => {
          this.resetPostChat();
          this.$aiq.notify.success('Post-Chat Survey configuration has been set.');
        }).catch(() => {
          this.$aiq.notify.error('Unable to set Post-Chat Survey.');
        });
    },

    setWebchatLanguageSettings(config) {
      return this.$store.dispatch('configs/setWebchatLanguageSettings', config)
        .then(() => {
          this.$aiq.notify.success('Webchat language settings has been set.');
        }).catch(() => {
          this.$aiq.notify.error('Unable to set language settings.');
        });
    },

    async saveWebchatConfigs({ config, configStrings, alertMessage }) {
      return Promise.all([
        this.$store.dispatch('configs/setWebchatConfigs', config),
        this.$store.dispatch('configs/setWebchatLanguageStrings', { webchat: configStrings }),
      ]).then(() => alertMessage ? this.$store.dispatch('alertMessages/createOrUpdate', alertMessage) : Promise.resolve())
        .then(() => {
          this.$aiq.notify.success('Webchat configuration has been set.');
        }).catch(({ body }) => {
          this.$aiq.notify.error(body?.error || 'Unable to set Webchat configuration.');
        });
    },
    async saveQueueConfigs({ config, configStrings }) {
      return Promise.all([
        this.$store.dispatch('configs/setWebchatConfigs', config),
        this.$store.dispatch('configs/setWebchatLanguageStrings', { webchat: configStrings }),
      ]).then(() => {
        this.$aiq.notify.success(this.$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.updated_success'));
      }).catch(({ body }) => {
        this.$aiq.notify.error(body?.error || this.$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.unable_update'));
      });
    },

    saveOnboarding({ onboarding, onboardingStrings }) {
      return Promise.all([
        this.$store.dispatch('configs/updateOnboarding', onboarding),
        this.$store.dispatch('configs/setWebchatLanguageStrings', { onboard: onboardingStrings })])
        .then(() => {
          this.$aiq.notify.success('Onboarding configuration has been set.');
        }).catch(() => {
          this.$aiq.notify.error('Unable to set onboarding configuration.');
        });
    },

    getOnboardSettingNames(target) {
      if (target === 'mobile') {
        return ['onboard', 'post_auth_onboard'];
      }

      if (target === 'anonymous') {
        return ['anonymous_onboard', 'post_auth_anonymous_onboard'];
      }

      if (target === 'external') {
        return ['external_onboard', 'post_auth_external_onboard'];
      }

      log.warn(`Unexpected target received: ${target}`);
      return [null, null];
    },

    getScore(index) {
      return this.localPostChatSurvey[this.localPostChatSurvey.survey_type].scores[index]?.score;
    },
    async onConfigUpdate({ target, preOnboardingConfig, postOnboardingConfig }) {
      const [preConfigSettingName, postConfigSettingName] = this.getOnboardSettingNames(target);
      const [pre, post] = await Promise.all([
        this.$store.dispatch('settings/updateSetting', {
          name: preConfigSettingName,
          payload: preOnboardingConfig,
        }),
        this.$store.dispatch('settings/updateSetting', {
          name: postConfigSettingName,
          payload: postOnboardingConfig,
        })]);

      this.preOnboardingConfig = pre;
      this.postOnboardingConfig = post;
    },

    async onTargetChange(newTarget) {
      if (!newTarget || !this.targets.includes(newTarget)) {
        log.warn(`Unexpected target received: ${newTarget}`);
        return;
      }

      const [preConfigSettingName, postConfigSettingName] = this.getOnboardSettingNames(newTarget);

      const [pre, post] = await Promise.all([
        this.$store.dispatch('settings/getSetting', { name: preConfigSettingName }),
        this.$store.dispatch('settings/getSetting', { name: postConfigSettingName })]);

      this.preOnboardingConfig = pre;
      this.postOnboardingConfig = post;
    },
  },
  watch: {
    webchatLanguageStrings(newVal) {
      this.localPostChatSurveyStrings = cloneDeep(get(newVal, 'surveys', {}));
    },
    'webchatLanguageSettings.selected_language_view': async function(languagekey) {
      await this.getWebchatLanguageStrings(languagekey);
    },
    webform(webform) {
      this.localForm = cloneDeep(webform);
    },
    'localForm.live_hours.end_time': function(endTime) {
      for (const weekday of this.weekdays) {
        this.localForm.live_hours.weekday[weekday.toLowerCase()].end_time = endTime;
      }
    },
    'localForm.live_hours.start_time': function(startTime) {
      for (const weekday of this.weekdays) {
        this.localForm.live_hours.weekday[weekday.toLowerCase()].start_time = startTime;
      }
    },
  },
};
