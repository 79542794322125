const tagsProgressChartConfig = {
  title: 'Tag Usage',
  metric: 'tags_progress',
  helpText: 'metrics_tab.insights_tab.tags_tab.tags_usage_tooltip',
  graphParams: {
    metricCategoryForY: 'tagsProgress',
    colColors: {},
  },
  maxTickLabelCount: 6,
};

export default tagsProgressChartConfig;
