import get from 'lodash/get';
import { canCreate } from '@/libs';
import profileIcon from '@/assets/photo.png';

const DEFAULT_MAX_LOAD = 200;
const DEFAULT_CONCURRENT_CAPACITY = 3;

export const BOT_EMAIL = 'bot@agentiq.com';

export const parse = (source = {}, data) => {
  const agent = { ...source, ...data };

  agent.email = agent.email || '';
  agent.file_id = agent.file_id || get(agent, 'picture.id') || 0;
  agent.max_load = get(agent, 'max_load', DEFAULT_MAX_LOAD);
  agent.concurrent_capacity = get(agent, 'concurrent_capacity', DEFAULT_CONCURRENT_CAPACITY);

  agent.profile = agent.profile || {};
  agent.profile.first_name = get(agent, 'profile.first_name', '');
  agent.profile.last_name = get(agent, 'profile.last_name', '');
  agent.profile.location = get(agent, 'profile.location', '');
  agent.profile.external_id = get(agent, 'profile.external_id', '');

  agent.channels = agent.channels || [];
  agent.teams = agent.teams || [];
  agent.roles = agent.roles || [];

  agent.picture = {
    payload: {
      url: get(agent, 'picture.payload.url', profileIcon),
    },
  };

  return agent;
};

export const defaultProfile = () => {
  const agent = {};

  agent.email = '';
  agent.file_id = 0;
  agent.max_load = DEFAULT_MAX_LOAD;
  agent.concurrent_capacity = DEFAULT_CONCURRENT_CAPACITY;

  agent.profile = {};
  agent.profile.first_name = '';
  agent.profile.last_name = '';
  agent.profile.location = '';
  agent.profile.external_id = '';

  agent.channels = agent.channels || [];
  agent.teams = agent.teams || [];
  agent.roles = agent.roles || [];

  agent.picture = {
    payload: {
      url: get(agent, 'picture.payload.url', profileIcon),
    },
  };

  return agent;
};


export const buildProfile = (updatingProfile) => {
  const agent = {};

  agent.id = updatingProfile.id;
  agent.profile = updatingProfile.profile;
  agent.email = updatingProfile.email;
  agent.max_load = parseInt(updatingProfile.max_load, 10);

  if (updatingProfile.file_id) {
    agent.file_id = updatingProfile.file_id;
  }

  return agent;
};

export const createRolesAccess = (permissions) => {
  const p = [];

  canCreate(permissions, '/agents/:agent/roles/admin') && p.push('admin');
  canCreate(permissions, '/agents/:agent/roles/manager') && p.push('manager');
  canCreate(permissions, '/agents/:agent/roles/agent') && p.push('agent');
  canCreate(permissions, '/agents/:agent/roles/superadmin') && p.push('superadmin');
  canCreate(permissions, '/agents/:agent/roles/ai-admin') && p.push('ai-admin');
  canCreate(permissions, '/agents/:agent/roles/viewer') && p.push('viewer');
  return p;
};

export const DEFAULT_BACKGROUND_URL = '';

export const DEFAULT_PICTURE_URL = profileIcon;

export const AGENT_PROFILE_MAPPING = [
  {
    type: 'string',
    field: 'fullName',
    label: 'Agent Full Name',
  },
  {
    type: 'string',
    field: 'profile.first_name',
    label: 'Agent First Name',
  },
  {
    type: 'string',
    field: 'profile.last_name',
    label: 'Agent Last Name',
  },
  {
    type: 'string',
    field: 'profile.display_name',
    label: 'Agent Display Name',
  },
];
