<template>
<svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <title>Video</title>
    <desc>Created with Sketch.</desc>
    <g id="video-and-cobrowsing" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-Copy" transform="translate(-511.000000, -396.000000)" stroke="#5993FF">
            <path d="M514,397 L526.372093,397 C527.476663,397 528.372093,397.895431 528.372093,399 L528.372093,402 L528.372093,402 L532.420675,399.122495 C532.870841,398.802543 533.495144,398.908102 533.815097,399.358267 C533.935377,399.527498 534,399.729971 534,399.937592 L534,408.062408 C534,408.614693 533.552285,409.062408 533,409.062408 C532.792379,409.062408 532.589906,408.997785 532.420675,408.877505 L528.372093,406 L528.372093,406 L528.372093,409 C528.372093,410.104569 527.476663,411 526.372093,411 L514,411 C512.895431,411 512,410.104569 512,409 L512,399 C512,397.895431 512.895431,397 514,397 Z" id="video"></path>
        </g>
    </g>
</svg>
</template>
