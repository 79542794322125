const tagsChartConfig = {
  name: 'tags_distribution',
  title: 'Tag Distribution',
  metric: 'tags_distribution',
  helpText: 'metrics_tab.insights_tab.tags_tab.tags_distribution_tooltip',
  exportType: 'tabular_chart_data',
  graphParams: {
    title: '',
    dataNameField: 'tag_name',
    dataValueField: 'tags_percent',
    colColors: {},
  },
};

export default tagsChartConfig;
