import moment from 'moment';
import { mapActions } from 'vuex';
import ExportPanel from './children/ExportPanel.vue';

export default {
  data() {
    return {
      type: null,
      namespace: null,
      isOpen: false,
      sortOrder: { prop: 'name', order: 'ascending' },
      selectedCompliance: null,
    };
  },
  components: {
    ExportPanel,
  },
  methods: {
    ...mapActions({
      getCompliance: 'compliance/getCompliance',
    }),

    openPopup(compliance) {
      this.selectedCompliance = compliance;
      this.isOpen = true;
    },

    onClosePopup() {
      this.selectedCompliance = null;
      this.isOpen = false;
    },

    async commitAction(payload) {
      try {
        const response = await this.getCompliance({...payload, type: this.type});
        const reportName = this.$t(`${this.namespace}.report_name`, {
          name: this.$t(`${this.namespace}.table.${payload.name}.title`),
          from: moment.unix(payload.startDate).format('MMDDYYYY'),
          to: moment.unix(payload.endDate).format('MMDDYYYY'),
          defaultValue: '{{name}} {{from}} to {{to}}',
        });
        const type = response.headers.get('Content-Type') || '';
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(new Blob([response.data], { type }));
        anchor.target = '_blank';
        anchor.download = `${reportName}${payload.fileExtension}`;
        anchor.click();
        this.$aiq.notify.success('Report was generated successfully');
      } catch (err) {
        this.$aiq.notify.error('Unable to download the report');
      }
      this.isOpen = false;
      this.selectedCompliance = null;
    },
  },
};
