<template lang="pug">
.main-column_header-actions
  .form-row
    FilteredInput#item-search.search-item-input(
      v-if="advanced"
      v-model="searchTerms"
      :size="size"
      :placeholder="searchBoxPlaceholder"
      @update:modelValue="$emit('search', $event)"
      @focus="$emit('focus')"
      @blur="$emit('blur')")
    aiq-input#item-search.search-item-input(
      v-else
      v-model="searchTerms"
      :size="size"
      :placeholder="searchBoxPlaceholder"
      @input="$emit('search', searchTerms)"
      @focus="$emit('focus')"
      @blur="$emit('blur')")
    aiq-tooltip(effect="dark", placement="top", :content="addTooltip || $t('global.add')", :show-after="500" :disabled="createBoxShown")
      aiq-button.button-add-new-item(size="small"
                                    @click="toggleCreateBox")
        i(:class="icon")
  .form-row(v-if="createBoxShown")
    aiq-input.main-column_new-input(v-model="newItemName"
                                    ref="createBox"
                                    :rows="rows"
                                    :type="createBoxType"
                                    :size="size"
                                    :placeholder="newBoxPlaceholder"
                                    @keyup.enter="create(newItemName)"
                                    @keyup.esc="createBoxShown = false")
    aiq-tooltip(v-if="advanced" effect="dark", placement="top", :content="toggle ? inactiveToggleName: activeToggleName" :show-after="500")
      aiq-button.button-switch-pin(size="small" @click="toggle = !toggle" :class="{ 'button-active': toggle }")
        fa-icon(:icon="[toggle ? 'fas' : 'far', toggleIcon]")
  .actions(v-if="advanced && createBoxShown")
    aiq-button(size="small" @click="toggleCreateBox") {{ $t('global.cancel') }}
    aiq-button(type="primary" size="small" @click="create(newItemName)") {{ $t('global.save') }}
</template>

<script>
export default {
  props: {
    newBoxPlaceholder: {
      type: String,
      default: 'Create new item',
    },
    searchBoxPlaceholder: {
      type: String,
      default: 'Search',
    },
    rows: {
      type: Number,
      default: 2,
    },
    size: {
      type: String,
      default: 'small',
    },
    createBoxType: {
      type: String,
      default: 'text',
    },
    toggleIcon: {
      type: String,
      default: '',
    },
    activeToggleName: {
      type: String,
      default: '',
    },
    inactiveToggleName: {
      type: String,
      default: '',
    },
    advanced: Boolean,
    search: {
      type: String,
      default: '',
    },
    addTooltip: {
      type: String,
      default: '',
    },
  },
  emits: ['search', 'create', 'blur', 'focus'],
  data() {
    return {
      createBoxShown: false,
      toggle: false,
      newItemName: '',
      searchTerms: this.search,
    };
  },
  watch: {
    search(newVal) {
      this.searchTerms = newVal;
    },
  },
  computed: {
    icon() {
      return this.createBoxShown ? 'el-icon-minus' : 'el-icon-plus';
    },
  },
  methods: {
    create(name) {
      if (!name) return;

      this.$emit('create', this.advanced ? { name, toggle: this.toggle } : name);
      this.newItemName = '';
      this.toggle = false;
    },
    toggleCreateBox() {
      this.newItemName = this.searchTerms;
      this.createBoxShown = !this.createBoxShown;
      if (this.createBoxShown) {
        this.$nextTick(() => {
          /* TODO (Gabe) - nextTick causing refs to not be defined for tests. Adding
           * this conditional for now.
           */
          if (this.$refs.createBox) {
            this.$refs.createBox.focus();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/aiq-variables.scss";
button.button-switch-pin {
  background-color: transparent;
  &.button-active {
    color: $aiq-color-active;
  }
}
</style>
