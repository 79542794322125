/**
 * Middle container header
 *
 * @props header The label of title
 * @props title Title text
 * @props showCopy boolean to indicate the visibility of copy button.
 *
 * @event save Title change event triggered by manually editing text.
 * @event copyclick Button click event triggered cy pressing copy button.
 */
export default {
  name: 'MiddlePaneHeader',
  props: {
    header: {
      type: String,
      default: 'Header',
    },
    title: {
      type: String,
      default: 'Title',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'copy'],
  data() {
    return {
      // flag to go either editing mode or viewing mode
      editMode: false,
      // temporary editing text store
      editingText: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    onSave() {
      if (!this.editMode) {
        return;
      }

      this.editMode = false;
      if (this.editingText !== this.title) {
        // Let parent know new text is typed
        this.$emit('save', this.editingText);
      }
    },

    onEdit() {
      if (this.editMode) {
        return;
      }

      // Update text with original text
      this.editingText = this.title;
      this.editMode = true;

      this.$nextTick(() => {
        this.$refs.editBox.focus();
      });
    },
    init() {
      this.editMode = false;
      this.editingText = '';
    },

    onCopy() {
      this.$emit('copy');
    },
  },
  watch: {
    title() {
      this.init();
    },
  },
};
