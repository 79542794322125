
/**
 * Purifies the input HTML by removing script, style tags, and empty elements.
 *
 * @param {string} html - The HTML string to be purified.
 * @returns {string} - The purified HTML with script, style tags, and empty elements removed.
 */

export const htmlPurifier = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Remove all script tags
  const scriptTags = doc.querySelectorAll('script');
  scriptTags.forEach((tag) => tag.remove());

  // Remove all style tags
  const styleTags = doc.querySelectorAll('style');
  styleTags.forEach((tag) => tag.remove());

  // Remove all empty tags
  const emptyTags = doc.querySelectorAll('*:empty');
  emptyTags.forEach((tag) => tag.remove());

  // Get the purified HTML
  const purifiedHtml = doc.body.innerHTML;
  return purifiedHtml;
};
