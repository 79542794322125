<template lang="pug">
  extends ManagementPage.pug
  block prepend child
    .action-container
      .actions-filter(v-if="actionOptions.length > 0")
        aiq-tooltip(placement="right" :disabled="hasRowSelections")
          template(v-slot:content)
            div.mgmt-page-actions-error
              .title {{ actionsErrorText.title }}
              .description {{ actionsErrorText.description }}
          aiq-select(placeholder="Actions"
                    size="default"
                    :model-value="chosenAction"
                    @change="$emit('chooseAction', $event)"
                    :disabled="!hasRowSelections")
            aiq-option(v-for="option in actionOptions"
                      :key="option"
                      :label="option"
                      :value="option")
      aiq-tooltip.help-icon(v-if="actionOptions.length > 0", placement="right")
        template(v-slot:content)
          div(v-html="$filters.sanitize(helpText)")
        i.iq-ico-explanation
      aiq-tooltip(effect="dark", content="Click to open advanced filters", placement="top", :show-after="1000", v-if="isMobileContext")
        aiq-button.dots-button(@click="toggleOpen", :class="{'closed': !openFilters}") &#8942;
</template>

<script>
import { mapState } from 'vuex';
import ManagementPage from './ManagementPage.vue';

export default {
  name: 'managementPageWithActions',
  extends: ManagementPage,
  props: {
    helpText: {
      type: String,
      default: '',
    },
    actionsErrorText: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
      }),
    },
    actionOptions: {
      type: Array,
      default: () => [],
    },
    chosenAction: {
      type: String,
      default: '',
    },
    hasRowSelections: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['chooseAction', 'toggleOpen'],
  computed: {
    ...mapState({
      isMobileContext: state => state.settings.isMobileContext,
    }),
  },
  methods: {
    toggleOpen() {
      this.$emit('toggleOpen', !this.openFilters);
    },
  },
  watch: {
    dateStart(newValue, _oldValue) {
      const ele = document.querySelector('div.date-range-filter.filter-in-header > div.date-range-filter-date-field-popup > div.el-input.el-input--prefix.el-input--suffix.el-date-editor.el-date-editor--date.normal-date-picker-popup.el-tooltip__trigger.el-tooltip__trigger > div > span.el-input__prefix > span > i');
      if (newValue === null) {
        ele.style.display = 'block';
      } else {
        ele.style.display = 'none';
      }
    },
    dateEnd(newValue, _oldValue) {
      const ele = document.querySelector('div.date-range-filter.filter-in-header > div.date-range-filter-date-field-popup > div.el-input.el-input--prefix.el-input--suffix.el-date-editor.el-date-editor--date.normal-date-picker-end-popup.el-tooltip__trigger.el-tooltip__trigger > div > span.el-input__prefix > span > i');
      if (newValue === null) {
        ele.style.display = 'block';
      } else {
        ele.style.display = 'none';
      }
    },
  },
  data() {
    return {
      enableActionsError: false,
    };
  },
};
</script>

<style lang="scss">
  .header {
    .header_actions {
      .action-container {
        .actions-filter {
          .el-select {
            .el-input {
              .el-input__inner {
                background: #69f;
                color: #fff;

                &[disabled] {
                  background: #9da4b1;
                }
              }

              .el-input__inner::placeholder{
                color: #fff;
                font-weight: bold;
                font-size: 12px;
              }
            }

            .select-action-down {
              left: 0 !important;
              .el-scrollbar{
                min-width: 180px !important;
              }
            }
          }
        }

        * > .dots-button:hover, .dots-button:focus {
          color: inherit;
        }

        .dots-button span {
          transition: transform .3s;
          display: block !important;
        }

        .closed {
          span {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  .mgmt-page-actions-error {
    text-align: center;
    .title {
      font-weight: 700;
      line-height: 2;
    }
    .description {
      font-weight: 300;
    }

  }
</style>

<style lang="scss" scoped>
  @import "./ManagementPage.scss";

    .dots-button{
      display: inline-block;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      min-height: 32px;
      font-size: 24px;
      line-height: 32px;
      padding: 0;
      border: 0;
      margin-right: 2px;
    }

    .header {
      display: flex;
      flex-direction: column;
      .header_actions {
        padding: 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include mobile{
          display: block !important;
        }
      }
      .actions-filter {
        width: 180px;
        height: 30px;
        display: inline-block;

         @include mobile {
           min-width: 80%;
          .el-select{
            width: 100%;
          }
        }
      }
      .select-box-container {
          width: 400px;
          height: 32px;
      }
    }
    .action-cotainer {
      min-width: 14.5rem;
    }
    .iq-ico-explanation::before {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      display: inline-block;
      vertical-align: middle;
    }
</style>


