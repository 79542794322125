<template lang="pug">
aiq-dialog(title="Calendar"
            :width="'80%'"
            :model-value="true"
            :show-close="false"
            :close-on-press-escape="true"
            :close-on-click-modal="true"
            :before-close="handleBeforeClose")
  .external-integrations(v-if="showOutlookIcon")
    MsOutlookIcon

  .view-schedules(v-if="showTablePage && !this.showOnlyForm")
    calendar(:schedules="schedules"
             :useNewCalendar="showNewCalendar"
             @change="fetchMonthSchedlues"
             @item-click="showCreateOrEditSchedule")

  .edit-schedules(v-else)
    .title
      h1  {{ editHeadingStr }}
    .form-body
      aiq-form(label-width="120px")
        aiq-form-item(label="Type" required)
          aiq-select.select-size(v-model="schedule.event_type"
                                 placeholder="Select Reminder Type"
                                 size="small"
                                 :clear-icon="Icon.CircleCloseFilled"
                                 :suffix-icon="Icon.CaretBottom"
                                 :disabled="isScheduleRun")
            aiq-option(v-for="item in supportingEvents"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value")
        aiq-form-item(label="Time" required)
          aiq-col(:span="11")
            aiq-date-picker.el-input__inner(v-model="dateObj"
                                            type="date"
                                            popper-class="date-picker-popup"
                                            style="width: 100%;"
                                            :disabled-date="disabledDate"
                                            :clearable="false"
                                            placeholder="Select date"
                                            format="YYYY/MM/DD"
                                            :disabled="isScheduleRun")
          aiq-col(:span="2")
            .dash -
          aiq-col(:span="11")
            aiq-time-select.time-pick-input(v-model="timeStr"
                                            popper-class="time-picker-popup"
                                            :start="startTime"
                                            step="00:15"
                                            end="23:45"
                                            placeholder="Select time"
                                            :clearable="false"
                                            :disabled="!dateObj || isScheduleRun ")
        aiq-form-item(v-if="this.schedule.customer_id" label="Customer")
          aiq-button.zero-padding(link type="primary" @click="goToConversation") {{ customerName }}
        aiq-form-item(label="Title")
          aiq-input(v-model="schedule.title"
                    placeholder="Title"
                    :disabled="isScheduleRun")
        aiq-form-item(label="Description")
          aiq-input(v-model="schedule.description"
                    type="textarea"
                    placeholder="Description"
                    :disabled="isScheduleRun")
        .guide * is required

  template(v-slot:footer)
    .table-page-footer(v-if="showTablePage && !this.showOnlyForm")
      aiq-button(@click="close") Close
      aiq-button(type="primary" @click="showCreateOrEditSchedule()") New Reminder
    .edit-page-footer(v-else)
      aiq-button(v-if="isEditing" type="danger" @click="cancelSchedule") Delete
      aiq-button(v-if="this.showOnlyForm" @click="close") Close
      aiq-button(v-if="!this.showOnlyForm" @click="cancelEdit") Cancel
      aiq-button(v-if="!isScheduleRun" type="primary"
                 @click="createOrUpdateSchedule"
                 :disabled="!isScheduleSavable || !isEdited") Save
</template>

<script>
import get from 'lodash/get';
import has from 'lodash/has';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import * as log from 'loglevel';
import { SCHEDULE_TYPE } from '@/constants/analytics';

import { getCustomerName, confirmBeforeDialogClose } from '@/libs';

import Calendar from './Calendar.vue';
import MsOutlookIcon from '@/assets/svg/ms-outlook.vue';


const SUPPORTED_SCHEDULES = [
  {
    label: 'Reminder Notification',
    value: 'reminder',
  },
];


const SUPPORTED_SCHEDULES_TYPE_TO_LABEL = SUPPORTED_SCHEDULES.reduce((lookup, obj) => {
  lookup[obj.value] = obj.label;
  return lookup;
}, {});

const TIME_INTERVAL = 15;

function getMomentNextTimeCycle(dateObj) {
  let addition = TIME_INTERVAL - (dateObj.getMinutes() % TIME_INTERVAL);
  // make sure the time is in future
  addition = (addition === 0) ? TIME_INTERVAL : addition;
  return moment(dateObj).add(addition, 'minutes');
}

function emptySchedule() {
  return {
    title: '',
    description: '',
    event_type: '',
    event_data: {},
    customer_id: null,
    execute_at: getMomentNextTimeCycle(new Date()).toISOString(),
  };
}

const YESTERDAY_DATEOBJECT = moment().subtract(1, 'days').toDate();

function isToday(date) {
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

const EDIT_MODE = {
  CREATE: 'create',
  UPDATE: 'update',
  DONE: 'done',
};

export default {
  name: 'SchedulesDialog',
  components: {
    Calendar,
    MsOutlookIcon,
  },
  computed: {
    ...mapState({
      schedulesByMonth: state => state.agent.schedules,
      showOutlookIcon: state => state.featureFlags.FEATURE_FLAGS.MS_OUTLOOK_SYNC,
      showNewCalendar: state => state.featureFlags.FEATURE_FLAGS.UPDATED_CALENDAR,
    }),

    schedulesLoggin() {
      const schedules = cloneDeep(this.schedule);
      schedules.type = this.showOnlyForm ? SCHEDULE_TYPE.NOTES : SCHEDULE_TYPE.CALENDAR;
      return schedules;
    },

    schedules() {
      if (this.visibleCalendar.year === 0) {
        return [];
      }

      const key = `${this.visibleCalendar.year}-${this.visibleCalendar.month}`;
      if (!has(this.schedulesByMonth, key)) {
        return [];
      }

      return this.schedulesByMonth[key];
    },

    supportingEvents() {
      return SUPPORTED_SCHEDULES;
    },

    isEditing() {
      return this.editMode === EDIT_MODE.UPDATE;
    },

    isScheduleRun() {
      return this.editMode === EDIT_MODE.DONE;
    },

    editHeadingStr() {
      if (this.editMode === EDIT_MODE.CREATE) {
        return 'New Reminder';
      }

      if (this.editMode === EDIT_MODE.UPDATE) {
        return 'Edit Reminder';
      }

      if (this.editMode === EDIT_MODE.DONE) {
        return 'View Reminder';
      }

      return '';
    },

    editMode() {
      if (get(this.schedule, 'id', -1) === -1) {
        return EDIT_MODE.CREATE;
      }

      if (get(this.schedule, 'executed_at', null)) {
        return EDIT_MODE.DONE;
      }

      return EDIT_MODE.UPDATE;
    },

    isEdited() {
      return !isEqual(this.schedule, this.originalSchedule);
    },

    isScheduleSavable() {
      if (!this.schedule) {
        return false;
      }

      return this.schedule.execute_at && this.schedule.event_type
        && moment(this.schedule.execute_at) > moment();
    },

    disabledDate() {
      return (date) => (date <= YESTERDAY_DATEOBJECT);
    },

    startTime() {
      if (this.dateObj && isToday(this.dateObj)) {
        // should change start time as it needs to show future time.
        return getMomentNextTimeCycle(new Date()).format('HH:mm');
      }
      return '00:00';
    },

    dateObj: {
      set(newDateObj) {
        if (newDateObj) {
          const m = moment(this.schedule.execute_at);
          m.set('year', newDateObj.getFullYear());
          m.set('month', newDateObj.getMonth());
          m.set('date', newDateObj.getDate());
          this.schedule.execute_at = m.toISOString();
        }
      },
      get() {
        return (this.schedule) ? moment(this.schedule.execute_at).toDate() : null;
      },
    },

    timeStr: {
      set(newTimeStr) {
        if (newTimeStr) {
          const m = moment(this.schedule.execute_at);
          const [hour, minute] = newTimeStr.split(':').map(s => parseInt(s, 10));
          m.set('hour', hour);
          m.set('minute', minute);
          this.schedule.execute_at = m.toISOString();
        }
      },
      get() {
        return (this.schedule) ? moment(this.schedule.execute_at).format('HH:mm') : '';
      },
    },
    customerName() {
      return getCustomerName(get(this.schedule, 'event_data.customer', {}));
    },
  },
  props: {
    showOnlyForm: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showTablePage: true,
      schedule: emptySchedule(),
      originalSchedule: null,
      visibleCalendar: {
        month: 0,
        year: 0,
      },
    };
  },
  mounted() {
    if (this.formData) {
      this.schedule = merge({}, this.schedule, pick(this.formData, ['id', ...Object.keys(this.schedule)]));

      if (this.schedule.id) {
        this.showCreateOrEditSchedule(this.schedule);
      }
    }

    const m = moment();
    this.visibleCalendar = { month: m.month() + 1, year: m.year() };
    this.fetchMonthSchedlues(this.visibleCalendar);
  },
  methods: {
    ...mapActions({
      selectSuggestions: 'conversations/selectSuggestions',
      obfuscateMessage: 'conversations/obfuscateMessage',
    }),

    fetchMonthSchedlues(date) {
      this.visibleCalendar = date;
      const query = { ...date, time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone };
      this.$store.dispatch('agent/fetchScheduledJobs', query);
    },

    getEventTypeLabel(eventType) {
      return SUPPORTED_SCHEDULES_TYPE_TO_LABEL[eventType];
    },

    getTimeFormatted(timeStr) {
      return moment(timeStr).format('MMM DD, YYYY hh:mm A');
    },

    getSchedules() {
      return {
        schedule: this.schedule,
        schedulesLoggin: this.schedulesLoggin,
      };
    },

    handleBeforeClose() {
      if ((this.showTablePage && !this.showOnlyForm) || !this.isEdited) {
        this.close();
        return;
      }

      confirmBeforeDialogClose(this, () => {
        if (this.showOnlyForm) {
          this.close();
        } else {
          this.cancelEdit();
        }
      });
    },

    close() {
      const type = this.showOnlyForm ? SCHEDULE_TYPE.NOTES : SCHEDULE_TYPE.CALENDAR;
      this.$store.dispatch('agent/changeDialogVisibility', { name: 'schedulesDialog', visible: false, type });
    },

    showCreateOrEditSchedule(schedule) {
      if (schedule) {
        const simpleSchedule = merge({}, pick(cloneDeep(schedule), ['id', ...Object.keys(emptySchedule())]));
        this.schedule = cloneDeep(simpleSchedule);
        this.originalSchedule = cloneDeep(simpleSchedule);
      } else {
        const newEmptySchdule = emptySchedule();
        this.schedule = cloneDeep(newEmptySchdule);
        this.originalSchedule = cloneDeep(newEmptySchdule);
      }
      this.showTablePage = false;
    },

    cancelEdit() {
      this.schedule = emptySchedule();
      this.originalSchedule = null;
      this.showTablePage = true;
    },

    async cancelSchedule() {
      if (this.schedule.id) {
        try {
          await this.$store.dispatch('agent/cancelScheduledJob', this.getSchedules());
          this.$aiq.notify.success('Success');
        } catch (err) {
          log.error(err);
          this.$aiq.notify.error('Unable to cancel the schedule');
        }
      }
      this.cancelEdit();

      if (this.showOnlyForm) {
        await this.$store.dispatch('conversations/refreshSchedule');
        this.close();
      }
    },

    async createOrUpdateSchedule() {
      try {
        if (this.schedule.id) {
          await this.$store.dispatch('agent/updateScheduledJob', this.getSchedules());
        } else {
          await this.$store.dispatch('agent/createScheduledJob', this.getSchedules());
        }

        this.$aiq.notify.success('Success');
      } catch (err) {
        log.error(err);
        this.$aiq.notify.error('Failed');
      }

      this.cancelEdit();

      if (this.showOnlyForm) {
        await this.$store.dispatch('conversations/refreshSchedule');
        this.close();
      }
    },

    goToConversation() {
      this.close();
      if (get(this.schedule, 'event_data.customer')) {
        this.$router.push({
          name: 'ConversationByCustomerId',
          params: { id: get(this.schedule, 'event_data.customer.id') },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/aiq-variables.scss";

.title {
  margin-bottom: 20px;
}

.time-range {
  width: 200px;
}

.date-picker-popup {
  width: 240px;
  height: 100px;
}

.data-picker-popup {
  width: 240px;
  height: 100px;
}

:deep(.time-pick-input) {
  .el-select__wrapper {
    min-height: unset;
    box-shadow: 0px 0px 0px 1px $aiq-icon-primary inset;
    flex-direction: row-reverse;
    .el-select__input {
      height: 22px;
      margin-left: unset;
    }
    .el-select__placeholder {
      font-size: 12px;
    }
    .el-select__suffix {
      display: none;
    }
  }
}

.form-body {
  :deep(.select-size.el-select .el-select__input) {
    height: 36px;
  }
  :deep(.el-input.el-input__inner .el-input__prefix .el-input__icon) {
    margin-right: unset;
    bottom: 0px;
    right: -10px;
  }
}

.dash {
  text-align: center;
}

.zero-padding {
  padding: 0px;
}

.guide {
  color: $aiq-color-error;
  text-align: right;
  font-weight: 300;
}

.external-integrations {
  display: flex;
  justify-content: end;
}
</style>
