<template lang="pug">
  .content-inner
    .header
      h1.title Routing Configuration

    .routing-container
      .sub-header--solid Auto Routing
      .routing-content-wrapper
        .form__inner-content
          .info-text-container
            .info-text Add customer into queue after the elapsed time if an agent is not involved in conversation.
          aiq-row
            aiq-col(:span="12")
              label Segments
              aiq-select(:model-value="selectedSegmentsForUnassignedCustomer"
                          placeholder="Select segments"
                          size="small"
                          multiple
                          :reserve-keyword="false"
                          @change="onSegmentChangeForUnassignedCustomer")
                  aiq-option(v-for="(segment) in segments"
                            :key="segment"
                            :label="segment"
                            :value="segment")
          aiq-row.padding-top-10(:gutter="12")
            aiq-col(:span="12")
              label Elapsed Time(secs)
                aiq-input(type="number" v-model="unassignedCustomers.elapsed_time" @change="onElapsedTimeChange")

    .routing-container.padding-top-10
      .sub-header--solid Auto Primary Agent Assignment
      .routing-content-wrapper
        .form__inner-content
          .info-text-container
            .info-text Assign a primary agent when an agent is assigned if no primary agent is set.
          aiq-row
            aiq-col(:span="12")
              label Segments
              aiq-select(:model-value="selectedSegmentsForPrimaryAssignment"
                         placeholder="Select segments"
                         size="small"
                         multiple
                         :reserve-keyword="false"
                         @change="onSegmentChangeForPrimaryAssignment")
                  aiq-option(v-for="(segment) in segments"
                            :key="segment"
                            :label="segment"
                            :value="segment")
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import { mapGetters } from 'vuex';

export default {
  name: 'routing-tab',
  computed: {
    ...mapGetters({
      segments: 'configs/segmentNames',
    }),
    selectedSegmentsForPrimaryAssignment() {
      return get(this.routing, 'primary_agent_assignment.by_segment', []);
    },
    selectedSegmentsForUnassignedCustomer() {
      return get(this.unassignedCustomers, 'segments', []);
    },
  },
  async mounted() {
    const [routing, unassignedCustomers] = await Promise.all([
      this.$store.dispatch('settings/getSetting', { name: 'routing' }),
      this.$store.dispatch('settings/getSetting', { name: 'unassigned_customers' })]);

    this.routing = routing;
    this.unassignedCustomers = unassignedCustomers;
  },
  data() {
    return {
      routing: {
        primary_agent_assignment: {
          by_segment: [],
        },
      },
      unassignedCustomers: {
        customer_created_first_timestamp: 0,
        elapsed_time: 0,
        segments: [],
      },
    };
  },
  methods: {
    updateSetting(name, payload) {
      return this.$store.dispatch('settings/updateSetting', { name, payload })
        .then(() => {
          this.$aiq.notify.success('Settings successfully saved.');
        }).catch(() => {
          this.$aiq.notify.error('Failed to update settings.');
        });
    },

    onSegmentChangeForPrimaryAssignment(segments) {
      set(this.routing, 'primary_agent_assignment.by_segment', segments);
      return this.updateSetting('routing', this.routing);
    },

    onSegmentChangeForUnassignedCustomer(segments) {
      set(this.unassignedCustomers, 'segments', segments);
      return this.updateSetting('unassigned_customers', this.unassignedCustomers);
    },

    onElapsedTimeChange(value) {
      set(this.unassignedCustomers, 'elapsed_time', parseInt(value, 10));
      return this.updateSetting('unassigned_customers', this.unassignedCustomers);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
@import "../../../../../styles/aiq-extensions.scss";

  .routing-content {
      @include space-between-h(15px);

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;

      &:first-of-type .routing-card-container {
        margin-bottom: 15px;
      }
  }

  .routing-content-wrapper {
      background: #FFFFFF;
      border-left: 1px solid #E0E6ED;
      border-bottom: 1px solid #E0E6ED;
      border-right: 1px solid #E0E6ED;
      padding: 12px 15px;
  }

  .routing-config-label {
    font-weight: bold;
    margin-left: 10px;
  }

  .routing-placeholder {
    @extend %agent-routing-sizing;
  }

  .padding-top-10 {
    padding-top: 10px;
  }
</style>
