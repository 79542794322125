<template lang="pug">
.today-conversations-container
  segment-select(v-if="initialSegments.length"
                :customer-segment-filter-options="customerSegments"
                :segment-alias="segmentAlias"
                :container-style="{ display: 'block' }"
                :is-select-multiple="true"
                :inital-selection="initialSegments"
                tooltip-placement="bottom"
                @change="handleSelectChange"
                @on-segment-hover="onSegmentHover"
                ref="selectorRef"
                :isCollapsible="true")
  today-heat-maps-list(:metrics="metrics"
                      :isLoading="isLoading"
                      :heat-maps-initial-confg="heatMapsInitialConfg"
                      :selected-options="selectedSegments")
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import max from 'lodash/max';
import sum from 'lodash/sum';
import { median } from '@/libs';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import TodayHeatMapsList from './components/TodayHeatMapsList.vue';

const DEFAULT_SEGMENT_SELECTIONS = ['anonymous', 'authenticated'];
const SEGMENT_SELECTIONS_CACHE_KEY = 'pulse/today/segments';

export default {
  name: 'PulseTodayConversations',
  components: {
    SegmentSelect,
    TodayHeatMapsList,
  },
  props: {
    metrics: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      customerSegments: 'configs/segments',
    }),
    heatMapsInitialConfg() {
      return [
        {
          id: 'conversations',
          title: 'Conversations',
          titleTooltip: {
            intro: this.$t('pulse_tab.today_tab.conversations_metrics_tab.conversations_chart.title_tootlip.intro'),
            details: [
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.conversations_chart.title_tootlip.listItem1'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.conversations_chart.title_tootlip.listItem2'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.conversations_chart.title_tootlip.listItem3'),
            ],
          },
          responseDataKey: 'new_conversations_per_segment_hourly',
          leftAggregate: {
            label: 'Median Today',
            combineElements: median,
            valueKey: 'count',
            defaultMetricCategory: 'count',
          },
          rightAggregate: {
            label: 'Total Today',
            combineElements: sum,
            valueKey: 'count',
            defaultMetricCategory: 'count',
          },
          tooltips: [
            { valueKey: 'count', label: 'Conversations', defaultMetricCategory: 'count' },
          ],
          tooltipsClassName: 'today-conversations-container',
          chartConfig: {
            metric: 'new_conversations',
            lines: {
              new_conversations: {
                valueKey: 'count',
                xKey: 'hour',
                yKey: 'segment',
              },
            },
          },
          colors: ['#E4EBF4', '#BDCDE5', '#91ABD3', '#6489C1', '#2257A7'],
        },
        {
          id: 'first-response-time',
          title: 'First Response Time',
          titleTooltip: {
            intro: this.$t('pulse_tab.today_tab.conversations_metrics_tab.first_response_time_chart.title_tootlip.intro'),
            details: [
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.first_response_time_chart.title_tootlip.listItem1'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.first_response_time_chart.title_tootlip.listItem2'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.first_response_time_chart.title_tootlip.listItem3'),
            ],
          },
          responseDataKey: 'response_times_per_segment_hourly',
          leftAggregate: {
            label: 'Median Today',
            combineElements: median,
            valueKey: 'median_time',
            defaultMetricCategory: 'interval',
          },
          rightAggregate: {
            label: 'Max Today',
            combineElements: max,
            valueKey: 'max_time',
            defaultMetricCategory: 'interval',
          },
          tooltips: [
            { valueKey: 'median_time', label: 'Median', defaultMetricCategory: 'interval' },
            { valueKey: 'max_time', label: 'Max', defaultMetricCategory: 'interval' },
          ],
          tooltipsClassName: 'today-conversations-container',
          chartConfig: {
            metric: 'response_times',
            lines: {
              response_times: {
                valueKey: 'median_time',
                xKey: 'hour',
                yKey: 'segment',
              },
            },
          },
          colors: ['#E2F1E6', '#B6DCC1', '#86C498', '#56AC6E', '#0B812B'],
        },
        {
          id: 'queue-size',
          title: 'Queue Size',
          titleTooltip: {
            intro: this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_size_chart.title_tootlip.intro'),
            details: [
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_size_chart.title_tootlip.listItem1'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_size_chart.title_tootlip.listItem2'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_size_chart.title_tootlip.listItem3'),
            ],
          },
          responseDataKey: 'queue_times_per_segment_hourly',
          leftAggregate: {
            label: 'Median Today',
            combineElements: median,
            valueKey: 'median_size',
            defaultMetricCategory: 'count',
          },
          rightAggregate: {
            label: 'Max Today',
            combineElements: max,
            valueKey: 'max_size',
            defaultMetricCategory: 'count',
          },
          tooltips: [
            { valueKey: 'median_size', label: 'Median', defaultMetricCategory: 'count' },
            { valueKey: 'max_size', label: 'Max', defaultMetricCategory: 'count' },
          ],
          tooltipsClassName: 'today-conversations-container',
          chartConfig: {
            metric: 'queue_size',
            lines: {
              queue_size: {
                valueKey: 'median_size',
                xKey: 'hour',
                yKey: 'segment',
              },
            },
          },
          colors: ['#FFF2DB', '#FFE9C2', '#FFDB9C', '#FFCD75', '#FFBE50'],
        },
        {
          id: 'queue-wait-time',
          title: 'Queue Wait Time',
          titleTooltip: {
            intro: this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_wait_time_chart.title_tootlip.intro'),
            details: [
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_wait_time_chart.title_tootlip.listItem1'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_wait_time_chart.title_tootlip.listItem2'),
              this.$t('pulse_tab.today_tab.conversations_metrics_tab.queue_wait_time_chart.title_tootlip.listItem3'),
            ],
          },
          responseDataKey: 'queue_times_per_segment_hourly',
          leftAggregate: {
            label: 'Median Today',
            combineElements: median,
            valueKey: 'median_time',
            defaultMetricCategory: 'interval',
          },
          rightAggregate: {
            label: 'Max Today',
            combineElements: max,
            valueKey: 'max_time',
            defaultMetricCategory: 'interval',
          },
          tooltips: [
            { valueKey: 'median_time', label: 'Median', defaultMetricCategory: 'interval' },
            { valueKey: 'max_time', label: 'Max', defaultMetricCategory: 'interval' },
          ],
          tooltipsClassName: 'today-conversations-container',
          chartConfig: {
            metric: 'queue_wait_time',
            lines: {
              queue_wait_time: {
                valueKey: 'median_time',
                xKey: 'hour',
                yKey: 'segment',
              },
            },
          },
          colors: ['#F8E2E2', '#EEB6B6', '#E38686', '#D75555', '#C60C0C'],
        },
      ];
    },
  },
  data() {
    return {
      segmentAlias: '',
      selectedSegments: [],
      initialSegments: [],
      isLoading: true,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch('configs/getCustomerSegments');
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }

    this.initializeSegmentSelections();
  },
  methods: {
    initializeSegmentSelections() {
      const cachedSegmentNames = localStorage.getItem(SEGMENT_SELECTIONS_CACHE_KEY);
      const initialSegmentNames = cachedSegmentNames ? cachedSegmentNames.split(',') : DEFAULT_SEGMENT_SELECTIONS;
      const initialSegments =
        this.customerSegments.filter(segment => initialSegmentNames.includes(segment.name));

      this.initialSegments = initialSegments;
      this.handleSelectChange(initialSegments);
    },
    handleSelectChange(newSelections) {
      this.selectedSegments = newSelections;

      if (!this.isLoading) {
        const segmentNames = newSelections.map(segment => segment.name);
        localStorage.setItem(SEGMENT_SELECTIONS_CACHE_KEY, segmentNames);
      }

      if (this.metrics.lastFetchedAt) {
        this.isLoading = false;
      }
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
  },
  watch: {
    'metrics.lastFetchedAt': function watchMetricsLastFetchedAt() {
      if (this.selectedSegments.length) {
        this.isLoading = false;
      }
    },
  },
};
</script>