<template lang="pug">
  .content-inner.membership-tab

    aiq-row(:gutter="28")
      aiq-col(:sm="12",:xs="24").field-container.is-required
        label Roles
        aiq-select(v-model="selectedRoles"
                    size="small"
                    multiple
                    collapse-tags
                    :suffix-icon="Icon.CaretBottom"
                    :disabled="disabled || ownProfile || !canEdit('/global/profile/roles')"
                    @change="onChangeRole($event)"
                    name="roles"
                    :reserve-keyword="false")
          aiq-option(v-for="item in availableRoles", :key="item", :label="$filters.toUpperFirst(item)", :value="item")

      aiq-col(:sm="12",:xs="24").field-container.is-required
        label Email
        aiq-input(v-model.trim="record.email",
                  size="small",
                  type="email",
                  name="email",
                  :disabled="disabled || ownProfile",
                  @input="onChange('email', $event)")

    aiq-row.field-container
      aiq-col(:sm="8",:xs="24").field-container
        .label-container
          label Primary Capacity
          aiq-tooltip(placement="bottom"
                     popper-class="capacity-explanation-popup"
                     :content="$filters.sanitize($t('global.primary_capacity_explanation'))"
                     raw-content)
            fa-icon.info-icon(:icon="['fas', 'circle-question']")
        aiq-input-number(v-model="record.max_load"
                          size="small"
                          :min="0"
                          name="max_load",
                          :disabled="disabled || ownProfile"
                          @change="onChange('max_load', $event)")

      aiq-col(:sm="8",:xs="24").field-container
        .label-container
          label Concurrent Capacity
          aiq-tooltip(placement="bottom"
                      popper-class="capacity-explanation-popup"
                      :content="$filters.sanitize($t('global.concurrent_capacity_explanation'))"
                      raw-content)
            fa-icon.info-icon(:icon="['fas', 'circle-question']")
        aiq-input-number(v-model="record.concurrent_capacity"
                          size="small"
                          :min="0"
                          name="concurrent_capacity",
                          :disabled="disabled || ownProfile"
                          @change="onChange('concurrent_capacity', $event)")

      aiq-col(:sm="8",:xs="24").field-container
        label External Id
        aiq-input(v-model="record.profile.external_id"
                  size="small"
                  name="external_id",
                  :disabled="disabled || ownProfile"
                  @input="onChange('profile.external_id', $event)")
    aiq-row.field-container
      label Assigned Teams
      .select-box-container
        aiq-select(v-model="record.teams"
                  value-key="id"
                  size="small"
                  multiple
                  :suffix-icon="Icon.CaretBottom"
                  :disabled="disabled || ownProfile"
                  @change="onTeamChange($event)"
                  name="teams"
                  placeholder="Select the teams you belong to"
                  :reserve-keyword="false")
          aiq-option(v-for="item in teams", :key="item.id", :label="item.name", :value="item")

    aiq-row.field-container
      label Watch Teams
      .select-box-container
        aiq-select(v-model="record.watchableTeams"
                  value-key="id"
                  size="small"
                  multiple
                  :suffix-icon="Icon.CaretBottom"
                  :disabled="disabled || !canEdit('/global/profile/watchable_teams') || isLocked"
                  name="watchableTeams"
                  placeholder="Select the teams to watch"
                  :reserve-keyword="false")
          aiq-option(v-for="item in teams", :key="item.id", :label="item.name", :value="item")

    aiq-row.field-container
      label Disclaimer
      aiq-input(:model-value="disclaimers",
                size="small",
                type="textarea"
                name="disclaimers"
                :disabled="disabled || isLocked"
                @input="onChange('disclaimers', $event)")
    aiq-row.field-container
      aiq-col(:span="12").sub-container
        label Carousel Eligible
        aiq-switch(style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                  :model-value="carouselEligible"
                  @update:modelValue="onCarouselEligibleChange",
                  :disabled="disabled || !canEdit('/global/profile/update_carousel_eligibility')"
                  name="carousel_eligible")
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import set from 'lodash/set';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { createRolesAccess } from '@/constants';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      selectedRoles: { required, $autoDirty: true},
      record: {
        email: { required, $autoDirty: true},
        max_load: { $autoDirty: true },
        concurrent_capacity: { $autoDirty: true },
        external_id: { $autoDirty: true },
        teams: { $autoDirty: true },
        watchableTeams: { $autoDirty: true },
        disclaimers: { $autoDirty: true },
        preferences: {
          carousel_eligible: { $autoDirty: true },
        },
      },
    };
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['change'],
  computed: {
    ...mapState({
      loggedIn: state => state.agent.profile,
      teams: state => state.teams.teams,
      permissions: state => state.agent.permissions,
    }),
    disclaimers() {
      return get(this.record, 'disclaimers.default', '');
    },
    availableRoles() {
      return createRolesAccess(this.permissions);
    },
    ownProfile() {
      return this.record.id === this.loggedIn.id;
    },
    isLocked() {
      return this.ownProfile && !this.canEdit('/global/profile/membership');
    },
    carouselEligible() {
      return get(this.record, 'preferences.carousel_eligible', true);
    },
  },
  watch: {
    record(newValue) {
      this.selectedRoles = get(newValue, 'roles', []);
    },
  },
  data() {
    return {
      maxInputLength: 50,
      roles: this.roles,
      selectedRoles: get(this.record, 'roles', []),
    };
  },
  methods: {
    // TODO: (xrd) I don't understand why we use selectedRole here
    // and were using record.teams for the teams. It seems like we should
    // just use v-model="record.roles" inside the select, no?
    onChangeRole(roles) {
      this.selectedRoles = roles;
      set(this.record, 'roles', roles);
      this.$emit('change', { key: 'roles', roles });
    },
    onTeamChange(teams) {
      set(this.record, 'teams', teams);
      this.$emit('change', { key: 'teams', teams });
    },
    onChange(key, val) {
      if (key === 'disclaimers') {
        set(this.record, key, set({}, 'default', val));
      }
      this.$emit('change', { key, val });
    },
    onCarouselEligibleChange(value) {
      set(this.record, 'preferences.carousel_eligible', value);
      this.$emit('change', { key: 'carousel_eligible', value });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-box-container {
  width: 100%;
}
.info-icon {
  margin-left: 5px;
}

.el-switch {
  margin-left: 30px;
}
</style>

<style lang="scss">
.capacity-explanation-popup.el-popper {
  max-width: 400px !important;
}
</style>

