import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import isEqual from 'lodash/isEqual';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

const { mapActions, mapState } = createNamespacedHelpers('configs');

const INFO = `Connect and integrate with Slack. <br><br>For more information and to
  get a webhook, please refer to the Slack docs <a target="_blank"
  href="https://get.slack.help/hc/en-us/articles/115005265063-Incoming-WebHooks-for-Slack">here</a>.`;

export default {
  name: 'SlackTab',
  components: {
    ManagementPage,
  },
  data() {
    return {
      localForm: { webhook: null },
    };
  },
  computed: {
    ...mapState({
      savedForm: state => state.integrations.slack.form,
    }),
    pristine() {
      return isEqual(this.localForm, this.savedForm);
    },
  },
  created() {
    this.$_info = INFO;
  },
  watch: {
    savedForm: {
      handler(savedForm) {
        this.localForm = cloneDeep(savedForm);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([
      'loadSlack',
      'updateSlack',
    ]),
    load() {
      this.loadSlack();
    },
    resetForm() {
      this.localForm = cloneDeep(this.savedForm);
    },
    saveForm() {
      return this.updateSlack(this.localForm)
        .then(() => {
          this.$aiq.notify.success('Slack credentials saved.');
        }).catch(() => {
          this.$aiq.notify.error('Error saving Slack credentials.');
        });
    },
  },
};