import axios from 'axios';
import merge from 'lodash/merge';

import { getUrl } from '@/config/api.routes';
import { CRUDStoreBuilder } from '../StoreBuilder';

const routingAlias = 'management.quickResponses';

const quickResponseModel = () => ({
  title: undefined,
  content: undefined,
  is_pinned: undefined,
  teams: [],
});

export default merge(
  new CRUDStoreBuilder(
    'management.quickResponses',
    'quickResponse',
    [],
    'data.models',
    true,
  ).createStore(),
  {
    state: {
      selected: {},
    },
    getters: {
      quickResponseModel,
    },
    mutations: {
      SELECT_ASSET(state, item) {
        state.selected = item;
        return item;
      },
    },
    actions: {
      selectQuickresponse({ commit }, item) {
        commit('SELECT_ASSET', item);
        return item;
      },
      pin(_, id) {
        return axios.put(`${getUrl(routingAlias)}/${id}/pin`, { value: true });
      },
      unpin(_, id) {
        return axios.put(`${getUrl(routingAlias)}/${id}/pin`, { value: false });
      },
      teams(_, { id, teams }) {
        return axios.put(`${getUrl(routingAlias)}/${id}/teams`, { teams });
      },
    },
  },
);
