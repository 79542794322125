<template lang="pug">
  .documents-container(ref="documents")
    .documents-list
      template(v-for="(item, index) in records.models")
        h3.inner-header(v-if="hasHeaderDate(index)") {{ getDate(item.created_at) }}
        aiq-tooltip(v-if="showDeletedDocument(item)",
                    effect="dark",
                    placement="top-end",
                    :show-after="1000",
                    :content="deletedDocumentTooltip(item)",
                    :disabled="!item.deleted_by" )
          div.drag(:transfer-data="{item: getDocumentItem(item), type: 'document'}"
              :key="item.id"
              :draggable="!item.deleted_by"
              :class="['document-item', { deleted: !!item.deleted_by }]"
              @click="!item.deleted_by && onDocumentClicked(getDocumentItem(item))")
            .document-container
              aiq-document-icon(:mimeType="getMimeType(item)" :documentExtension="getDocExt(item)" :fileId="''+item.id")
              .document-content
                .document-title {{ getName(item) }}
                .inner-content
                  aiq-timeago(:since="item.created_at" :minTime="0")
                .inner-content Sender: {{ getSender(item) }}
              a.url__link(@click.stop="onHandleView(item.attachment)"
                          target="_blank") View
    infinite-loading(ref="infiniteLoading"
                    v-if="doInfiniteScroll"
                    spinner="waveDots"
                    :distance="10"
                    @infinite="$emit('onScrollLoad', $event)")
      template(v-slot:spinner)
        .filter-loading
          span.filter-loading-item(v-for="item in 5")
      template(v-slot:no-results) No documents history
      template(v-slot:complete) No more results

</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import get from 'lodash/get';
import { MEDIA_TYPE } from '@/constants/analytics';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

const LABEL_UNTITLED = 'Untitled';

export default {
  name: 'documents',
  mixins: [GetGlobalSource],
  props: {
    records: {
      type: Object,
      default: () => {},
    },
    doInfiniteScroll: {
      type: Boolean,
      default: false,
    },
    getSender: {
      type: Function,
      default: () => null,
    },
    hasHeaderDate: {
      type: Function,
      default: () => null,
    },
    getDate: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({ hasAppendedDocs: 'documents/hasAppendedDocs' }),
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations({
      appendDoc: 'documents/APPEND_DOC',
    }),
    showDeletedDocument(item) {
      return !item.deleted_by || this.canView('/conversations/message/audit');
    },
    deletedDocumentTooltip({ deleted_by_agent }) {
      return `Deleted by ${get(deleted_by_agent, 'fullName', '')}`;
    },
    getDocumentItem(item) {
      return {
        file: {
          payload: get(item, 'attachment.file', {}),
        },
      };
    },
    getName(item) {
      return get(item, 'attachment.file.name', LABEL_UNTITLED);
    },
    getMimeType(item) {
      return get(item, 'attachment.file.mime', '');
    },
    getDocExt(item) {
      return get(item, 'attachment.file.filename', '').split('.').pop();
    },
    onDocumentClicked(document) {
      this.$store.dispatch('documents/appendDocument', {
        document,
        source: MEDIA_TYPE.HISTORY });
    },
    appendDocumentFailure() {
      this.$aiq.notify.info('Only one document per message is supported');
    },
    async onHandleView(fileMessage) {
      const url = await this.getFileUrl(get(fileMessage, 'file', {}));
      if (url) {
        window.open(url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.documents-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.inner-header {
  padding: 0 0 10px 0;
  color: #48576a;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}
.document-item {
  position: relative;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 #bfcbd9;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 3px 2px #bfcbd9;
  }
  &:active {
    box-shadow: none;
  }

  &_title {
    padding: 10px;
    color: #48576A;
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
  }

  &.deleted {
    opacity: .5;
    cursor: not-allowed;
    a.url__link {
      cursor: pointer;
    }
  }
}
.document-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 #bfcbd9;
  display: flex;
  flex-direction: row;
  max-height: 93px;
  justify-content: space-evenly;
  line-height: 16px;
  overflow: hidden;
  padding: 12px 0;

  .document-content {
    width: 181px;

    .document-title {
      color: #41536d;;
      font-family: 'Museo Sans';
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .inner-content {
      color: #8492a5;
      font-size: 11px;
    }
    .url__link {
      line-height: 16px;
    }
  }
}
</style>

