<template lang="pug">
.content-inner
    .header
      h1.title Dashboard Configuration
    aiq-collapse.scrollbar__collapse(accordion)
      aiq-collapse-item(title="Metrics Configuration")
        aiq-table.scrollbar__category(:data="localConfiguration.graphs")
          aiq-table-column(label="Name" prop="name")
          aiq-table-column(label="Visiblity" prop="show")
            template(v-slot:default="scope")
              aiq-switch(active-text="",
                         inactive-text="",
                         style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                         @change="onSaveMetrics(scope.$index, $event)",
                         v-model="scope.row.show")
      aiq-collapse-item(title="Commands Configuration")
        aiq-table.scrollbar__category(:data="localConfiguration.rawCommands")
          aiq-table-column(label="Name" prop="system_name")
          aiq-table-column(label="Dashboard" prop="chat")
            template(v-slot:default="scope")
              aiq-switch(active-text="",
                         inactive-text="",
                         style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                         @change="onSaveCommands('chat', scope.$index, !scope.row.chat.hidden)",
                         :model-value="!scope.row.chat.hidden")
          aiq-table-column(label="IqTools" prop="iqtools")
            template(v-slot:default="scope")
              aiq-switch(active-text="",
                         inactive-text="",
                         style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                         @change="onSaveCommands('iqtools', scope.$index, !scope.row.iqtools.hidden)",
                         :model-value="!scope.row.iqtools.hidden")
      //- Disabled "User Guide UI" as we dont want to pay.
      //- Remove code once it is clear to purge it.
        aiq-collapse-item(title="User Guide Configuration")
          .label-switch
            h4 Trigger on First Login
            aiq-switch(active-text="",
                      inactive-text="",
                      style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                      @change="onSaveUserGuiding('login_to_launch', $event)",
                      v-model="localConfiguration.user_guiding.login_to_launch")

          .label-switch
            h4 Trigger on Menu Click
            aiq-switch(active-text="",
                      inactive-text="",
                      style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                      @change="onSaveUserGuiding('menu_to_launch', $event)",
                      v-model="localConfiguration.user_guiding.menu_to_launch")

          .label-input-box
            h4 Max Login Count
            aiq-input(v-model="localConfiguration.user_guiding.max_count_login_to_launch"
                      @keyup.enter="$event.target.blur()"
                      @change="onSaveUserGuiding('max_count_login_to_launch', $event)",
                      size="small")
          .label-input-box
            h4 Start Guide
            aiq-input(v-model="localConfiguration.user_guiding.start_guide"
                      size="small" disabled)
          .label-input-box
            h4 End Guide
            aiq-input(v-model="localConfiguration.user_guiding.end_guide"
                      size="small" disabled)
          .label-input-box
            h4 Container ID
            aiq-input(v-model="localConfiguration.user_guiding.container_id"
                      size="small" disabled)
      aiq-collapse-item(title="Side Panel Configuration")
        .label-select-box
          h4 Customer Profiles
          aiq-button(size="small" @click="showProfileEditorDialog = true") Profile Field Editor
          profile-editor-dialog(title="Profile Editor"
                                :visible="showProfileEditorDialog"
                                :items="profileFields"
                                :types="supportingTypes"
                                @close="showProfileEditorDialog = false"
                                @error="$aiq.notify.error($event)"
                                @save="saveFieldChange")
          h4 Customer Info Display in Dashboard
          profile-mapping(:items="profileFields"
                          :mappings="localConfiguration.side_panel.profile_mapping"
                          @error="$aiq.notify.error($event)"
                          @change="onSaveSidePanel('profile_mapping', $event)"
                          @update="updateSidePanelMapping($event)"
                          )

        .label-input-box
          h4 Customer External Link
          aiq-input(v-model="localCustomConfig.external_customer_link" size="small"
                    :disabled="isExternalIdEditable"
                    @keyup.enter="$event.target.blur()"
                    @change="onSaveSidePanel('external_customer_link', $event)")
      aiq-collapse-item(v-if="canView('/conversations/message/translation') " title="Language Configuration")
        .label-input-box.languages
          h4 Supported Language Translation (max 5)
          aiq-select(v-model="localConfiguration.supported_language_translation"
                    :multiple-limit="5"
                    :filterable="true"
                    placeholder="Languages"
                    value-key="code"
                    size="small"
                    multiple
                    :reserve-keyword="false"
                    @change="onSaveSupportedLanguageTranslation")
               aiq-option(v-for="language in languages",
                          :key="language.code",
                          :label="language.name",
                          :value="language")
        .label-input-box
          h4 Dashboard Custom Language
          aiq-upload.img-uploader(name="file"
                                  :http-request="onUploadLanguageFile"
                                  action="url"
                                  accept="application/json"
                                  :show-file-list="false"
                                  :on-success="onLanguageUploadSuccess")
            aiq-button Add
          .language-group
            aiq-tag(v-for="langFile in customLanguages"
                    :key="langFile.id"
                    closable
                    type="info"
                    @close="onDeleteFile(langFile.id, FileType.CUSTOM_LANGUAGE)") {{ langFile.payload.key }}

      aiq-collapse-item(title="Chat Panel Configuration")
        .label-input-box
          h4 Chat Input Box
          aiq-table.scrollbar__category(:data="inputBoxInvisibilityBySegments")
            aiq-table-column(label="Hide Icon" prop="key")
            aiq-table-column(label="Segments" prop="val")
              template(v-slot:default="scope")
                aiq-select(:model-value="scope.row.val"
                           placeholder="Segments"
                           size="small"
                           multiple
                           :reserve-keyword="false"
                           @change="onSegmentChange(scope.row, $event)")
                  aiq-option(v-for="item in segments"
                            :key="item"
                            :label="item"
                            :value="item")

      aiq-collapse-item(title="Video Configuration")
        .label-input-box
          h4 Video Background
          aiq-upload.img-uploader(name="file"
                                  :http-request="onUploadBackground"
                                  action="url"
                                  accept="image/*"
                                  :show-file-list="false"
                                  :on-success="onBackgroundUploadSuccess")
            aiq-button Add
          .video-bg-group
            .bg-group(v-for="bg in videoBackgrounds" :key="bg.id")
              .video-bg
                aiq-image.bg-size(:file="bg.payload")
                aiq-button(@click="onDeleteFile(bg.id, FileType.VIDEO_BACKGROUND)"
                           size="small"
                           type="danger")
                  i.el-icon-close
      aiq-collapse-item(title="Account Fields Configuration")
        aiq-table.scrollbar__category(:data="localConfiguration.account_fields")
          aiq-table-column(label="Name" prop="name")
          aiq-table-column(label="Visiblity" prop="show")
            template(v-slot:default="scope")
              aiq-switch(active-text="",
                         inactive-text="",
                         style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                         :disabled="scope.row.readonly"
                         @change="onSaveAccountFields(scope.$index, $event)",
                         v-model="scope.row.show")
      aiq-collapse-item(:title="$t('settings_tab.agent_tab.dashboard.timezone.title')")
        .timezone-header
          h3 {{ $t('settings_tab.agent_tab.dashboard.timezone.default') }}
          .timezone-tooltip
            aiq-tooltip(:content="$t('settings_tab.agent_tab.dashboard.timezone.tooltip')" placement="top")
              fa-icon.image-guide(:icon="['fas', 'circle-question']")
        aiq-select(placeholder="Select"
                   v-model="timezoneValue"
                   size="small"
                   )
          aiq-option(v-for="timezone in timezoneOptions"
                    :key="timezone"
                    :label="timezone"
                    :value="timezone")
        .timezone-button
          aiq-button(@click="cancelTimeZone") {{$t('global.cancel')}}
          aiq-button(@click="saveTimeZone" :disabled="disableTimeZoneButton" type="primary") {{$t('global.save')}}

</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { mapState, mapGetters } from 'vuex';
import ProfileEditorDialog from './ProfileEditorDialog.vue';
import ProfileMapping from './ProfileMapping.vue';
import { DEFAULT_SYSTEM_LANGUAGE } from '@/constants/settings';
import { IMAGE_ACL_TYPES } from '@/constants';
import moment from 'moment';
import { isEmpty } from 'lodash';

const FILE_TYPE = {
  VIDEO_BACKGROUND: 'video-background',
  CUSTOM_LANGUAGE: 'custom-dashboard-language',
};
const MINUTE = 60;

export default {
  name: 'dashboard-tab',
  components: {
    ProfileEditorDialog,
    ProfileMapping,
  },
  data() {
    return {
      EDITABLE_FIELDS: ['name', 'email', 'phone', 'external_id'],
      localCustomConfig: {
        external_customer_link: '',
      },
      showProfileEditorDialog: false,
      timezoneValue: '',
      localConfiguration: {
        graphs: [],
        commands: [],
        user_guiding: {
          login_to_launch: false,
          menu_to_launch: false,
          max_count_login_to_launch: -1,
          start_guide: -1,
          end_guide: -1,
          container_id: '',
        },
        side_panel: {
          editable_customer_info: [],
          visible_customer_info: [],
          profile_mapping: [],
        },
        supported_language_translation: [],
        account_fields: [],
      },
    };
  },
  computed: {
    ...mapState({
      configuration: state => state.configs.config,
      customConfig: state => state.configs.tenantVariables,
      profileFields: state => state.configs.customizables.customer.profile,
      supportingTypes: state => state.configs.customizables.customer.supporting_types,
      languages: state => state.translation.languages,
      chatPanelConfig: state => state.settings.chatPanelConfig,
      videoBackgrounds: state => state.configs.videoBackground.models,
      customLanguages: state => state.configs.customLanguages.models,
      timezone: state => state.configs.timezone,
    }),
    ...mapGetters({
      segments: 'configs/segmentNames',
    }),
    getMaxTime() {
      return this.tempSettings.wait_time;
    },
    getMaxChats() {
      return this.tempSettings.maximum_chats;
    },
    getAllowAssignment() {
      return this.tempSettings.enable;
    },
    FileType() {
      return FILE_TYPE;
    },
    isExternalIdEditable() {
      const mapping = this.localConfiguration.side_panel.profile_mapping.find(m => m.field === 'external_id');
      if (!mapping) {
        return false;
      }

      // Use custom link only when external id is non editable
      return get(mapping, 'editable', false);
    },
    timezoneOptions() {
      const usaTimeZone = [ 'US/Mountain',
        'US/Pacific',
        'US/Central',
        'US/Eastern',
        'US/Hawaii',
        'US/Alaska'];
      const canadaTimeZones = [ 'Canada/Atlantic',
        'Canada/Central',
        'Canada/Eastern',
        'Canada/Mountain',
        'Canada/Newfoundland',
        'Canada/Pacific'];
      const timezones = ['UTC', ...usaTimeZone, ...canadaTimeZones];
      const tzWithOffset = timezones.map(tz => {
        return this.calculateGMTOffset(tz);
      });
      return tzWithOffset;
    },
    disableTimeZoneButton() {
      return this.calculateGMTOffset(get(this.timezone, 'input', '')) === this.timezoneValue;
    },
    inputBoxInvisibilityBySegments() {
      return Object.entries(get(this.chatPanelConfig, 'invisibility_control_by_segment.inputbox', {}))
        .map(([key, val]) => ({ key, val }));
    },
  },
  async mounted() {
    this.cloneCustomConfig(this.customConfig);
    this.cloneConfiguration(this.configuration);
    this.$store.dispatch('configs/getEnvConfigs');
    this.$store.dispatch('configs/getCustomizableSettings');
    this.$store.dispatch('settings/getAPISettings');
    this.$store.dispatch('configs/getCustomerSegments');
    this.$store.dispatch('configs/getVideoCallBackgrounds');
    this.$store.dispatch('configs/getCustomLanguageFiles');
    this.$store.dispatch('configs/getTimeZoneConfig');

    this.$store.dispatch('settings/getSetting', {
      name: 'dashboard_chat_panel_configuration',
      stateName: 'chatPanelConfig',
    });
    if (this.canView('/conversations/message/translation')) {
      this.$store.dispatch('translation/languages');
    }
  },
  methods: {
    cloneCustomConfig(newVal) {
      this.localCustomConfig = cloneDeep(newVal);
    },
    cloneConfiguration(newVal) {
      this.localConfiguration = cloneDeep(newVal);
    },
    async onSaveUserGuiding(fieldName, value) {
      if (fieldName === 'max_count_login_to_launch') {
        value = Number(value);
        if (Number.isNaN(value)) {
          this.$aiq.notify.error('Only number is allowed');
          return this.cloneConfiguration(this.configuration);
        }
      }

      this.localConfiguration.user_guiding[fieldName] = value;
      return this.saveConfiguration();
    },
    /**
     * Calculates the GMT offset for a given timezone.
     * @param {string} tz - The timezone identifier.
     * @returns {string} The formatted GMT offset string.
     */
    calculateGMTOffset(tz) {
      // Return an empty string if the timezone is empty
      if (isEmpty(tz)) {
        return '';
      }
      // Get the UTC offset in minutes for the given timezone
      const offset = moment.tz(tz).utcOffset();
      // Format the offset as a GMT string and return
      return `${tz} (GMT${offset >= 0 ? '+' : '-'}${Math.abs(offset / MINUTE)})`;
    },
    onSaveMetrics(index, value) {
      this.localConfiguration.graphs[index].show = value;
      return this.saveConfiguration();
    },
    onSaveAccountFields(index, value) {
      this.localConfiguration.account_fields[index].show = value;
      return this.saveConfiguration();
    },
    onSaveCommands(fieldName, index, value) {
      this.localConfiguration.rawCommands[index][fieldName].hidden = value;
      return this.saveConfiguration();
    },
    onSaveSidePanel(fieldName, value) {
      if (fieldName === 'external_customer_link') {
        this.localCustomConfig[fieldName] = value;
        return this.saveCustomConfig();
      }

      this.localConfiguration.side_panel[fieldName] = value;
      return this.saveConfiguration();
    },
    updateSidePanelMapping(mappings) {
      this.localConfiguration.side_panel.profile_mapping = mappings;
    },
    onSaveSupportedLanguageTranslation() {
      /**
       * Before saving we need to ensure default system language is in the list
       * to make sure consistent with our platform default language.
      */
      const defaultSystemLanguageStillSelected =
        this.localConfiguration.supported_language_translation
          .some((language) => language.code === DEFAULT_SYSTEM_LANGUAGE.code);
      if (defaultSystemLanguageStillSelected) {
        return this.saveConfiguration();
      }
      this.localConfiguration.supported_language_translation
        .unshift(cloneDeep(DEFAULT_SYSTEM_LANGUAGE));
      this.$aiq.notify.error(`Removing system default language (${DEFAULT_SYSTEM_LANGUAGE.name}) from the list is not allowed`);
    },
    async saveConfiguration() {
      try {
        await this.$store.dispatch('configs/saveDashboardConfig', cloneDeep(this.localConfiguration));
        this.$aiq.notify.success('Saved');
      } catch (err) {
        this.$aiq.notify.error('Unable to save the value.');
        this.cloneConfiguration(this.configuration);
      }
    },
    async saveTimeZone() {
      try {
        await this.$store.dispatch('configs/updateTimeZoneConfig',
          { input: this.timezoneValue.replace(/\(.*?\)/, '').trim() });
        this.$aiq.notify.success(this.$t('settings_tab.agent_tab.dashboard.timezone.update_successful'));
      } catch (err) {
        this.$aiq.notify.error(this.$t('settings_tab.agent_tab.dashboard.timezone.update_failed'));
      }
    },
    cancelTimeZone() {
      this.timezoneValue = this.calculateGMTOffset(this.timezone.input);
    },
    async saveCustomConfig() {
      try {
        await this.$store.dispatch('configs/saveCustomConfig', this.localCustomConfig);
        this.$aiq.notify.success('Saved');
      } catch (err) {
        this.$aiq.notify.error('Unable to save the value.');
        this.cloneCustomConfig(this.customConfig);
      }
    },
    async saveFieldChange(items) {
      const customizablePayload = {
        customer: {
          supporting_types: this.supportingTypes,
          profile: items,
        },
      };
      try {
        await this.$store.dispatch('configs/setCustomizableSettings', customizablePayload);
        this.$aiq.notify.success('Saved');
      } catch (err) {
        this.$aiq.notify.error('Unable to save the value.');
      }

      this.showProfileEditorDialog = false;
    },
    async onSegmentChange(row, val) {
      try {
        const newConfig = cloneDeep(this.chatPanelConfig);
        set(newConfig, `invisibility_control_by_segment.inputbox.${row.key}`, val);
        await this.$store.dispatch('settings/updateSetting', {
          name: 'dashboard_chat_panel_configuration',
          payload: newConfig,
          stateName: 'chatPanelConfig',
        });
        this.$aiq.notify.success('Saved');
      } catch (err) {
        this.$aiq.notify.success('Unable to save the value');
      }
    },
    onUploadBackground(data) {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      formData.append('type', FILE_TYPE.VIDEO_BACKGROUND);
      return this.$store.dispatch('configs/upload', formData);
    },
    onBackgroundUploadSuccess() {
      return this.refreshFiles(FILE_TYPE.VIDEO_BACKGROUND);
    },
    onLanguageUploadSuccess() {
      return this.refreshFiles(FILE_TYPE.CUSTOM_LANGUAGE);
    },
    refreshFiles(type) {
      if (type === FILE_TYPE.VIDEO_BACKGROUND) {
        return this.$store.dispatch('configs/getVideoCallBackgrounds');
      }

      if (type === FILE_TYPE.CUSTOM_LANGUAGE) {
        return this.$store.dispatch('configs/getCustomLanguageFiles');
      }

      return null;
    },
    onUploadLanguageFile(data) {
      if (this.customLanguages.length > 0) {
        // Currently, it supports only english so if there is one already set,
        // it should be removed.
        return this.$aiq.notify.error('Please remove existing file.');
      }

      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      formData.append('type', FILE_TYPE.CUSTOM_LANGUAGE);

      // We may need to support for different files per locale in future
      // For now, only English is supported to override language file.
      formData.append('locale', 'en');
      return this.$store.dispatch('configs/upload', formData);
    },
    async onDeleteFile(id, type) {
      try {
        const args = { id };
        if (type === this.FileType.CUSTOM_LANGUAGE) {
          // Hard delete the file
          args.depth = 'hard';
        }
        await this.$store.dispatch('configs/deleteFile', args);
        await this.refreshFiles(type);
      } catch (err) {
        this.$aiq.notify.error('Fail to remove the background');
      }
    },
  },
  watch: {
    customConfig: {
      handler(newVal) {
        this.cloneCustomConfig(newVal);
      },
      deep: true,
    },
    timezone: {
      handler(newVal) {
        this.timezoneValue = this.calculateGMTOffset(get(newVal, 'input', ''));
      },
      deep: true,
    },
    configuration: {
      handler(newVal) {
        this.cloneConfiguration(newVal);
      },
      deep: true,
    },
  },
};

</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
@import "../../../../../styles/aiq-extensions.scss";


.label-switch {
}

.label-input-box, .label-select-box {
  h4 {
    margin-top: 10px;
  }
}

.scrollbar__collapse {
  @include scrollable(calc(100vh - 204px));
}

.label-input-box.languages {
  width: 50%;
}

</style>

<style lang="scss">
@import "../../../../../styles/aiq-mixins.scss";
.scrollbar__category {
  .el-table__body-wrapper {
    @include scrollable(calc(100vh - 210px));
  }
}

.video-bg-group {
  display: flex;
}

.bg-group {
  margin-right: 10px;
}

.video-bg {
  display: flex;
}

.bg-size {
  width: 128px;
}

.timezone-header {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}
.timezone-tooltip {
  float: left;
  padding-left: 10px;
  padding-top: 3px;
}

.timezone-button {
  float: right;
  padding-top: 30px;
  margin-bottom: 20px;
}
</style>
