<template lang="pug">
.assign-agent-modal_content
  .agents-assigned-list
    aiq-tag(v-for="item of selectedAgents", :key="item.id", :closable="item.email !== botEmail && item.id !== primaryAgentId", :close-transition="true", @close="remove(item.id)")
      aiq-image(:src="$_get(item, 'picture.payload.url', $_DEFAULT_PICTURE_URL)")
      .agent-info.status-indicator
        agent-status(:available="item.available")
        .agent-name(:title="item.fullName") {{ item.fullName }}
    aiq-input.agents-list_search(type="text", placeholder="Assign to agent", v-model="filter")

  aiq-row.assign-agent-team-filter
    aiq-col(:span="12" :offset="12")
      aiq-select(placeholder="Filter by team"
                  size="small"
                  filterable
                  clearable
                  :clear-icon="Icon.CircleCloseFilled"
                  :suffix-icon="Icon.CaretBottom"
                  v-model="teamFilter")
                aiq-option(v-for="team in allTeams"
                          :key="team.id"
                          :label="team.name"
                          :value="team.id")

  .agents-list.scroll
    .agents-list_item(v-for="item of agentsFilteredList",
                      @click="add(item)",
                      :key="item.id")
      aiq-image(:src="$_get(item, 'picture.payload.url', $_DEFAULT_PICTURE_URL)")
      .agent-info
        .status-indicator
          agent-status(:available="item.available")
          .agent-name(:title="item.fullName") {{ item.fullName }}
        .agent-email(:title="item.email") {{ item.email }}

  .el-dialog__footer
    aiq-button(@click="cancel()") Cancel
    aiq-button(type="primary", @click="save()", :disabled="isSaveDisabled") Save

</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { DEFAULT_PICTURE_URL } from '@/constants';
import { BOT_EMAIL } from '@/constants/agent';
import AgentStatus from '@/components/AgentStatus.vue';

export default {
  name: 'assignAgentForm',
  components: {
    AgentStatus,
  },
  props: {
    agents: {
      type: Array,
      required: true,
    },
    primaryAgentId: {
      type: Number,
    },
  },
  data() {
    return {
      filter: '',
      selectedAgents: [],
      teamFilter: null,
      isSaveDisabled: false,
    };
  },
  emits: ['save', 'cancel'],
  created() {
    this.$_DEFAULT_PICTURE_URL = DEFAULT_PICTURE_URL;
    this.$_get = (object, key, defaultValue) => get(object, key, defaultValue);
  },

  mounted() {
    this.selectedAgents = [...this.agents];
    this.$store.dispatch('teams/getTeamsList', [{ limit: 1000 }]);
  },
  computed: {
    ...mapGetters({
      allAgents: 'agents/sortedList',
      allTeams: 'teams/sortedTeams',
    }),
    botEmail() {  // eslint-disable-line
      return BOT_EMAIL;
    },
    agentsFilteredList() {
      return this.allAgents
        .filter(e => !this.selectedAgents.find(j => j.id === e.id) && !e.deleted_at)
        .filter(e => (this.teamFilter ?
          (e.teams.map(t => t.id).includes(this.teamFilter)) :
          (String(e.fullName).toLowerCase().indexOf(this.filter.toLowerCase()) > -1)));
    },
  },
  methods: {
    remove(id) {
      this.selectedAgents = this.selectedAgents.filter(e => e.id !== id);
    },

    save() {
      this.isSaveDisabled = true;
      this.$emit('save', this.selectedAgents);
    },

    cancel() {
      this.$emit('cancel');
    },

    getIsChanged() {
      return !isEqual(sortBy(this.selectedAgents, ['id']), sortBy(this.agents, ['id']));
    },

    add(agent) {
      const {
        fullName,
        id,
        email,
        available,
        picture,
      } = agent;
      this.selectedAgents.push({
        fullName,
        id,
        email,
        available,
        picture,
      });

      this.filter = '';
    },
  },
};
</script>

<style lang="scss">
@import "./../../../../../../styles/aiq-variables.scss";

.status-indicator {
  display: flex;
  flex-direction: row;
}

.agents-assigned-list {
  .status-indicator i {
    margin-right: 5px;
    padding-top: 4px;
  }

  .agent-info.status-indicator {
    align-items: center;
    padding-top: 2px;
  }

  .agent-info.status-indicator {
    align-items: center;
  }
}

.agents-list {
  .status-indicator i {
    margin-top: 3px;
  }
}

.status-indicator i:not(.status-available) {
  margin-right: 5px;
}

.assign-agent-modal_content {

  .el-dialog__footer {
    padding-right: 0;
    padding-left: 0;
  }
}
.agents-assigned-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-bottom: 1px solid #7E92A3;

  .el-tag {
    background-color: #F3F6FD;
  }

  .el-tag, .el-tag__content {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 10px 0 0;
    margin: 0 5px 2px 0;

    .el-tag__close {
      color: #BFCBD9;
      font-size: 18px;
      margin-left: 0px;
      height: 18px;
      width: 18px;
    }
  }
  img {
    width: 28px;
    height: 28px;
    margin: 2px 5px 0px 1px;
    border-radius: 50%;
  }
  .agent-name {
    color: #324057;
    font-size: 12px;
    font-weight: 300;
  }
  .agent-availability-indicator {
    left: 18px;
    width: 8px;
    height: 8px;
  }
}
.agents-list_search {
  min-width: 150px;
  min-height: auto;
  margin-left: 11px;
  flex: 1 0 0%;

  input {
    padding: 0;
    border: 0;

    &::-webkit-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
    &::-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-moz-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #BFCBD9;
      font-size: 12px;
      font-weight: 300;
    }
  }
}
.agents-list {
  display: flex;
  flex-flow: wrap;
  overflow-x: hidden;

  &.scroll {
    max-height: 184px;
  }
}
.agents-list_item {
  position: relative;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  width: 150px;
  margin-bottom: 10px;
  cursor: pointer;

  img {
    width: 36px;
    height: 36px;
    margin-right: 4px;
    border-radius: 50%;
  }
  .agent-name {
    width: 100%;
    max-width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .agent-email {
    width: 100%;
    max-width: 90px;
    color: #8492A6;
    font-size: 10px;
    line-height: 10px;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.agent-availability-indicator {
  position: absolute;
  bottom: 0;
  left: 25px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #E91E63;

  &.available {
    background-color: #30C28B;
  }
}

.assign-agent-team-filter {
  padding-bottom: 1em;
  padding-top: 0.4em;
}
</style>
