import * as log from 'loglevel';
import difference from 'lodash/difference';

const TAG = 'libs/settings';

export const getAttachAgentActions = (agent, originalAgent) => {
  log.debug(TAG, 'getAttachAgentActions start:', agent, originalAgent);
  const actions = [];

  if (difference(agent.roles, originalAgent.roles).length || difference(originalAgent.roles, agent.roles).length) {
    actions.push({ action: 'agents/setRoles', type: 'roles', data: agent.roles });
  }

  if (agent.channels) {
    const filter = x => !new Set(originalAgent.channels).has(x);
    const channels = new Set([...new Set(agent.channels)].filter(filter));
    channels.forEach(channel => {
      actions.push({ action: 'agents/setRoles', type: 'channels', data: channel });
    });
  }

  if (agent.teams) {
    const existingTeamIds = originalAgent.teams.map(e => e.id);
    const newTeamIds = agent.teams.map(e => e.id);
    log.debug(TAG, 'getAttachAgentActions', existingTeamIds, newTeamIds);
    difference(newTeamIds, existingTeamIds).forEach(id => {
      actions.push({ action: 'agents/attachTeam', type: 'teams', data: { id } });
    });
  }

  log.debug(TAG, 'getAttachAgentActions end:', actions);
  return actions;
};

export const getDetachAgentActions = (agent, originalAgent) => {
  const actions = [];

  if (agent.channels) {
    const filter = x => !new Set(agent.channels).has(x);
    const channels = (new Set([...new Set(originalAgent.channels)].filter(filter)));
    channels.forEach(channel => {
      actions.push({ action: 'agents/detachRole', type: 'channels', data: channel.toLowerCase() });
    });
  }

  if (agent.teams) {
    const existingTeamIds = originalAgent.teams.map(e => e.id);
    const newTeamIds = agent.teams.map(e => e.id);
    difference(existingTeamIds, newTeamIds).forEach(id => {
      actions.push({ action: 'agents/detachTeam', type: 'teams', data: { id, type: 'member' } });
    });
  }

  return actions;
};
