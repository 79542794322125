<template lang="pug">
.docu-sign
  .tab-nav(v-if="!showEnvelopDetailPage")
    .envelop-list(v-loading="envelopePage === null")
      .list-filters
        aiq-input.filter-item(v-model="searchText" placeholder="Search Text"  @change="getEnvelopes" size="small")
        aiq-date-picker.filter-item(v-model="selectedDateRange"
                            type="daterange"
                            @change="getEnvelopes")
      .table-content
        aiq-table.table-content-table(:data="envelopes" @row-click="onEnvelopeRowClick")
          aiq-table-column(label="Title")
            template(v-slot:default="scope")
              span {{ noName(scope.row.emailSubject) }}
          aiq-table-column(label="Status")
            template(v-slot:default="scope")
              span {{ $filters.toUpperFirst(scope.row.status) }}
          aiq-table-column(label="Sent By")
            template(v-slot:default="scope")
              span {{ scope.row.sender.userName }}
          aiq-table-column(label="Created Date")
            template(v-slot:default="scope")
              span {{ $filters.formatTime(scope.row.sentDateTime) }}
    .button-group
      aiq-button(@click="onSendCancel") Cancel

  .tab-nav(v-if="showEnvelopDetailPage")
    h1.title Share Signing Url
    .body
      .envelope-info
        .form-field-container
          aiq-row
            aiq-col(:span="4")
              .form-field-label Envelope ID
            aiq-col(:span="18")
              aiq-input(v-model="envelopeId" placeholder="Envelope ID" size="small" disabled)

      .envelope-info(v-if="envelopDetails")
        .envelope-sub-details
          h3.title Documents
          aiq-tag(v-for="document in envelopDetails.envelopeDocuments" :key="document.documentId") {{ document.name }}
        .envelope-sub-details(v-if="selectedRecipient")
          h3.title Recipients
          .recipient-details(v-for="recipient in allRecipents")
            .active-recipient(v-if="recipient === selectedRecipient")
                aiq-tag Signer to share via message
            .form-field-container
              aiq-row
                aiq-col(:span="4")
                  .form-field-label Email
                aiq-col(:span="20")
                  aiq-input(:model-value="recipient.email" size="small" type="email" disabled)

            .form-field-container
              aiq-row
                aiq-col(:span="4")
                  .form-field-label Name
                aiq-col(:span="20")
                  aiq-input(:model-value="recipient.name" size="small" disabled)

            .form-field-container
              aiq-row
                aiq-col(:span="4")
                  .form-field-label Role
                aiq-col(:span="20")
                  aiq-input(:model-value="recipient.recipientType" size="small" disabled)

    .button-group
      aiq-button(@click="reset") Cancel
      aiq-button(@click="onSendSignMessage" type="primary" :disabled="!selectedRecipient") Send

  // ********** The below UI for template implementation is disabled ************

  .tab-nav(v-if="false")  // Hide Template implementation
    aiq-tabs(v-model="activeTab")
      aiq-tab-pane(label="Templates" name="templates" v-loading="templatePage === null")
        aiq-table(:data="templates" @row-click="onTemplateRowClick")
          aiq-table-column(label="Name")
            template(v-slot:default="scope")
              span {{ noName(scope.row.name) }}
          aiq-table-column(label="Owner")
            template(v-slot:default="scope")
              span {{ scope.row.owner.userName }}
          aiq-table-column(label="Created Date")
            template(v-slot:default="scope")
              span {{ $filters.formatTime(scope.row.created) }}
          aiq-table-column(label="Last Change")
            template(v-slot:default="scope")
              span {{ $filters.formatTime(scope.row.lastModified) }}
      aiq-tab-pane(label="Manage" name="manage")
        h1 TODO
  .tab-nav(v-if="false")  // Hide Template implementation
    .template-configuration(v-loading="templateDetails === null")
      .template-description
        h1 {{ templateDetails ? templateDetails.name : '' }}
        p {{ templateDetails ? templateDetails.description : '' }}

      .template-sub-group
        h3 Attached Documents
        .document-list
          aiq-tag(v-for="doc in attachedDocuments" :key="doc.documentId" ) {{ doc.name }}

      .template-sub-group
        h3 Recipients
        .recipient(v-for="recipient in recipientsToSend")

          h4 {{ recipient.type }}

          aiq-row
            aiq-col(:span="6")
              label Email
            aiq-col(:span="18")
              aiq-input(v-model="recipient.email" size="small" type="email")

          aiq-row
            aiq-col(:span="6")
              label Name
            aiq-col(:span="18")
              aiq-input(v-model="recipient.name" size="small")

          aiq-row
            aiq-col(:span="6")
              label Role
            aiq-col(:span="18")
              aiq-input(v-model="recipient.roleName" size="small" disabled)

      .template-sub-group
        h3 Message
        label Email Subject
        aiq-input(v-model="emailSubject")
        label Email Content
        aiq-input(v-model="emailBlurb" type="textarea")

      .button-group
        aiq-button(@click="onSendCancel") Cancel
        aiq-button(@click="onSendTemplate" type="primary") Send

</template>

<script>
import * as log from 'loglevel';
import get from 'lodash/get';
import pick from 'lodash/pick';
import concat from 'lodash/concat';
import moment from 'moment';
import { getNonReactiveDateRange } from '@/pages/Metrics/configs/dateRange';

const TAG = '[DocuSignMain]';

// TODO: There are many more types that we need to support
const SURROPTED_RECIPIENT = ['carbonCopies', 'signers'];

export default {
  name: 'DocuSignMain',
  data() {
    return {
      // Start: Hidden values for template implementation
      activeTab: 'templates',
      templatePage: null,
      templateDetails: null,
      isTemplateDetailsLoading: false,
      showTemplateDetails: false,
      recipientsToSend: [],
      emailSubject: '',
      emailBlurb: '',
      // End: Hidden values for template implementation

      // Envelop Detail Page Data
      showEnvelopDetailPage: false,
      envelopeId: null,
      envelopDetails: null,
      recipients: null,
      selectedRecipient: null,

      // Envelop List Page Data
      envelopePage: null,
      searchText: '',
      selectedDateRange: [
        moment().subtract(1, 'months').toISOString(),
        moment().toISOString(),
      ],
    };
  },
  props: {
    customer: {
      type: Object,
      default: {},
    },
  },
  emits: ['close', 'sign-message'],
  computed: {
    customerEmail() {
      return get(this.customer, 'profile.email', '');
    },
    allRecipents() {
      return get(this.recipients, 'signers', []);
    },
    customerUUID() {
      return get(this.customer, 'identities.uuids.0.uuid', null);
    },
    templates() {
      return get(this.templatePage, 'envelopeTemplates', []);
    },
    envelopes() {
      return get(this.envelopePage, 'envelopes', []);
    },
  },
  async mounted() {
    try {
      // Note: Template implementation is currently disabled. Uncomment the below line if needed.
      // this.templatePage = await this.$store.dispatch('integrations/getTemplates', 'docusign');

      // populate search text with email
      this.searchText = get(this.customer, 'profile.email', '');

      await this.getEnvelopes();
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  },
  methods: {
    noName(value) {
      return value === '' ? '[No Name]' : value;
    },
    async getEnvelopes() {
      const search = this.searchText;
      const [startDate, endDate] = getNonReactiveDateRange(this.selectedDateRange);
      const start_date = moment(startDate).toISOString();
      const end_date = moment(endDate).toISOString();

      this.envelopePage = await this.$store.dispatch('integrations/getEnvelopes', {
        integrationName: 'docusign',
        query: {
          search,
          start_date,
          end_date,
        },
      });
    },
    async onFetchEnvelope() {
      try {
        const response = await this.$store.dispatch('integrations/getEnvelope', {
          integrationName: 'docusign',
          envelopeId: this.envelopeId,
        });
        this.emailSubject = get(response, 'emailSubject', '');
        this.envelopDetails = response;
        this.recipients = response.recipients;

        // Support only signer as it is unclear if we need to support other recipients
        if (get(this.recipients, 'signers', []).length !== 0) {
          this.selectedRecipient =
            this.recipients.signers.find(signer => signer.email === this.customerEmail);
        }
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },
    async onEnvelopeRowClick(item) {
      this.showEnvelopDetailPage = true;
      this.envelopeId = item.envelopeId;

      await this.onFetchEnvelope();
    },
    async onTemplateRowClick(item) {
      this.showTemplateDetails = true;
      try {
        this.templateDetails = await this.$store.dispatch('integrations/getTemplateDetails', {
          integrationName: 'docusign',
          templateId: item.templateId,
        });
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }

      log.debug(TAG, 'Fetched template', this.templateDetails);

      this.recipientsToSend = [];
      for (const [key, recipients] of Object.entries(get(this.templateDetails, 'recipients', {}))) {
        if (SURROPTED_RECIPIENT.includes(key)) {
          this.recipientsToSend = concat(this.recipientsToSend,
            recipients.map(r => ({ type: key, ...pick(r, ['name', 'email', 'roleName']) })));
        }
      }

      this.attachedDocuments = get(this.templateDetails, 'documents', []);
      this.emailSubject = this.templateDetails.emailSubject;
      this.emailBlurb = this.templateDetails.emailBlurb;
    },
    reset() {
      this.emailSubject = '';
      this.emailBlurb = '';
      this.recipientsToSend = [];
      this.templateDetails = null;
      this.showTemplateDetails = false;

      this.envelopeId = null;
      this.envelopDetails = null;
      this.selectedRecipient = null;
      this.showEnvelopDetailPage = false;
    },
    onSendCancel() {
      this.reset();
      this.$emit('close');
    },
    async onSendSignMessage() {
      try {
        // insert clientUserId
        this.selectedRecipient.clientUserId = this.customerUUID;

        // Update the envelope and send
        const ret = await this.$store.dispatch('integrations/updateEnvelopeRecipientsAndSend', {
          integrationName: 'docusign',
          envelopeId: this.envelopeId,
          recipients: this.recipients,
        });
        log.debug(TAG, 'Updated recipients', ret);

        // Update to parent component to create a url
        this.$emit('sign-message', {
          title: this.emailSubject,
          integration: 'docusign',
          payload: {
            document_reference_id: this.envelopeId,
            recipient: pick(this.selectedRecipient, ['name', 'email', 'userId', 'recipientId', 'clientUserId']),
          },
        });
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      }
    },
    async onSendTemplate() {
      try {
        const payload = {
          integrationName: 'docusign',
          template: { templateId: this.templateDetails.templateId },
          recipients: this.recipientsToSend,
          emailSubject: this.emailSubject,
          emailBlurb: this.emailBlurb,
        };
        log.debug(TAG, 'send a template request', payload);
        await this.$store.dispatch('integrations/sendTemplate', payload);
        this.$aiq.notify.success('Successful');
      } catch (err) {
        this.$aiq.notify.error(`Failed: ${err.message}`);
      }

      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../styles/aiq-variables.scss";

.body {
  margin-top: 10px;
  margin-bottom: 10px;
}

.envelope-info {
  padding: 10px;
}

.form-field-container {
  margin-top: 10px;
  .form-field-label {
    display: inline-block;
    width: 115px;
    color: #475669;
    letter-spacing: 0;
    font-family: $aiq-font-family;
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
  }
  .get-envelop-btn {
    margin-left: 10px;
  }
}
.template-description {
  margin-bottom: 10px;
}

.template-sub-group {
  margin-top: 10px;
}

.recipient {
  border: gray solid 1px;
  padding: 5px;
  margin-top: 10px;
  h3 {
    margin-bottom: 10px;
  }
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}

.selectable {
  cursor: pointer;
}

.table-content {
  height: 50vh;
}

.table-content-table {
  height: 100%;
  overflow-y: scroll;
}

.envelope-sub-details {
  margin-top: 10px;
}

.list-filters {
  display: flex;
  justify-content: flex-end;
  margin: 10px;

  .filter-item {
    width: 40%;
    margin-left: 10px;
  }
}

.recipient-details {
  padding: 5px 10px 10px 10px;
  margin: 5px;
  border: 1px solid grey;
  border-radius: 25px;
}

.active-recipient {
  display: flex;
  justify-content: flex-end;
}
</style>
