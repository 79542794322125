import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import has from 'lodash/has';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import * as log from 'loglevel';
import { randomString } from '@/libs/stringUtils';
import { stringToNameObject } from '@/libs/profile';
import { IMAGE_ACL_TYPES, DEFAULT_PICTURE_URL } from '@/constants';

const defaultThemeSchema = {
  style: '',
  bot: {},
};

const defaultBotSchema = {
  profile: {
    first_name: '',
    last_name: '',
  },
};

export default {
  name: 'theme-tab',
  data() {
    return {
      themeConfig: null,
      currentThemeId: null,
    };
  },
  async mounted() {
    try {
      this.themeConfig = await this.$store.dispatch(
        'settings/getSetting',
        { name: 'webchat_theme_configuration' },
      );
    } catch (err) {
      this.$aiq.notify.error('Unable to find theme configuration');
    }
  },
  computed: {
    customStyle() {
      return get(this.themeConfig, 'default.style', '');
    },
    customConfigs() {
      if (!this.themeConfig) {
        return [];
      }
      return Object.entries(this.themeConfig).map(([themeId, value]) => {
        value.bot_name = value.bot.profile ? `${value.bot.profile.first_name} ${value.bot.profile.last_name}`.trim() : '';
        return { themeId, value };
      });
    },
    defaultBotImage() {
      return DEFAULT_PICTURE_URL;
    },
  },
  methods: {
    customBot(themeId) {
      return !!get(this.themeConfig, `${themeId}.bot`, {});
    },

    async updateThemeConfig(payload) {
      try {
        this.themeConfig = await this.$store.dispatch('settings/updateSetting', {
          name: 'webchat_theme_configuration',
          payload,
        });

        this.$aiq.notify.success('Updated');
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },

    onStyleChange(val, themeId) {
      const requestPayload = cloneDeep(this.themeConfig);
      set(requestPayload, `${themeId}.style`, val);
      return this.updateThemeConfig(requestPayload);
    },

    onBotProfileEnabled(val, themeId) {
      const payload = cloneDeep(this.themeConfig);
      if (val) {
        set(payload, `${themeId}.bot`, cloneDeep(defaultBotSchema));
      } else {
        set(payload, `${themeId}.bot`, {});
      }

      return this.updateThemeConfig(payload);
    },

    generateThemeId() {
      const themeId = randomString();
      if (has(this.themeConfig, themeId)) {
        return this.generateThemeId();
      }
      return themeId;
    },

    onAddNewTheme() {
      const themeId = this.generateThemeId();

      this.themeConfig = {
        ...this.themeConfig,
        [themeId]: cloneDeep(defaultThemeSchema),
      };
    },

    onDeleteTheme(themeId) {
      this.updateThemeConfig(omit(this.themeConfig, themeId));
    },

    async onUploadBotImageThemeId(themeId) {
      this.currentThemeId = themeId;
    },

    async onUploadBotImage(data) {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      const payload = await this.$store.dispatch('configs/upload', formData);
      return payload;
    },

    onBotImageFailure(err) {
      this.$aiq.notify.error('Unable to upload:', err.message);
    },

    async onBotImageSuccess(responsePayload) {
      if (!this.currentThemeId) {
        return log.error('Unable to find themeId to attach');
      }

      const requestPayload = cloneDeep(this.themeConfig);
      set(requestPayload, `${this.currentThemeId}.bot.picture`, responsePayload);
      this.currentThemeId = null;
      return this.updateThemeConfig(requestPayload);
    },

    onBotNameChanged(val, themeId) {
      if (typeof val !== 'string') {
        return log.warn(`Bot name is not string but ${val}`);
      }

      const requestPayload = cloneDeep(this.themeConfig);
      set(requestPayload, `${themeId}.bot.profile`, stringToNameObject(val));
      return this.updateThemeConfig(requestPayload);
    },

    shouldShowAddBotBtn(botConfig) {
      return isEmpty(botConfig);
    },
  },
};