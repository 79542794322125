<template lang="pug">
  modal-editor.edit-segment-modal(ref="modalEditor"
                                  recordType="Segment"
                                  :visible="visible"
                                  :show-close="false"
                                  :record="record"
                                  :preparePayload="preparePayload"
                                  :buttons="buttons"
                                  :isRecordValid="isSubmittable"
                                  :afterSave="(record) => $emit('afterSave', record)"
                                  :onError="onError"
                                  v-on="$attrs")
    template(v-slot:content)
      .field-container.img-uploader-container
        .image-title-header
          label Icon
        aiq-upload.img-uploader(name="file"
                                :http-request="uploadIcon"
                                action="url"
                                accept="image/*"
                                :show-file-list="false"
                                :on-success="handleIconSuccess")
          i.iq-ico-close(v-if="showImage" @click.stop="removeIcon")
          aiq-image(v-if="showImage"
                    :key="getRecordProp('payload.icon_url', null)"
                    :src="getRecordProp('payload.icon_url', null)")
          .img-uploader-add(v-else)

      .field-container.top-right.is-required
        label Name
        FilteredInput(:disabled="!!id"
                  size="small"
                  v-model="record.name"
                  name="name"
                  :max-length="25"
                  :shouldFilterSpaces="true"
                  )
      .field-container.top-right
        label Description
        aiq-input(size="small"
                  v-model="record.payload.alias"
                  name="alias"
                  )
      .field-container.full-width
        label Teams
        aiq-select(v-model="record.payload.team_ids"
                  placeholder="Teams"
                  size="small"
                  name="teams"
                  value-key='id'
                  multiple
                  :reserve-keyword="false")
          aiq-option(v-for="item in teams"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id")
        .field-container.top-left
            label Automatic Data Masking
            aiq-tooltip(effect="dark",
                      content="Enable this to automatically mask sensitive data, such as Social Security and card numbers. When a customer, or member, has more than one segment defined, you must enable this function, for all segments, for automatic data masking to occur.",
                      placement="bottom",
                      :show-after="500")
              aiq-switch(v-model="record.payload.automaticDLP"
                        style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
                        )
        .field-container.top-right
          label Queue Priority
          aiq-tooltip(effect="dark",
                      content="Set a queue priority of this segment. Segments with higher queue priority will receive priority in assignment to agents.",
                      placement="bottom",
                      v-if="false"
                      :show-after="500")
          aiq-input(size="small"
                  v-model.number="record.payload.queue_priority"
                  name="queue_priority"
                  type="number"
                  width="150"
                  min="1")
</template>

<script>
import merge from 'lodash/merge';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import { NON_DELETABLE_SEGMENTS } from '@/constants/segment';
import { IMAGE_ACL_TYPES, STATUS_CODES } from '@/constants';
import Editor from '@/components/Editor/Editor.vue';
import ModalEditor from '@/components/ModalEditor/ModalEditor.vue';

export default {
  name: 'customer-tab-segments-edit',
  extends: Editor,
  components: {
    ModalEditor,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      record: {
        file: { $autoDirty: true },
        name: { required, $autoDirty: true },
        payload: {
          alias: { $autoDirty: true },
          team_ids: { $autoDirty: true },
          automaticDLP: { $autoDirty: true },
          icon_url: { $autoDirty: true },
          queue_priority: { $autoDirty: true, minValue: minValue(1) },
        },
      },
    };
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    nextPriority: {
      type: Number,
      default: null,
    },
  },
  emits: ['afterSave'],
  computed: {
    ...mapGetters({
      teams: 'teams/teams',
    }),
    showImage() {
      return this.iconUploaded || get(this.record, 'payload.icon_url', null);
    },
    buttons() {
      const defaultButtons = ['close', 'save'];
      return NON_DELETABLE_SEGMENTS.includes(this.record.name) ? defaultButtons : [...defaultButtons, 'delete'];
    },
  },
  data() {
    return {
      iconUploaded: false,
      modelPath: 'segments/segmentModel',
      selectAction: 'segments/selectSegment',
      fetchAction: 'segments/getSegment',
    };
  },
  methods: {
    async resolveRecord() {
      if (this.id) {
        const { data } = await this.$store.dispatch(this.fetchAction, [this.id]);
        return merge(this.record, data);
      }
      return Promise.resolve(this.record);
    },
    removeIcon() {
      this.record = merge(this.record, { payload: { icon_url: null } });
      this.iconUploaded = false;
    },
    handleIconSuccess({ url }) {
      this.iconUploaded = false;
      this.record = merge(this.record, { payload: { icon_url: url } });
      this.iconUploaded = true;
    },
    uploadIcon(data) {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      return this.$store.dispatch('configs/upload', formData);
    },
    onError(err) {
      const containsSpace = !/^[A-Za-z0-9_-]+$/.test(this.record.name);
      if (err.status === STATUS_CODES.UNPROCESSABLE_ENTITY && containsSpace) {
        this.$aiq.notify.error('Segments cannot include special characters or spaces.');
        return true;
      }
      return false;
    },
    preparePayload(record) {
      let priority;
      if (record.payload.icon_url) {
        priority = get(record, 'payload.priority', this.nextPriority);
      }
      record.payload.priority = priority;
      return record;
    },
  },
};
</script>

<style lang="scss" scoped>
.iq-ico-close {
  background-color: red;
  position: absolute;
  right: 0;
    &::before {
      width: 20px;
      height: 20px;
      border-radius: 25px;
    }
}
.el-switch {
  margin-left: 30px;
}
</style>
