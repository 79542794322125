<template lang="pug">
.entity-dialog
  .top-divider
    .dialog-config-group
      h4.middle-column_content-title API
      aiq-cascader(:model-value="endpointWithDomain"
                  expand-trigger="hover"
                  placeholder="Select endpoint"
                  popper-class="dialog-entity__popper"
                  size="small"
                  :options="processedEndpoints"
                  :show-all-levels="false"
                  @change="onEndpointSelect")

  .dialog-config-group
    h4.middle-column_content-title Request
    select-box-pairs(:base-pairs="tempRequestPairs"
                    :options-left="requestParamOptions"
                    placeholder-left="Select Request Parameter"
                    :options-right="requestEntityOptions"
                    :placeholder-right="rightBoxPlaceHolderOnRequest"
                    :class-obj-right="useEntityIcon ? entityClassObj : ''"
                    :addable-based-on="addableForRequestBasedOn"
                    :use-raw-input-on="rawboxForRequestOn"
                    @update="onPairParamSelect($event, 'request')")

  .dialog-config-group(v-if="multipleResponses")
    h4.middle-column_content-title Entity & Response
    aiq-checkbox(v-if="!disableCheckBoxes" :model-value="saveEntity"
                @change="$emit('update:saveEntity', $event)")
      | Save entities
    select-box-pairs(:base-pairs="tempResponsePairs"
                    :options-left="responseEntityOptions"
                    placeholder-left="Select Entity"
                    :class-obj-left="useEntityIcon ? entityClassObj : ''"
                    :options-right="responseParamOptions"
                    :addable-based-on="addableForResponseBasedOn"
                    :use-raw-input-on="rawboxForResponseOn"
                    placeholder-right="Select Response Parameter"
                    @update="onPairParamSelect($event, 'response')")

  .dialog-config-group(v-else)
    h4.middle-column_content-title Response
    aiq-select(no-data-text="None available"
              size="small"
              :model-value="tempResponseValue"
              @change="onSingleResponseSelect($event)")
      aiq-option(v-for="item in responseParamOptions",
                :key="item",
                :value="item",
                :model-value="item")

  .top-divider
    aiq-checkbox(v-if="!disableCheckBoxes" :model-value="useConditionalActions"
                @change="$emit('update:useConditionalActions', $event)")
      | Use conditional actions
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';
import * as log from 'loglevel';

import { SelectBoxPairs } from '@/components';

const { mapGetters } = createNamespacedHelpers('settings');

export default {
  name: 'entityDialog',
  components: {
    SelectBoxPairs,
  },
  props: {
    domainName: {
      type: String,
      default: '',
    },
    endpointName: {
      type: String,
      default: '',
    },
    requestPairs: {
      type: Array,
      default: () => [],
    },
    responsePairs: {
      type: Array,
      default: () => [],
    },
    useConditionalActions: Boolean,
    saveEntity: {
      type: Boolean,
      default: false,
    },
    disableCheckBoxes: {
      type: Boolean,
      default: false,
    },
    addableForRequestBasedOn: {
      type: String,
      default: 'both',
    },
    addableForResponseBasedOn: {
      type: String,
      default: 'both',
    },
    rawboxForRequestOn: {
      type: String,
      default: 'none',
    },
    rawboxForResponseOn: {
      type: String,
      default: 'none',
    },
    useEntityIcon: {
      type: Boolean,
      default: true,
    },
    multipleResponses: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'update:requestPairs',
    'update:responsePairs',
    'update:domainName',
    'update:endpointName',
    'update:saveEntity',
    'update:useConditionalActions',
  ],
  data() {
    return {
      conditionalDialogsActiveFlag: process.env.FEATURE_FLAGS.CONDITIONAL_DIALOGS,
      entityClassObj: {
        'is-blue': true,
        'entity-item': true,
      },
      // responsePairs is aan array of arries and 0.1 refers to
      // always the second item of the first row
      tempResponseValue: get(this.responsePairs, '0.1', null),
    };
  },
  computed: {
    ...mapGetters([
      'endpointsByDomain',
      'endpointsMap',
    ]),
    endpointWithDomain() {
      return [this.domainName, this.endpointName];
    },
    processedEndpoints() {
      return this.endpointsByDomain.map(domain => {
        const children = domain.children.map(endpoint => ({
          value: endpoint.name,
          label: endpoint.name,
        }));

        return {
          value: domain.name,
          label: domain.name,
          children,
          expandTrigger: 'hover',
        };
      });
    },

    rightBoxPlaceHolderOnRequest() {
      return ['right', 'both'].includes(this.rawboxForRequestOn) ? 'ex) customer.profile.external_id' : 'Select Entity';
    },

    selectedEndpoint() {
      return this.endpointsMap[this.endpointName] || {};
    },

    // For Request Params
    tempRequestPairs() {
      const request_hints = this.selectedEndpoint.request_hints || [];
      const _requestPairs = this.requestPairs.map(pair => ({ content: pair }));
      const requiredHints = request_hints.filter(h => h.required);

      const missingRequiredPairs = requiredHints.reduce((missingPairs, requiredHint) => {
        const found = _requestPairs.find(pair => pair.content[0] === requiredHint.name);
        if (!found) {
          missingPairs.push({
            content: [requiredHint.name, ''],
            deleteDisabled: true,
          });
        } else {
          found.deleteDisabled = true;
        }
        return missingPairs;
      }, []);

      return missingRequiredPairs.concat(_requestPairs);
    },
    requestParamOptions() {
      const options = get(this, 'selectedEndpoint.request_hints', []);
      return options.map(hint => hint.name);
    },
    requestEntityOptions() {
      return this.$store.getters['entities/selectionList'].map(e => e.name);
    },

    // For Response Params
    tempResponsePairs() {
      return this.responsePairs.map(pair => ({ content: pair }));
    },
    responseParamOptions() {
      const options = get(this, 'selectedEndpoint.response_hints', []);
      return options.map(hint => hint.name);
    },
    responseEntityOptions() {
      return this.$store.getters['entities/selectionList'].map(e => e.name);
    },
  },
  methods: {
    onPairParamSelect(_pairs, type) {
      log.debug('onPairParamSelect', _pairs, type);
      const pairs = _pairs.map(pair => pair.content);
      this.$emit(`update:${type}Pairs`, pairs);
    },
    onEndpointSelect([domain_name, endpoint_name]) {
      log.debug('onEndpointSelect', domain_name, endpoint_name);
      this.$emit('update:requestPairs', []);
      this.$emit('update:responsePairs', []);

      this.$emit('update:domainName', domain_name);
      this.$emit('update:endpointName', endpoint_name);
    },
    onSingleResponseSelect(selection) {
      log.debug('onSelect', selection);
      this.tempResponseValue = selection;
      this.$emit('update:responsePairs', [['value', selection]]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-mixins.scss";

</style>
