<template lang="pug">
  .notification-dropdown(v-if="show" v-click-away="onClickOutside")
    .notification-dropdown-container
      .notification-header
        .notification-title
          h5 Notifications
        .notification-buttons
          aiq-button(link type="primary" @click="onReadAll") Read All
      ul.scroll.dropdown-menu
        li(v-for="(option, idx) in options" :key="idx")
          .item(:class="{ readmark: option.read_state === 'read'}")
            .item-type-color(v-if="option.read_state !== 'read'")
              .item-type-color-pink(v-if="option.payload.type === 'customer'")
              .item-type-color-blue(v-if="option.payload.type === 'agent'")
              .item-type-color-grey(v-if="option.payload.type === 'system'")
            .item-details
              .item-details-title
                span {{ getMessage(option) }}
              .item-details-time
                aiq-timeago(:since="option.created_at" :minTime="0")
              .button-options
            .item-delete
              i.el-icon-close(@click="deleteOption(option)")
            .item-click-area(@click="updateOption(option)")

        li.no-item(v-if="options.length === 0")
          span {{ this.noDataText }}

        infinite-loading(ref="infiniteLoading"
                        :distance="10"
                        :identifier="'notification'+resetCount"
                        spinner="waveDots"
                        @infinite="onScrollLoad")
          template(v-slot:spinner)
            .filter-loading
              span.filter-loading-item(v-for="item in 5")
          template(v-slot:complete)
            span
</template>

<script>
import get from 'lodash/get';
import { NOTIFICATIONS } from '@/constants/analytics';
import { getFullNamefromModel } from '@/libs/profile';


const NOTIFICATION_TYPE = {
  SELECTED_AS_PRIMARY: 'selected_as_primary_agent',
  REMOVED_FROM_PRIMARY: 'removed_from_primary_agent',
  ASSIGNED_TO_CONVERSATION: 'assigned_to_conversation',
  DETACHED_FROM_CONVERSATION: 'detached_from_conversation',
  MESSAGE_FROM_AGENT: 'message_from_agent',
  UNEXPECTED_AGENT_STATUS_CHANGED: 'unexpected_agent_status_change',
  JOB_STATUS_UPDATE: 'job_status_update',
  RECALL_REMINDER: 'recall_reminder',
  RECEIVED_PRIMARY_CUSTOMER_MESSAGE_WHEN_NOT_CONNECTED: 'message_from_primary_customer_when_not_connected',
};


export default {
  name: 'notification-dropdown',
  props: {
    options: {
      type: [Array, Object],
    },
    noDataText: {
      type: [String],
      default: 'You don\'t have notifications now. As soon as something happens, you\'ll find it here.',
    },
    show: {
      type: [Boolean],
      default: false,
    },
  },
  emits: ['select', 'delete', 'close', 'readall', 'bottom'],

  data() {
    return {
      resetCount: 0,
    };
  },
  methods: {
    updateOption(option) {
      this.$store.dispatch(
        'notifications/emitEventToSocket',
        { name: NOTIFICATIONS.NOTIFICATION_CLICKED,
          payload: {
            agent: this.$store.getters['agent/profile'],
            type: get(option, 'payload.type'),
          },
        },
      );
      this.$emit('select', option);
    },

    deleteOption(option) {
      this.$store.dispatch(
        'notifications/emitEventToSocket',
        { name: NOTIFICATIONS.NOTIFICATION_DELETE,
          payload: {
            agent: this.$store.getters['agent/profile'],
            type: get(option, 'payload.type'),
          },
        },
      );
      this.$emit('delete', option);
    },

    onClickOutside() {
      this.$emit('close');
    },

    onReadAll() {
      this.$store.dispatch(
        'notifications/emitEventToSocket',
        { name: NOTIFICATIONS.READ_ALL,
          payload: {
            agent: this.$store.getters['agent/profile'],
          },
        },
      );
      this.$emit('readall');
    },

    onScrollLoad($state) {
      this.$emit('bottom', {
        done: () => {
          $state.loaded();
        },
        complete: () => {
          $state.complete();
        },
      });
    },
    getMessageTypeBasedOnName(notificationType, notification) {
      const id = notification?.data?.id;
      return this.$t(
        `notification.item.${notificationType}.description_with_id`,
        {
          defaultValue: notification.message,
          id,
        },
      );
    },
    getInterpolationData(notificationType, inputData, defaultMessage) {
      const data = {};
      switch (notificationType) {
        case NOTIFICATION_TYPE.SELECTED_AS_PRIMARY:
        case NOTIFICATION_TYPE.REMOVED_FROM_PRIMARY:
        case NOTIFICATION_TYPE.ASSIGNED_TO_CONVERSATION:
        case NOTIFICATION_TYPE.DETACHED_FROM_CONVERSATION:
        case NOTIFICATION_TYPE.RECEIVED_PRIMARY_CUSTOMER_MESSAGE_WHEN_NOT_CONNECTED:
          {
            // customer
            const id = get(inputData, 'id');
            const name = getFullNamefromModel(inputData);
            data.name = name || this.$t('notification.common.customer', { id });
          }
          break;
        case NOTIFICATION_TYPE.MESSAGE_FROM_AGENT:
          {
            // agent
            const id = get(inputData, 'id');
            const name = getFullNamefromModel(inputData);
            data.name = name || this.$t('notification.common.agent', { id });
          }
          break;
        case NOTIFICATION_TYPE.UNEXPECTED_AGENT_STATUS_CHANGED:
          {
            // status and reason
            const reason = get(inputData, 'reason', '');
            data.status = get(inputData, 'status', '');
            data.reason = this.$t(`notification.unexpected_agent_status_change_reason.${reason}`, defaultMessage);
          }
          break;
        case NOTIFICATION_TYPE.JOB_STATUS_UPDATE:
          // title and status
          data.title = get(inputData, 'title', '');
          data.status = get(inputData, 'status', '');
          break;
        case NOTIFICATION_TYPE.RECALL_REMINDER:
          // title
          data.title = get(inputData, 'title', '');
          break;
        default:
          break;
      }

      return data;
    },
    getMessage(item) {
      const notification = item.payload;
      if (!notification.notification_type) {
        // backword compatibility
        return notification.message;
      }

      const notificationType = notification.notification_type;
      const interpolationData = this.getInterpolationData(
        notificationType,
        notification.data,
        notification.message,
      );
      let localizedMessage = this.$t(
        `notification.item.${notificationType}.description`,
        {
          defaultValue: notification.message,
          ...interpolationData,
        },
      );
      const shouldLocalizeBasedOnId = [NOTIFICATION_TYPE.ASSIGNED_TO_CONVERSATION, NOTIFICATION_TYPE.DETACHED_FROM_CONVERSATION].includes(notificationType) && notification.message.includes('Customer');
      if (shouldLocalizeBasedOnId) {
        localizedMessage = this.getMessageTypeBasedOnName(notificationType, notification);
      }
      return localizedMessage;
    },
    resetInfiniteLoading() {
      this.resetCount++;
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/aiq-variables.scss";

.notification-dropdown {
  position: relative;
  display: inline-block;
}

.notification-dropdown-container {
  position: fixed;
  top: 64px;
  right: 0px;
  z-index: 1000;
  float: left;
  width: 317px;
  height: calc(100vh - 64px);
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 1px 1px 0px rgba(191, 203, 217, 1);
  padding: 10px;
  line-height: 20px;
  h5 {
    color: $aiq-text-main-title;
  }

  button {
    padding: 0px;
  }
}

.dropdown-menu {
  height: calc(100vh - 125px);
  padding: 10px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
}

li {
  list-style: none;
}

.item {
  position: relative;
  min-height: 46px;
  display: block;
  clear: both;
  border-radius: 2px;
  color: rgba(50, 64, 87, 1);
  border-radius: 2px;
  box-shadow: 0px 0px 4px 1px rgba(191, 203, 217, 1);
  margin-top: 4px;
  line-height: 20px;

  .item-delete {
    position: absolute;
    top: 5px;
    right: 10px;
    color: $aiq-icon-primary;
    cursor: pointer;
    opacity: 0;
    i {
      font-size: 10px;
      width: unset;
    }
  }

  .item-click-area {
    position: absolute;
    width: calc(100% - 32px);
    height: 100%;
    cursor: pointer;
    top: 0px;
  }
}

.item:hover {
  background: $aiq-bgc-list-item-hover;
  .item-delete {
    opacity: 1;
    cursor: pointer;
  }
}

.item-details {
  padding: 5px 20px 5px 9px;
  height: calc(100% - 10px);
}

.item-type-color {
  float: left;
  width: 4px;
  height: 100%;
  position: absolute;
}

.item-type-color-pink {
  width: 100%;
  height: 100%;
  background: rgba(230, 91, 179, 1);
  border-radius: 2px 0px 0px 2px;
}

.item-type-color-grey {
  width: 100%;
  height: 100%;
  background: rgba(191, 203, 217, 1);
  border-radius: 2px 0px 0px 2px;
}

.item-type-color-blue {
  width: 100%;
  height: 100%;
  background: rgba(70, 113, 179, 1);
  border-radius: 2px 0px 0px 2px;
}

.item-details-title {
  font-size: 12px;
  text-wrap: wrap;
}

.item-details-time {
  font-size: 10px;
}

.no-item {
  padding: 30px;
  color: rgba(94, 115, 130, 1);
  text-align: center;
  span {
    display: block;
    text-wrap: wrap;
    line-height: 20px;
  }
}

.readmark {
  background: $aiq-bgc-list-item-inactive;
  color: rgba(83, 100, 128, 1);
  border: 0px;
}

</style>
