<template lang="pug">
.sidebar-inner
  router-link.sidebar-item(v-for="item in showItems"
                          :key="item.href"
                          :to="item.href"
                          :class="getClass(item)")
    font-awesome-icon.iq-ico-item(:icon="[getIconType(item), getIcon(item)]" size="xl")
    | {{ $filters.toUpperFirst(item.title) }}
</template>

<script>
const SIDEBAR_ICONS = {
  Platform: 'display-chart-up',
  Customer: 'users',
  Member: 'users',
  Agents: 'user-headset',
  Insights: 'magnifying-glass-chart',
  Assist: 'lightbulb-exclamation-on',
  Admin: 'headset',
  Teams: 'people-group',
  Channels: 'network-wired',
  Tags: 'tag',
  Categories: 'folder-open',
  Assets: 'briefcase',
  Documents: 'file',
  Integrations: 'gears',
  APIs: 'terminal',
  Import: 'file-import',
  Intents: 'location-crosshairs',
  Entities: 'at',
  Dialogs: 'messages-question',
  Workflows: 'arrow-progress',
  'System Triggers': 'sliders',
};

export default {
  name: 'sidebarDefault',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    showItems() {
      return this.items.filter(item => !item.hideItem);
    },
  },
  methods: {
    getClass(item) {
      return {
        'router-link-active': item.href === this.currentPath,
        'sidebar-item_font--small': item.fontSize === 'small',
      };
    },
    getIcon(item) {
      return SIDEBAR_ICONS[item.title];
    },
    getIconType(item) {
      return this.getIcon(item) === 'arrow-progress' ? 'fass' : 'fas';
    },
  },
};
</script>

<style lang="scss" scoped>
.md-theme-agentiq {

.sidebar-inner {
    border-radius: 0 4px 0 0;
}


.sidebar-item {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    line-height: 12px;

    &:hover {

      &:not([class*="active"]) {
        background-color: #d6e1f1;
        color: #4671B3;
        text-decoration: none;
      }
      &[class*="active"] {
        color: #fff;
        text-decoration: none;
      }
    }

    &[class*="active"] {
      color: #fff;
    }

    &:not(md-button) {
      color: #4671B3;
      font-weight: 300;
    }

    &.sidebar-item_font--small {
      font-size: 10px;
    };
  }
}
</style>
