<template lang="pug">
  management-page.scrollable(title="Availability"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
    template(v-slot:body)
      .chart-configuration
        .agent-table-toolbar
            .flex-spacer
                FilteredInput.search(v-model="searchTerm" size="small" placeholder="Search Name")
            .dropdown-filters
                .dropdown-filter
                    aiq-select(placeholder="Filter by Teams with Agents"
                                         v-model="chosenAgentTeamFilterOptions"
                                         multiple
                                         :reserve-keyword="false"
                                         collapse-tags
                                          @change="onAgentsTeamsFilterChange($event)"
                                         value-key='id'
                                         size="small")

                         aiq-option(v-for="option in agentsTeamsFilterOptions"
                                  :key="option.id"
                                  :label="optionLabel(option)"
                                  :value="option")
            .time-range-filter
              aiq-date-picker(v-model="dateRange"
                              @change="handleDatePickerChange($event)"
                              type="daterange"
                              placeholder="Select date range",
                              popper-class="metrics-time-range__date-picker-popup"
                              :shortcuts="datePickerOptions.shortcuts"
                              :disabled-date="datePickerOptions.disabledDate"
                              :teleported="false"
                              format="YYYY/MM/DD"
                              :clearable="false")

      .events_table(v-for="tableData in TableDataCollection")
         .title-bar
           span {{tableData.label}}
           aiq-button.export(@click="exportTable(tableData.data, tableData.total_dow_duration_col, tableData.total_duration_col)")
             i.iq-ico-export
         aiq-table.scrollable(:data="tableData.data"
                            ref="AgentsTable"
                            v-loading="loading")
           aiq-table-column(label="Agent", :min-width="8", label-class-name="is-bordered")
             template(v-slot:default="scope")
               span {{ getAgentName(scope.row) }} &nbsp;
           aiq-table-column(:label="tableData.title", :min-width="5", align="center", label-class-name="is-bordered")
             aiq-table-column(v-for="dow in dows"
                :prop="dow.name"
                :min-width="5"
                :label="dow.label"
                :key="dow.key"
                label-class-name="is-bordered")
               template(v-slot:default="scope")
                   span {{ getDayAvailability(scope.row[tableData.total_dow_duration_col], dow.name) }} &nbsp;
           aiq-table-column(:label="tableData.TotalColLable", :min-width="7", :sortable="true", :sort-method="tableData.sortTD", label-class-name="is-bordered")
             template(v-slot:default="scope")
               .highlight-col
                 span {{ getTotalAvailability(scope.row, tableData.total_duration_col) }} &nbsp;
</template>


<style lang="scss">
@import "../../../../styles/aiq-variables.scss";
</style>

<script>
import get from 'lodash/get';
import omit from 'lodash/omit';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import PlatformFiltersMixin from '../../Platform/children/selectableFilters/SelectableFilters';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

// eslint-disable-next-line no-unused-vars
import { downloadAsFile } from '@/libs';
import { loadFilterOptions } from '../../helpers/filterHelper';
import { hoursFormatter } from '../../helpers/formatters';
import { defaultDateRange, dstShift, datePickerDefaultOptions, setToEndOfDay, getNonReactiveDateRange, lastAvailableMonthAndQuarterRange, createPickerDateRangeOption, dstDateRangeUtc } from '../../configs/dateRange';

const DAY_RANGE = 6;
const DOWS = [
  { key: 1, name: 'monday', label: 'Monday' },
  { key: 2, name: 'tuesday', label: 'Tuesday' },
  { key: 3, name: 'wednesday', label: 'Wednesday' },
  { key: 4, name: 'thursday', label: 'Thursday' },
  { key: 5, name: 'friday', label: 'Friday' },
  { key: 6, name: 'saturday', label: 'Saturday' },
  { key: 7, name: 'sunday', label: 'Sunday' },
];

const getStartEndMomentDates = (dateEpoch, deltaIncrement,
  deltaType = 'days') => ([
  deltaIncrement ? moment(dateEpoch) : moment(dateEpoch).startOf(deltaType),
  deltaIncrement ? moment(dateEpoch).add(deltaIncrement,
    deltaType) : moment(dateEpoch).endOf(deltaType),
]);

const breakdownOptions = [

  {
    value: 'day',
    label: 'Daily',
    isDefault: true,
    xAxisFormat: value => moment(value).format('MMM DD'),
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 0, 'days'),
  },
  {
    value: 'week',
    label: 'Weekly',
    xAxisFormat: value => {
      const dateRange = getStartEndMomentDates(value, DAY_RANGE);
      const format = 'MMM DD';
      return `${dateRange[0].format(format)} - ${dateRange[1].format(format)}`;
    },
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 1, 'weeks'),
  },
  {
    value: 'month',
    label: 'Monthly',
    xAxisFormat: value => moment(value).format('MMM'),
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 1, 'months'),


  },
];


export default {
  pageResource: '/metrics',
  components: {
    ManagementPage,
  },
  mixins: [PlatformFiltersMixin],
  data() {
    return {
      unwantedResponseFields: [
        'id',
        'days_avg_availability',
        'days_avg_busy',
      ],
      searchTerm: '',
      teamsEndpoint: 'all_teams',
      dows: DOWS,
      availabilityDataConfig:
        {
          title: 'Available Hours',
          total_dow_duration_col: 'days_total_availability',
          total_duration_col: 'sum_total_days_availability',
          TotalColLable: 'Total Available Hours',
          sortTD: this.onSortTA,
        },
      busyDataConfig: {
        title: 'Busy Hours',
        total_dow_duration_col: 'days_total_busy',
        total_duration_col: 'sum_total_days_busy',
        TotalColLable: 'Total Busy Hours',
        sortTD: this.onSortTB,
      },
      chosenAgentTeamFilterOptions: [],
      selectedFilters: [],
      TableDataCollection: [],
      selectedBreakdownOption: breakdownOptions.find(
        ({ isDefault }) => isDefault,
      ),
      dateRange: cloneDeep(defaultDateRange()),
      loading: true,
    };
  },
  computed: {
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
      };
    },
  },
  watch: {
    dateRange: debounce(async function onDateRangeChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.loadList();
      }
    }, 1),
    searchTerm() {
      this.loadList();
    },
    selectedFilters() {
      this.loadList();
    },
  },
  methods: {
    makeTableDataCollection() {
      this.TableDataCollection = [this.availabilityDataConfig, this.busyDataConfig];
    },
    getAgentName(datapoint) {
      return `${datapoint.agents_full_name || ''}`;
    },
    getMedianResponseTime(datapoint) {
      const responseTime = get(datapoint, this.MedianResponseColName);
      return isUndefined(responseTime) || responseTime === '' ? '' : `${responseTime}s`;
    },
    getDayAvailability(availabilityByDay, day) {
      const availabilityHours = get(availabilityByDay, day);
      return hoursFormatter(availabilityHours, undefined, false);
    },
    getTotalAvailability(row, colName) {
      const availabilityHours = get(row, colName);
      return hoursFormatter(availabilityHours, undefined, false);
    },
    getNumberOfConversations(datapoint) {
      return datapoint.total_conversations;
    },
    onSortTA(a, b) {
      return this.sortObjOnProp(a, b, this.availabilityDataConfig.total_duration_col);
    },
    onSortTB(a, b) {
      return this.sortObjOnProp(a, b, this.busyDataConfig.total_duration_col);
    },
    onSortRT(a, b) {
      return this.sortObjOnProp(a, b, this.MedianResponseColName);
    },
    sortObjOnProp(obj1, obj2, colName) {
      return get(obj1, colName) < get(obj2, colName) ? 1 : -1;
    },
    unrollDays(datapoint, unrollField) {
      const availabilityPerDay = get(datapoint, unrollField);
      const formattedAvailabilityPerDay = DOWS
        .map(dow => dow.name)
        .reduce((obj, day) => {
          obj[day] = this.getDayAvailability(availabilityPerDay, day);
          return obj;
        }, {});
      return Object.assign(omit(datapoint, unrollField), formattedAvailabilityPerDay);
    },
    removeUnWantedFields(datapoint) {
      this.unwantedResponseFields
        .map(f => unset(datapoint, f));
      return datapoint;
    },
    transform(datapoint, unrollField, totalField) {
      datapoint = this.removeUnWantedFields(this.unrollDays(datapoint,
        unrollField));
      datapoint[totalField] = this.getTotalAvailability(datapoint, totalField);
      return datapoint;
    },
    exportTable(data, unrollField, totalField) {
      downloadAsFile({
        dataType: 'table',
        dataSource: 'metrics',
        data: cloneDeep(data).map(dp => this.transform(dp, unrollField, totalField)),
      });
    },
    async loadList() {
      this.availabilityDataConfig.data = await this.loadMetrics('stats_agent_availability');
      this.busyDataConfig.data = await this.loadMetrics('stats_agent_busy');
      this.makeTableDataCollection();
    },
    async loadMetrics(endpoint) {
      const periodStart = dstShift(this.dateRange[0]);
      const periodEnd = dstShift(this.dateRange[1]);
      if (!(periodStart && periodEnd)) {
        return;
      }
      const params = {
        period_start: periodStart,
        period_end: moment.unix(periodEnd).endOf('day').unix(),
        bucket: this.selectedBreakdownOption.value,
      };
      if (!isEmpty(this.selectedFilters)) {
        params.filters = this.selectedFilters;
      }
      if (!isEmpty(this.searchTerm)) {
        params.query = this.searchTerm;
      }
      return this.$store
        .dispatch('metrics/getMetric', [endpoint, params])
        .then(data => {
          this.loading = false;
          return get(data, 'dataset.0.data_points', []);
        });
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
  },


  async mounted() {
    await this.loadList();
    await loadFilterOptions(this, this.teamsEndpoint);
  },
};
</script>

<style lang="scss">
.events_table {
  .el-table {
      td {
          border-right: none;
      }
  }
}
</style>

<style lang="scss" scoped>
  @import "../../../../styles/aiq-mixins.scss";
  .scrollable {
      overflow-y: auto;
  }
  .el-table--fit{
    height: calc(100% - 100px);
  }

  .status-indicator {
    &::before {
      margin-top: 1px;
    }
  }
  .right-aligned {
    float: right;
  }
  .chart-grid {
    margin: 24px 0;
  }

  .highlight-col {
      font-weight: bold;
  }
  .chart-configuration {
    margin-bottom: 24px;

    .agent-table-toolbar {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .dropdown-filters {
          display: flex;
          align-content: flex-end;
          .dropdown-filter {
              margin-left: 12px;
              width: 23em;
              height: 30px;
          }
      }
      .time-range-filter {
        position: relative;
        margin-left: 12px;
      }
      .search {
        width: 246px;
      }
    }
  }
  .events_table{
      margin-top: 25px;
      .title-bar {
          span {
              float: left;
          }
          .el-button{
              float: right;
              margin: 10px;
          }
      .el-button.export {
        height: 30px;
        width: 40px;
        padding: 0;
        margin-left: 5px;
      }
    }
  }
</style>
