import { mapActions, mapGetters } from 'vuex';

import EntityCustomSourceList from './EntityCustomSourceList.vue';
import EntityDefaultSource from './EntityDefaultSource.vue';
import ConfiguredEntities from './EntityList.vue';
import PreconfiguredEntities from './PreconfiguredEntities/PreconfiguredEntities.vue';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';

const PRECONFIGURED_DESCRIPTION = 'System entities that are not configurable.';
export default {
  name: 'entities',
  components: {
    ConfiguredEntities,
    EntityCustomSourceList,
    EntityDefaultSource,
    ManagementPage,
    PreconfiguredEntities,
  },
  data() {
    return {
      // Default page
      pageIndex: 1,

      preconfiguredDescription: PRECONFIGURED_DESCRIPTION,
      preconSearchTerms: '',
    };
  },
  computed: {
    ...mapGetters({
      preconfiguredEntitiesFunc: 'entities/preconfiguredEntities',
    }),
    preconfiguredEntities() {
      return this.preconfiguredEntitiesFunc(this.preconSearchTerms);
    },
  },
  created() {
    const params = [
      { q: '', limit: 1000, offset: 0 },
      true,
    ];

    this.getEntitysourcesList(params);
    this.getEntitiesList(params);
  },

  methods: {
    ...mapActions({
      getEntitiesList: 'entities/getEntitiesList',
      getEntitysourcesList: 'entitySources/getEntitysourcesList',
    }),
    onMenuSelected(pageIndex) {
      this.pageIndex = parseInt(pageIndex, 10);
    },
    onFetchData({ searchTerm }) {
      this.preconSearchTerms = searchTerm;
    },
  },
  watch: {
    pageIndex() {
      this.preconSearchTerms = '';
    },
  },
};
