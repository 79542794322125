<template lang='pug' id='donut'>
extends ./Base.pug
</template>

<script>
import BaseChart from './Base.vue';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

export default {
  extends: BaseChart,
  name: 'aiqDonutChart',
  data() {
    return {
      colColors: cloneDeep(this.graphParams.colColors),
    };
  },
  methods: {
    getChartType() {
      return 'donutChart';
    },
    dataValues() {
      const { dataset } = this.report;
      if (isEmpty(dataset)) {
        return [];
      }
      return get(dataset[0], 'data_points', []);
    },
    getColumns() {
      return this.dataValues().map((obj) => [
        get(obj, this.graphParams.dataNameField, ''),
        get(obj, this.graphParams.dataValueField, ''),
      ]);
    },
    reduceToColors(obj, colName, colNumber) {
      if (!obj[colName]) {
        set(obj, colName, this.colorChooser(colNumber));
      }
      return obj;
    },
    getTitle() {
      return this.graphParams.title;
    },
    setColorScheme() {
      // Not sure why colColors are overlapped.
      // Maybe it will be c3.js or c3+vue.js problem.
      // Anyway, we can reset it here.
      this.colColors = cloneDeep(this.graphParams.colColors);
      this.getColumns()
        .map((x) => x[0])
        .reduce(this.reduceToColors, this.colColors);
    },
    c3GenerateParams() {
      this.setShowLegend(false);
      this.setColorScheme();
      return {
        bindto: this.chartContainerSelector(),
        data: {
          columns: this.getColumns(),
          type: 'donut',
          colors: this.colColors,
        },
        donut: {
          title: this.getTitle(),
        },
        legend: {
          show: this.showLegend,
          position: this.graphParams.legendPosition || 'bottom',
        },
      };
    },
  },
};
</script>

<style lang='scss'>
</style>
