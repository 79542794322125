import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import map from 'lodash/map';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { INGESTION_MODES, SMS_TEMPLATE_HEADING, WEBCHAT_TEMPLATE_HEADINGS} from '@/constants';
import { confirmBeforeDialogClose } from '@/libs';

const INFO = 'Displays ingested records.';
const localForm = { sms_templates: [], template_variables: [], web_chat_templates: [] };
const CSV_LIMIT = 300;
export default {
  name: 'Import',
  components: {
    ManagementPage,
  },
  data() {
    return {
      // File Path
      modes: INGESTION_MODES,
      selectedState: 'failure',
      uploadDialogShown: false,
      uploadDialogConfirmationShown: false,
      fileNameToUpload: '',
      inUploadingProgress: false,
      fileNotSelected: true,
      file: null,
      shouldOverride: false,
      pagination: {
        offset: 0,
        limit: 20,
        rowCount: 0,
        pageCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters({ RECORD_STATE: 'ingestion/RECORD_STATE' }),
    ...mapState({
      records: state => state.ingestion.records,
      mode: state => state.ingestion.mode,
      messageTemplate: state => state.configs.messageTemplate || localForm,
    }),
    stateOptions() {
      return Object.values(this.RECORD_STATE)
        .sort()
        .map(val => ({ label: upperFirst(val), value: val }));
    },
  },
  created() {
    this.$_info = INFO;
  },
  async mounted() {
    this.load();
    await this.$store.dispatch('configs/getMessageTemplateConfig');
    this.modes = INGESTION_MODES.concat(this.parseMessageTemplates(this.messageTemplate));
  },
  methods: {
    ...mapActions({
      loadImportedData: 'ingestion/loadImportedData',
      resolveConflict: 'ingestion/resolveConflict',
      ingestFile: 'ingestion/ingestFile',
    }),
    ...mapMutations({
      setMode: 'ingestion/SET_MODE',
    }),
    /**
     * Takes the message template config and parses it to a list of options that
     * can be used to select which template to use when sending an outbound
     * message or web chat message
     *
     * @param {Object} template - The message template config
     * @returns {Array<Object>} - A list of options that can be used to select
     * which template to use.
     */
    parseMessageTemplates(template) {
      const templates = template.message_templates || [];

      /**
       * Maps the templates to the required format.
       */
      const parsed_item = templates.map(
        ((template, index) => {
          return {
            key: template.title,
            label: template.title,
            endpoint: template.template_type === 'sms' ? '/sms/withtemplate' : '/message/withtemplate',
            headings: template.template_type === 'sms' ? SMS_TEMPLATE_HEADING : WEBCHAT_TEMPLATE_HEADINGS,
            isDefault: false,
            overridable: false,
            template_index: index,
          };
        }),
      );

      return parsed_item;
    },
    async load() {
      try {
        const res = await this.loadImportedData({
          state: this.selectedState,
          pagination: this.pagination,
        });
        this.pagination = res.pagination;
      } catch (err) {
        // Unable to load data
        this.$aiq.notify.error('Unable to fetch data.');
      }
    },
    async sendResolveConflict(recordId, resolution) {
      try {
        await this.resolveConflict({ ...recordId, resolution });
        this.$aiq.notify.success('The record has been resolved.');
      } catch (err) {
        this.$aiq.notify.error('Unable to resolve the conflict.');
      }
    },
    async onUploadChange(data) {
      this.fileNameToUpload = get(data, 'name', '');
      this.file = data.raw;
      this.fileNotSelected = false;
    },
    onChangeState(newState) {
      this.selectedState = newState;
      this.pagination = {
        offset: 0,
        limit: 20,
        rowCount: 0,
        pageCount: 0,
      };
      this.load();
    },
    clearLocalData() {
      this.fileNameToUpload = '';
      this.file = null;
      this.fileNotSelected = true;
      this.shouldOverride = false;
    },
    async upload() {
      this.inUploadingProgress = true;
      if (this.file) {
        try {
          const ingestion = await this.ingestFile({ data: this.file, override: this.shouldOverride });
          const ingestion_error = get(ingestion, 'failure', []);
          if (!ingestion_error.length) {
            this.$aiq.notify.success(this.$t('settings_tab.import_tab.upload_alert.ingestion_success', { filename: this.fileNameToUpload }));
          } else {
            this.$aiq.notify.error(this.$t('settings_tab.import_tab.upload_alert.ingestion_error', { filename: this.fileNameToUpload }));
          }
        } catch (err) {
          const errors = get(err, 'response.data.errors', []);
          if (errors.length) {
            const errorString = errors[0].reason;
            this.$aiq.notify.error(errorString);
          } else {
            const error = get(err, 'response.data');
            const errorMsg = error || this.$t('settings_tab.import_tab.upload_alert.upload_failed', { filename: this.fileNameToUpload });
            this.$aiq.notify.error(errorMsg);
          }
        }
      }
      this.inUploadingProgress = false;
      this.uploadDialogConfirmationShown = false;
      this.$nextTick(() => {
        this.uploadDialogShown = false;
      });

      this.clearLocalData();
      await this.load();
    },
    async onPageChanged(page) {
      this.pagination.offset = this.pagination.limit * (page - 1);
      await this.load();
    },
    csvExport() { // eslint-disable-line
      const csvContent = this.mode.headings.join(',');
      const data = `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`;
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', `${this.mode.label} template.csv`);
      link.click();
    },
    toCSV(obj) { // eslint-disable-line
      return map(obj, (value) => value).join(', ');
    },
    async readCsv() {
      const reader = new FileReader();
      const csvData = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsText(this.file);
      });
      const rows = csvData.split('\n');
      rows.shift(); // remove the header row
      return rows.map((row) => row.split(','));

    },
    uploadCsv() {
      if (this.shouldOverride) {
        this.uploadDialogShown = false;
        this.uploadDialogConfirmationShown = true;
      } else {
        this.upload();
      }
    },
    async openUploadConfirmationDialog() {
      const csvRecord = await this.readCsv();
      // if the csv file is greater than the limit, ask the user if they want to continue
      // only display warning for message template variables types
      if (csvRecord.length >= CSV_LIMIT && this.mode.template_index !== undefined) {
        this.$aiq.confirm(
          this.$t('settings_tab.import_tab.upload_alert.warning'),
          this.$t('settings_tab.import_tab.upload_alert.upload_too_large'),
          {
            confirmButtonText: this.$t('settings_tab.import_tab.upload_alert.continue'),
          },
        ).then(() => {
          this.uploadCsv();
        }).catch(() => {});
      } else {
        this.uploadCsv();
      }

    },
    openUploadDialog() {
      this.uploadDialogShown = true;
      this.uploadDialogConfirmationShown = false;
    },
    handleImportCsvModalBeforeClose() {
      // The default value for mode is the first value of modes
      // Therefore if they're not equal, it means user has changed it
      const userHasUnsavedInfo = this?.file || this.modes?.[0].label !== this.mode?.label;
      if (userHasUnsavedInfo) {
        confirmBeforeDialogClose(this, this.closeImportCsvModal);
      } else {
        this.closeImportCsvModal();
      }
    },
    closeImportCsvModal() {
      this.uploadDialogShown = false;
      this.uploadDialogConfirmationShown = false;
      this.clearLocalData();
      this.setMode(this.modes?.[0]);
    },
  },
};