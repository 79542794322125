<template lang="pug">
  aiq-dropdown(ref="dropdown" :key="items.length" trigger="click", @command="$emit('translate', $event)", @visible-change="onDropDownVisibleChange")
    div
      aiq-tooltip(effect="dark", content="Translate", placement="bottom", :show-after="1000", :disabled="isDropdownListShown" v-if="canView('/conversations/inputbox/translation')")
        aiq-button
          fa-icon(:icon="['fas', 'language']" class="panel-icon")
    template(v-slot:dropdown)
      aiq-dropdown-menu
        aiq-dropdown-item(:disabled="true")
          b {{ title }}
        aiq-dropdown-item(v-for="item in items", :key="item.code", :command="item") {{ item.name }}
</template>
<script>
import { mapActions } from 'vuex';
import { get, cloneDeep } from 'lodash';
import { DEFAULT_SYSTEM_LANGUAGE } from '@/constants/settings';

export default {
  name: 'TranslateButton',
  props: {
    languages: {
      type: Array,
      default: () => [],
      required: true,
    },
    message: {
      type: String,
      default: () => null,
      required: false,
    },
  },
  computed: {
    items() {
      return this.languages.filter(language => language.code !== get(this.detectLanguage, 'code'));
    },
    title() {
      if (this.items.length) return `Translate ${get(this.detectLanguage, 'name', '')} to`;
      return 'No languages available';
    },
  },
  data() {
    return {
      isDropdownListShown: false,
      detectLanguage: cloneDeep(DEFAULT_SYSTEM_LANGUAGE),
    };
  },
  watch: {
    items: {
      deep: true,
      handler() {
        const visible = this.$refs.dropdown.visible;
        this.$nextTick(() => {
          this.$refs.dropdown.visible = visible;
        });
      },
    },
  },
  methods: {
    ...mapActions({
      detect: 'translation/detect',
    }),
    async onDropDownVisibleChange(isVisible) {
      this.isDropdownListShown = isVisible;
      if (this.isDropdownListShown && this.message) {
        this.detectLanguage = await this.detect({ q: this.message });
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
  .panel-icon {
  cursor: pointer;
}

.el-dropdown {
  display: inline-flex;
}
.el-dropdown-menu {
  position: relative;
  width: 165px;
  max-height: 242px;
  overflow-y: scroll;
}
:deep(.el-dropdown-menu__item.is-disabled) {
  color: black;
  white-space: normal;
  float: left;
  word-wrap: break-word;
}
  </style>
