export const getAgentStatusOptions = (allStatuses, includeAll = true) => {
  const result = [];
  for (const agentStatus of allStatuses) {
    if (agentStatus.selectable || includeAll) {
      result.push({
        id: agentStatus.status,
        label: agentStatus.label,
        status: agentStatus.status,
        subStatus: '',
      });
    }

    for (const subStatus of agentStatus.subStatuses) {
      if (subStatus.selectable || includeAll) {
        result.push({
          id: `${agentStatus.status}.${subStatus.status}`,
          label: `${agentStatus.label} | ${subStatus.label}`,
          status: agentStatus.status,
          subStatus: subStatus.status,
        });
      }
    }
  }
  return result;
};