<template lang="pug">
layout-main(v-if="useLayout")
  .content-inner.sidebar-pane-routing.ml-0
    aiq-tabs(v-model="activeTab"
             @tab-click="onTabChange"
             type="border-card"
             )
      aiq-tab-pane(v-for="tab in tabs"
                  :key="tab.path"
                  :label="tab.label"
                  :name="tab.name")
        router-view(v-if="tab.name === activeTab")
.content-inner.sidebar-pane-routing(v-else)
  aiq-tabs(v-model="activeTab"
            @tab-click="onTabChange"
            type="border-card"
            )
    aiq-tab-pane(v-for="tab in tabs"
                :key="tab.path"
                :label="tab.label"
                :name="tab.name")
      router-view(v-if="tab.name === activeTab")
</template>

<script>
import get from 'lodash/get';
import LayoutMain from '@/components/layouts/main.vue';

function gotToFirstValidChild(children, ctx) {
  const firstChild = children.find((child) => ctx.canView(ctx.buildResourcePath(child.path)));
  ctx.$router.push({ name: firstChild.name });
}

export default {
  name: 'SidebarAndPanesWithRouting',
  components: {
    LayoutMain,
  },
  props: {
    useLayout: {
      type: Boolean,
      default: false,
    },
    baseResource: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      activeTab: get(this.$route, 'name', ''),
      tabs: this.children
        .filter(({ path }) => path && this.canView(this.buildResourcePath(path)))
        .map(({ path, meta, name }) => ({ path, label: meta.label, name })),
    };
  },
  watch: {
    $route(to) {
      this.activeTab = to.name;
    },
  },
  methods: {
    onTabChange(tab) {
      this.$router.push({ name: tab.paneName });
    },
    buildResourcePath(resource) {
      return `${this.baseResource}/${resource}`;
    },
  },
  beforeRouteEnter(to, _, next) {
    next(vm => {
      const { baseResource, children } = vm;
      if (baseResource === to.path) {
        gotToFirstValidChild(children, vm);
        return false;
      }
      return true;
    });
  },
  // We need it when beforeRouteEnter is not called
  // which is when navigating from a child route to a parent route
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.meta.parentName) {
      gotToFirstValidChild(this.children, this);
      return;
    }
    next();
  },
};
</script>



<style lang="scss">
  .content-inner.sidebar-pane-routing {
    &.ml-0 {
      margin-left: 0px;
    }
    .el-tabs {
      border-bottom-width: 0px;
      box-shadow: none;

      .el-tabs__content {
        overflow-y: auto;
        height: calc(100vh - 118px);
      }
    }
  }
</style>

