import { mapState, mapGetters } from 'vuex';

import aiqCustomCascader from './CustomCascader.vue';
import { STATUS_CODES } from '@/constants';

const getFilteredList = function getFilteredList(state, prefix, key) {
  const filteredList = state.entitySources.entitySources
    .filter(source => source.type.startsWith(prefix));

  if (key) {
    return filteredList.map(val => val[key]);
  }
  return filteredList.map(val => ({
    value: val.id,
    label: `@${(`${val.type}.${val.name}`).replace('custom.', '')}`,
  }));
};

export default {
  name: 'entitylist',
  watch: {
    entities(newItems) {
      if (newItems.length !== this.rowProperties.length) {
        this.rowProperties = newItems.map(() => ({
          isDescriptionEditing: false,
          isExampleEditing: false,
        }));
      }
    },
  },
  components: {
    aiqCustomCascader,
  },
  data() {
    return {
      editFlag: false,
      newEntityName: '',
      newEntitySourceId: -1,
      searchTerms: '',
      sortOrder: 'ascending',
      rowProperties: [],
    };
  },
  mounted() {
    this.rowProperties = this.$store.state.entities.entities.map(() => ({
      isDescriptionEditing: false,
      isExampleEditing: false,
    }));
  },
  computed: {
    ...mapState({
      sources: state => state.entitySources.entitySources,
      // Dropdown options
      options: state => [{
        value: 'sys',
        label: '@system',
        children: getFilteredList(state, 'sys'),
      },
      {
        value: 'customer',
        label: '@customer',
        children: getFilteredList(state, 'customer'),
      },
      {
        value: 'product',
        label: '@product',
        children: getFilteredList(state, 'product'),
      },
      {
        value: 'custom.regex',
        label: '@regex',
        children: getFilteredList(state, 'custom.regex'),
        hasInputbox: true,
        hasViewbox: true,
        viewValues: getFilteredList(state, 'custom.regex', 'validator'),
      },
      {
        value: 'custom.list',
        label: '@list',
        children: getFilteredList(state, 'custom.list'),
      }],
      selectedSources(state) {
        // id to array of path
        const list = this.entities;
        const sources = state.entitySources.entitySources;
        return list.map(entity => {
          if (!entity.entity_source_id) {
            return [];
          }

          const source = sources.find(src => src.id === entity.entity_source_id);
          if (!source) {
            return [];
          }

          return [source.type, source.id];
        });
      },
    }),
    ...mapGetters({
      configuredEntities: 'entities/configuredEntities',
    }),
    // TODO (Gabe) - do not directly mutate entities
    entities() {
      // Note: this.sortOrder is not used
      return this.configuredEntities(this.searchTerms, this.sortOrder);
    },
  },
  methods: {
    createNewEntity() {
      if (!this.entities.length
        || (this.entities.length > 0 && this.entities[0].name)) {
        this.entities.unshift({ name: '' });
        this.selectedSources.unshift([]);
        this.rowProperties.unshift({
          isDescriptionEditing: false,
          isExampleEditing: false,
        });
      }
    },
    getRowProperties(index, key) {
      if (this.rowProperties.length === 0) {
        return false;
      }
      return this.rowProperties[index][key];
    },
    setRowProperties(index, key, val) {
      if (this.rowProperties.length === 0) {
        return;
      }
      this.rowProperties[index][key] = val;
    },
    getIsDescriptionEditing(index) {
      return this.getRowProperties(index, 'isDescriptionEditing');
    },
    getIsExampleEditing(index) {
      return this.getRowProperties(index, 'isExampleEditing');
    },
    setDescriptionEditing(index, val) {
      this.setRowProperties(index, 'isDescriptionEditing', val);
    },
    setExampleEditing(index, val) {
      this.setRowProperties(index, 'isExampleEditing', val);
    },
    onSelectedEntitySource(row, selected) {
      const [type, id] = selected;
      if (this.entities[row].name) {
        if (this.entities[row].entity_source_id !== id) {
          this.entities[row].example = '';
        }
        this.entities[row].entity_source_id = id;
        this.entities[row].type = type;

        this.updateEntity(this.entities[row]);
      } else {
        this.newEntitySourceId = id;
        this.commitNewEntity();
      }
    },
    updateEntity(entity) {
      this.$store.dispatch('entities/updateEntity',
        { name: entity.name, item: entity })
        .then(() => this.$aiq.notify.success(`${entity.name} has been updated.`)).catch(() => this.$aiq.notify.error(`${entity.name} is not updated.`));
    },
    onRowUpdate(rowIndex) {
      if (this.rowProperties[rowIndex]) {
        this.rowProperties[rowIndex].isDescriptionEditing = false;
        this.rowProperties[rowIndex].isExampleEditing = false;

        this.updateEntity(this.entities[rowIndex]);
      }
    },
    commitNewEntity() {
      if (this.newEntityName && this.newEntitySourceId !== -1) {
        this.entities.shift();
        this.selectedSources.shift();
        this.rowProperties.shift();
        this.$store.dispatch('entities/createEntity', {
          name: this.newEntityName,
          entity_source_id: this.newEntitySourceId,
        }).then(() => this.$aiq.notify.success(`${this.newEntityName} has been saved.`)).catch(() => this.$aiq.notify.error(`${this.newEntityName} already exists.`));
      } else if (this.newEntityName) {
        this.$aiq.notify.info('Type of the entity required.');
      }
    },
    onRegexValueChanged({ sourceId, regex }) {
      if (sourceId && regex) {
        const entitySource = this.sources.filter(src => src.id === sourceId)[0];

        if (entitySource) {
          entitySource.validator = regex;
          this.$store.dispatch('entitySources/updateEntitysource', {
            id: sourceId,
            item: entitySource,
          }).then(() => this.$aiq.notify.success(`${entitySource.name} has been updated.`)).catch(() => this.$aiq.notify.error('Update failed.'));
        }
      }
    },
    onCreateRegexEntity(name) {
      this.$store.dispatch('entitySources/createEntitysource', {
        type: 'custom.regex',
        name,
      }).then(() => this.$aiq.notify.success(`${name} has been created.`)).catch(err => {
        switch (err.response.status) {
          case STATUS_CODES.CONFLICT:
            this.$aiq.notify.error(`${name} already exists.`);
            break;
          default:
            this.$aiq.notify.error(err.response.data || 'Creation failed.');
            break;
        }
      });
    },
  },
};