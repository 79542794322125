<template lang="pug">
managementPage.scrollable(title="Documents"
              @fetchData="loadList"
              @openEditor="openEditor"
              :searchPageSize="searchPageSize",
              :showTotals="true"
              :teamFilter="true"
              :teamOptions="teamOptions"
              :pagination="pagination"
              :creatable="canView('/settings/documents/new')"
              ref="managementPage")
  router-view(v-slot:editor)
  template(v-slot:body)
    aiq-table.manage-documents-scrollbar(:data="items" v-loading="loading")
      aiq-table-column(label="Title"
                      label-class-name="is-bordered"
                      min-width="90"
                      prop="name"
                      :sort-method="onSortTitle"
                      :sortable="true")
      aiq-table-column(label="Type"
                      label-class-name="is-bordered"
                      min-width="50"
                      prop="file.payload.mime"
                      :sortable="true")
      aiq-table-column(label="Description"
                      label-class-name="is-bordered"
                      prop="description"
                      min-width="194")
      aiq-table-column(align="center"
                      label="Link"
                      label-class-name="is-bordered"
                      width="63")
        template(v-slot:default="scope")
          a.aiq-link-preview(@click.prevent="onHandleView(scope.row)"
                             :href="$_get(scope.row, 'file.payload.url', '')"
                             target="_blank") View
      aiq-table-column(v-if="canView('/settings/documents/:number')"
                      align="center"
                      label-class-name="is-bordered"
                      width="71")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="openEditor(scope.row)") Edit
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { PAGINATION_DEFAULT, TEAM_LIMIT } from '@/constants/pagination';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';


const SEARCH_PAGE_SIZE = 25;
export default {
  mixins: [GetGlobalSource],
  components: {
    ManagementPage,
  },
  data() {
    return {
      pagination: PAGINATION_DEFAULT,
      loading: true,
      pageResource: '/settings/assets',
      assetEditForm: false,
      temporalAsset: {},
      searchPageSize: SEARCH_PAGE_SIZE,
    };
  },
  computed: {
    ...mapState({
      items: state => state.documents.documents,
      agentProfile: state => state.agent.profile,
    }),
    ...mapGetters({
      sortedTeams: 'teams/sortedTeams',
    }),
    teamOptions() {
      return this.canView('/users/filters/show_all_teams') ? this.sortedTeams : this.agentProfile.teams;
    },
  },
  created() {
    this.$_get = (object, key, defaultValue) => get(object, key, defaultValue);
  },
  async mounted() {
    await this.getTeamsList([{ limit: TEAM_LIMIT }]);
    /**
     * Using $refs to have access to the variable bound to the teams filter dropdown.
     * Default behavior is selecting all teams to which I have access to.
     */
    set(this.$refs, 'managementPage.teamOption', cloneDeep(this.teamOptions));
  },
  methods: {
    ...mapActions({
      getTeamsList: 'teams/getTeamsList',
    }),
    async loadList({ searchTerm, searchOffset, teamOption }) {
      const params = {
        limit: this.searchPageSize,
        query: searchTerm,
        offset: searchOffset,
        teams: teamOption.map(x => x.id),
      };

      const response = await this.$store.dispatch('documents/getDocumentsList', [
        params,
        searchOffset === 0,
      ]);
      this.loading = false;
      this.pagination = get(response, 'data.pagination', PAGINATION_DEFAULT);
      return response;
    },
    onSortTitle(a, b) {
      const aValue = a.name.toLowerCase().replace(/\s+/g, '');
      const bValue = b.name.toLowerCase().replace(/\s+/g, '');
      return aValue < bValue ? 1 : -1;
    },
    openEditor(record) {
      this.record = {
        ...this.$store.getters['documents/documentModel'],
        ...record,
      };

      this.$store.dispatch('documents/selectDocument', this.record)
        .then(() => {
          const recordId = this.record.id || 'new';
          const path = `${this.$route.path}/${recordId}`;

          this.$router.push({ path });
        });
    },
    async onHandleView(document) {
      const url = await this.getFileUrl(get(document, 'file.payload'));
      if (url) {
        window.open(url);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  @import "../../../styles/aiq-mixins.scss";
  :deep(.el-select__tags-text) {
    display: inline-grid;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 32px;
  }
</style>

<style lang="scss">
@import "../../../styles/aiq-mixins.scss";

.el-table--fit{
  height: calc(100% - 100px);
}

</style>

