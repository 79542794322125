<template lang="pug">
.today-container
  aiq-tabs(v-if="tabs.length"
            v-model="activeTab")
    aiq-tab-pane(v-for="tab in tabs"
                :key="tab.name"
                :label="tab.label"
                :name="tab.name"
                lazy)
      component(:is="tab.component"
                :metrics="metrics")
</template>

<script>
import { mapState } from 'vuex';
import PulsePanel from '../common/panel.vue';
import Conversations from './children/Conversations.vue';
import Agents from './children/Agents.vue';
import Availability from './children/Availability.vue';

const TABS = [
  {
    name: 'conversations',
    label: 'Conversations Metrics',
    component: 'Conversations',
  },
  {
    name: 'agents',
    label: 'Agent Metrics',
    component: 'Agents',
  },
  {
    name: 'availability',
    label: 'Availability',
    component: 'Availability',
  },
];

export default {
  name: 'PulseToday',
  extends: PulsePanel,
  components: {
    Conversations,
    Agents,
    Availability,
  },
  props: {
    action: {
      type: String,
      default: 'getTimesliceMetrics',
    },
    storeKey: {
      type: String,
      default: 'timeslice',
    },
  },
  computed: {
    ...mapState({
      showAvailabilityTab: state => state.featureFlags.FEATURE_FLAGS.PULSE_TODAY_AVAILABILITY_TAB,
    }),
    tabs() {
      return this.showAvailabilityTab ? TABS : TABS.filter(tab => tab.name !== 'availability');
    },
  },
  data() {
    return {
      activeTab: TABS[0].name,
      activeTabMetrics: null,
    };
  },
};
</script>


