const COLORS = {
  red: '#FF6384',
  bougainvillea: '#F78181',
  blood_red: '#FF0000',
  orange: '#FFBE50',
  tangerine: '#FF8000',
  yellow: '#FFEC71',
  grey: '#D7D9DE',
  blue: '#4671B3',
  green: '#4BC0C0',
  grass: '#088A08',
  bottle_green: '#254636',
  steel_blue: '#4671b3',
  steel_blue_2: '#4785b6',
  celestial_blue: '#4999ba',
  verdigris: '#4aacbd',
  medium_turquoise: '#4bc0c0',
  medium_turquoise_2: '#4bc0c6',
  lavender: '#A9A9F5',
};

export default COLORS;
