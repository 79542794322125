import SidebarAndPanesWithRouting from '@/components/layouts/SidebarAndPanesWithRouting.vue';
import Reporting from './Reporting.vue';
import Auditing from './Auditing.vue';

const rootRoute = {
  path: '/compliance',
  name: 'Compliance',
  component: SidebarAndPanesWithRouting,
  meta: {
    auth: true,
    permissioned: true,
    basePath: '/compliance',
  },
  props: {
    useLayout: true,
    baseResource: '/compliance',
  },
};

const commonChildMeta = {
  auth: true,
  permissioned: true,
  resource: rootRoute.path,
  parentName: rootRoute.name,
};


const childRoutes = [
  {
    path: 'reporting',
    name: 'Compliance.Reporting',
    component: Reporting,
    meta: {
      label: 'Reporting',
      ...commonChildMeta,
    },
  },
  ...(process.env.FEATURE_FLAGS.AUDITING_TAB ?
    [{
      path: 'auditing',
      name: 'Compliance.Auditing',
      component: Auditing,
      meta: {
        label: 'Auditing',
        ...commonChildMeta,
      },
    }] : []),
];

rootRoute.props.children = childRoutes;

export default {
  ...rootRoute,
  children: childRoutes,
};
