<script >
import { KEYBOARD_KEYS } from '@/constants';

import { h as createEl } from 'vue';

export default {
  name: 'editableInputRow',
  props: ['value', 'index'],
  emits: ['change', 'save', 'remove'],
  data() {
    return {
      isEditing: false,
      content: `${this.value}`,
    };
  },
  watch: {
    value(newValue) {
      this.content = newValue;
    },
  },
  render() {
    const deleteButton = this.createIcon(createEl, 'el-icon-close', this.onDelete);

    return (this.isEditing) ? createEl('div',
      {
        class: 'editable-edit-container',
      }, [
        createEl('input', {
          value: this.content,
          class: 'editable-input',
          onInput: (event) => {
            this.content = event.target.value;
            this.$emit('change', event.target.value);
          },
          onKeyup: (event) => {
            switch (event.key) {
              case KEYBOARD_KEYS.ESCAPE:
                this.content = this.value;
                this.isEditing = false;
                break;
              case KEYBOARD_KEYS.ENTER:
                this.onSave();
                break;
              default:
            }
          },
        }),
        deleteButton,
      ]) : createEl('div', {
      class: 'editable-edit-container',
    }, [
      createEl('span',
        {
          class: 'editable-span',
          title: this.content,
        }, this.content),
      this.createIcon(createEl, 'el-icon-edit', this.onEdit),
      deleteButton,
    ]);
  },
  methods: {
    createIcon(createEl, className, clicked) {
      return createEl(
        'i',
        {
          class: className,
          onClick: event => {
            clicked(event);
          },
        },
      );
    },

    onEdit() {
      this.isEditing = !this.isEditing;
    },

    onSave() {
      this.isEditing = !this.isEditing;
      this.$emit('save', { content: this.content, index: this.index });
    },

    onDelete(e) {
      this.$emit('remove', this.index);
      // FIXME: This needs to be handled by parent component.
      e.stopPropagation();
    },
  },
};
</script>
<style lang="scss">
.editable-view-container {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #BFCBD9;
  box-sizing: border-box;
  color: #1f2d3d;
  display: inline-block;
  font-size: inherit;
  height: 30px;
  line-height: 1;
  outline: none;
  padding: 3px 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  position: relative;
}

.editable-edit-container {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #BFCBD9;
  box-sizing: border-box;
  color: #1f2d3d;
  display: inline-block;
  font-size: inherit;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  position: relative;
}

.editable-input {
  width: 100%;
  padding-right: 35px;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.editable-span {
  display: block;
  max-width: 100%;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.editable-edit-container .el-icon-close{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #BFCBD9;
  font-size: 10px;
  cursor: pointer;
}

.editable-edit-container .el-icon-check{
  position: absolute;
  top: 10px;
  right: 30px;
  color: #BFCBD9;
  font-size: 10px;
  cursor: pointer;
}

.editable-edit-container .el-icon-edit{
  position: absolute;
  top: 10px;
  right: 30px;
  color: #BFCBD9;
  font-size: 10px;
  cursor: pointer;
}

.dialogs-buttons-list_item {
  height: 55px;
  padding: 6px 9px 6px 6px;
  margin: 0 -8px 0 -6px;
  border-radius: 4px 0 0 4px;

  &.is-active {
    box-shadow: inset 0 2px 0 0 rgba(191,203,217,0.50);
    background-color: #E0E7F5;

    > .editable-edit-container {
      border: 1px solid #5993FF;
    }
  }
}



</style>
