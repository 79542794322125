<template lang="pug">
div.drop.message-input-wrapper(v-if="showTextInput" @drop="(item) => attachItem(item)")
  .message-input-content(
    ref="input-container",
    :class="{ 'with-attachment': attachedFiles.length !== 0 }"
  )
    .message-input.scroll-small.show-on-hover(
      :id="$_IDS.CHAT_INPUT",
      :class="{ 'prevent-cursor': !messagePermission }"
      :contenteditable="messagePermission",
      @keydown.enter.stop.prevent="",
      @keydown="systemKeysListener",
      @click="onClick",
      @input="onInput",
      @focus="onFocus",
      @blur="onBlur",
      @paste="onPaste",
      :placeholder="placeholder"
    )
  .items-attached(:class="{ 'is-hidden': !hasAttachments }")
    .images-content
      .image-item.attached-file(v-for="item in attachedFiles")
        i.iq-ico-close.image-remove(@click="removeFile(item)")
        aiq-Image.image-item_img-preview(v-if="isFileImage(item)"
                                         :key="item.id"
                                         :imageKey="item.key"
                                         :src="item.url"
                                         :acl="item.acl"
                                         :bucket="item.bucket")
        aiq-document-icon(v-else
                          :mimeType="getMimeType(item)"
                          :documentExtension="getDocExt(item)"
                          :fileId="''+item.id"
                          width="64px"
                          height="auto")
        .attachement-name {{ item.name || item.filename}}
</template>

<script>
/**
   * User types in this ChatInput component and the typed text is synced with the store.
   * ChatInput's ancestors will then react to these values. Meanwhile, this ChatInput
   * component only updates its input text when
   *   1) watches store for any selected values and sets to that value
   *   2) watches store for changes in selected conversation and will clear the input text
   */

import { nextTick } from 'vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  mapState,
} from 'vuex';
import some from 'lodash/some';
import {
  getCaretPosition,
} from '@/libs';
import { KEYBOARD_KEYS, IMAGE_ACL_TYPES } from '@/constants';
import { IDS } from '@/pages/Conversations/conversationConstants';
import { AGENT_ASSET, AGENT_DOCUMENT } from '@/constants/analytics';

const imgTagRegex = /<img\s+src=\\".*"\s+.*>/i;
const suspiciousClipboardDataTypes = {
  notAllowed: 'Files',
  needsChecking: ['text/plain', 'text/html'],
};

export default {
  name: 'chatInput',
  inject: ['chatEventEmitter', 'focusOnInputbox'],
  props: {
    placeholder: {
      type: String,
    },
    isCommandsOpened: {
      type: Boolean,
      default: () => false,
    },
    selectedResultText: {
      type: String,
    },
    messagePermission: {
      type: Boolean,
      default: () => false,
    },
    showTextInput: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: [
    'resetChatInput',
    'resetSelect',
    'systemKeys',
    'updateInput',
  ],
  data() {
    return {
      systemsKeys: [
        KEYBOARD_KEYS.DOWN_ARROW,
        KEYBOARD_KEYS.TAB,
        KEYBOARD_KEYS.UP_ARROW,
      ],
      dragState: false,
      eventEmitterCallbacks: {},
      IMAGE_ACL_TYPES,
    };
  },
  computed: {
    ...mapState({
      // textInput: state => state.chat.textInput,
      selectedConversation: state => state.conversations.selected,
      isMobileContext: state => state.settings.isMobileContext,
    }),
    attachedFiles() {
      return this.$store.getters['files/attachedFiles'];
    },
    hasAttachments() {
      return !isEmpty(this.attachedFiles);
    },
    showEmoji() {
      return process.env.SHOW_EMOJI && this.canView('/conversations/inputbox/message');
    },
  },
  watch: {
    attachedFiles() {
      this.scrollLastImageIntoView();
      this.focusOnInputbox();
    },
  },
  created() {
    this.$_get = (object, key, defaultValue) => get(object, key, defaultValue);
    this.$_IDS = IDS;
  },
  mounted() {
    this.eventEmitterCallbacks = {
      clearInput: this.clearInput.bind(this),
      clearFiles: this.clearFiles.bind(this),
      setText: this.setText.bind(this),
    };
    this.chatEventEmitter.on('clearInput', this.eventEmitterCallbacks.clearInput);
    this.chatEventEmitter.on('clearFiles', this.eventEmitterCallbacks.clearFiles);
    this.chatEventEmitter.on('setText', this.eventEmitterCallbacks.setText);
    this.chatEventEmitter.on('addEmoji', (emoji) => {
      this.addEmoji(emoji);
    });
  },
  beforeUnmount() {
    this.chatEventEmitter.off('clearInput', this.eventEmitterCallbacks.clearInput);
    this.chatEventEmitter.off('clearFiles', this.eventEmitterCallbacks.clearFiles);
    this.chatEventEmitter.off('setText', this.eventEmitterCallbacks.setText);

    this.$emit('resetChatInput');
  },
  methods: {
    // Stops the user from pasting images directly into the chat input
    onPaste(event) {
      let containsImgTag = false;

      if (event.clipboardData.types.includes(suspiciousClipboardDataTypes.notAllowed)) {
        containsImgTag = true;
      } else {
        containsImgTag = some(suspiciousClipboardDataTypes.needsChecking, (type) => {
          const pastedHtml = event.clipboardData.getData(type);
          return imgTagRegex.test(pastedHtml);
        });
      }

      if (containsImgTag) {
        event.preventDefault();
        this.$aiq.notify.error(this.$t('notification.pasting_images_not_allowed.description'));
      }
    },
    safeGetImage(image, type) {
      if (type === 'asset') {
        return get(image, 'file.payload.thumbnail.url', '');
      } if (type === 'file') {
        return get(image, 'thumbnail.url', '');
      }

      return '';
    },
    attachItem(item) {
      const commonData = {
        conversation_id: this.selectedConversation.id,
      };
      let payload;

      switch (item.type) {
        case 'file':
          payload = {
            id: item.item.file_id,
            title: item.item.name,
          };
          this.$store.dispatch('files/attachFile', { file: item.item, source: 'chatInput' });
          break;
        default:
      }

      this.$store.dispatch('notifications/emitEventToSocket', {
        name: (item.type === 'asset') ? AGENT_ASSET.CLICK : AGENT_DOCUMENT.CLICK,
        payload: {
          ...commonData,
          payload,
        },
      });
    },


    removeAllFiles() {
      if (!isEmpty(this.attachedFiles)) {
        [...this.attachedFiles].forEach(this.removeFile, this);
      }
    },

    removeFile(file) {
      this.$store.dispatch('files/removeAttachedFile', file);
    },

    onClick(event) {
      this.$emit('resetSelect');
      const textInput = event.target.innerHTML;
      const caretPosition = getCaretPosition(event.target);
      this.updateChat({ textInput, caretPosition });
    },

    onInput(event) {
      const textInput = event.target.innerHTML;
      const caretPosition = getCaretPosition(event.target);

      this.updateChat({ textInput, caretPosition });
      this.chatEventEmitter.emit('typing', textInput);
    },

    onFocus() {
      this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`).classList.add('is-focused');
    },

    onBlur() {
      this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`).classList.remove('is-focused');
    },

    addEmoji(e) {
      const text = `${this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`).innerHTML}${e.native}`;
      this.setText(text);
    },

    clearInput() {
      const inputElem = this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`);
      inputElem && (inputElem.innerHTML = '');

      this.clearFiles();

      this.updateChat({
        textInput: '',
        caretPosition: -1,
      });
    },

    clearFiles() {
      this.removeAllFiles();
    },

    scrollLastImageIntoView() {
      nextTick(() => {
        const lastImage = this.$el.querySelector('.image-item:last-of-type');

        if (lastImage) {
          lastImage.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },

    setText(text) {
      setTimeout(() => {
        const inputElem = this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`);
        inputElem.innerHTML = text;
        this.focusOnInputbox();
        const caretPosition = getCaretPosition(inputElem);
        this.updateChat({ textInput: text, caretPosition });
      }, 1);
    },

    systemKeysListener(event) {
      const inputElem = this.$el.querySelector(`#${this.$_IDS.CHAT_INPUT}`);
      const textInput = inputElem.innerHTML;
      let caretPosition = getCaretPosition(inputElem);
      const { key } = event;


      switch (key) {
        case KEYBOARD_KEYS.ENTER:
          if (!event.shiftKey) {
            this.$emit('systemKeys', event);
          }
          break;
        case KEYBOARD_KEYS.LEFT_ARROW:
          caretPosition = caretPosition === 0 ? 0 : caretPosition - 1;
          this.$emit('resetSelect');
          break;
        case KEYBOARD_KEYS.BACKSPACE:
          caretPosition = caretPosition === 0 ? 0 : caretPosition - 1;
          this.$emit('resetSelect');
          break;
        case KEYBOARD_KEYS.RIGHT_ARROW: {
          const len = textInput.length;
          caretPosition = caretPosition === len ? len : caretPosition + 1;

          this.$emit('resetSelect');
          break;
        }
        case KEYBOARD_KEYS.ESCAPE:
          this.clearInput();
          break;
        default:
          if (this.systemsKeys.includes(key)) {
            event.preventDefault();
            this.$emit('systemKeys', event);
            event.stopPropagation();
          }
      }

      this.updateChat({ textInput, caretPosition });
    },
    updateChat(payload) {
      this.$emit('updateInput', payload);
    },
    getDocExt(file) {
      return get(file, 'filename', '')
        .split('.')
        .pop();
    },
    getMimeType(file) {
      return get(file, 'mime', '');
    },
    isFileImage(file) {
      return this.getMimeType(file).includes('image');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../../styles/aiq-variables.scss";
@import "./../../../../../../styles/media-queries.scss";
@import "./../../../../../../styles/attach-files.scss";

.message-input-wrapper {
  width: 100%;
  @include mobile {
    width: 100%;
  }
  &.dragged-over {
    background: rgba(#000, 0.3);
  }
}

.items-attached {
  background-color: #fff;
  border: none;
  border-style: none;
}

.message-input-content {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  /*width: calc(100% - 110px);*/
  min-height: 36px;
  max-height: 275px;
  border: 1px solid $aiq-bdc-message-input;
  background-color: #fff;
  transition: 0.3s;
  border-bottom: none;
  border-left: none;
  border-right: none;

  &.is-focused {
    background-color: #fff;
  }
  &.with-attachment {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 0;
  }
}
.message-input {
  width: 100%;
  padding: 10px 30px 10px 10px;
  color: $aiq-text-message-input;
  font-size: 14px;
  line-height: 17px;
  font-weight: 100;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  outline: none;
  cursor: text;

  &:empty {
    &:before {
      display: block;
      content: attr(placeholder);
      color: $aiq-text-message-input-placeholder;
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.image-item.attached-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .attachement-name {
    font-size: 12px;
    font-weight: 300;
  }
}

.button-emoji {
  position: absolute;
  top: 25px;
  right: 10px;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  margin: 0;
  line-height: 1px;
  border: 0;
  background: transparent;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    background-color: transparent;

    [class^="iq-ico"] {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='xMidYMid' width='20' height='20' viewBox='0 0 18 18'%3E%3Cdefs%3E%3Cstyle%3E.cls-1 %7B fill: %23afafaf; fill-rule: evenodd; %7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M15.36 15.36C17.12 13.607 18 11.487 18 9 18 6.513 17.12 4.39 15.36 2.63 13.607.877 11.487 0 9 0 6.513 0 4.39.877 2.63 2.63.877 4.39 0 6.513 0 9 0 11.487.877 13.607 2.63 15.36 4.39 17.12 6.513 18 9 18 11.487 18 13.607 17.12 15.36 15.36M9 1.52C11.067 1.52 12.83 2.25 14.29 3.71 15.75 5.17 16.48 6.933 16.48 9 16.48 11.067 15.75 12.83 14.29 14.29 12.83 15.757 11.067 16.49 9 16.49 6.933 16.49 5.167 15.757 3.7 14.29 2.247 12.83 1.52 11.067 1.52 9 1.52 6.933 2.247 5.17 3.7 3.71 5.167 2.25 6.933 1.52 9 1.52M4.51 10.41C4.397 10.523 4.34 10.667 4.34 10.84 4.347 12.26 4.787 13.337 5.66 14.07L5.67 14.08C6.457 14.767 7.587 15.11 9.06 15.11 10.467 15.11 11.57 14.757 12.37 14.05 13.25 13.297 13.693 12.227 13.7 10.84 13.7 10.667 13.64 10.523 13.52 10.41 13.407 10.29 13.267 10.23 13.1 10.23L4.93 10.23C4.77 10.23 4.63 10.29 4.51 10.41M4.12 7.14C4.02 7.26 3.98 7.403 4 7.57 4.013 7.73 4.087 7.863 4.22 7.97 4.34 8.07 4.48 8.113 4.64 8.1L6.16 7.94C6.32 7.92 6.45 7.847 6.55 7.72 6.657 7.593 6.703 7.447 6.69 7.28 6.67 7.12 6.597 6.987 6.47 6.88 6.343 6.78 6.197 6.74 6.03 6.76L4.52 6.93C4.36 6.943 4.227 7.013 4.12 7.14M13.65 8.28C13.897 8.053 14.02 7.77 14.02 7.43 14.02 7.077 13.897 6.783 13.65 6.55 13.423 6.317 13.14 6.2 12.8 6.2 12.46 6.2 12.167 6.317 11.92 6.55 11.687 6.783 11.57 7.077 11.57 7.43 11.57 7.77 11.687 8.053 11.92 8.28 12.167 8.527 12.46 8.65 12.8 8.65 13.14 8.65 13.423 8.527 13.65 8.28Z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
}

.prevent-cursor {
  cursor: not-allowed;
}
</style>
