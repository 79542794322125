<template lang="pug">
  modal-editor.edit-agent-modal(ref="modalEditor"
                                :visible="visible"
                                :show-close="false"
                                recordType="Agent"
                                recordTypeName="Account"
                                :record="record"
                                :preparePayload="preparePayload"
                                :resource="resource"
                                :buttons="buttons"
                                :afterSave="afterSave"
                                :afterClose="afterClose"
                                :isRecordValid="isSubmittable"
                                :isProfileValid="isProfileTabValid"
                                :showSaveButton="showSaveButton"
                                :showNextButton="showNextButton"
                                :disabled="disabled"
                                :cancelButtonText="cancelButtonText"
                                :canDeleteItem="canRevokeRecord"
                                :skipConfirmation="!isRecordDirty"
                                @next="onNextPage")
    template(v-slot:content)
      .account-container
        aiq-tabs(v-model="selectedTab")
          aiq-tab-pane(v-for="form in childForms" :key="form.name" :label="form.label" :name="form.name")
            component(:is="form.name"
                      :record="record"
                      :permissions="permissions"
                      :disabled="disabled")
    template(v-if="canRevokeOrRestore" v-slot:delete-button)
      aiq-button(v-if="canRestoreRecord"
                type="danger"
                :plain="true"
                :disabled="isBusy"
                @click="restore") Restore
      aiq-dropdown(v-if="!canRestoreRecord && canRevokeRecord"
                  ref="dropdown"
                  trigger="click",
                  @command="revoke")
        aiq-button.button-delete(:plain="true", type="danger") Revoke
        template(v-slot:dropdown)
          aiq-dropdown-menu
            aiq-dropdown-item(:disabled="true")
              b Actions
            aiq-dropdown-item(v-for="action in ['Deactivate', 'Delete']", :key="action", :command="action") {{ action }}
</template>

<script>
import get from 'lodash/get';
import pick from 'lodash/pick';
import { mapState } from 'vuex';
import Promise from 'bluebird';
import * as log from 'loglevel';
import { useVuelidate } from '@vuelidate/core';
import { extractErrorMessage, getAttachAgentActions, getDetachAgentActions } from '@/libs';
import Editor from '@/components/Editor/Editor.vue';
import ModalEditor from '@/components/ModalEditor/ModalEditor.vue';
import membershipForm from '@/components/ProfileInfo/Membership/Membership.vue';
import statusForm from '@/components/ProfileInfo/Status/Status.vue';
import profileForm from '@/components/ProfileInfo/Profile/Profile.vue';
import { TEAM_LIMIT } from '@/constants';
import { BOT_EMAIL } from '@/constants/agent';

const TAG = 'ProfileEditor';

export default {
  name: 'agentEditor',
  extends: Editor,
  components: {
    ModalEditor,
    profileForm,
    membershipForm,
    statusForm,
  },
  setup() {
    // Validation errors coming from profileForm, membershipForm, or statusForm
    // gets recorded in this parent's this.v$.errors
    // Take a look at https://vuelidate-next.netlify.app/advanced_usage.html#nested-validations
    return { v$: useVuelidate() };
  },
  props: {
    afterClose: {
      type: Function,
      default: () => {},
    },
    resource: {
      type: String,
    },
    buttons: {
      type: Array,
      default: ['close'],
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      permissions: state => state.agent.permissions,
    }),
    isProfileTabValid() {
      return !!(get(this.record, 'profile.first_name') && get(this.record, 'profile.last_name'));
    },
    showNextButton() {
      if (!this.childForms.length) {
        return;
      }
      // show only new profile creation and at the last tab.
      return !this.record.id && this.selectedTab !== this.childForms[this.childForms.length - 1].name;
    },
    canRevokeOrRestore() {
      // if button delete is include means we're from settings -> agents -> manage component
      return this.buttons.includes('delete');
    },
    canRevokeRecord() {
      return this.record.id && this.record.email !== BOT_EMAIL;
    },
    canRestoreRecord() {
      return !!this.record.deleted_at;
    },
  },
  data() {
    return {
      selectedTab: null,
      isLoading: false,
      modelPath: 'agents/agentModel',
      selectAction: 'agents/selectAgent',
      fetchAction: 'agents/getAgents',
      childForms: [],
    };
  },
  mounted() {
    // Note: DO NOT ADD functions.
    // Use resolveRecord as it is called in mounted() of Parent component.
  },
  methods: {
    // override resolveRecord hook from Editor.vue to support loading from header > My Account link
    async resolveRecord() {
      const childForms = [
        { name: 'profileForm', label: 'Profile' },
        ...(this.canEdit('/global/profile/membership') ? [{ name: 'membershipForm', label: 'Membership' }] : []),
        { name: 'statusForm', label: 'Preferences' },
      ];
      this.childForms = childForms;
      this.selectedTab = childForms[0].name;

      const teams = (await this.$store.dispatch('teams/getTeamsList', [{ limit: TEAM_LIMIT }])).data.models;

      const selectedAgent = this.$store.state.agents.selected;
      if (selectedAgent.id) {
        return Promise.resolve(selectedAgent);
      }

      const { id } = this.$route.params;
      if (id === 'new') {
        // Note: Here it uses nextTick() due to this.record should be initially empty
        //       as the parent component initialize with the object only the first time.
        //       Refer to 'watch' of ModalEditor.vue
        this.$nextTick(async() => {
          const defaultAssignments = await this.$store.dispatch('settings/getSetting', { name: 'default_agent_assignments' });

          // team is integer array. Here it converts the array to object array
          // for profile editor to understand
          defaultAssignments.teams =
            teams.filter(team => defaultAssignments.teams.includes(team.id));

          // use only teams and roles for default assignment.
          // default channel assignment is deprecated.
          this.record = { ...this.record, ...pick(defaultAssignments, ['teams', 'roles']) };
        });
        return this.record;
      }
      return (await this.$store.dispatch(this.fetchAction, [id])).data;
    },
    preparePayload(record) {
      const r = {
        ...record,
      };
      delete r.channels;
      delete r.roles;
      delete r.teams;
      delete r.conversations;

      return r;
    },
    async afterSave(savedRecord, originalRecord) {
      log.debug(TAG, 'afterSave', 'savedRecord', originalRecord);
      // compare editing record to original record
      const actions = [
        ...getDetachAgentActions(this.record, originalRecord),
        ...getAttachAgentActions(this.record, originalRecord),
      ];
      log.debug(TAG, 'calling dependent actions', JSON.stringify(actions));
      const actionErrorList = [];

      await this.$store.dispatch('agent/updateInactivityTimeout', {
        id: savedRecord.id,
        enabled: get(savedRecord, 'inactivity.enabled', false),
        duration: get(savedRecord, 'inactivity.duration', 0),
      });

      // refresh selected agent in state
      if (savedRecord.id === this.$store.state.agent.profile.id) {
        actions.push({
          action: 'agent/setProfile',
          data: 'profile',
          dataset: savedRecord,
        });
      }

      Promise.map(actions, action => {
        const params = action.data === 'profile' ? action.dataset : {
          type: action.type,
          roles: action.data,
          agent: savedRecord,
        };
        return this.$store.dispatch(action.action, params)
          .catch(() => {
            actionErrorList.push(action.data);
          });
      }, { concurrency: 1 }).then(() => {
        if (actionErrorList.length) {
          const msg = this.record.id ? 'create' : 'update ';
          this.$aiq.notify.error(`Failed to ${msg} ${actionErrorList.join(', ')}.`);
        }
      }).finally(() => {
        this.isLoading = false;
        this.$store.dispatch('agent/refreshAgentData');
      });

      return Promise.resolve(savedRecord);
    },
    onNextPage() {
      const nextIndex = this.childForms.findIndex((form) => form.name === this.selectedTab) + 1;
      this.selectedTab = this.childForms[nextIndex % this.childForms.length].name;
    },
    revoke(action) {
      this[action.toLowerCase()]();
    },
    deactivate() {
      const actions = [
        '- Removes them from the carousel',
        '- Removes them from queue assignment',
        '- Detaches them from locked conversations',
        '- Detaches them from assignments',
        '- Immediately removes their access',
        '<br/>Do you want to proceed?',
      ];
      this.$aiq.confirm('Deactivating an agent will immediately:', actions.join('<br/>'), {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then(() => {
        const { modalEditor } = this.$refs;
        modalEditor.isBusy = true;
        const action = modalEditor.buildAction(this.record, 'Agent', 'deactivate');
        return this.$store.dispatch(action, this.record.id)
          .then(() => {
            this.$aiq.notify.success('Agent has been deactivated.');
            modalEditor.closeEditor();
          })
          .catch(err => {
            this.$aiq.notify.error(extractErrorMessage(err) || 'Deactivate failed.');
          })
          .finally(() => {
            modalEditor.isBusy = false;
          });
      });
    },
    delete() {
      const actions = [
        '- Removes their teams',
        '- Removes them from the carousel',
        '- Removes them from queue assignment',
        '- Detaches them from locked conversations',
        '- Detaches them from assignments and removes their search ability',
        '- Immediately removes their access',
        '- Removes them from the Agents page',
        '- Removes them from the Settings > Agents table',
        '<br/>Do you want to proceed? <b>Deletion is permanent</b>.',
      ];
      this.$aiq.confirm('Deleting an agent will immediately:', actions.join('<br/>'), {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      }).then(() => {
        const { modalEditor } = this.$refs;
        modalEditor.isBusy = true;
        const action = modalEditor.buildAction(this.record, 'Agent', 'delete');
        return this.$store.dispatch(action, this.record.id)
          .then(() => {
            this.$aiq.notify.success('Agent has been deleted.');
            modalEditor.closeEditor();
          })
          .catch(err => {
            this.$aiq.notify.error(extractErrorMessage(err) || 'Delete failed.');
          })
          .finally(() => {
            modalEditor.isBusy = false;
          });
      });
    },
    restore() {
      this.$aiq.confirm(
        'Restore?',
        'Are you sure you want to restore this Agent?',
      ).then(
        () => {
          const { modalEditor } = this.$refs;
          modalEditor.isBusy = true;
          const action = modalEditor.buildAction(this.record, 'Agent', 'restore');
          return this.$store.dispatch(action, this.record.id)
            .then(() => {
              this.$aiq.notify.success('Agent has been restored.');
              modalEditor.closeEditor();
            })
            .catch(err => {
              this.$aiq.notify.error(extractErrorMessage(err) || 'Restore failed.');
            })
            .finally(() => {
              modalEditor.isBusy = false;
            });
        },
      );
    },
  },
};
</script>

<style lang="scss">
@import "./../../../../../styles/media-queries.scss";
  .el-dialog.modal-editor {
    width: 680px;
      @include mobile {
        width: 90%;
    }
  }
  .button-delete {
    margin-right: 10px;
  }
</style>
