<template lang="pug" id="timeline">
extends ./Base.pug
</template>

<script>
import * as d3 from 'd3';
import { timelines } from 'd3-timelines';
import BaseChart from './Base.vue';
import isEmpty from 'lodash/isEmpty';

export default {
  extends: BaseChart,
  name: 'aiqTimelineChart',
  computed: {
    colorScale() {
      const { domain, range } = this.graphParams;
      return d3.scaleOrdinal(domain, range);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.renderChart);
    });
  },
  beforeUnmount() {
    this.$nextTick(() => {
      window.removeEventListener('resize', this.renderChart);
    });
  },
  methods: {
    getChartType() {
      return 'timelineChart';
    },
    removeChart() {
      this.DestroyChart();
      d3
        .select(this.chartContainerSelector())
        .selectAll('svg')
        .remove();
    },
    renderChart() {
      this.removeChart();
      if (!isEmpty(this.report)) {
        const { colorProperty, timeRange: { beginning, ending }, layout: { row, axis, chartMargins } } = this.graphParams;
        const { top, bottom, left, right } = chartMargins;
        const numberOfRows = this.report.length;

        // Set the tooltip
        const tooltip = d3.select(this.chartContainerSelector())
          .append('div')
          .attr('class', 'timeline-tooltip')
          .style('visibility', 'hidden');
        tooltip
          .append('div')
          .attr('class', 'tooltip-header');
        tooltip
          .append('div')
          .attr('class', 'tooltip-body');
        tooltip
          .append('div')
          .attr('class', 'tooltip-footer');

        // Set the chart
        const chart = timelines()
          .stack()
          .colors(this.colorScale)
          .colorProperty(colorProperty)
          .margin({ left, right, top, bottom })
          .beginning(beginning)
          .ending(ending)
          .showTimeAxisTick()
          .tickFormat({
            format: function(d) { return d3.timeFormat('%I %p')(d) },
            tickTime: d3.timeHour,
            tickInterval: 1,
            tickSize: 6,
          })
          .mouseover((event, _, datum) => {
            // tooltip location
            tooltip
              .style('left', `${event.pageX}px`)
              .style('top', `${event.pageY}px`);
            // tooltip header
            tooltip
              .select('.tooltip-header')
              .text(datum.label);
            // tooltip body
            const tooltipBody = tooltip.select('.tooltip-body');
            for (const item of datum.tooltipData.items) {
              const row = tooltipBody
                .append('div')
                .attr('class', 'row');
              row
                .append('div')
                .text(item.color);
              row
                .append('div')
                .text(item.status);
              row
                .append('div')
                .text(item.duration);
            }

            tooltip
              .style('visibility', 'visible');
          })
          .mouseout(() => {
            tooltip
              .style('visibility', 'hidden');
          });

        // Set the SVG
        const chartHeight = top + bottom + numberOfRows * (row.height + row.margin) + axis.height;
        d3
          .select(this.chartContainerSelector())
          .append('svg')
          .attr('width', '100%')
          .attr('height', chartHeight)
          .datum(this.report)
          .call(chart);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.chart-container) {
  height: unset;

  svg {
    background: #fff;
    padding-left: 5px;
  }

  .timeline-tooltip {
    position: fixed;
    border: 1px solid #A8A9AB;
    border-radius: 4px;
    background-color: #fff;
    color: #000;
    padding: 8px;

    .tooltip-header {
      text-align: center;
    }

    .tooltip-body {
      font-size: 12px;

      .row {
        display: flex;
        width: 100%;

        div:nth-child(1) {
          flex: 10% 10% 10%;
        }

        div:nth-child(2) {
          flex: 70% 70% 70%;
        }

        div:nth-child(3) {
           flex: 20% 20% 20%;
        }
      }
    }

    .tooltip-footer {
      font-size: 12px;
    }

  }
}
</style>