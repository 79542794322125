<template lang="pug">
  .segment-card
    .left
      segment-select(:customer-segment-filter-options="allSegments"
                    :segment-alias="segmentAlias"
                    :inital-selection="selectedOption"
                    :containerStyle="{ display: 'block' }"
                    tooltipPlacement="bottom"
                    @change="handleSelectChange"
                    @on-segment-hover="onSegmentHover")
      .metrics(v-if="selectedOption")
        aiq-tooltip(v-for="metric in cardData.segmentMetrics"
                   :key="metric.label"
                   placement="top",
                   :show-after="300"
                   :disabled="!metric.tooltipContent.length")
          template(v-slot:content)
            div(v-for="tooltipItem in metric.tooltipContent") {{ `${tooltipItem.label}: ${tooltipItem.value}` }}
          .metric-item-row
            router-link(v-if="metric.goToConversationParams"
                      :to="{ name: 'ConversationByCustomerId', query: { ...metric.goToConversationParams }}"
                      class="metric-item")
              span {{ metric.label }}
              span {{ metric.value }}
            .metric-item(v-else)
              span {{ metric.label }}
              span {{ metric.value }}
    .right
      aiq-button.remove-btn(v-if="isRemovable" link type="primary" @click="handleRemove")
        fa-icon(:icon="['fas', 'circle-xmark']")

</template>

<script>
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import get from 'lodash/get';

export default {
  name: 'SegmentCard',
  components: {
    SegmentSelect,
  },
  props: {
    idx: {
      type: Number,
      required: true,
    },
    isRemovable: {
      type: Boolean,
      default: true,
    },
    cardData: {
      type: Object,
      default: () => {},
    },
    allSegments: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['remove-segment-card', 'change-segment'],
  data() {
    return {
      selectedOption: this.cardData.segmentName,
      segmentAlias: '',
    };
  },
  methods: {
    handleRemove() {
      this.$emit('remove-segment-card', this.idx);
    },
    handleSelectChange(newOption) {
      this.selectedOption = newOption.name;
      this.$emit('change-segment', this.idx, newOption.name);
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-card {
  flex-grow: 1;
  display: flex;

  &:nth-child(3n+1) {
    .left {
      .metrics {
        background: #BDCDE5;

        a.metric-item:hover {
          background: #91ABD3;
        }
      }
    }
  }

  &:nth-child(3n+2) {
    .left {
      .metrics {
        background: #B6DCC1;

        a.metric-item:hover {
          background: #86C498;
        }
      }
    }
  }

  &:nth-child(3n+3) {
    .left {
      .metrics {
        background: #F6F6F6;

        a.metric-item:hover {
          background: #DDD;
        }
      }
    }
  }

  .left {
    flex-grow: 1;

    .selector {
      :deep(.el-input__inner) {
          border: none;
      }
    }

    .metrics {
      margin-top: 10px;
      border-radius: 5px;

      .metric-item-row {
        .metric-item {
          display: flex;
          justify-content: space-between;
          line-height: 25px;
          padding: 5px 15px;

          :first-child {
            color: #000;
          }

          :last-child {
            color: #152E53;
          }
        }

        &:last-child :hover {
          border-radius: 0 0 5px 5px;
        }

        &:not(:first-child):not(:last-child) {
          .metric-item {
            padding-left: 30px;
          }
        }
      }
    }
  }

  .right {
    margin-left: 5px;
    margin-right: 10px;

    .remove-btn {
      padding: 5px 0px;
      color:#6B6D70;
    }
  }


}

</style>
