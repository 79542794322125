<template lang="pug">
  .webchat-config
    .form-container
      .sub-header--solid Webchat Language Settings
      .form__inner-content
        .settings-container
          .settings__item
            .field-label Enable Multiple Language Support
            aiq-switch(v-model="localWebchatLanguageSettings.enable_multiple_language_support",
                        @change="onEnableMutipleLanguageChange")
            .field-label.ml-80 Enabled Languages
            aiq-select.languages(v-model="localWebchatLanguageSettings.enabled_languages"
                      :filterable="true"
                      placeholder="Languages"
                      value-key="code"
                      size="small"
                      :suffix-icon="Icon.CaretBottom"
                      :disabled="!localWebchatLanguageSettings.enable_multiple_language_support"
                      @change="onEnabledLanguagesChange"
                      @remove-tag="onRemoveLanguage"
                      multiple
                      :reserve-keyword="false")
                aiq-option(v-for="language in availableLanguages",
                            :id="language.code"
                            :key="language.code",
                            :label="language.name",
                            :value="language.code",
                            :disabled="language.disabled")
        .d-flex
          .controls
            .settings-container(v-if="localWebchatLanguageSettings.enable_multiple_language_support")
              .settings__item
                aiq-select(v-model="streamLanguage"
                          placeholder="Language"
                          value-key="code"
                          size="small"
                          :suffix-icon="Icon.CaretBottom"
                          :clear-icon="Icon.CircleCloseFilled"
                          clearable)
                    aiq-option(v-for="language in enableLanguages",
                                :key="language.code",
                                :label="language.name",
                                :value="language.code")
                .upload-button
                  aiq-upload.no-margin(ref="upload"
                                      accept=".csv"
                                      :action="'placeholder-url'"
                                      :auto-upload="false"
                                      :on-change="uploadLanguageStrings"
                                      :show-file-list="false")
                    aiq-button(:disabled="!streamLanguage || inUploadingProgress" size="small") Upload
                aiq-button(:disabled="!streamLanguage || inUploadingProgress"
                          size="small"
                          type="primary"
                          @click="downloadLanguageStringsCsv") Download

            .settings-container(v-if="localWebchatLanguageSettings.enable_multiple_language_support")
              .settings__item
                .field-label Select Language to View
                aiq-select(v-model="localWebchatLanguageSettings.selected_language_view"
                          :filterable="true"
                          placeholder="Language to View"
                          value-key="code"
                          size="small"
                          :suffix-icon="Icon.CaretBottom"
                          @change="updateWebchatLanguageSettings")
                    aiq-option(v-for="language in enableLanguages",
                                :key="language.code",
                                :label="language.name",
                                :value="language.code")
          .alert
              aiq-alert(v-if="summary.failure.length"
                title="All translations were uploaded except for"
                type="warning")
                div.fixed-body
                  ul
                    li(v-for="record in summary.failure") → {{ record.Key }}

</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { mapMutations } from 'vuex';
import { AVAILABLE_LANGUAGES, DEFAULT_SYSTEM_LANGUAGE } from '@/constants/settings';

export default {
  name: 'language-settings',
  props: {
    webchatLanguageSettings: {
      type: Object,
    },
  },
  data() {
    return {
      streamLanguage: null,
      localWebchatLanguageSettings: cloneDeep(this.webchatLanguageSettings),
      availableLanguages: cloneDeep(AVAILABLE_LANGUAGES),
      inUploadingProgress: false,
      summary: { failure: [] },
    };
  },
  emits: ['save'],
  watch: {
    webchatLanguageSettings: {
      immediate: true,
      handler(newVal) {
        this.summary = { failure: [] };
        this.localWebchatLanguageSettings = cloneDeep(newVal);
      },
    },
  },
  computed: {
    enableLanguages() {
      const languages = this.localWebchatLanguageSettings.enabled_languages;
      return this.availableLanguages.filter(x => languages.includes(x.code));
    },
  },
  methods: {
    ...mapMutations({
      setWebchatLanguageString: 'configs/SET_WEBCHAT_LANGUAGE_STRINGS',
    }),
    updateWebchatLanguageSettings: debounce(function updateLanguageSettings() {
      this.$emit('save', this.localWebchatLanguageSettings);
    }, 1000), // eslint-disable-line no-magic-numbers
    onEnableMutipleLanguageChange(enabled) {
      if (!enabled) {
        this.localWebchatLanguageSettings.enabled_languages = [DEFAULT_SYSTEM_LANGUAGE.code];
        this.localWebchatLanguageSettings.selected_language_view = DEFAULT_SYSTEM_LANGUAGE.code;
      }
      this.updateWebchatLanguageSettings();
    },
    onEnabledLanguagesChange(languages) {
      // we need to ensure DEFAULT_SYSTEM_LANGUAGE is always selected
      if (languages.some(code => code === DEFAULT_SYSTEM_LANGUAGE.code)) {
        this.updateWebchatLanguageSettings();
      } else {
        this.localWebchatLanguageSettings.enabled_languages.unshift(DEFAULT_SYSTEM_LANGUAGE.code);
        this.$aiq.notify.error('You cannot remove default system language.');
      }
    },
    onRemoveLanguage(code) {
      // If the DEFAULT_SYSTEM_LANGUAGE attempt to be removed, do nothing because is not possible
      if (code === DEFAULT_SYSTEM_LANGUAGE.code) {
        return;
      }

      // Clean upload/download selected language
      this.streamLanguage = this.streamLanguage === code ? null : this.streamLanguage;

      // Switch selected language to view
      if (this.localWebchatLanguageSettings.selected_language_view === code) {
        this.localWebchatLanguageSettings.selected_language_view = DEFAULT_SYSTEM_LANGUAGE.code;
        this.updateWebchatLanguageSettings();
      }
    },
    async uploadLanguageStrings(data) {
      const fileNameToUpload = get(data, 'name', '');
      const file = data.raw;
      this.inUploadingProgress = true;
      this.summary = { failure: [] };
      if (file) {
        try {
          const payload = { data: file, language: this.streamLanguage };
          const { result, summary } = await this.$store.dispatch('configs/updateLanguageStrings', payload);
          if (this.streamLanguage === this.localWebchatLanguageSettings.selected_language_view) {
            this.setWebchatLanguageString(result);
          }
          this.summary = summary;
          this.$aiq.notify.success(`${fileNameToUpload} has been ingested.`);
        } catch (err) {
          this.$aiq.notify.error('Unable to upload the file.');
        }
      }
      this.inUploadingProgress = false;
    },
    async downloadLanguageStringsCsv() {
      const payload = { language: this.streamLanguage };
      const response = await this.$store.dispatch('configs/getLanguageStringsCsv', payload);
      const type = response.headers.get('Content-Type') || '';
      const anchor = document.createElement('a');
      anchor.href = URL.createObjectURL(new Blob([response.data], { type }));
      anchor.target = '_blank';
      anchor.download = `Language ${this.streamLanguage}`;
      anchor.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";
.el-select-dropdown
.el-select-dropdown__wrap
.el-select-dropdown__list
.selected:hover {
  color: white;
}

.form-container {
  flex: 1;
  margin-bottom: 20px;

  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .no-margin {
      padding-right: 10px;
      margin: 0px;
    }

    .settings-container {
      display: flex;

      .settings__item {
        align-self: flex-start;
        display: inline-flex;
        align-items: center;
        flex-grow: 1;
        min-width: 589px;
      }
    }


    .field-label {
      color: #475669;
      margin-right: 10px;
      letter-spacing: 0;
      font-family: $aiq-font-family;
      font-size: 14px;
      font-weight: 300;
    }

    .header-config-container {
      width: 482px;
    }
  }
  .upload-button {
    margin-left: 8px !important;
  }
  .el-select {
    flex: 0.3;
    &.languages {
      flex: unset;
      width: 400px;
    }
  }
  .ml-80 {
    margin-left: 80px !important;
  }
  .d-flex {
    display: flex;
    .alert {
      flex-grow: 1;
      .fixed-body {
          display: flex;
          overflow-y: scroll;
          height: 112px;
          ul {
            columns: 2;
          }
        }
      }
  }

}
</style>
