<template lang="pug">
    .rich-text
      .editor
        .select-size
            aiq-select(v-model="selectSize"
                                :filterable="false"
                                value-key="id"
                                popper-class="agent-status-dropdown"
                                :teleported="false"
                                size="small"
                                )
                aiq-option(v-for="item in sizeOptions",
                                    :key="item.id",
                                    :label="item.label",
                                    :value="item"
                                    :style="item.size ? getOptionStyle(item.size): ''"
                                    @click="fixSize(item)"
                                    )
        .separator
        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.bold')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'bold']",
                @click="editor.chain().focus().toggleBold().run()")
        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.italic')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'italic']"
                @click="editor.chain().focus().toggleItalic().run()")
        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.underline')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'underline']"
                @click="editor.chain().focus().toggleUnderline().run()")

        .separator

        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.ol')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'list-ol']"
                @click="editor.chain().focus().toggleOrderedList().run()")

        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.ul')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'list']"
                @click="editor.chain().focus().toggleBulletList().run()")

        .separator
        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.insert_link')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'paperclip']" @click="showLinkDialog()")
        aiq-dialog(:title="$t('rich_text_editor.editor.insert_link')"
                   :model-value="showAddLink"
                   :close-on-click-modal="true"
                   :before-close="handleBeforeClose"
                   :close-on-press-escape="true"
                   :show-close="true")
          .dialog-content {{$t('rich_text_editor.editor.insert_link')}}
          aiq-input(:placeholder="$t('rich_text_editor.editor.insert_link')"
                    v-model="link"
                    class="lynq-input"
                    @keyup.enter="insertLink")
        .separator
        .icons
          aiq-tooltip(effect="dark", :content="$t('rich_text_editor.editor.clear_formatting')", placement="bottom", :show-after="1000")
            fa-icon(:icon="['fas', 'trash']"
              @click="editor.chain().focus().unsetAllMarks().run()")
      editor-content(:editor="editor")
      .files(v-if="inChat")
        .items-attached(:class="{ 'is-hidden': !hasAttachments }")
          .images-content
            .image-item.attached-file(v-for="item in attachedFiles")
              i.iq-ico-close.image-remove(@click="removeFile(item)")
              aiq-Image.image-item_img-preview(v-if="isFileImage(item)"
                                              :key="item.id"
                                              :imageKey="item.key"
                                              :src="item.url"
                                              :acl="item.acl"
                                              :bucket="item.bucket")
              aiq-document-icon.doc-preview(v-else
                                :mimeType="getMimeType(item)"
                                :documentExtension="getDocExt(item)"
                                :fileId="''+item.id"
                                width="64px"
                                height="auto")
              .attachement-name {{ item.name || item.filename}}
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

const CHAT_INPUT_EXPIRY_TIME = 1000 * 60 * 60 * 24; // eslint-disable-line
const imgTagRegex = /<img\s+src=\\".*"\s+.*>/i;
const suspiciousClipboardDataTypes = {
  notAllowed: 'Files',
  needsChecking: ['text/plain', 'text/html'],
};

export default {
  name: 'RichText',
  components: {
    EditorContent,
  },
  inject: ['richTextEventEmitter'],

  data() {
    return {
      editor: null,
      selectSize: 'Normal',
      showAddLink: false,
      link: '',
      sizeOptions: [
        {
          id: 1,
          label: this.$t('rich_text_editor.editor.normal'),
        },
        {
          id: 2,
          label: this.$t('rich_text_editor.editor.h1'),
          size: 20,
        },
        {
          id: 3,
          label: this.$t('rich_text_editor.editor.h2'),
          size: 18,
        },
        {
          id: 4,
          label: this.$t('rich_text_editor.editor.h3'),
          size: 16,
        },
        {
          id: 5,
          label: this.$t('rich_text_editor.editor.h4'),
          size: 14,
        },
        {
          id: 6,
          label: this.$t('rich_text_editor.editor.h5'),
          size: 12,
        },
        {
          id: 7,
          label: this.$t('rich_text_editor.editor.h6'),
          size: 10,
        },
      ],
    };
  },

  props: {
    placeHolder: {
      type: String,
      default: 'Type your message here...',
    },
    inChat: {
      type: Boolean,
      default: false,
    },
    cacheKey: {
      type: String,
      default: '',
    },

  },
  emits: ['update'],
  computed: {
    getOptionStyle() {
      return (index) => {
        return {
          fontSize: `${index}px`,
          fontWeight: 'bold',
          color: '#333',
        };
      };
    },
    attachedFiles() {
      return this.$store.getters['files/attachedFiles'];
    },
  },

  mounted() {
    this.editor = new Editor({
      content: '',
      onUpdate: ({ editor }) => {
        if (this.cacheKey) {
          if (editor.getText().length) {
            sessionStorage.setItem(this.cacheKey, JSON.stringify({
              val: editor.getHTML(),
              expiry: (new Date()).getTime() + CHAT_INPUT_EXPIRY_TIME,
            }));
          } else {
            sessionStorage.removeItem(this.cacheKey);
          }
        }
        this.$emit('update', {
          html: editor.getHTML(),
          text: editor.getText(),
        });
      },
      onPaste: (event) => {
        let containsImgTag = false;

        if (event.clipboardData.types.includes(suspiciousClipboardDataTypes.notAllowed)) {
          containsImgTag = true;
        } else {
          containsImgTag = some(suspiciousClipboardDataTypes.needsChecking, (type) => {
            const pastedHtml = event.clipboardData.getData(type);
            return imgTagRegex.test(pastedHtml);
          });
        }

        if (containsImgTag) {
          event.preventDefault();
          this.$aiq.notify.error(this.$t('notification.pasting_images_not_allowed.description'));
        }
      },
      extensions: [
        StarterKit,
        Underline,
        BulletList,
        ListItem,
        Placeholder.configure({
          placeholder: this.placeHolder,
        }),
        Link.configure({
          openOnClick: false,
          defaultProtocol: 'https',
        })],
    });

    if (this.cacheKey) {
      this.resetCachedText();
    }
    this.richTextEventEmitter.on('clearInput', () => {
      this.editor.commands.clearContent();
      this.$emit('update', null);
    });

    this.richTextEventEmitter.on('translate', (translation) => {
      this.editor.commands.setContent(translation);
    });

    this.richTextEventEmitter.on('addEmoji', (emoji) => {
      this.addEmoji(emoji);
    });

    this.richTextEventEmitter.on('setText', (item) => {
      this.editor.commands.setContent(item);
      this.$emit('update', {
        html: this.editor.getHTML(),
        text: this.editor.getText(),
      });
    });
  },

  methods: {
    /**
     * Toggles the size of the selected text based on the id given.
     * @param {Object} item - The object containing the id of the size to toggle
     */
    fixSize(item) {
      /*eslint-disable no-magic-numbers*/
      switch (item.id) {
        case 1:
          this.editor.chain().focus().setParagraph().run();
          break;
        case 2:
          this.editor.chain().focus().toggleHeading({ level: 1 }).run();
          break;
        case 3:
          this.editor.chain().focus().toggleHeading({ level: 2 }).run();
          break;
        case 4:
          this.editor.chain().focus().toggleHeading({ level: 3 }).run();
          break;
        case 5:
          this.editor.chain().focus().toggleHeading({ level: 4 }).run();
          break;
        case 6:
          this.editor.chain().focus().toggleHeading({ level: 5 }).run();
          break;
        case 7:
          this.editor.chain().focus().toggleHeading({ level: 6 }).run();
          break;
        default:
          break;
      }
    },

    /**
     * Retrieves the current HTML content of the editor.
     * @returns {string} The HTML representation of the editor's content.
     */

    getHtml() {
      return this.editor.getHTML();
    },

    /**
     * Retrieves the current raw text content of the editor.
     * @returns {string} The raw text representation of the editor's content.
     */
    getRawText() {
      const text = this.editor.getText();
      return text;
    },

    resetCachedText() {
      let text = '';
      const cachedItem = sessionStorage.getItem(this.cacheKey);
      if (cachedItem) {
        const { val, expiry } = JSON.parse(cachedItem);
        if ((new Date()).getTime() > expiry) {
          sessionStorage.removeItem(this.cacheKey);
        } else {
          text = val;
        }
      }
      this.editor.commands.setContent(text);
      this.$emit('update', {
        html: this.editor.getHTML(),
        text: this.editor.getText(),
      });
    },
    removeFile(file) {
      this.$store.dispatch('files/removeAttachedFile', file);
    },

    getMimeType(file) {
      return get(file, 'mime', '');
    },
    isFileImage(file) {
      return this.getMimeType(file).includes('image');
    },
    getDocExt(file) {
      return get(file, 'filename', '')
        .split('.')
        .pop();
    },
    hasAttachments() {
      return !isEmpty(this.attachedFiles);
    },

    showLinkDialog() {
      const { view } = this.editor;
      const { from, to } = view.state.selection;
      const html = this.getHTMLLinkBetween(this.editor, from, to);
      if (html) {
        this.link = html;
      } else {
        this.link = '';
      }
      this.showAddLink = true;
    },

    getHTMLLinkBetween(
      editor,
      from,
      to,
    ) {
      const { state } = editor;
      let anchorLink = null;

      state.doc.nodesBetween(from, to, (node, _pos, _parent) => {
        if (node.marks.some(mark => mark.type.name === 'link')) {
          anchorLink = node.marks[0].attrs.href;
        }
      });

      return anchorLink;
    },

    insertLink() {
      if (this.link.trim() === '') {
        this.editor
          .chain().focus().unsetLink().run();
      } else {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: this.link.startsWith('http') ? this.link : `https://${this.link}`})
          .run();
      }
      this.showAddLink = false;
    },

    addEmoji(e) {
      this.editor.chain().focus().insertContent(e.native).run();
    },

    handleBeforeClose() {
      this.showAddLink = false;
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>
<style lang="scss" scoped>

:deep(ol) {
    margin: unset;
    padding: unset;
    padding-left: 1.5rem;
}

:deep(ul) {
    margin: unset;
    padding: unset;
    padding-left: 1.5rem;
}

:deep(ol li) {
    list-style-type: initial;
    list-style: decimal;
    margin: unset;
    padding: unset;
}

:deep(ul li) {
    list-style: disc;
    list-style-type: initial;
    margin: unset;
    padding: unset;
}

.images-content {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  gap:10px;
}

.iq-ico-close {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #5993ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 130px;
  margin-bottom: -20px;
  z-index: 1;
}

.image-item.attached-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .attachement-name {
    font-size: 12px;
    font-weight: 300;
  }
}

img {
  height: 150px;
  width: 150px;
}

.editor {
    width: 100%;
    background-color: lightgray;
    display: flex;
}

.select-size {
    width: 150px;
    margin-right: 10px;
}
.separator {
    width: 1px;
    background-color: #9fa9b6;
    display: inline-block;
    margin-top: 3px;
    margin-bottom: 3px;

}
.icons {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 3px;
    font-weight: bold;
    cursor: pointer;
}

:deep(p.is-editor-empty:first-child::before) {
    color: grey;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

:deep(.el-select__wrapper) {
    background-color: lightgray;
}

:deep(.el-select .el-select__caret) {
    color: black
}
:deep(.ProseMirror) {
    min-height: 200px;
    max-height: 350px;
    overflow-y: auto;
    padding-left: 5px;
    font-size: 15px;
}
</style>