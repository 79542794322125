import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import get from 'lodash/get';
import * as log from 'loglevel';

import CustomersDashboard from '../pages/CustomersDashboard/CustomersDashboard.router';
import AgentsDashboard from '../pages/AgentsDashboard/AgentsDashboard.router';
import IQTools from '../pages/IQTools/IQTools.router';
import Login from '../pages/Login/Login.vue';
import Conversations from '../pages/Conversations/Conversations.router';
import Metrics from '../pages/Metrics/Metrics.router';
import Pulse from '../pages/Pulse/Pulse.router';
import Error from '../pages/Error/Error.router';
import OAuth from './oauth';
import Settings from '../pages/Settings/Settings.router';
import Compliance from '../pages/Compliance/Compliance.router';
import Redirect from '../pages/Redirect/Redirect.router';
import MyPerformance from '../pages/MyPerformance/MyPerformance.router';
// (Gabe) For some reason unit tests can't find ifShowPopup from @/libs directly
/* TODO: VUE3
import { defaultRoute, ifShowPopup } from '@/libs/routerHelper';
*/
import { defaultRoute } from '@/libs/routerHelper';
import { canView } from '@/libs/aclMiddleware';

/* TODO: VUE3
// Add Vue router except test due to
// incompatibility with vue-test-utils
// Ref: https://stackoverflow.com/questions/55523639/vue-test-utils-could-not-overwrite-property-route-this-is-usually-caused-by-a
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}
*/

const showMyPerformancePage = get(store, 'state.featureFlags.FEATURE_FLAGS.MY_PERFORMANCE_PAGE', false);
const TAG = '[ROUTER]:';

const routerOption = {
  mode: 'history',
  history: true,
  hashbang: false,
  routes: [
    {
      path: '',
      redirect: defaultRoute,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        auth: false,
        resource: '/login',
      },
    },
    ...Conversations,
    CustomersDashboard,
    AgentsDashboard,
    IQTools,
    Settings,
    Metrics,
    Pulse,
    Compliance,
    ...(showMyPerformancePage ? [MyPerformance] : []),
    Error,
    OAuth,
    Redirect,
  ],
};

const router = createRouter({
  history: createWebHistory(),
  routes: routerOption.routes,
});

router.beforeEach((to, from, next) => {
  log.debug(TAG, to.path, to.meta.auth, store.getters['agent/isAclTokenReady']);

  if (!to.meta.auth) {
    next();
  } else if (to.meta.auth === store.getters['agent/isAclTokenReady']) {
    const shouldCheckPermission = get(to, 'meta.permissioned', true); // true by default
    const checkView = canView(store.getters['agent/permissions'], to.path);
    if (shouldCheckPermission && to.meta.auth && !checkView) {
      /* TODO: VUE3 there is not router.app
      router.app.$aiq.notify.error(`No permission at ${to.path}`);
      */
      return next(false);
    }

    // unsaved content popup
    /* TODO: VUE3 there is not router.app
    ifShowPopup({ store, from, next }, router.app.$aiq.confirm);
    */
    next();
  } else {
    const redirectPath = window.location.pathname !== '/' ? to.fullPath : '';
    if (store.getters['agent/isAclTokenReady']) {
      next({ path: defaultRoute });
    } else {
      next({ path: '/login', query: { r: redirectPath }});
    }
  }
});

router.afterEach((to) => {
  store.dispatch('settings/setContextNavText', get(to, 'meta.label', to.name));
});

export default router;
