<template lang="pug">
modal-editor.edit-action-modal(:visible="visible"
                              isRecordValid
                              :title="title"
                              :useDefaultFooter="false"
                              :afterClose="onClose"
                              ref="modalEditor"
                              :skipConfirmation="!isRecordDirty"
                              )
    template.form(v-slot:content, v-loading="isLoading")
      .card-title-container
        .field-container
          .form-title
            label Name:
          aiq-input.input-with-select(placeholder="File Name"
                                      name="name"
                                      disabled
                                      :model-value="$t(`${translationAlias}.table.${compliance.name}.title`)")
            template(v-slot:append)
              aiq-select(placeholder="Extensions"
                         class="extension-select"
                         v-model="selectedFileExtension")
                aiq-option(v-for="option in allowedFileExtensions"
                          :key="option"
                          :label="option"
                          :value="option")
        aiq-row.spacing.d-block
          aiq-col.field-container(:sm="12",:xs="24")
            .field-container.de-time-range-filter(v-if="compliance.useTimeRange")
              .form-title
                label Date Range:
              aiq-date-picker.date-range(v-model="selectedDateRange"
                            @change="handleDatePickerChange($event)"
                            start-placeholder="Start date"
                            end-placeholder="End date"
                            type="daterange"
                            placeholder="Select date range"
                            popper-class="date-picker-popup"
                            :default-time="defaultTime"
                            :shortcuts="datePickerOptions.shortcuts"
                            :disabled-date="datePickerOptions.disabledDate"
                            format="YYYY/MM/DD"
                            :clearable="true")
          aiq-col.field-container(:sm="12",:xs="24"
                                  v-for="{ label, paramkey, valuekey, options, multiple, initialValue } in compliance.dependecies"
                                  :key="paramkey")
            .field-container.filter-in-header
              .form-title
                label {{ label }}:
              aiq-select(:placeholder="`Select ${label}`"
                          :multiple="multiple"
                          :clearable="multiple"
                          :value="initialValue"
                          v-model="customizedDependencies[paramkey]"
                          value-key="id"
                          size="small"
                          collapse-tags
                          :reserve-keyword="false")
                aiq-option(v-for="item in options", :key="item.id", :label="item.name", :value="valuekey ? item[valuekey] : item")

        .field-container(v-if="showAgentSelect")
          .select-agent
            .form-title
              label Agents:
            collapsible-select.selector(placeholder="Filter by Agents"
                    size="small"
                    v-model="selectedAgents"
                    :value="selectedAgents"
                    multiple
                    value-key='id'
                    :reserve-keyword="false")
              aiq-option(v-for="agent in allAgents"
                          :key="agent.id"
                          :label="agent.fullName"
                          :value="agent.id")

        .field-container.is-required(v-if="allowedColumns.length")
          label.title Select the information to include in the report:
          aiq-checkbox.select-all.check-box(:indeterminate="isIndeterminate"
                                            v-model="selectAll"
                                            @change="handleSelectAllChange") Select All
          aiq-checkbox-group.columns-checkboxes(v-model="selectedColumns"
                                                @change="handleSelectedColumnsChange")
            aiq-checkbox.check-box(v-for="col in allowedColumns"
                                   :label="col"
                                   :key="col") {{returnCheckBoxTranslatedLabel(col)}}
      .el-dialog__footer
        .footer-container
          p.required-notice * is required
          aiq-button(@click="onClose") Cancel
          aiq-button(:disabled="!canCommitAction"
                    type="primary"
                    @click="commitAction") Download
</template>

<script>

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import Editor from '@/components/Editor/Editor.vue';
import { ALLOWED_FILE_EXTENSIONS, DASHBOARD_FILE_ROUTE_PATH } from '@/constants';
import { getNonReactiveDateRange, lastAvailableMonthAndQuarterRange, setToEndOfDay } from '@/pages/Metrics/configs/dateRange';
import CollapsibleSelect from '@/components/CollapsibleSelect/CollapsibleSelect.vue';
import { AGENT_LIMIT } from '@/constants/pagination';

const MAX_DEFAULT_DAYS = 90;

const DEFAULT_DATE_RANGE = [
  moment()
    .subtract(1, 'week')
    .startOf('day'),
  moment()
    .subtract(1, 'day')
    .endOf('day'),
];
const AGENTS_RELATED_REPORT = ['agent_capacity', 'agents', 'agent_timeline'];
const createPickerDateRangeOption = option => ({
  text: option.label,
  value() {
    const range = [option.startTime(), option.endTime()];
    return range;
  },
});

export default {
  name: 'export',
  extends: Editor,
  components: {CollapsibleSelect},
  props: {
    title: {
      type: String,
      default: 'Download Report',
    },
    translationAlias: {
      type: String,
      default: 'reporting_tab',
    },
    compliance: {
      type: Object,
      default: () => ({ columns: [] }),
    },
    fileExtensions: {
      type: Array,
      default: () => ALLOWED_FILE_EXTENSIONS,
    },
    agentsRelatedReport: {
      type: Array,
      default: () => AGENTS_RELATED_REPORT,
    },
  },
  emits: ['commitAction', 'close'],
  data() {
    return {
      selectedColumns: [],
      selectedDateRange: cloneDeep(DEFAULT_DATE_RANGE),
      selectedFileExtension: ALLOWED_FILE_EXTENSIONS.CSV,
      customizedDependencies: {},
      selectAll: true,
      isLoading: false,
      isCustomDateRange: false,
      // Property to achieve a 'check all' effect. Same as indeterminate in native checkbox.
      isIndeterminate: false,
      selectedAgents: [],
      allAgents: [],
    };
  },
  mounted() {
    this.selectedColumns = [...this.allowedColumns];

    if (!isEmpty(this.compliance.dependecies)) {
      this.customizedDependencies = this.compliance.dependecies
        .filter(d => d.initialValue)
        .reduce((acc, current) => ({ ...acc, [current.paramkey]: current.initialValue }), {});
    }
    this.$store.dispatch('agents/getAgentsList', [{
      limit: AGENT_LIMIT,
    }]).then((response) => {
      this.allAgents = response.data.models.filter(e => e.fullName !== '');
    });
  },
  computed: {
    allowedFileExtensions() {
      return Object.values(this.fileExtensions);
    },
    allowedColumns() {
      return this.compliance.columns ?? [];
    },
    canCommitAction() {
      return !this.allowedColumns.length || this.selectedColumns.length;
    },
    datePickerOptions() {
      return {
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
        disabledDate: (time) => time.getTime() > moment().endOf('day'),
      };
    },
    defaultTime() {
      // Docs: https://element-plus.org/en-US/component/datetime-picker.html#default-time-value-for-start-date-and-end-date
      // Per element plus docs, default time property do not care about date information it only use time from the date ranges defined bellow
      const currentDate = new Date();
      return [new Date(currentDate.getFullYear(), 1, 1, 0, 0, 0), new Date(currentDate.getFullYear(), 2, 1, 23, 59, 59)]; // eslint-disable-line no-magic-numbers
    },
    /**
     * Return true if the export type is 'agent_capacity', 'agents' or 'agent_timeline'
     * @returns {boolean}
     */
    showAgentSelect() {
      return this.agentsRelatedReport.includes(this.compliance.name);
    },
  },
  methods: {
    handleSelectAllChange(val) {
      this.selectedColumns = val ? this.allowedColumns : [];
      this.isIndeterminate = false;
    },
    handleSelectedColumnsChange(value) {
      const checkedCount = value.length;
      this.selectAll = checkedCount === this.allowedColumns.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allowedColumns.length;
    },
    resolveRecord() {
      return Promise.resolve(this.record);
    },
    returnCheckBoxTranslatedLabel(key) {
      const translationKey = key.toLowerCase().replaceAll(' ', '_');
      // return translated label if key is available, if not return key as default value
      return this.$t(`${this.translationAlias}.${translationKey}`, key);
    },
    async commitAction() {
      if (this.isCustomDateRange) {
        if (!(await this.customDateRangeConfirm())) return;
      }
      this.isLoading = true;
      const params = {
        name: this.compliance.name,
        columns: this.selectedColumns,
        headers: this.selectedColumns.map(item => this.returnCheckBoxTranslatedLabel(item)),
        fileExtension: this.selectedFileExtension,
        fileUrlPrefix: DASHBOARD_FILE_ROUTE_PATH,
        agentIds: this.selectedAgents,
        ...this.customizedDependencies,
      };
      if (get(this.selectedDateRange, 'length')) {
        Object.assign(params, {
          startDate: moment(this.selectedDateRange[0]).unix(),
          endDate: moment(this.selectedDateRange[1]).unix(),
        });
      }
      this.$emit('commitAction', params);
    },
    customDateRangeConfirm() {
      const title = 'Warning';
      const text = `Selecting a large date range may take excessive time to download. 
    <br/><br/> Do you want to continue? `;

      return new Promise(resolve => {
        this.$aiq.confirm(title, text, {
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Continue',
        })
          .then(() => resolve(true), () => resolve(false));
      });
    },

    handleDatePickerChange(newDateRange) {
      this.selectedDateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    openedWatcher(newValue) {
      if (!newValue) {
        this.isLoading = false;
      }
    },
    selectedDateRangeWatcher(newValue) {
      if (!get(newValue, 'length')) {
        this.isCustomDateRange = true;
        return;
      }
      const startDate = moment(newValue[0]);
      const endDate = moment(newValue[1]);
      const diffInMonths = endDate.diff(startDate, 'days');
      this.isCustomDateRange = diffInMonths >= MAX_DEFAULT_DAYS;
    },
    onClose() {
      this.$emit('close');
    },
  },
  watch: {
    opened(newValue) {
      this.openedWatcher(newValue);
    },
    selectedDateRange(newValue) {
      this.selectedDateRangeWatcher(newValue);
    },
  },
};
</script>


<style lang="scss" scoped>
@import "./../../../styles/media-queries.scss";

.edit-action-modal {
  color: #475669;
  font-family: museo sans, Roboto, sans-serif;
  label {
    color: #475669;
    font-size: 14px;
    font-weight: 100;
  }
}

.select-agent {
  margin-bottom: 10px;
}
.form-title {
  padding-bottom: 5px;
}
.columns-checkboxes {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  .el-checkbox{
    width: 30.5%;
  }
  @include mobile{
    .el-checkbox{
      width: 100%;
    }
  }
  .el-checkbox:first-child {
    margin-left: 15px;
  }
}
.select-all{
  display: block;
  margin: 10px 0 5px 0;
}

.required-notice {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 8px;
  color: red;
  font-size: 14px;
  font-weight: 100;
}

.is-required {
  .title {
    font-size: 12px !important;
    justify-content: start;
    margin-bottom: 0px;
  }
  label::before {
    margin-right: 5px;
    content: "*";
    color: red;
  }
  label.el-checkbox {
    &::before {
      content: "" !important;
    }
  }
}
.de-time-range-filter {
  .date-range {
    margin-top: 10px;
    font-size: 12px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.spacing > .field-container:nth-child(odd) {
  padding-right: 5px;
}
.spacing > .field-container:nth-child(even) {
  padding-left: 5px;
}

.spacing > .field-container:nth-child(n+3) {
  padding-top: 14px;
}

.input-with-select {
  margin-top: 10px;
  margin-bottom: 14px;
}

:deep(.input-with-select > .el-input__inner) {
  pointer-events:none;
  color:#AAA;
  background:#F5F5F5;
}
:deep(.extension-select.el-select .el-input) {
  width: 110px;
  max-height: 30px;
}
:deep(.el-input-group__append){
  border:1px;
}
:deep(.modal-editor.el-dialog .el-dialog__body .el-select){
  margin-top: 0px;
  margin-bottom: 0px;
}
:deep(.modal-editor.el-dialog .el-dialog__body .el-input) {
  margin-top: 0px;
  margin-bottom: 14px;
}
:deep(.el-select--small .el-select__selection) {
  height: 30px;
}
:deep(.modal-editor.el-dialog .el-dialog__body .field-container){
  flex: unset;
}
:deep(.el-select-dropdown) {
  top:-18px;
}
:deep(.el-range-editor.el-input__wrapper) {
  height: 30px;
}

:deep(.el-input.is-disabled .el-input__inner) {
  height: 30px;
}

:deep(.el-input-group--append .el-input-group__append .el-select .el-select__wrapper) {
  padding-top:0px;
  box-shadow: 0 1px 0 0 var(--el-input-border-color) inset, 0 0 0 0 var(--el-input-border-color) inset, -1px 0 0 0 var(--el-input-border-color) inset;
}
.extension-select {
  width: 115px;
  height: 30px;
}
.check-box {
  height: 100%;
  margin-right: 0px;
}
</style>
