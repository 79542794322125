<template lang="pug">
.mapping-item-container
  .mapping-item
    aiq-input(v-model="selectedAigObjectName" size="medium" disabled)
  .small-mapping-item.text-center
    i.el-icon-arrow-right(v-if="arrowRight" v-for="idx in arrowCounts")
    i.el-icon-arrow-left(v-if="!arrowRight" v-for="idx in arrowCounts")
  .mapping-item
    aiq-input(v-model="selectedCrmObjectName" size="small" disabled)
  .small-mapping-item.text-center
    a(@click="onEdit") Edit
  .small-mapping-item.text-center
    a(@click="onRemove") Remove
  .small-mapping-item.text-center
    aiq-switch(:model-value="value.enabled",
               style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA"
               @change="onToggle")

</template>

<script>
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'object-mapping-item',
  props: {
    value: {
      type: Object,
      default: {
        // snake case due to the schema coming from backend
        aiq_object_id: -1,
        crm_object_id: -1,
        sync_type: 'push',
        field_mappings: [],
        enabled: false,
      },
    },
    aiqObjects: {
      type: Array,
      default: [],
    },
    crmObjects: {
      type: Array,
      default: [],
    },
  },
  emits: ['toggle', 'remove', 'edit'],
  computed: {
    selectedAigObjectName() {
      const object = this.aiqObjects.find(item => item.id === this.value.aiq_object_id);
      return upperFirst(get(object, 'name', ''));
    },
    selectedCrmObjectName() {
      const object = this.crmObjects.find(item => item.id === this.value.crm_object_id);
      return upperFirst(get(object, 'name', ''));
    },
    arrowRight() {
      return this.value.sync_type.split('_')[1] === 'push';
    },
    arrowCounts() {
      return this.value.sync_type.split('_')[0] === 'batch' ? 2 : 1; // eslint-disable-line no-magic-numbers
    },
  },
  methods: {
    onToggle() {
      this.$emit('toggle');
    },

    onEdit() {
      this.$emit('edit');
    },

    onRemove() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss">
@import "./../../../../../styles/aiq-variables.scss";
.mapping-item-container {
  display: flex;
  position: relative;

  .mapping-item {
    width: 20%;
    margin: 5px;
    display: inline-block;
    text-align: center;
  }

  .small-mapping-item {
    width: 10%;
    margin: 5px;
    display: inline-block;
    text-align: center;
  }

  .text-center {
    padding: 5px;
  }
  a {
    cursor: pointer;
  }
}

.icons {
}
</style>
