<template lang="pug">
.email-templates
  .selection(v-if="templates.length !== 0")
    aiq-row
      label {{ $t('settings_tab.admin_tab.email_tab.email_template.selection_label') }}
    aiq-row
      aiq-col(:span="21")
        aiq-select(placeholder="Template"
                  size="small"
                  v-model="selectedTemplateTitle")
          aiq-option(v-for="name in templateNames", :key="name", :label="name", :value="name")
      aiq-col(:span="1")
      aiq-col(:span="2")
        aiq-button(type="success" size="small" @click="onNewTemplate") {{ $t('settings_tab.admin_tab.email_tab.email_template.add') }}
  .template-details(v-if="(mode === 'add' || (mode === 'edit' && !!selectedTemplateTitle))")
    aiq-row
      label {{ $t('settings_tab.admin_tab.email_tab.email_template.title') }}
      aiq-input(v-model="template.title" :disabled="mode === 'edit'")
    aiq-row
      label {{ $t('settings_tab.admin_tab.email_tab.email_template.from') }}
      aiq-input(v-model="template.from" type="email")
    aiq-row
      label {{ $t('settings_tab.admin_tab.email_tab.email_template.to') }}
    aiq-row
      aiq-col(:span="24")
        aiq-keyword-input(:list="template.to"
                          @createItem="addReceiver"
                          @deleteItem="deleteReceiver"
                          :deletable="true")
    aiq-row
      label {{ $t('settings_tab.admin_tab.email_tab.email_template.subject') }}
      aiq-input(v-model="template.subject")
    aiq-row
      label {{ $t('settings_tab.admin_tab.email_tab.email_template.body') }}
    aiq-row
      .editor
        vue-editor(v-model="template.html" :editorToolbar="toolbar")

    aiq-row
      .content-footer
        aiq-button(@click="onCancel") {{ $t('global.cancel') }}
        aiq-button(v-if="this.selectedTemplateTitle" type="danger" @click="onDelete") {{ $t('global.delete') }}
        aiq-button(type="primary" @click="onSave" :disabled="!shouldSaveButtonEnabled") {{ $t('global.save') }}
</template>

<script>
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { VueEditor } from 'vue3-editor';
import { EMAIL_EDITOR_CONFIG } from '@/constants/settings';
import { validateEmail } from '@/libs/validationUtils';

const emptyTemplate = {
  title: '',
  from: '',
  to: [],
  subject: '',
  html: '',
};

export default {
  name: 'EmailTemplates',
  components: {
    VueEditor,
  },
  props: {
    templates: {
      type: Array,
      default: [],
    },
  },
  emits: ['update'],
  computed: {
    shouldHideAddItem() {
      return this.templates.length === 0;
    },
    templateNames() {
      return this.templates.map(t => t.title);
    },
    shouldSaveButtonEnabled() {
      // Enable Save button in either changed or new item.
      return this.isFormatValid && (this.isTemplateChanged || this.canAddNewItem);
    },
    isFormatValid() {
      return !isEmpty(this.template.title)
        && !isEmpty(this.template.from)
        && !isEmpty(this.template.to)
        && !isEmpty(this.template.html)
        && !isEmpty(this.template.subject)
        && validateEmail(this.template.from);
    },
    isTemplateChanged() {
      return this.mode === 'edit' && this.selectedTemplateTitle !== null
        && !isEqual(this.template, this.templates.find(t => this.selectedTemplateTitle === t.title));
    },
    canAddNewItem() {
      return this.selectedTemplateTitle === null && this.mode === 'add';
    },
    // editor configuration
    toolbar() {
      return EMAIL_EDITOR_CONFIG;
    },
  },
  watch: {
    templates(newVal) {
      this.mode = newVal.length === 0 ? 'add' : 'edit';
    },
    selectedTemplateTitle(newVal) {
      if (!isEmpty(newVal)) {
        this.template = cloneDeep(this.templates.find(t => t.title === newVal));
        this.mode = 'edit';
      } else {
        this.template = cloneDeep(emptyTemplate);
      }
    },
  },
  data() {
    return {
      mode: this.templates.length === 0 ? 'add' : 'edit',
      selectedTemplateTitle: null,
      template: cloneDeep(emptyTemplate),
    };
  },
  methods: {
    addReceiver(email) {
      const trimmed = email.trim();
      const isEmailValid = validateEmail(trimmed);
      if (isEmailValid) this.template.to.push(trimmed);
      else this.$aiq.notify.error(this.$t('settings_tab.admin_tab.email_tab.email_template.invalid_email'));
    },
    deleteReceiver(email) {
      this.template.to = this.template.to.filter(e => e !== email);
    },
    onCancel() {
      if (this.mode === 'edit') {
        this.selectedTemplateTitle = null;
      } else if (this.mode === 'add') {
        this.template = cloneDeep(emptyTemplate);
      }
    },
    onSave() {
      const newTemplates = cloneDeep(this.templates);
      if (this.mode === 'edit') {
        const index = this.templates.findIndex(t => t.title === this.selectedTemplateTitle);
        newTemplates[index] = this.template;
      } else if (this.mode === 'add') {
        newTemplates.push(this.template);
      }
      return this.onUpdate(newTemplates);
    },
    async onDelete() {
      try {
        await this.$aiq.confirm(
          this.$t('settings_tab.admin_tab.email_tab.email_template.on_delete.title'),
          this.$t('settings_tab.admin_tab.email_tab.email_template.on_delete.body'),
          {
            confirmButtonText: this.$t('global.continue'),
            cancelButtonText: this.$t('global.cancel'),
          },
        );
        const newTemplates = this.templates.filter(t => t.title !== this.selectedTemplateTitle);
        this.selectedTemplateTitle = null;
        this.template = cloneDeep(emptyTemplate);
        return this.onUpdate(newTemplates);
      } catch (_) {
        // Do nothing
        return null;
      }
    },
    onUpdate(templates) {
      return this.$emit('update', templates);
    },
    onNewTemplate() {
      this.mode = 'add';
      this.template = cloneDeep(emptyTemplate);
      this.selectedTemplateTitle = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
@import "../../../../../styles/aiq-extensions.scss";

.content-footer {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.editor {
  width: 100%;
  ::v-deep .ql-editor {
    background: #fff;
  }
  ::v-deep .quillWrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
  }
  ::v-deep .ql-toolbar{
    border: none;
    border-bottom: 1px solid #ccc;
  }
  ::v-deep .ql-container{
    border: none;
  }
}
.keyword-input {
  background: #fff;
  height: 30px;
  ::v-deep .keyword-list {
    padding-top: 0px;
    height: 30px;
    .el-tag {
      margin-top: 2px;
    }
    .el-input{
      height: 30px;
    }
  }
}
::v-deep .el-input input {
    height: 30px;
}
</style>
