export const getRgbaArray = color => {
  const div = document.createElement('div');
  div.style.backgroundColor = color;
  document.body.appendChild(div);
  let rgba = getComputedStyle(div).getPropertyValue('background-color');
  div.remove();

  if (rgba.indexOf('rgba') === -1) {
    rgba += ',1';
  }

  // eslint-disable-next-line no-useless-escape
  return rgba.match(/[\.\d]+/g).map(a => +a);
};

// eslint-disable-next-line no-magic-numbers
export const increaseOpacity = (color, opacity = 0.5) => {
  const rgbaArr = getRgbaArray(color);
  rgbaArr[rgbaArr.length - 1] *= opacity;
  return `rgba(${rgbaArr.join()})`;
};
