<template lang="pug">
aiq-collapse.domain-config-container(v-model="activeNames")
  aiq-collapse-item.domain-config-container(
    title="Basics for the Group"
    name="basics")
    .domain-config
      .form-field
        h5 API Group Name
        aiq-input(:model-value="modelValue.name"
                  @update:modelValue="onUpdateField('name', $event)")
      .form-field
        h5 Description
        aiq-input(:model-value="modelValue.description"
                  @update:modelValue="onUpdateField('description', $event)"
                  size="small")
      .form-field.domain-path
        .form-subfield
          h5 Protocol
          aiq-input(:model-value="modelValue.protocol"
                    @update:modelValue="onUpdateField('protocol', $event)")
        span ://
        .form-subfield--expand
          h5 Host
          aiq-input(:model-value="modelValue.host"
                    @update:modelValue="onUpdateField('host', $event)")
      .form-field.domain-authorization
        .authorization-type
          h5 Authorization type
          aiq-select(:model-value="modelValue.auth_type"
                    :popper-append-to-body="false"
                    popper-class="authorization-type-popper"
                    @update:modelValue="onUpdateAuthType($event)")
            aiq-option(v-for="type in AUTH_TYPES"
                      :key="type.value"
                      :label="type.label"
                      :value="type.value")
      .bearer-from(v-if="auth_type==='jwt'")
        h5 Key
        aiq-input(:model-value="modelValue.auth_payload.key"
                  type="password"
                  @update:modelValue="onUpdateField('auth_payload', { key: $event })")

      .bearer-from(v-if="modelValue.auth_type==='oauth2'")
        h5 Grant Type
        aiq-select(:model-value="localAuthPayload.grant_type"
                  :popper-append-to-body="false"
                  popper-class="authorization-type-popper"
                  @update:modelValue="onUpdateAuthPayload('grant_type', $event)")
          aiq-option(v-for="type in supportedGrantTypes"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value")
        h5 Access Token URL
        aiq-input(:model-value="localAuthPayload.access_token_url"
                  @update:modelValue="onUpdateAuthPayload('access_token_url', $event)")
        h5 Client ID
        aiq-input(:model-value="localAuthPayload.client_id"
                  @update:modelValue="onUpdateAuthPayload('client_id', $event)")
        h5 Client Secret
        aiq-input(:model-value="localAuthPayload.client_secret"
                  type="password"
                  @update:modelValue="onUpdateAuthPayload('client_secret', $event)")
        .password-type(v-if="localAuthPayload.grant_type === 'password'")
          h5 Username
          aiq-input(:model-value="localAuthPayload.username"
                    @update:modelValue="onUpdateAuthPayload('username', $event)")
          h5 Password
          aiq-input(:model-value="localAuthPayload.password"
                    type="password"
                    @update:modelValue="onUpdateAuthPayload('password', $event)")
        h5 Scope
        aiq-input(:model-value="localAuthPayload.scope"
                  @update:modelValue="onUpdateAuthPayload('scope', $event)")
        h5 Audience
        aiq-input(:model-value="localAuthPayload.audience"
                  @update:modelValue="onUpdateAuthPayload('audience', $event)")
        h5 Access Token Authentication
        aiq-select(:model-value="localAuthPayload.token_method"
                  :popper-append-to-body="false"
                  popper-class="authorization-type-popper"
                  @update:modelValue="onUpdateAuthPayload('token_method', $event)")
          aiq-option(v-for="type in TOKEN_AUTH_TYPES"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value")

      .form-field
        h5 Headers
        input-box-pairs(:pairs="headersList"
                        :style-left="headerStyleLeft"
                        :style-right="headerStyleRight"
                        @update="onHeadersPairsUpdate")

      .save-buttons-container
        aiq-button(:disabled="isPristine"
                  @click="$emit('cancel')") Cancel
        aiq-button(:disabled="isPristine"
                  type="primary"
                  @click="$emit('save')")
          | {{ isNew ? 'Create Domain' : 'Save' }}

</template>

<script>
import clone from 'lodash/clone';
import { InputBoxPairs } from '@/components';
import { pairsListToObject } from '@/libs';

// TODO (Gabe) - Pull from backend
const AUTH_TYPES = [
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'Bearer Token',
    value: 'jwt',
  },
  {
    label: 'OAuth 2',
    value: 'oauth2',
  },
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Customer Access Token',
    value: 'customer_access_token',
  },
];
const TOKEN_AUTH_TYPES = [
  {
    label: 'Basic authentication in headers',
    value: 'basic_auth_header',
  },
  {
    label: 'Payload in request body',
    value: 'payload_body',
  },
];
const HEADER_STYLE_LEFT = { width: '200px' };
const HEADER_STYLE_RIGHT = { 'flex-grow': 1 };

const DEFAULT_OAUTH2_PAYLOAD = {
  grant_type: 'client_credentials',
  access_token_url: '',
  client_id: '',
  client_secret: '',
  scope: '',
  audience: '',
  username: '',
  password: '',
  token_method: TOKEN_AUTH_TYPES[0].value,
};

const DEFAULT_JWT_PAYLOAD = {
  key: '',
};

const GRANT_TYPE_LIST = [
  {
    value: 'client_credentials',
    label: 'Client Credentials',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

export default {
  name: 'domainConfig',
  components: {
    InputBoxPairs,
  },
  props: {
    isPristine: {
      type: Boolean,
      default: true,
    },
    emitter: Object,
    expanded: {
      type: Boolean,
      default: false,
    },
    id: Number,
    modelValue: {
      type: Object,
      default: {},
    },
  },
  emits: ['update:modelValue', 'cancel', 'save'],
  computed: {
    isNew() {
      return !this.id;
    },
    supportedGrantTypes() {
      return GRANT_TYPE_LIST;
    },

    headersList() {
      return Object.entries(this.modelValue.headers);
    },
  },
  data() {
    return {
      activeNames: this.expanded ? ['basics'] : [],
      localAuthPayload: clone(this.modelValue.auth_payload),
    };
  },


  created() {
    this.AUTH_TYPES = AUTH_TYPES;
    this.TOKEN_AUTH_TYPES = TOKEN_AUTH_TYPES;
    this.headerStyleLeft = HEADER_STYLE_LEFT;
    this.headerStyleRight = HEADER_STYLE_RIGHT;
  },
  watch: {
    modelValue(newVal) {
      this.localAuthPayload = clone(newVal.auth_payload);
    },
  },
  methods: {
    onUpdateAuthType(newType) {
      let newPayload = null;
      switch (newType) {
        case 'oauth2':
          newPayload = DEFAULT_OAUTH2_PAYLOAD;
          break;
        case 'jwt':
          newPayload = DEFAULT_JWT_PAYLOAD;
          break;
        default:
          newPayload = {};
      }
      this.$emit('update:modelValue', {
        ...this.modelValue,
        auth_type: newType,
        auth_payload: newPayload,
      });
    },
    onUpdateAuthPayload(field, val) {
      return this.onUpdateField('auth_payload', { ...this.modelValue.auth_payload, [field]: val });
    },
    onHeadersPairsUpdate(pairs) {
      this.onUpdateField('headers', pairsListToObject(pairs));
    },
    onUpdateField(name, value) {
      this.$emit('update:modelValue', { ...this.modelValue, [name]: value });
    },
  },
};
</script>


<style lang="scss">
.domain-config-container {
  .domain-config {
    .el-input__inner {
      height: 30px;
    }
    .domain-authorization {
      .authorization-type {
        .authorization-type-popper {
          min-width: 140px !important;
          width: 140px !important;
          left: 0 !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/aiq-mixins.scss";
@import "../../../../styles/aiq-extensions.scss";

h5 {
  @extend %settings__subheader;
}

.form-subfield {
  width: 200px;
}

.form-subfield--expand {
  flex-grow: 1;
};

.domain-config {
  @include space-between-v(16px);

  padding: 16px 16px 24px 16px;
  .domain-path {
    display: flex;
    align-items: flex-end;

    span {
      margin: 0 4px 4px 4px;
    }

    .form-field--expand {
      display: flex;
      flex-direction: column;
    }
  }

  .domain-authorization {
    display: flex;
  }

  .save-buttons-container {
    align-self: flex-end;
  }
}

</style>
