<template lang="pug">
  .screen-share
    aiq-tooltip(effect="dark", :content="$t('icon_tooltips.screen_share')", placement="bottom", :show-after="1000")
     aiq-button.share-button(:disabled="disableShare" @click="screenshareClick")
      fa-icon(:icon="['far', 'screencast']" class="panel-icon cast-icon")
  iframe(:v-if="triggerIframe" :src="presentURL" width="100%" height="100%" style="visibility: hidden; display: none;")
</template>

<script>
import CobrowseAPI from 'cobrowse-agent-sdk';
import CobrowseIO from 'cobrowse-sdk-js';
import * as log from 'loglevel';

export default {
  name: 'screenShare',
  props: {
    clientIdentity: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  emits: ['session-ended', 'session-started'],
  data() {
    return {
      presentURL: '',
      disableShare: false,
      triggerIframe: false,
    };
  },
  methods: {
    async screenshareClick() {
      try {
        const [cobrowseConfig, token] = await Promise.all([
          this.$store.dispatch('agent/getCobrowseConfig'),
          this.$store.dispatch('agent/getCobrowseToken', { identity: this.clientIdentity, name: this.name })]);


        if (!cobrowseConfig.enabled) {
          throw new Error('Cobrowse is not enabled. Check Backend config.');
        }

        const cobrowseApi = new CobrowseAPI();
        cobrowseApi.token = token;

        const session = await cobrowseApi.sessions.create({ full_device: 'requested' });

        this.presentURL = `https://cobrowse.io/session/${session.id}?token=${token}&agent_tools=none&device_controls=none&end_action=none&popout=none&session_details=none`;
        await this.onScreenSharePermission(cobrowseConfig.license, session);

      } catch (err) {
        this.$aiq.notify.error(err.message);
        log.error(err);
      }

    },
    async onScreenSharePermission(licenseKey, session) {
      const media = await navigator.mediaDevices.getDisplayMedia({
        video: {
          cursor: 'always',
          width: { ideal: 1400 },
          height: { ideal: 1000 },
          frameRate: { max: 10 },
        },
        audio: false,
      });

      await CobrowseIO.client();

      CobrowseIO.license = licenseKey;
      CobrowseIO.redactedViews = ['.container'];
      CobrowseIO.capabilities = ['full_device'];
      CobrowseIO.showSessionControls = () => { };
      CobrowseIO.hideSessionControls = () => { };
      CobrowseIO.confirmSession = async() => true;
      CobrowseIO.confirmFullDevice = async() => media;
      CobrowseIO.confirmRemoteControl = async() => false;

      let presentationActiveTriggered = false;
      let sessionEnded = false;

      /**
     * Listens for the session state change event
     * @event 'session.updated'
     * @param {Object} presentSession - The session object
     */
      CobrowseIO.on('session.updated', presentSession => {
      /**
       * If the session is active and we haven't already sent a notification
       * then send a notification and disable the share button
       */
        if (presentSession.isActive()) {
          if (!presentationActiveTriggered) {
            presentationActiveTriggered = true;
            this.disableShare = true;
          }

          /**
         * If the session is active but not in full device mode, then
         * disable the share button and end the session
         */
          if (!presentSession.fullDevice()) {
            this.disableShare = false;
            session.end();
          }
        }
      });

      /**
     * Listen for the session ended event and stop the cobrowse session
     * @event 'session.ended'
     * @param {Object} presentSession - The session object
     */
      CobrowseIO.on('session.ended', async _presentSession => {
        if (sessionEnded) return;
        sessionEnded = true;
        try {
        // Close the cobrowse session
          if (media) {
          // Stop the media streams
            media.getTracks().forEach(track => track.stop());
          }
          // Set the share button to enabled
          this.disableShare = false;
          // Stop the cobrowse client
          await CobrowseIO.stop();
          // Notify the user that the session has ended
          this.$aiq.notify.info(this.$t('screen_share.screen_share_ended'));
          // Emit the session ended event
          this.$emit('session-ended', session);
          this.triggerIframe = false;
        } catch (err) {
          log.error(err);
        }
      });

      await CobrowseIO.start({ allowIFrameStart: true, register: false });
      // Use the Client SDK to join the session
      await CobrowseIO.getSession(session.id);
      this.triggerIframe = true;

      this.$aiq.notify.info(this.$t('screen_share.screen_share_started'));
      this.$emit('session-started', {
        session: session,
        url: this.presentURL,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.share-button {
  background-color: #fff !important;
}

</style>