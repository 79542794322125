<template lang="pug">
aiq-table.scrollbar(ref="complianceTable"
                  :data="compliance"
                  :default-sort="sortOrder"
                  v-loading="listPending")
  aiq-table-column(label="Report Name", prop="name", :sortable="true", width="300" label-class-name="is-bordered")
    template(v-slot:default="scope")
      aiq-button(link type="primary" size="small", @click="openPopup(scope.row)")
        | {{ $t(`reporting_tab.table.${scope.row.name}.title`) }}
  aiq-table-column(label="Description", :sortable="true", label-class-name="is-bordered")
    template(v-slot:default="scope")
      div {{ $t(`reporting_tab.table.${scope.row.name}.description`) }}

ExportPanel(v-if="isOpen"
            :compliance="selectedCompliance"
            @close="onClosePopup"
            @commitAction="commitAction"
            )
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ComplianceMixin from './Compliance.mixin';

const SEARCH_PAGE_SIZE = 100;

export default {
  name: 'reporting',
  mixins: [ComplianceMixin],
  data() {
    return {
      pagination: {
        offset: 0,
        limit: 0,
        rowCount: 0,
      },
      type: 'report',
      namespace: 'reporting_tab',
      isLoadingAll: false,
      searchPageSize: SEARCH_PAGE_SIZE,
    };
  },
  mounted() {
    try {
      this.getComplianceList();
    } catch (err) {
      this.$aiq.notify.error('Unable to get reports');
    }
  },
  computed: {
    ...mapState({
      compliance: state => state.compliance.compliance,
      listPending: state => state.compliance.listPending,
    }),
  },
  methods: {
    ...mapActions({
      getComplianceList: 'compliance/getComplianceList',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./../../styles/aiq-extensions.scss";
*:focus {
  border-color: transparent;
  background: unset;
  color: #409eff;
}
</style>
