import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { getUrl } from '@/config/api.routes';
import { processCommands } from '@/libs';

const getModel = () => ({
  commands: {
    chatCommands: [],
    iqtoolsCommands: [],
    chatEnabledCommandsLookup: {},
    iqtoolsEnabledCommandsLookup: {},
  },
  graphs: [],

  user_guiding: {
    login_to_launch: false,
    menu_to_launch: false,
    max_count_login_to_launch: 4,
  },
  side_panel: {
    editable_customer_info: [],
  },
  supported_language_translation: [],
  account_fields: [],
});

export default {
  namespaced: true,
  state: {
    config: getModel(),
    customizables: {
      customer: {
        profile: [],
      },
    },
    channels: {
      webchat: {
        webform: {
          message: { header: {} },
          live_hours: { weekday: {} },
        },
        postChatSurvey: {
          ask_resolved: null,
          survey_type: null,
        },
        webchat_configs: {
          features: {
            IMAGE_UPLOAD: true,
            SHOW_CLOSE_BTN: { visibility: [] },
            SHOW_MINIMIZE_BTN: { visibility: [] },
            SHOW_LOGOUT_BTN: { visibility: [] },
            SHOW_QUEUE_TRANSPARENCY: {
              visibility: [],
              display_message: '',
              segment_queue: false,
              queue_hours: false,
              hours_per_day: [],
              after_hours_workflow: '',
              leave_queue: false,
              leave_queue_prompt: '',
              leave_queue_workflow: '',
            },
            BLOCK_TIME_DRIFT: { enabled: false, display_message: '', tolerable_time: 0 },
          },
          configs: {
            SOCKET_HOST: '',
            WEBCHAT_TITLE: '',
            WELCOME_MESSAGE: '',
            targets: [],
            hamburger: {
              menu: [],
            },
          },
          theme: {
            color: {
              'base-theme-primary': '',
              'base-theme-secondary': '',
            },
            bubble: {
              open: {
                background_color: '',
                image_url: '',
              },
              close: {
                background_color: '',
                image_url: '',
              },
            },
          },
        },
        onboarding: {
          introduction: { pages: [], version: '' },
          t_and_c: { tAndC: '', version: '' },
          prechat_survey: { title: '', description: '', version: '' },
        },
      },
    },
    agent: [
      {
        name: 'first_name',
        show: true,
        require: true,
        type: 'input',
      },
      {
        name: 'last_name',
        show: true,
        require: true,
        type: 'input',
      },
    ],
    // TODO (Gabe) namespace integrations
    integrations: {
      slack: {
        form: {
          webhook: null,
        },
      },
      zendesk: {
        form: {
          username: null,
          accessToken: null,
        },
      },
      authentication: {
        value: {
          product: 'okta',
          authority: '',
          client_id: '',
          client_secret: '',
          grant_type: '',
          post_logout_redirect_uri: '',
          redirect_uri: '',
          response_type: '',
          scope: '',
          use_pkce: false,
          use_saml: false,
          show_internal_openid_config: false,
          claims: {
            first_name_field: '',
            middle_name_field: '',
            last_name_field: '',
            email_field: '',
          },
          external_identity: {
            enabled: false,
            field_name: '',
            field_type: '',
          },
        },
      },
      customer_segment: {
        source: {
          fields: [],
          field_name: '',
          field_location: 'claim',
        },
        enabled: false,
        segment_webchat_configs: [],
      },
      wellknown_config: {
        issuer: '',
        authorization_endpoint: '',
        token_endpoint: '',
        userinfo_endpoint: '',
        revocation_endpoint: '',
        end_session_endpoint: '',
      },
    },
    tenantVariables: {
      external_customer_link: '',
    },
    customerSegments: {
      models: [],
    },
    agentNotification: {
      template_variables: [],
      notifications: [],
    },
    videoBackground: {
      models: [],
      pagination: {},
    },
    customLanguages: {
      models: [],
      pagination: {},
    },
    language_setting: {
      enable_multiple_language_support: false,
      enabled_languages: [],
      selected_language_view: 'en',
    },
    language_strings: {},
    releaseNotesLink: process.env.LYNQ_PORTAL,
  },
  getters: {
    graphsConfigs: state => state.config.graphs.reduce((acc, graph) => {
      if (graph.show) {
        const { show, name, ...params } = graph;
        acc[graph.name] = params;
      }

      return acc;
    }, {}),
    chatCommands: state => state.config.commands.chatCommands,
    iqtoolsCommands: state => state.config.commands.iqtoolsCommands,
    chatEnabledCommandsLookup: state => state.config.commands.chatEnabledCommandsLookup,
    iqtoolsEnabledCommandsLookup: state => state.config.commands.iqtoolsEnabledCommandsLookup,
    postChatSurvey: state => state.channels.webchat.postChatSurvey,
    segmentNames: state => state.customerSegments.models.map(item => item.name).sort(),
    segments: (state) => orderBy(
      state.customerSegments.models.map((item) => ({
        id: item.id,
        name: item.name,
        icon_url: get(item, 'payload.icon_url'),
        priority: get(item, 'payload.priority'),
        alias: get(item, 'payload.alias'),
        queue_priority: get(item, 'payload.queue_priority', 1),
      })),
      [(item) => item.name.toLowerCase()],
    ),
    segmentTeams: state => orderBy(state.customerSegments.models.map(item => ({
      ...item,
      alias: get(item, 'payload.alias'),
      icon_url: get(item, 'payload.icon_url'),
      priority: get(item, 'payload.priority'),
      queue_priority: get(item, 'payload.queue_priority'),
      team_ids: get(item, 'payload.team_ids', []),
    })), [item => item.name.toLowerCase()]),
  },
  mutations: {
    SET_CONFIGS(state, config) {
      state.config = { ...config };
      // keeping original setting into rawCommands
      state.config.rawCommands = state.config.commands;
      state.config.commands = processCommands(state.config.commands);
    },

    SET_CUSTOMIZABLES(state, customizables) {
      state.customizables = customizables;
    },

    SET_POST_CHAT_SURVEY(state, settings) {
      state.channels.webchat.postChatSurvey = settings;
    },

    SET_WEBFORM(state, webform) {
      state.channels.webchat.webform = webform;
    },

    SET_WEBCHAT_CONFIG(state, webchat_configs) {
      // Gaurantee that two level deep merge happen.
      const oldConfig = state.channels.webchat.webchat_configs;
      const features = { ...oldConfig.features, ...webchat_configs.features };
      const configs = { ...oldConfig.configs, ...webchat_configs.configs };
      const theme = { ...oldConfig.theme, ...webchat_configs.theme };

      state.channels.webchat.webchat_configs = { features, configs, theme };
    },

    SET_WEBCHAT_LANGUAGE_STRINGS(state, language_strings) {
      state.language_strings = language_strings;
    },

    SET_WEBCHAT_LANGUAGE_SETTINGS(state, language_setting) {
      state.language_setting = language_setting;
    },

    SET_AGENT_CONFIG(state, agent) {
      state.agent = [...agent];
    },

    SET_SLACK_INTEGRATION_FORM(state, form) {
      state.integrations.slack.form = form;
    },

    SET_ZENDESK_INTEGRATION_FORM(state, form) {
      state.integrations.zendesk.form = form;
    },

    SET_AUTHENTICATION_INTEGRATION_FORM(state, payload) {
      state.integrations.authentication = { ...state.integrations.authentication, ...payload };
    },

    SET_WELLKNOWN_CONFIG(state, payload) {
      state.integrations.wellknown_config = { ...state.integrations.wellknown_config, ...payload };
    },

    SET_CUSTOMER_SEGMENT_FORM(state, payload) {
      state.integrations.customer_segment = payload;
    },

    SET_ONBOARDING(state, payload) {
      state.channels.webchat.onboarding = payload;
    },

    SET_TENANT_VARIABLES(state, payload) {
      state.tenantVariables = { ...state.tenantVariables, ...payload };
    },

    SET_CUSTOMER_SEGMENTS(state, segments) {
      state.customerSegments = segments;
    },

    SET_STATE(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {
    async getEnvConfigs({ commit }) {
      const ret = await Promise.all([
        axios.get(`${getUrl('settings.base')}/dashboard_config`),
        axios.get(`${getUrl('settings.base')}/client_dependent_variables`)]);

      commit('SET_CONFIGS', get(ret[0], 'data'));
      commit('SET_TENANT_VARIABLES', get(ret[1], 'data'));
    },

    async getCustomizableSettings({ commit }) {
      const res = await axios.get(`${getUrl('settings.base')}/customizables`);
      commit('SET_CUSTOMIZABLES', get(res, 'data'));
    },

    async setCustomizableSettings({ commit }, payload) {
      const res = await axios.put(`${getUrl('settings.base')}/customizables`, payload);
      commit('SET_CUSTOMIZABLES', pick(get(res, 'data', {}), ['customer']));
    },

    async saveDashboardConfig({ commit }, payload) {
      if (payload.rawCommands && payload.commands) {
        payload.commands = payload.rawCommands;
        delete payload.rawCommands;
      }
      const res = await axios.put(`${getUrl('settings.base')}/dashboard_config`, payload);
      commit('SET_CONFIGS', pick(get(res, 'data', {}), ['graphs', 'commands', 'user_guiding', 'side_panel', 'supported_language_translation', 'account_fields']));
    },

    async saveCustomConfig({ commit }, payload) {
      const res = await axios.put(`${getUrl('settings.base')}/client_dependent_variables`, payload);
      commit('SET_TENANT_VARIABLES', pick(get(res, 'data', {}), ['external_customer_link']));
    },

    getPostChatSurvey({ commit }) {
      return axios.get(`${getUrl('settings.base')}/post_chat_survey`).then(data => {
        if (data.data) {
          commit('SET_POST_CHAT_SURVEY', data.data);
        }
        return data.data;
      });
    },

    getWebchatWebform({ commit }) {
      axios.get(`${getUrl('settings.base')}/feedback_form`).then(data => {
        commit('SET_WEBFORM', data.data);
        return data;
      });
    },

    getWebchatConfigs({ commit }) {
      return axios.get(`${getUrl('settings.base')}/webchat_configs`).then(data => {
        commit('SET_WEBCHAT_CONFIG', data.data);
        return data.data;
      });
    },

    setWebchatConfigs({ commit }, payload) {
      return axios.put(`${getUrl('settings.base')}/webchat_configs`, payload).then(() => {
        commit('SET_WEBCHAT_CONFIG', payload);
      });
    },

    getWebchatLanguageStrings({ commit }, { languagekey }) {
      return axios.get(`${getUrl('settings.base')}/webchat_strings_${languagekey}`).then(data => {
        commit('SET_WEBCHAT_LANGUAGE_STRINGS', data.data);
        return data.data;
      });
    },

    setWebchatLanguageStrings({ commit, state }, strings) {
      if (isEmpty(state.language_strings)) {
        return Promise.reject(new Error('Unable to set language strings.'));
      }
      const languagekey = state.language_setting.selected_language_view;
      const payload = { ...state.language_strings, ...strings };
      return axios.put(`${getUrl('settings.base')}/webchat_strings_${languagekey}`, payload).then(data => {
        commit('SET_WEBCHAT_LANGUAGE_STRINGS', data.data);
        return data.data;
      });
    },

    getWebchatLanguageSettings({ commit }) {
      return axios.get(`${getUrl('settings.base')}/webchat_language_settings`).then(data => {
        commit('SET_WEBCHAT_LANGUAGE_SETTINGS', data.data);
        return data.data;
      });
    },

    setWebchatLanguageSettings({ commit }, payload) {
      return axios.put(`${getUrl('settings.base')}/webchat_language_settings`, payload).then(() => {
        commit('SET_WEBCHAT_LANGUAGE_SETTINGS', payload);
      });
    },

    setPostChatSurvey({ commit }, payload) {
      return axios.put(`${getUrl('settings.base')}/post_chat_survey`, payload).then(() => {
        commit('SET_POST_CHAT_SURVEY', payload);
      });
    },

    loadSlack({ commit }) {
      return axios.get(`${process.env.MANAGEMENT_HOST}slack`).then(data => {
        const form = {
          webhook: data.data.webhook_url,
        };

        commit('SET_SLACK_INTEGRATION_FORM', form);
        return data;
      });
    },

    updateSlack({ commit, state }, form) {
      const { webhook } = state.integrations.slack.form;
      const method = webhook ? 'put' : 'post';
      const entryForm = {
        webhook_url: form.webhook,
      };

      return axios[method](`${process.env.MANAGEMENT_HOST}slack`, entryForm)
        .then(data => {
          commit('SET_SLACK_INTEGRATION_FORM', form);
          return data;
        });
    },

    async loadAuthentication({ commit }) {
      const res = await axios.get(`${getUrl('management.integrations')}/authentication`);
      const convertedValue = JSON.parse(res.data.value);
      const jsonBody = { ...res.data, value: convertedValue };
      commit('SET_AUTHENTICATION_INTEGRATION_FORM', jsonBody);
    },

    async loadWellKownConfiguration({ commit }) {
      const res = await axios.get(`${getUrl('settings.base')}/openid_configuration`);
      commit('SET_WELLKNOWN_CONFIG', res.data);
    },

    updateAuthentication({ commit, state }, value) {
      if (!value) {
        return;
      }

      const newValue = { ...state.integrations.authentication, value };
      const stringJsonValue = JSON.stringify(value);
      const newPayload = { ...state.integrations.authentication, value: stringJsonValue };

      return axios.put(`${getUrl('management.integrations')}/authentication`, newPayload)
        .then(() => {
          commit('SET_AUTHENTICATION_INTEGRATION_FORM', newValue);
          return newValue;
        });
    },

    async loadCustomerSegment({ commit }) {
      const res = await axios.get(`${getUrl('settings.base')}/customer_segment`);
      commit('SET_CUSTOMER_SEGMENT_FORM', res.data);
    },

    updateCustomerSegment({ commit }, value) {
      if (!value) {
        return;
      }

      return axios.put(`${getUrl('settings.base')}/customer_segment`, value)
        .then(() => {
          commit('SET_CUSTOMER_SEGMENT_FORM', value);
          return value;
        });
    },

    updateWellknownConfiguration({ commit }, value) {
      if (!value) {
        return;
      }

      return axios.put(`${getUrl('settings.base')}/openid_configuration`, value)
        .then(() => {
          commit('SET_WELLKNOWN_CONFIG', value);
          return value;
        });
    },

    async loadOnboarding({ commit }) {
      let res = await axios.get(`${getUrl('settings.base')}/introduction`);
      const introduction = res.data.content ? JSON.parse(res.data.content) : { pages: [] };
      introduction.version = get(res, 'data.version', 'v 0.0');
      res = await axios.get(`${getUrl('settings.base')}/t_and_c`);
      const t_and_c = res.data.content ? JSON.parse(res.data.content) : { tAndC: '' };
      t_and_c.version = get(res, 'data.version', 'v 0.0');
      res = await axios.get(`${getUrl('settings.base')}/prechat_survey`);
      const prechat_survey = res.data.content ? JSON.parse(res.data.content)
        : { title: '', description: '' };
      prechat_survey.version = get(res, 'data.version', 'v 0.0');
      const payload = { introduction, t_and_c, prechat_survey };
      commit('SET_ONBOARDING', payload);
      return payload;
    },

    async updateOnboarding({ commit }, payload) {
      const introductionContent = JSON.stringify(pick(payload.introduction, ['pages']));
      const tAndCContent = JSON.stringify(pick(payload.t_and_c, ['tAndC', 'tAndC_langkey']));
      const introductionVersion = get(payload, 'introduction.version');
      const tAndCcVersion = get(payload, 't_and_c.version');

      await Promise.all([
        axios.put(`${getUrl('settings.base')}/introduction`, {
          content: introductionContent,
          name: 'introduction',
          version: introductionVersion,
        }),
        axios.put(`${getUrl('settings.base')}/t_and_c`, {
          content: tAndCContent,
          name: 't_and_c',
          version: tAndCcVersion,
        }),
      ]);

      commit('SET_ONBOARDING', payload);
      return payload;
    },

    loadZendesk({ commit }) {
      return axios.get(`${process.env.MANAGEMENT_HOST}zendesk`).then(data => {
        const form = {
          username: data.data.username,
          accessToken: data.data.access_token,
        };

        commit('SET_ZENDESK_INTEGRATION_FORM', form);
        return data;
      });
    },

    updateZendesk({ commit, state }, form) {
      const { username, accessToken } = state.integrations.zendesk.form;
      const method = username && accessToken ? 'put' : 'post';
      const entryForm = {
        username: form.username,
        access_token: form.accessToken,
      };

      return axios[method](`${process.env.MANAGEMENT_HOST}zendesk`, entryForm)
        .then(data => {
          commit('SET_ZENDESK_INTEGRATION_FORM', form);
          return data;
        });
    },

    setWebchatWebform({ commit }, payload) {
      return axios.put(`${getUrl('settings.base')}/feedback_form`, payload).then(data => {
        commit('SET_WEBFORM', payload);
        return data;
      }).catch(err => {
        throw err;
      });
    },

    updateAgentConfig({ commit }, agent) {
      // need to add method for sending data to server
      commit('SET_AGENT_CONFIG', agent);
    },

    getCustomerSegments({ commit }) {
      return axios.get(`${getUrl('settings.customerSegments')}`).then(data => {
        commit('SET_CUSTOMER_SEGMENTS', data.data);
        return data;
      }).catch(err => {
        throw err;
      });
    },

    async getCustomerSegmentDetails(_, payload) {
      try {
        const data = await axios.get(`${getUrl('settings.customerSegments')}/${payload.id}`);
        return data.data;
      } catch (err) {
        return false;
      }
    },

    async getAgentNotificationConfig({ commit }) {
      const notificationConfig = (await axios.get(`${getUrl('settings.base')}/agent_notification`)).data;
      commit('SET_STATE', { key: 'agentNotification', value: notificationConfig });
      return notificationConfig;
    },

    async getMessageTemplateConfig({ commit }) {
      const messageTemplateConfig = (await axios.get(`${getUrl('settings.base')}/message_template`)).data;
      commit('SET_STATE', { key: 'messageTemplate', value: messageTemplateConfig });
      return messageTemplateConfig;
    },

    async updateAgentNotificationConfig({ commit }, payload) {
      const notificationConfig = (await axios.put(`${getUrl('settings.base')}/agent_notification`, payload)).data;
      commit('SET_STATE', { key: 'agentNotification', value: notificationConfig });
      return notificationConfig;
    },

    async updateMessageTemplateConfig({ commit }, payload) {
      const messageTemplateConfig = (await axios.put(`${getUrl('settings.base')}/message_template`, payload)).data;
      commit('SET_STATE', { key: 'messageTemplate', value: messageTemplateConfig });
      return messageTemplateConfig;
    },

    async getTimeZoneConfig({ commit }) {
      const timeZoneConfig = (await axios.get(`${getUrl('settings.base')}/timezone`)).data;
      commit('SET_STATE', { key: 'timezone', value: timeZoneConfig });
      return timeZoneConfig;
    },

    async updateTimeZoneConfig({ commit }, payload) {
      const timeZoneConfig = (await axios.put(`${getUrl('settings.base')}/timezone`, payload)).data;
      commit('SET_STATE', { key: 'timezone', value: timeZoneConfig });
      return timeZoneConfig;
    },

    async getVideoCallBackgrounds({ commit }) {
      const ret = (await axios.get(getUrl('files.base'), {
        params: { type: 'video-background' },
      })).data;

      commit('SET_STATE', { key: 'videoBackground', value: ret });
      return ret;
    },

    async getCustomLanguageFiles({ commit }) {
      const ret = (await axios.get(getUrl('files.base'), {
        params: { type: 'custom-dashboard-language' },
      })).data;

      commit('SET_STATE', { key: 'customLanguages', value: ret });
      return ret;
    },

    async upload(_, formData) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post(`${getUrl('files.base')}/upload`, formData, config);
      return data;
    },

    async deleteFile(_, { id, depth }) {
      return (await axios.delete(`${getUrl('files.base')}/${id}`, { params: { depth } })).data;
    },

    getLanguageStringsCsv(_, { language }) {
      return axios.get(`${getUrl('settings.base')}/language/${language}`, { responseType: 'blob' });
    },

    async updateLanguageStrings(_, { data, language }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const form = new FormData();
      form.append('file', data);
      const res = await axios.post(`${getUrl('settings.base')}/language/${language}`, form, config);
      return res.data;
    },
  },
};
