import { Observable } from 'rxjs/Observable';

import debounce from 'lodash/debounce';
import trim from 'lodash/trim';
import isUndefined from 'lodash/isUndefined';
import socketio from 'socket.io-client';

import { STATUS_CODES } from '@/constants';
import profileIcon from '@/assets/photo.png';

const ARGS_LENGTH_WITHOUT_SORTBY = 2;
const ARGS_LENGTH_WITH_SORTBY = 3;
const DEFAULT_DEBOUNCE_TIMEOUT = 1000;

export const aliasFilter = word => {
  // Checks if word is a wordset template
  const result = word.match(/(\{[^{}]*\})/g);
  if (result) {
    // Return the first word of the wordset
    const splitted = result[0].split('|');
    const first = splitted[0];
    return trim(first, ' {');
  }

  return word;
};

export function alphabetSort(...args) {
  // Should be called with an optional sortBy as the first argument

  let a; let
      b;
  let sortBy = 'name';

  if (args.length === ARGS_LENGTH_WITHOUT_SORTBY) {
    [a, b] = args;
  } else if (args.length === ARGS_LENGTH_WITH_SORTBY) {
    [sortBy, a, b] = args;
  } else {
    throw new Error('Improper params passed to alphabetSort:', args);
  }

  const aLower = (a[sortBy] || '').toString().toLowerCase();
  const bLower = (b[sortBy] || '').toString().toLowerCase();
  if (aLower < bLower) return -1;
  if (aLower > bLower) return 1;
  return 0;
}

/**
 * availability should be sorted in the below order
 * - available
 * - paused(busy)
 * - away
 */
const AVAILABILITY_ORDER = {
  available: 0,
  paused: 1,
  away: 2,
};
export function availabilitySort(a, b) {
  return AVAILABILITY_ORDER[a] <= AVAILABILITY_ORDER[b] ? 1 : -1;
}

export const containsClass = (targetClassList, listToCheck) => {
  for (const className of listToCheck) {
    if (targetClassList.contains(className)) {
      return true;
    }
  }

  return false;
};

export const decodeHTMLEntities = str => {
  if (str && typeof str === 'string') {
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
      .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
  }
  return str;
};

export const defaultBuildingKitTabs = [
  {
    icon: 'iq-ico-dialog-building-kit-intent',
    title: 'All',
    key: 'all',
  },
  {
    icon: 'iq-ico-dialog-building-kit-intent',
    title: 'Intents',
    key: 'intent',
  },
  {
    icon: 'iq-ico-dialog-building-kit-response',
    title: 'Responses',
    key: 'response',
  },
  {
    icon: 'iq-ico-dialog-building-kit-action',
    title: 'Commands',
    key: 'command',
  },
  {
    icon: 'iq-ico-dialog-building-kit-dialog',
    title: 'Dialogs',
    key: 'dialog',
  },
  {
    key: 'workflow',
    icon: 'iq-ico-dialog-building-kit-workflow',
    title: 'Workflows',
  },
];

export const defaultProfile = {
  first_name: 'Firstname',
  last_name: 'Lastname',
  nickname: 'username',
  picture: profileIcon,
};

export const defaultToastedConfig = {
  position: 'bottom-right',
  duration: 3000,
  showClose: false,
};

export const getInitials = (first, last) => [first.toUpperCase()[0], last.toUpperCase()[0]].join('').trim();

// TODO (Gabe) convert entire app to passing in element (using refs)
export const getInputKeyupSubject = (id, timeout = DEFAULT_DEBOUNCE_TIMEOUT, elem) => Observable
  .create((observer) => {
    const element = id ? document.querySelector(`#${id}`) : elem;
    // TODO (Gabe) - check if need to remove event listener
    if (element) {
      element.addEventListener('keyup', debounce(evt => {
        observer.next(evt);
      }, timeout));
    }
  });

// currentIdx of -1 means no current position. Returns 0-index position.
export const getNextCycledPosition = ({ currentIdx, idxOffset, length }) => {
  if (currentIdx <= 0 && idxOffset < 0) { return length - 1 }
  return (currentIdx + idxOffset) % length;
};

export const isCaseInsensitiveEqual = (a = '', b) => a.toString().localeCompare(b, 'en', { sensitivity: 'base' }) === 0;

export const enforceSpaceAfterComma = text => {
  // Enforces only one space after comma
  let split = text.split(',');
  split = split.map(e => e.trim());
  const joined = split.join(', ');

  return joined;
};

// Moves the cursor to the end of the Content Editable element
export const setEndOfContentEditable = elem => {
  const range = document.createRange();
  range.selectNodeContents(elem);
  range.collapse(false);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
};

export const getCaretPosition = e => {
  if (e.isContentEditable) {
    e.focus();
    const range = document.getSelection().getRangeAt(0);
    const rangeCopy = range.cloneRange();
    rangeCopy.selectNodeContents(e);
    rangeCopy.setEnd(range.endContainer, range.endOffset);
    return rangeCopy.toString().length;
  }
  // for textarea/input elements
  return e.selectionStart;
};

export const iqtoolsErrorHandler = (component, err, type) => {
  if (err.response.status === STATUS_CODES.CONFLICT) {
    component.$aiq.notify.error(`Duplicate; ${type} names must be unique.`);
  } else if (err.response.data.name === 'INVALID_STRING_PATTERN') {
    component.$aiq.notify.error(`Invalid name given. ${err.response.data.reason}`);
  } else {
    component.$aiq.notify.error(`Unable to save ${type}`);
  }
};

// does not set any pairs with undefined values
export const pairsListToObject = pairs => pairs
  .reduce((acc, p) => {
    const [key, val] = p;
    if (!isUndefined(key) && !isUndefined(val)) {
      acc[key] = val;
    }

    return acc;
  }, {});

export const scrollPosition = e => e.scrollHeight - e.scrollTop - e.clientHeight;

export const unicodeToASCII = text => {
  const r = /\\u([\d\w]{4})/gi;
  return text.replace(r, (match, grp) => String.fromCharCode(parseInt(grp, 16))).replace(/\\n/g, '');
};

// Note(jaekwan): I dont completely understand how these options work
// but we are facing an simliar issue with https://github.com/socketio/socket.io-client/issues/1097
// and there is a resolution in stackoverflow as
// https://stackoverflow.com/questions/47696304/node-js-app-fails-to-connect-using-socket-io-client/47697179
// We might want to revisit this place in future.
let token = null;
export const socketConnection = socketio(process.env.SOCKET_HOST, {
  secure: true,
  reconnection: true,
  rejectUnauthorized: false,
  autoConnect: false,
  transport: ['websocket', 'polling'],
  auth: (cb) => cb({ token }), // cb is the callback to return token
});

// A workaround function to set a token to socket io client instance later point
socketConnection.setTokenAndConnect = function(newToken) {
  token = newToken;
  return this.connect();
};
