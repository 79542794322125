<template lang="pug">
  management-page.scrollable(title="Summary"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
    template(v-slot:body)
      .chart-configuration
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="24")
            overview-chart(v-if="configs.active_customers"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :filters="selectedFilters"
                          :chartConfig="activeCustomersChartConfig"
                          :formatXTick="xAxisFormatForActiveCustomers"
                          yLabel="Count"
                          grouped=false
                          visualization='aiq-bar-chart'
                          :useDebounceForLoadMetrics="true"
                          )
      .chart-toolbar(v-if="CUSTOMER_METRICS_FILTERS")
        aiq-row(:gutter="16")
          .dropdown-filters
            .dropdown-filter
              segment-select(:customer-segment-filter-options="CustomerSegmentFilterOptions"
                      :segment-alias="segmentAlias"
                      is-select-multiple
                      is-select-collapse-tags
                      @change="onFilterChange"
                      @on-segment-hover="onSegmentHover"
                      :isCollapsible="true")
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="12")
            overview-chart(v-if="configs.customers_by_conversations"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :chartConfig="customerByConversationsChartConfig"
                          :filters="selectedFilters"
                          :yLabel="$t('metrics_tab.customer_tab.summary_tab.customers_by_conversations.y_axis')"
                          yLabelPosition="outer-middle"
                          :formatYTick="intTickFormatter"
                          xLabel="Conversations"
                          xLabelPosition="outer-center"
                          visualization='aiq-category-bar-chart-no-lines'
                          :useDebounceForLoadMetrics="true"
                          )
          aiq-col(:span="12")
            overview-chart(v-if="configs.customers_by_messages"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :chartConfig="customerByMessagesChartConfig"
                          :filters="selectedFilters"
                          :yLabel="$t('metrics_tab.customer_tab.summary_tab.customers_by_messages.y_axis')"
                          yLabelPosition="outer-middle"
                          :formatYTick="intTickFormatter"
                          xLabel="Messages"
                          xLabelPosition="outer-center"
                          visualization='aiq-category-bar-chart-no-lines'
                          :useDebounceForLoadMetrics="true"
                          )

          aiq-col(:span="24")
            overview-chart(v-if="configs.customers_by_login"
                          :dateRange="dateRangeUtc"
                          :breakdown="selectedBreakdownOption.value"
                          :chartConfig="customerByLoginsChartConfig"
                          :filters="selectedFilters"
                          :yLabel="$t('metrics_tab.customer_tab.summary_tab.customers_by_login.y_axis')"
                          yLabelPosition="outer-middle"
                          :formatYTick="intTickFormatter"
                          xLabel="Logins"
                          xLabelPosition="outer-center"
                          visualization='aiq-category-bar-chart-no-lines'
                          :useDebounceForLoadMetrics="true"
                          )



</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import SegmentSelect from '@/components/SegmentsSelect/SegmentsSelect.vue';
import activeCustomersChartConfig from '../../configs/activeCustomers';
import customerByConversationsChartConfig from '../../configs/customersByConversations';
import customerByLoginsChartConfig from '../../configs/customersByLogins';
import customerByMessagesChartConfig from '../../configs/customersByMessages';
import { intTickFormatter } from '../../helpers/formatters';
import {
  datePickerDefaultOptions,
  breakdownOptions,
  defaultDateRange,
  selectedBreakdownOption,
  setToEndOfDay,
  getNonReactiveDateRange,
  lastAvailableMonthAndQuarterRange,
  createPickerDateRangeOption,
  dstDateRangeUtc,
} from '../../configs/dateRange';
import { dropDownToSelected } from '../../helpers/filterHelper';

const xAxisFormatForActiveCustomers = (value) => moment(value).format('MMM DD');

export default {
  pageResource: '/metrics',
  name: 'CustomersSummary',
  components: {
    ManagementPage,
    OverviewChart,
    SegmentSelect,
  },
  data() {
    // Translate strings in config data
    const chartConfigs = [
      activeCustomersChartConfig,
      customerByLoginsChartConfig,
      customerByMessagesChartConfig,
      customerByConversationsChartConfig,
    ];

    for (const config of chartConfigs) {
      config.title = this.$t(`metrics_tab.customer_tab.summary_tab.${config.name}.title`, config.title);
      config.helpText = this.$t(`metrics_tab.customer_tab.summary_tab.${config.name}.help`, config.helpText);
    }

    return {
      activeCustomersChartConfig,
      customerByLoginsChartConfig,
      customerByMessagesChartConfig,
      customerByConversationsChartConfig,
      xAxisFormatForActiveCustomers,
      breakdownOptions,
      intTickFormatter,
      selectedFilters: [],
      percentFormatter: v => `${v}%`,
      intCountFormatter: v => (Math.floor(parseFloat(v)) === parseFloat(v) ? v : null),
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
      selectedBreakdownOption: selectedBreakdownOption(),
      segmentAlias: '',
    };
  },
  computed: {
    ...mapGetters({
      configs: 'configs/graphsConfigs',
      CustomerSegmentFilterOptions: 'configs/segments',
    }),
    ...mapState({
      CUSTOMER_METRICS_FILTERS: state => state.featureFlags.FEATURE_FLAGS.CUSTOMER_METRICS_FILTERS,
    }),
    datePickerOptions() {
      return {
        ...datePickerDefaultOptions(),
        shortcuts: lastAvailableMonthAndQuarterRange.map(createPickerDateRangeOption),
      };
    },
    dateRangeUtc() {
      return dstDateRangeUtc(this.dateRange);
    },
  },
  mounted() {
    this.$store.dispatch('configs/getCustomerSegments');
  },
  methods: {
    setBreakdown(value) {
      this.selectedBreakdownOption = breakdownOptions.find(
        option => option.value === value,
      );
    },
    setSelectedFilters(filterName, chosenFilters) {
      this.selectedFilters = dropDownToSelected(chosenFilters, this.selectedFilters, filterName);
    },
    onFilterChange(chosenFilters) {
      this.setSelectedFilters('customer_segments', chosenFilters);
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
    onSegmentHover(segment) {
      this.segmentAlias = get(segment, 'alias', '');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";

.scrollable {
  overflow-y: auto;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.chart-configuration {
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .members-filter {
    display: flex;
    align-items: center;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}
.chart-toolbar {
  display: flex;
  margin-bottom: 20px;
  .dropdown-filters {
    display: flex;
    align-items: flex-start;
    width: 60%;

    .dropdown-filter {
      margin-right: 12px;
      width: 40em;
      height: 30px;
      :deep(.el-select) {
        width:  75%;
      }
    }
  }
  .flex-spacer {
    flex-grow: 1;
  }
}

.time-range-filter {
  position: relative;
}
</style>
