<template lang="pug">
.content-inner.profile-tab
  aiq-row
    aiq-col(:sm="12",:xs="24")
      .profile-form
        .field-container-wrapper(v-show="!isMobileContext")
          .field-container(v-if="accountFieldsConfig.profile_image.show")
            label Profile Image
              aiq-tooltip(placement="top" :content="PROFILE_IMG_GUIDE")
                fa-icon.image-guide(:icon="['fas', 'circle-question']")
            .d-flex
              aiq-upload.img-uploader.button(v-model="pictureImageUrl"
                                            name="profile.picture"
                                            :http-request="upload"
                                            :action="url"
                                            accept="image/*"
                                            :headers="headers"
                                            :show-file-list="false"
                                            :before-upload="handleBeforeProfileImageUpload"
                                            :on-error="onUploadFailed"
                                            :disabled="isReadOnly"
                                            :on-success="handleProfileImageUploadSuccess")
                aiq-button(size="small" :disabled="isReadOnly" type="primary" v-if="!isLoadingProfileImage") Upload
                font-awesome-icon(v-if="isLoadingProfileImage"
                                  size="lg"
                                  icon="spinner"
                                  spin)
              aiq-button(size="small"
                        :disabled="isReadOnly"
                        type="danger"
                        v-if="!isLoadingProfileImage && canRemovePictureImage"
                        @click="handleImageRemoval('profile', {url: '', file_id: 0}, handleProfileImageUploadSuccess)") Delete


          .field-container(v-if="accountFieldsConfig.background_image.show")
            label Background Image
              aiq-tooltip(placement="top" :content="BG_IMG_GUIDE")
                fa-icon.image-guide(:icon="['fas', 'circle-question']")
            .d-flex
              aiq-upload.img-uploader.button(v-model="backgroundImageUrl"
                                            name="profile.background"
                                            :http-request="upload"
                                            :action="url"
                                            accept="image/*"
                                            :headers="headers"
                                            :show-file-list="false"
                                            :before-upload="handleBeforeBackgroundImageUpload"
                                            :on-error="onUploadFailed"
                                            :disabled="isReadOnly"
                                            :on-success="handleBackgroundImageUploadSuccess")
                aiq-button(size="small" :disabled="isReadOnly" type="primary" v-if="!isLoadingBackgroundImage") {{ $t('modal_editor.agent.upload' )}}
                font-awesome-icon(v-if="isLoadingBackgroundImage"
                                  size="lg"
                                  icon="spinner"
                                  spin)
              aiq-button(size="small"
                        :disabled="isReadOnly"
                        type="danger"
                        v-if="!isLoadingBackgroundImage && canRemoveBackgroundImage"
                        @click="handleImageRemoval('background', {url: '', file_id: 0}, handleBackgroundImageUploadSuccess)") {{ $t('modal_editor.agent.delete' )}}

        .field-container
          aiq-row.field-container-wrapper(v-if="accountFieldsConfig.first_name.show" :gutter="16").d-block
            aiq-col(:sm="12",:xs="24").field-container.is-required
              label First Name
              .inline-intro
                | (e.g. Clark)
              aiq-input(v-model="record.profile.first_name"
                        :maxlength="maxInputLength"
                        size="small"
                        name="profile.first_name"
                        :disabled="isReadOnly"
                        @keydown="onNameKeyPress"
                        @change="onChange('profile.first_name', $event)")

            aiq-col(v-if="accountFieldsConfig.last_name.show" :sm="12",:xs="24").field-container.is-required
              label Last Name
              .inline-intro
                | (e.g. Kent)
              aiq-input(v-model="record.profile.last_name"
                        :maxlength="maxInputLength"
                        size="small"
                        name="profile.last_name"
                        :disabled="isReadOnly"
                        @keydown="onNameKeyPress"
                        @change="onChange('profile.last_name', $event)")

        .field-container(v-if="accountFieldsConfig.display_name.show")
          label {{ $t('modal_editor.agent.display_name') }}
          .inline-intro
                | ({{ $t('modal_editor.agent.display_name_intro') }})
          aiq-input(v-model="record.profile.display_name"
                    :maxlength="maxInputLength"
                    size="small"
                    name="profile.display_name"
                    :disabled="isReadOnly"
                    @change="onChange('profile.display_name', $event)")

        .field-container(v-if="accountFieldsConfig.tagline.show")
          label Tagline
          .inline-intro
                | (e.g. Faster than a speeding bullet!)
          aiq-input(v-model="record.profile.tagline"
                    :maxlength="maxInputLength"
                    size="small"
                    name="profile.tagline"
                    :disabled="isReadOnly"
                    @change="onChange('profile.tagline', $event)")

        .field-container(v-if="accountFieldsConfig.expertise.show")
          label Expertise
          .inline-intro
                | (e.g. Leaping tall buildings in a single bound.)
          aiq-input(v-model="record.profile.expertise"
                    :maxlength="maxInputLength"
                    size="small"
                    name="profile.expertise"
                    :disabled="isReadOnly"
                    @change="onChange('profile.expertise', $event)")

        .field-container(v-if="accountFieldsConfig.about_me.show")
          label About Me
          .inline-intro
                | (e.g. I spend my free time protecting Earth.)
          aiq-input(v-model="record.profile.about"
                    type="textarea" size="small"
                    name="profile.about"
                    :disabled="isReadOnly"
                    @change="onChange('profile.about', $event)")

        aiq-row.field-container-wrapper(v-if="accountFieldsConfig.hours.show" :gutter="16").d-block
          aiq-col(:lg="12",:sm="24").field-container
            label Hours
            aiq-input(v-model="record.profile.availability"
                      :maxlength="maxInputLength"
                      size="small"
                      name="profile.hours"
                      :disabled="isReadOnly"
                      @change="onChange('profile.availability', $event)")

          aiq-col(v-if="accountFieldsConfig.location.show" :lg="12",:sm="24").field-container
            label Location
            aiq-input(v-model="record.profile.location"
                      :maxlength="maxInputLength"
                      size="small"
                      name="profile.location"
                      :disabled="isReadOnly"
                      @change="onChange('profile.location', $event)")

        .field-container-wrapper
          .field-container.w-100(v-if="accountFieldsConfig.phone.show")
            label Phone
            aiq-input(v-model="record.profile.phone"
                      :maxlength="maxInputLength",
                      size="small",
                      name="profile.phone",
                      placeholder="555-555-1234",
                      :disabled="isReadOnly"
                      @input="onChangePhone('profile.phone', $event)")
          .field-container(v-if="accountFieldsConfig.ext.show && shouldShowPhoneExt")
            label Ext
            aiq-input(v-model="record.profile.phoneext"
                      :maxlength="10",
                      size="small",
                      name="profile.phoneext",
                      placeholder="1234567890",
                      :disabled="isReadOnly"
                      @change="onChange('profile.phoneext', $event)")

        .field-container-wrapper(v-if="accountFieldsConfig.intro_video_url.show && shouldShowVideoUrl")
          .field-container.w-100
            label Intro Video URL
            aiq-input(v-model="record.profile.video_url"
              :maxlength="maxInputLength",
              size="small",
              name="profile.video_url",
              placeholder="Video URL",
              :disabled="isReadOnly"
              @change="onChange('profile.video_url', $event)")

        .field-container-wrapper(v-if="accountFieldsConfig.schedule_url.show")
          .field-container.w-100
            label Schedule Link
            aiq-input(v-model="record.profile.schedule_url"
              :maxlength="maxInputLength",
              size="small",
              name="profile.schedule_url",
              placeholder="External Scheduling Service URL",
              :disabled="isReadOnly"
              @change="onChange('profile.schedule_url', $event)")

        .field-container-wrapper(v-show="isMobileContext")
          .field-container
            label Profile Image
              aiq-tooltip(placement="top" :content="PROFILE_IMG_GUIDE")
                fa-icon.image-guide(:icon="['fas', 'circle-question']")
            .d-flex
              aiq-upload.img-uploader.button(v-model="pictureImageUrl"
                                            name="profile.picture"
                                            :http-request="upload"
                                            :action="url"
                                            accept="image/*"
                                            :headers="headers"
                                            :show-file-list="false"
                                            :before-upload="handleBeforeProfileImageUpload"
                                            :on-error="onUploadFailed"
                                            :disabled="isReadOnly"
                                            :on-success="handleProfileImageUploadSuccess")
                aiq-button(size="small" :disabled="isReadOnly" type="primary" v-if="!isLoadingProfileImage") {{ $t('modal_editor.agent.upload' )}}
                font-awesome-icon(v-if="isLoadingProfileImage"
                                  size="lg"
                                  icon="spinner"
                                  spin)
              aiq-button(size="small"
                        :disabled="isReadOnly"
                        type="danger"
                        v-if="!isLoadingProfileImage && canRemovePictureImage"
                        @click="handleImageRemoval('profile', {url: '', file_id: 0}, handleProfileImageUploadSuccess)") {{ $t('modal_editor.agent.delete' )}}

          .field-container
            label Background Image
              aiq-tooltip(placement="top" :content="BG_IMG_GUIDE")
                fa-icon.image-guide(:icon="['fas', 'circle-question']")
            .d-flex
              aiq-upload.img-uploader.button(v-model="backgroundImageUrl"
                                            name="profile.background"
                                            :http-request="upload"
                                            :action="url"
                                            accept="image/*"
                                            :headers="headers"
                                            :show-file-list="false"
                                            :before-upload="handleBeforeBackgroundImageUpload"
                                            :on-error="onUploadFailed"
                                            :disabled="isReadOnly"
                                            :on-success="handleBackgroundImageUploadSuccess")

                aiq-button(size="small" :disabled="isReadOnly" type="primary" v-if="!isLoadingBackgroundImage") {{ $t('modal_editor.agent.upload' )}}
                font-awesome-icon(v-if="isLoadingBackgroundImage"
                                  size="lg"
                                  icon="spinner"
                                  spin)
              aiq-button(size="small"
                        :disabled="isReadOnly"
                        type="danger"
                        v-if="!isLoadingProfileImage && canRemoveBackgroundImage"
                        @click="handleImageRemoval('background', {url: '', file_id: 0}, handleBackgroundImageUploadSuccess)") {{ $t('modal_editor.agent.delete' )}}

    aiq-col(:sm="12",:xs="24")
      div(:class="{'profile-preview': !isMobileContext}")
        .inline-intro
          .inline-card-intro
            | Profile Card Preview
        message(:message="profilePreviewData" disabled=true, :accountFieldsVisibility="accountFields"
                channel="webchat")

</template>

<script>
import set from 'lodash/set';
import get from 'lodash/get';
import { DEFAULT_BACKGROUND_URL, DEFAULT_PICTURE_URL, IMAGE_ACL_TYPES } from '@/constants';
import { hosts } from '@/config/api.routes';
import { defaultProfile } from '@/libs';
import { mapState } from 'vuex';
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const AgentCarouselItemMessageTemplate = {
  disabled: true,
  payload: {
    message_type: 'carousel',
    carousel: {
      attachments: [{
        message_type: 'agent',
        agent: {
          selection: {
            reply: {
              content: 'Select  Me',
              postback: {
                payload: {
                  type: 'select_agent',
                  index: 0,
                  value: 1324,
                },
              },
            },
            message_type: 'reply',
          },
        },
      }],
    },
  },
};

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      pictureImageUrl: { $autoDirty: true },
      backgroundImageUrl: { $autoDirty: true },
      record: {
        profile: {
          first_name: { required, $autoDirty: true },
          last_name: { required, $autoDirty: true },
          display_name: { $autoDirty: true },
          tagline: { $autoDirty: true },
          expertise: { $autoDirty: true },
          about: { $autoDirty: true },
          availability: { $autoDirty: true },
          location: { $autoDirty: true },
          phone: { $autoDirty: true },
          phoneext: { $autoDirty: true },
          video_url: { $autoDirty: true },
          schedule_url: { $autoDirty: true },
        },
      },
    };
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    permissions: {
      type: Object,
    },
  },
  emits: ['change'],
  computed: {
    ...mapState({
      loggedIn: state => state.agent.profile,
      isMobileContext: state => state.settings.isMobileContext,
      accountFields: state => state.configs.config.account_fields,
    }),
    accountFieldsConfig() {
      return this.accountFields.reduce((acc, item) => ({ ...acc, [item.name]: item }), {});
    },
    isReadOnly() {
      const isMyProfile = this.record.id === this.loggedIn.id;
      return this.disabled || (isMyProfile && !this.canEdit('/global/profile/profile'));
    },
    PROFILE_IMG_GUIDE() {
      return 'For best results use a 1:1 ratio image';
    },
    BG_IMG_GUIDE() {
      return 'For best results use a 2:1 ratio image';
    },
    canRemovePictureImage() {
      const image = get(this.record, 'picture.payload.url');
      return image && image !== DEFAULT_PICTURE_URL;
    },
    canRemoveBackgroundImage() {
      const image = get(this.record, 'background.payload.url');
      return image && image !== DEFAULT_BACKGROUND_URL;
    },
    profilePreviewData() {
      return this.buildProfilePreviewData(this.record);
    },
  },
  data() {
    return {
      backgroundImageUrl: '',
      pictureImageUrl: '',
      defaultProfile,
      headers: {
        Authorization: this.$store.getters['agent/authToken'],
      },
      isLoadingBackgroundImage: false,
      isLoadingProfileImage: false,
      maxInputLength: 50,
      url: `${hosts.management}files/upload`,
      shouldShowPhoneExt: process.env.FEATURE_FLAGS.SHOW_PHONE_EXT,
      shouldShowVideoUrl: process.env.FEATURE_FLAGS.SHOW_VIDEO_URL,
    };
  },
  mounted() {
    this.pictureImageUrl = get(this.record, 'picture.payload.url');
    if (this.pictureImageUrl === DEFAULT_PICTURE_URL) {
      this.record.picture.payload.url = '';
    }
    this.backgroundImageUrl = get(this.record, 'background.payload.url');
    if (this.backgroundImageUrl === DEFAULT_BACKGROUND_URL) {
      this.record.background.payload.url = '';
    }
  },
  methods: {
    buildProfilePreviewData(agent) {
      if (!agent) {
        return null;
      }

      const data = { ...AgentCarouselItemMessageTemplate };
      data.payload.carousel.attachments[0].agent = {
        status: 'away',
        background_image_url: get(agent, 'background.payload.url', ''),
        profile_image_url: get(agent, 'picture.payload.url', ''),
        ...agent.profile,
        selection: {
          reply: {
            content: `Select ${agent.profile.first_name || ''}`,
            postback: {},
          },
        },
      };

      return data;
    },

    onChange(key, val) {
      val = val.trim ? val.trim() : val;
      set(this.record, key, val);
      this.$emit('change', { key, val });
    },

    onNameKeyPress(event) {
      const specialCharsRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g; // eslint-disable-line
      const inputLabels = {
        'profile.first_name': 'First Name',
        'profile.last_name': 'Last Name',
      };
      if (specialCharsRegex.test(event.key)) {
        this.$aiq.notify.error(this.$t('modal_editor.notify_errors.no_special_characters', { label: inputLabels[event.target.name] }));
        event.preventDefault();
      }
    },

    onChangePhone(key, val) {
      const cleaned = val.replace(/[^+0-9]/g, '')
        .replace(/^(\+?\d)?(\d{3})(\d{3})(\d{4})$/, '$1($2) $3-$4');
      let replace = false;
      if (cleaned.match(/^(\+?\d)?\(\d{3}\) \d{3}-\d{4}$/)) {
        replace = true;
      }
      this.$emit('change', {
        key,
        val: replace ? cleaned : val,
      });
      if (replace) {
        this.record.profile.phone = cleaned;
      }
    },

    onImageRemovalConfirm(type) {
      return new Promise(resolve => {
        this.$aiq.confirm(
          this.$t(`modal_editor.${type}_image_removal_confirm.title`),
          this.$t(`modal_editor.${type}_image_removal_confirm.message`),
          {
            cancelButtonText: this.$t(`modal_editor.${type}_image_removal_confirm.cancel_button`),
            confirmButtonText: this.$t(`modal_editor.${type}_image_removal_confirm.confirm_button`),
          },
        ).then(() => resolve(true), () => resolve(false));
      });
    },

    upload(data) {
      const formData = new FormData();
      formData.append('file', data.file);
      // agent profile & background images are stored as public resources.
      formData.append('acl', IMAGE_ACL_TYPES.PUBLIC);
      return axios
        .post(this.url,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((res) => res.data);
    },

    handleBeforeProfileImageUpload() {
      this.isLoadingProfileImage = true;
    },

    handleBeforeBackgroundImageUpload() {
      this.isLoadingBackgroundImage = true;
    },

    async handleImageRemoval(type, payload, callback) {
      const response = await this.onImageRemovalConfirm(type);
      if (response) {
        callback(payload);
      }
    },

    handleImageUploadSuccess(res, config) {
      const name = config.localProp;

      this[name] = false;
      this[`${name}ImageUrl`] = res.url;
      set(this.record, config.localProp, {
        payload: {
          url: res.url,
        },
      });

      set(this.record, config.prop, res.file_id);
      this.$emit('change', { key: config.prop, val: res.file_id });
    },

    handleProfileImageUploadSuccess(res) {
      this.handleImageUploadSuccess(
        res,
        {
          loadingProp: 'isLoadingProfileImage',
          localProp: 'picture',
          prop: 'file_id',
        },
      );
      this.isLoadingProfileImage = false;
    },

    handleBackgroundImageUploadSuccess(res) {
      this.handleImageUploadSuccess(
        res,
        {
          loadingProp: 'isLoadingBackgroundImage',
          localProp: 'background',
          prop: 'profile.background_file_id',
        },
      );
      this.isLoadingBackgroundImage = false;
    },

    beforeUploadPicture(loadingProp) {
      this[loadingProp] = true;
    },

    onUploadFailed() {
      this.isLoadingBackgroundImage = false;
      this.isLoadingProfileImage = false;
      this.$aiq.notify.info('Failed to upload picture.');
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/media-queries.scss";

@include mobile {
  .carousel-content{
    overflow: hidden;
    .carousel-item.carousel-content-item{
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.el-dialog  {
  .d-flex {
    display: flex;
    align-items: baseline;
  }
  .img-uploader.button {
    margin-right: 10px;
    .el-upload {
      margin-top: 10px;
      margin-bottom: 14px;
      text-align: inherit;

      // todo remove when we move away from vue-infinite-loading
      .loading-spiral {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

</style>

<style lang="scss" scoped>
@import "../../../styles/media-queries.scss";
@import "../../../styles/aiq-variables.scss";

@include mobile {

    .el-dialog .el-dialog__body .field-container-wrapper .field-container ~ .field-container{
      margin-left: 0px;
    }

    .d-block{
      display: block !important;
    }

    .w-100{
      width:100% !important;
    }
}

.profile-tab {
    display: flex;
    justify-content: space-between;

    .field-container-wrapper {
      .field-container {
        .image-guide {
          margin-left: 5px;
          &:hover {
            color: $aiq-bgc-header;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
.inline-intro{
  display: inline-block;
  margin-left:0.25rem;
  padding-bottom: 0rem;
  .inline-card-intro{
    padding-bottom: 0.75rem;
  }
}

.profile-preview {
  padding-left: 24px;
  padding-right: 3px; // allow for agent card shadow
}

.el-dialog  .img-uploader.button {
  width: inherit;
  height: inherit;
  border: 0;
  background-color: inherit;
}
</style>
