<template lang="pug">
</template>

<script>
import get from 'lodash/get';
import GetGlobalSource from '@/libs/mixins/GetGlobalSource';

export default {
  name: 'view-file',
  mixins: [GetGlobalSource],
  async mounted() {
    // request presigned request
    const fileKey = get(this.$route, 'params.key');
    if (fileKey) {
      window.location.href = await this.getSignedSource(undefined, fileKey);
    } else {
      // back to dashboard root on exception
      this.$router.push({ path: '/' });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
