<template lang="pug">
.templates
    .template-type
      label {{ $t('settings_tab.admin_tab.message_template.message_type') }}
      aiq-select(placeholder="Type"
                size="small"
                @change="changeTemplateType"
                v-model="templateType")
        aiq-option(v-for="type in templateOptions", :key="type", :label="type", :value="type")
    .selection(v-if="templateType !== ''")
      aiq-row
        label {{ $t('settings_tab.admin_tab.message_template.select_existing') }}
      aiq-row
        aiq-col(:span="21")
          aiq-select(placeholder="Template"
                    size="small"
                    @change="selectedExistingTemplate"
                    v-model="selectedTemplateTitle")
            aiq-option(v-for="name in templateNames", :key="name", :label="name", :value="name")
        aiq-col(:span="1")
        aiq-col(:span="2")
          aiq-button(type="success" size="small" @click="onNewTemplate") {{ $t('settings_tab.admin_tab.email_tab.email_template.add') }}
      .template(v-if="templateType !== ''")
          .template-details(v-if="(mode === 'add' || (mode === 'edit' && !!selectedTemplateTitle))")
              .content
                aiq-row
                    .is-required
                    label {{ $t('settings_tab.admin_tab.message_template.content_title') }}
                    aiq-input(v-model="template.title" :disabled="mode === 'edit'")
              .content
                aiq-row
                    .is-required
                    label {{ $t('settings_tab.admin_tab.message_template.content') }}
                    aiq-input(v-model="template.content", class="textarea", type="textarea")
              .content
                aiq-row
                    label {{ $t('settings_tab.admin_tab.message_template.tags') }}
                aiq-row
                    aiq-col(:span="24")
                      aiq-select(v-model="template.tags"
                        size="small"
                        multiple
                        value-key="id"
                        name="tags"
                        class="tag-selection"
                        :reserve-keyword="false")
                        aiq-option(v-for="item in tagList"
                                :key="item"
                                :label="item"
                                :value="item"
                                filterable
                                default-first-option)
                .required-footer
                  .is-required-btn {{ $t('settings_tab.admin_tab.message_template.required') }}
              aiq-row
                .content-footer
                  aiq-button(@click="onCancel") {{ $t('global.cancel') }}
                  aiq-button(v-if="this.selectedTemplateTitle" type="danger" @click="onDelete") {{ $t('global.delete') }}
                  aiq-button(type="primary" class="save-btn" @click="Save" :disabled="!shouldSaveButtonEnabled") {{ $t('global.save') }}
</template>
<script>
import { cloneDeep, isEmpty, isEqual, get } from 'lodash';
import { TAG_LIMIT } from '@/constants/pagination';

const DURATION = 1500;
const emptyTemplate = {
  title: '',
  content: '',
  tags: [],
};
export default {
  name: 'message-template',
  props: {
    templates: {
      type: Object,
      default: {},
    },
  },
  async mounted() {

    const data = await this.$store.dispatch('tags/getTagsList', [{ limit: TAG_LIMIT }]);
    this.tagList = get(data, 'data.models', []).map(e => e.name);
  },
  data() {
    return {
      mode: '',
      selectedTemplateTitle: null,
      template: cloneDeep(emptyTemplate),
      templateType: 'Lynq Up Message',
      templateOptions: ['SMS', 'Lynq Up Message'],
      tagList: [],
    };
  },
  methods: {
    onNewTemplate() {
      this.mode = 'add';
      this.template = cloneDeep({...emptyTemplate, template_type: this.getCurrentTemplate()});
      this.selectedTemplateTitle = null;
    },

    changeTemplateType() {
      this.mode = '';
      this.selectedTemplateTitle = null;
    },
    getCurrentTemplate() {
      return this.templateType.includes('SMS') ? 'sms' : 'webchat';
    },

    selectedExistingTemplate() {
      this.selectedTemplate(this.selectedTemplateTitle);
    },
    onCancel() {
      if (this.mode === 'edit') {
        this.selectedTemplateTitle = null;
      } else if (this.mode === 'add') {
        this.template = cloneDeep({...emptyTemplate, template_type: this.getCurrentTemplate()});
      }
    },
    Update(templates, message) {
      return this.$emit('update', templates, message);
    },
    Save() {
      const newTemplates = cloneDeep(this.templates);
      const message = this.mode === 'edit' ?
        this.$t('settings_tab.admin_tab.message_template.message_temp_updated') :
        this.$t('settings_tab.admin_tab.message_template.message_temp_created');

      if (this.mode === 'edit') {
        const index = this.templates.findIndex(t => (t.title === this.selectedTemplateTitle && t.template_type === this.getCurrentTemplate()));
        newTemplates[index] = this.template;
      } else if (this.mode === 'add') {
        newTemplates.push(this.template);
        setTimeout(() => {
          this.changeTemplateType();
        }, DURATION);
      }
      return this.Update(newTemplates, message);
    },

    async onDelete() {
      try {
        await this.$aiq.confirm(
          this.$t('settings_tab.admin_tab.email_tab.email_template.on_delete.title'),
          this.$t('settings_tab.admin_tab.email_tab.email_template.on_delete.body'),
          {
            confirmButtonText: this.$t('global.continue'),
            cancelButtonText: this.$t('global.cancel'),
          },
        );
        const newTemplates = cloneDeep(this.templates);
        const index = this.templates.findIndex(t => (t.title === this.selectedTemplateTitle && t.template_type === this.getCurrentTemplate()));

        if (index !== -1) {
          newTemplates.splice(index, 1);
        }
        this.selectedTemplateTitle = null;
        this.template = cloneDeep({...emptyTemplate, template_type: this.getCurrentTemplate()});
        return this.Update(newTemplates, this.$t('settings_tab.admin_tab.message_template.message_temp_deleted'));
      } catch (_) {
        // Do nothing
        return null;
      }
    },
    selectedTemplate(newVal) {
      if (!isEmpty(newVal)) {
        this.template = cloneDeep(this.templates.filter(t => t.template_type === this.getCurrentTemplate()).find(t => t.title === newVal));
        this.mode = 'edit';
      } else {
        this.template = cloneDeep({...emptyTemplate, template_type: this.getCurrentTemplate()});
      }
    },
  },
  emits: ['update'],
  computed: {

    templateNames() {
      return this.templates.filter(t => t.template_type === this.getCurrentTemplate()).map(t => t.title);
    },
    shouldSaveButtonEnabled() {
      return this.isFormatValid && (this.isTemplateChanged || this.canAddNewItem);
    },
    isFormatValid() {
      return !isEmpty(this.template.title)
        && !isEmpty(this.template.content);
    },

    isTemplateChanged() {
      return this.mode === 'edit' && this.selectedTemplateTitle !== null
        && !isEqual(this.template, this.templates.filter(t => t.template_type === this.getCurrentTemplate()).find(t => this.selectedTemplateTitle === t.title));
    },
    canAddNewItem() {
      return this.selectedTemplateTitle === null && this.mode === 'add';
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-textarea__inner) {
    height: 250px;
}

.content-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.required-footer {
  padding: 10px;
  padding-right: 3px;
  width: 100%;
  text-align: right;
  margin-bottom: -10px;
}

.phone_numbe {
  margin-top: 15px;
}

.selection {
  margin-top: 15px;
}

.content {
  margin-top: 15px;
}

.is-required::after {
  content: "*";
  color: #FF0000;
}

.is-required-btn::before {
  color: #FF0000;
  margin-left: 5px;
  content: "*";
}

.is-required-btn {
  color: #FF0000;
}

.is-required + label {
  margin-left: 5px;
}

:deep(.el-input__inner) {
  height: 30px;
}


</style>