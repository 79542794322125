<template lang="pug">
.manage-template
  .header
    h1.title {{ $t('settings_tab.admin_tab.message_template.title') }}
  aiq-collapse.scrollbar__collapse(accordion)
    aiq-collapse-item(:title="$t('settings_tab.admin_tab.message_template.message_variable')")
     template-variable(@update="SaveTemplate" @delete="RemoveTemplate" :variables="messageTemplate.template_variables")
    aiq-collapse-item(:title="$t('settings_tab.admin_tab.message_template.title')")
     message-template(:templates="messageTemplate.message_templates || []" @update="SaveSmsAndWebChatTemplate")

</template>

<script>
import TemplateVariable from './TemplateVariable.vue';
import MessageTemplate from './MessageTempate.vue';
import { mapState } from 'vuex';

const localForm = { sms_templates: [], template_variables: [], web_chat_templates: [] };

export default {
  name: 'manage-template',
  data: () => ({
    variable_length: 0,
  }),
  components: {
    TemplateVariable,
    MessageTemplate,
  },
  computed: {
    ...mapState({
      messageTemplate: state => state.configs.messageTemplate || localForm,
    }),
  },
  async mounted() {
    await this.$store.dispatch('configs/getMessageTemplateConfig');
    this.variable_length = this.messageTemplate.template_variables.length;
  },
  methods: {

    async onSave(message = this.$t('settings_tab.admin_tab.message_template.saved')) {
      try {
        await this.$store.dispatch('configs/updateMessageTemplateConfig', this.messageTemplate);
        this.$aiq.notify.success(message);
      } catch (err) {
        this.$aiq.notify.error(err.message);
      }
    },

    RemoveTemplate(name) {
      this.messageTemplate.template_variables = this.messageTemplate.template_variables.filter((template) => template.name !== name);
      this.onSave(this.$t('settings_tab.admin_tab.message_template.message_var_deleted'));
    },
    SaveSmsAndWebChatTemplate(payload, message) {
      this.messageTemplate.message_templates = payload;
      this.onSave(message);
    },
    /**
     * SaveTemplate updates the template variables and triggers a save operation.
     * @param {Array} payload - The new template variables array.
     * @returns {Promise|void} The result of the save operation if variables are updated, otherwise void.
     */
    SaveTemplate(payload) {
      let msg;
      // Determine the appropriate message based on whether a new variable was added
      if (payload.length !== this.variable_length) {
        msg = this.$t('settings_tab.admin_tab.message_template.message_var_saved');
        this.variable_length = payload.length; // Update the stored length of variables
      } else {
        msg = this.$t('settings_tab.admin_tab.message_template.message_var_updated');
      }

      // Trigger the save operation and return its result
      return this.onSave(msg);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/aiq-mixins.scss";
@import "../../../../../styles/aiq-extensions.scss";

.scrollbar__collapse {
  @include scrollable(calc(100vh - 204px));
}
</style>