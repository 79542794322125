<template lang="pug">
  .aiq-basic-input-form-container
    .aiq-basic-input-form_content
      aiq-input(size="small", type="textarea",
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="New expression",
                v-model="itemTextData")
    .aiq-basic-input-form_actions
      aiq-button.aiq-button-delete(v-if="allowDelete",
                size="small",
                @click="deleteItem",
                native-type="button") Delete
      aiq-button(@click="cancel",
                size="small",
                native-type="button") Cancel
      aiq-button(@click="save",
                size="small",
                native-type="button"
                type="primary") Save
</template>

<script>
export default {
  name: 'basic-input-form',
  props: {
    itemId: [String, Number],
    itemTextProp: String,
    allowDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      itemTextData: this.itemTextProp,
    };
  },
  emits: ['save', 'cancel', 'deleteItem'],
  methods: {
    deleteItem() {
      this.$emit('deleteItem', this.itemId);
    },

    cancel() {
      this.$emit('cancel', this.itemId);
    },

    save() {
      this.$emit('save', this.itemId, this.itemTextData);
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/aiq-variables.scss";

.aiq-basic-input-form-container {
  margin-bottom: 4px;
  border-radius: 4px;
  border: 1px solid #5993FF;
  background-color: #fff;
  overflow: hidden;
  transition: border-radius 1s, border-width 1s;
}
.aiq-basic-input-form_content {
  padding: 8px 8px 0;

  .el-input,
  .el-textarea {
    margin-bottom: 12px;
  }
}
.aiq-basic-input-form_actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 8px 12px;
}
.aiq-button-add-new-function {
  width: 100%;
  padding: 1px;
  margin-bottom: 12px;
  color: #5993FF;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  border: 1px solid #5993FF;
}
.aiq-button-delete {
  margin-left: 0;
  margin-right: auto;
  border-color: #FF9999;
}
</style>
