import axios from 'axios';

import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import LogRocket from 'logrocket';
import moment from 'moment';
import urlJoin from 'url-join';
import * as log from 'loglevel';
import { getUrl } from '@/config/api.routes';
import { Events } from '@/libs';
import { canView } from '@/libs/aclMiddleware';
import { parse, AGENT_STATUS } from '@/constants';
import { userGuidingConfigIsValid } from '@/libs/userguiding';
import { REMINDERS, INACTIVITY_TIMEOUT } from '@/constants/analytics';

// Use session storage to store tokens
const secureStorage = sessionStorage || localStorage;

export const TUTORIAL_COMPLETE = 'complete';
export const TUTORIAL_INCOMPLETE = 'incomplete';
export const TUTORIAL_REVIEW = 'review';
const TUTORIAL_COMPLETION_STATE = 'tutorialCompletionState';
const CHECK_INACTIVITY_INTERVAL = 60 * 1000; // eslint-disable-line no-magic-numbers
const TAG = '[Agent Store]';
const tutorialCompletionState =
  () => localStorage.getItem(TUTORIAL_COMPLETION_STATE) || TUTORIAL_INCOMPLETE;
const setTutorialCompletedState = (value) => localStorage.setItem(TUTORIAL_COMPLETION_STATE, value);

export default {
  namespaced: true,

  state: {
    token: null,
    access: null,
    aclToken: null,
    connection: null,
    tutorialCompletionState: tutorialCompletionState(),
    // FIXME: The below 'profile' is the current agent payload. the name, 'profile', is bad
    // as agent payload also contains 'profile'
    profile: {},
    windowActive: true,
    agentActive: [],
    idlePopupOpened: false,
    appId: Math.random(),
    permissions: {},
    notifications: {
      items: [],
      uread_count: 0,
    },
    schedules: { },
    globalUiVisibility: {
      schedulesDialog: false,
    },
    globalDialogData: {
      schedulesDialog: null,
    },
    inactivity: {
      intervalInstance: null,
      idleTime: 0,
      eventHandler: null,
    },
    preferences: {},
  },

  getters: {
    authToken: state => (state.aclToken ? `Bearer ${state.aclToken}` : null),
    token: state => state.aclToken,
    isAuth: state => !!(state.token || secureStorage.getItem('idToken') || ''),
    isAclTokenReady: state => !!(state.aclToken),
    profile: state => state.profile,
    email: state => state.profile.email || '',
    available: state => get(state, 'profile.available', AGENT_STATUS.Away.label),
    active: state => state.agentActive.find(e => e),
    permissions: state => state.permissions,
    tutorialInProgress: state => state.tutorialCompletionState === TUTORIAL_REVIEW,
    teams: state => get(state, 'profile.teams', []),
    views: state => {
      if (isEmpty(state.permissions)) {
        return [];
      }
      return ['all', 'team', 'queue', 'mine'].filter(viewScope => {
        if (canView(state.permissions, `/conversations/${viewScope}`)) {
          return true;
        }
        return false;
      });
    },
    watchableTeamIds: state => get(state, 'profile.watchableTeams', []).map(({ id }) => id),
  },

  mutations: {
    SKIP_TUTORIAL(state) {
      setTutorialCompletedState(TUTORIAL_COMPLETE);
      state.tutorialCompletionState = TUTORIAL_COMPLETE;
    },

    REVIEW_TUTORIAL(state) {
      setTutorialCompletedState(TUTORIAL_REVIEW);
      state.tutorialCompletionState = TUTORIAL_REVIEW;
    },

    SET_CREDENTIALS(state, [accessToken, idToken]) {
      secureStorage.setItem('idToken', idToken);
      secureStorage.setItem('accessToken', accessToken);
      state.token = idToken;
      state.access = accessToken;
    },

    UNSET_CREDENTIALS(state) {
      state.token = null;
      state.access = null;
      state.aclToken = null;
      secureStorage.removeItem('idToken');
      secureStorage.removeItem('accessToken');
    },

    SET_TOKEN(state, token) {
      state.aclToken = token;
    },

    SET_CONNECTION(state, connection) {
      state.connection = connection;
    },

    SET_PERSONAL_PROFILE(state, profile) {
      LogRocket.identify(`dashboard-${profile.email}`, {
        email: profile.email,
        profile: profile.profile,
      });
      state.profile = parse(state.profile, profile);
    },

    CHANGE_AVAILABILITY(state, value) {
      state.profile[AGENT_STATUS.Available.field] = value;
    },

    CHANGE_AGENT_STATUS(state, newAgentStatus) {
      state.profile.available = newAgentStatus.value;
      state.profile.busy_status.status = newAgentStatus.status;
    },

    LOCK_CONVERSATION(state, id) {
      state.profile['locks'] = [...get(state, 'profile.locks', []), { conversation_id: `${id}` }];
    },

    UNLOCK_CONVERSATION(state, id) {
      state.profile['locks'] = get(state.profile, 'locks', []).filter(e => parseInt(e.conversation_id, 10) !== id);
    },

    UPDATE_AGENT(state, agent) {
      state.profile = agent || {};
    },

    UPDATE_AGENT_PROFILE(state, profile) {
      state.profile['isAvailable'] = profile.available;
      state.profile = parse(state.profile, profile);
    },

    CHANGE_WINDOW_ACTIVITY(state, value) {
      state.windowActive = value;
    },

    CHANGE_USER_ACTIVITY(state, value = false) {
      if (state.appId) {
        state.agentActive[state.appId] = value;

        if (!state.agentActive.find(e => !!e)) {
          Events.emit('open.idle.popup');
        }
      }
    },

    CHANGE_IDLE_POPUP_STATUS(state, value) {
      state.idlePopupOpened = value;
      if (!value) {
        Events.emit('close.idle.popup');
      }
    },

    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },

    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = { ...notifications };
    },

    SET_SCHEDULES(state, items) {
      state.schedules = items;
    },

    APPEND_SCHEDULES(state, { key, items }) {
      if (!state.schedules[key]) {
        state.schedules[key] = [];
      }

      const resultItems = state.schedules[key];
      items.forEach(item => {
        const matching = resultItems.find(i => i.id === item.id);
        if (!matching) {
          resultItems.push(item);
        }
      });
      state.schedules[key] = resultItems;

      state.schedules = { ...state.schedules };
    },

    UPDATE_SCHEDULES(state, item) {
      const key = moment(item.execute_at).format('YYYY-M');
      state.schedules[key] = state.schedules[key].map(s => ((s.id === item.id) ? item : s));
    },

    DELETE_SCHEDULES(state, item) {
      const key = moment(item.execute_at).format('YYYY-M');
      state.schedules[key] = get(state, `schedules.${key}`, []).filter(s => (s.id !== item.id));
    },

    UPDATE_GLOBAL_UI_VISIBILITY(state, { name, data, visible = false }) {
      if (name && has(state.globalUiVisibility, name)) {
        state.globalUiVisibility[name] = visible;

        if (data) {
          state.globalDialogData[name] = data;
        } else {
          state.globalDialogData[name] = null;
        }
      }
    },

    APPEND_NOTIFICATIONS(state, notifications) {
      state.notifications = {
        ...state.notifications,
        items: [...state.notifications.items, ...notifications.items],
      };
    },

    DELETE_NOTIFICATION(state, notification) {
      state.notifications = {
        ...state.notifications,
        items: state.notifications.items.filter(item => item.id !== notification.id),
      };
    },

    UPDATE_NOTIFICATION(state, notification) {
      const id = get(notification, 'id');
      if (id) {
        const items = state.notifications.items;
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].id === id) {
            items[i] = notification;
            break;
          }
        }
        state.notifications = { ...state.notifications };
      }
    },

    UPDATE_STATE(state, { key, value }) {
      set(state, key, value);
    },
  },

  actions: {
    setProfile({ commit }, data) {
      commit('SET_PERSONAL_PROFILE', data);
      return data;
    },

    showTutorial({ commit, rootState }) {
      const userGuideConfig = get(rootState, 'configs.config.user_guiding', {});
      if (userGuidingConfigIsValid(userGuideConfig)) {
        commit('REVIEW_TUTORIAL');
      }
    },

    skipTutorial({ commit }) {
      commit('SKIP_TUTORIAL');
    },

    setCredentials({ commit }, [accessToken, idToken]) {
      commit('SET_CREDENTIALS', [accessToken, idToken]);
    },

    logout({ dispatch }) {
      return axios.get(`${getUrl('management.agents', 'me')}/logout`).then(data => {
        dispatch('unsetCredentials');
        return data;
      });
    },

    refreshAgentData({ commit }) {
      return axios.get(getUrl('management.agents', 'me')).then(data => {
        commit('SET_PERSONAL_PROFILE', data.data);
        return data;
      });
    },

    updateAgentData({ commit }, agent) {
      return axios.put(getUrl('management.agents', 'me'), agent).then(data => {
        commit('SET_PERSONAL_PROFILE', data.data);
        return data;
      });
    },

    unsetCredentials({ commit }) {
      return new Promise(resolve => {
        commit('UNSET_CREDENTIALS');
        resolve();
      });
    },

    changeAvailability({ commit }, value) {
      return axios.put(`${getUrl('management.agents', 'me')}/available`, { value }).then(data => {
        commit('CHANGE_AVAILABILITY', value);
        return data;
      });
    },

    changeAgentStatus({ commit }, newAgentStatus) {
      return axios.put(`${getUrl('management.agents', 'me')}/available`, newAgentStatus).then(data => {
        commit('CHANGE_AGENT_STATUS', newAgentStatus);
        return data;
      });
    },

    async getAgentStatusHistory() {
      const result = await axios.get(`${getUrl('management.agents', 'me')}/available/history`);
      return result.data;
    },

    changeWindowActivity({ commit }, value) {
      commit('CHANGE_WINDOW_ACTIVITY', value);
    },

    changeUserActivity({ commit }, value) {
      commit('CHANGE_USER_ACTIVITY', value);
    },

    changeIdlePopupActivity({ commit }, value) {
      commit('CHANGE_IDLE_POPUP_STATUS', value);
    },

    initPermissions({ commit, state, dispatch }) {
      const opt = {
        headers: { Authorization: `Bearer ${state.token}` },
      };
      return Promise.all([
        axios.get(getUrl('management.agents', 'me'), opt),
        axios.get(`${getUrl('management.agents', 'me')}/acl`, opt),
      ]).then(data => {
        commit('SET_PERSONAL_PROFILE', data[0].data);
        commit('SET_PERMISSIONS', get(data[1], 'data.resources', {}));
        commit('SET_TOKEN', get(data[1], 'data.token', {}));

        log.debug('Token exchange is completed and acl token is available.');
        return dispatch('instrumentations/setUserContext', data[0].data, { root: true });
      });
    },

    getNotifications({ commit }, { limit, offset }) {
      return axios.get(`${getUrl('management.agents', 'me')}/notifications`, {
        params: { limit, offset },
      }).then(data => {
        const isFirstPage = get(data, 'data.pagination.offset', 0) === 0;
        const items = get(data, 'data.models', []);
        const unread_count = get(data, 'data.unread_count', 0);

        if (isFirstPage) {
          commit('SET_NOTIFICATIONS', { items, unread_count });
        } else {
          commit('APPEND_NOTIFICATIONS', { items, unread_count });
        }
        return get(data, 'data', { pagination: { limit: 10, offset: 0 } });
      });
    },

    markReadNotification({ commit, rootState, dispatch }, { notification, reminder }) {
      const needUpdate = get(notification, 'read_state', 'unread') === 'unread';
      if (needUpdate) {
        const id = get(notification, 'id');
        return axios.put(`${getUrl('management.agents', 'me')}/notifications/${id}`, {
          read_state: 'read',
        }).then(data => {
          commit('UPDATE_NOTIFICATION', get(data, 'data', {}));
          return data;
        });
      }
      dispatch(
        'notifications/emitEventToSocket',
        { name: REMINDERS.NOTIFICATION_CLICKED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            reminder },
        },
        { root: true },
      );
    },

    deleteNotification({ commit, rootState, dispatch }, { notification, reminder }) {
      dispatch(
        'notifications/emitEventToSocket',
        { name: REMINDERS.NOTIFICATION_CLICKED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            reminder },
        },
        { root: true },
      );
      const id = get(notification, 'id');
      return axios.delete(`${getUrl('management.agents', 'me')}/notifications/${id}`)
        .then(() => {
          commit('DELETE_NOTIFICATION', notification);
          return;
        });
    },

    readAllNotification() {
      return axios.put(`${getUrl('management.agents', 'me')}/notifications`);
    },

    async fetchScheduledJobs({ commit }, { month, year, time_zone }) {
      const res = await axios.get(`${getUrl('management.agents', 'me')}/schedules`,
        { params: { month, year, time_zone } });

      const key = moment().set('year', year).set('month', month - 1).format('YYYY-M'); // month starts from 0
      commit('APPEND_SCHEDULES', { key, items: get(res, 'data.models', []) });
      return res.data;
    },

    async updateScheduledJob({ commit, rootState, dispatch }, { schedule, schedulesLoggin }) {
      const res = await axios.put(`${getUrl('management.agents', 'me')}/schedules/${schedule.id}`,
        schedule);
      const payload = get(res, 'data', 0);
      if (payload) {
        // The payload contains only the result. So will use request data to update.
        commit('UPDATE_SCHEDULES', schedule);
      }
      dispatch(
        'notifications/emitEventToSocket',
        { name: REMINDERS.UPDATED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            reminder: schedulesLoggin },
        },
        { root: true },
      );
      return payload;
    },

    async cancelScheduledJob({ commit, rootState, dispatch }, { schedule, schedulesLoggin }) {
      await axios.delete(`${getUrl('management.agents', 'me')}/schedules/${schedule.id}`);
      commit('DELETE_SCHEDULES', schedule);
      dispatch(
        'notifications/emitEventToSocket',
        { name: REMINDERS.REMOVED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            reminder: schedulesLoggin },
        },
        { root: true },
      );
      return schedule;
    },

    async createScheduledJob({ commit, rootState, dispatch }, { schedule, schedulesLoggin }) {
      const res = await axios.post(`${getUrl('management.agents', 'me')}/schedules`, schedule);
      commit('APPEND_SCHEDULES', {
        key: moment(get(res, 'data.execute_at')).format('YYYY-M'),
        items: [get(res, 'data', {})] });
      dispatch(
        'notifications/emitEventToSocket',
        { name: REMINDERS.ADDED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            reminder: schedulesLoggin },
        },
        { root: true },
      );
    },

    changeDialogVisibility({ commit, rootState, dispatch }, payload) {
      commit('UPDATE_GLOBAL_UI_VISIBILITY', payload);
      dispatch(
        'notifications/emitEventToSocket',
        { name: REMINDERS.VISIBILITY_TOGGLED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            reminder: payload },
        },
        { root: true },
      );
    },

    disableVacationMode({ state, dispatch }) {
      const agent = cloneDeep(state.profile);
      set(agent, 'busy_status.status', '');
      return dispatch('updateAgentData', agent);
    },

    async getVideoToken(_, params) {
      const res = await axios.get(urlJoin(getUrl('channel'), '/channels/video/token'), { params });
      return res.data.token;
    },

    async getVoiceToken(_, params) {
      const res = await axios.get(urlJoin(getUrl('channel'), '/channels/voice/token'), { params });
      return res.data.token;
    },

    async getCobrowseConfig() {
      return (await axios.get(urlJoin(getUrl('channel'), '/channels/cobrowse/config'))).data;
    },

    async getCobrowseToken(_, params) {
      const res = await axios.get(urlJoin(getUrl('channel'), '/channels/cobrowse/token'), { params });
      return res.data.token;
    },

    async getCobrowseAuditUrl(_, params) {
      const res = await axios.get(urlJoin(getUrl('channel'), '/channels/cobrowse/audit/presigned'), { params });
      return res.data.presigned;
    },

    bootstrap({ commit }) {
      const token = secureStorage.getItem('idToken') || '';
      const access = secureStorage.getItem('accessToken') || '';
      commit('SET_CREDENTIALS', [access, token]);
    },

    startInactivityTimeout({ state, dispatch, commit }) {
      // make sure internval is not running
      dispatch('stopInactivityTimeout');

      const enabled = get(state, 'profile.inactivity.enabled', false);
      const duration = get(state, 'profile.inactivity.duration', 0);

      if (enabled && duration > 0) {
        log.debug(TAG, `Start inactivity interval until ${duration} mins pass`);
        const intervalInstance = setInterval(() => {
          const idleTime = state.inactivity.idleTime + 1;
          commit('UPDATE_STATE', { key: 'inactivity.idleTime', value: idleTime });
          log.debug(TAG, 'interval', idleTime, duration);
          if (idleTime >= duration) {
            dispatch(
              'notifications/emitEventToSocket',
              { name: INACTIVITY_TIMEOUT.LOGGEDOUT,
                payload: {
                  agent: state.profile,
                  inactivity: { enabled, duration },
                },
              },
              { root: true },
            );

            dispatch('stopInactivityTimeout');
            dispatch('logout');
          }
        }, CHECK_INACTIVITY_INTERVAL);

        commit('UPDATE_STATE', { key: 'inactivity.intervalInstance', value: intervalInstance });

        // Store a event handler reference to remove the handler
        commit('UPDATE_STATE', {
          key: 'inactivity.eventHandler',
          value: () => {
            if (state.inactivity.idleTime !== 0) {
              log.debug(TAG, `activity detected after ${state.inactivity.idleTime} minutes`);
              commit('UPDATE_STATE', { key: 'inactivity.idleTime', value: 0 });
            }
          },
        });
        window.addEventListener('mousemove', state.inactivity.eventHandler);
        window.addEventListener('keypress', state.inactivity.eventHandler);
      } else {
        log.debug(TAG, 'startInactivityTimeout', 'Skip starting inactivity', enabled, duration);
      }
    },

    stopInactivityTimeout({ state, commit }) {
      if (state.inactivity.intervalInstance) {
        log.debug(TAG, 'Stop inactivity interval');
        clearInterval(state.inactivity.intervalInstance);
        window.removeEventListener('mousemove', state.inactivity.eventHandler);
        window.removeEventListener('keypress', state.inactivity.eventHandler);
        commit('UPDATE_STATE', { key: 'inactivity.eventHandler', value: null });
        commit('UPDATE_STATE', { key: 'inactivity.intervalInstance', value: null });
        commit('UPDATE_STATE', { key: 'inactivity.idleTime', value: 0 });
      }
    },

    updateInactivityTimeout({ dispatch, state, commit }, { id, enabled, duration }) {
      log.debug(TAG, `updateInactivityTimeout enabled:${enabled} after: ${duration}`);
      const me = get(state, 'profile');
      if (id === me.id) {
        dispatch(
          'notifications/emitEventToSocket',
          { name: INACTIVITY_TIMEOUT.UPDATED,
            payload: {
              agent: me,
              inactivity: { enabled, duration },
            },
          },
          { root: true },
        );

        commit('UPDATE_STATE', {key: 'profile.inactivity.idleTime', value: duration});
        commit('UPDATE_STATE', {key: 'profile.inactivity.enabled', value: enabled});

        if (enabled) {
          dispatch('startInactivityTimeout');
        } else {
          dispatch('stopInactivityTimeout');
        }
      }
    },

    saveToSessionCache(_, { name, value }) {
      if (typeof value !== 'string') {
        throw new Error('value should be string to save');
      }
      secureStorage.setItem(name, value);
    },

    getFromSessionCache(_, { name }) {
      return secureStorage.setItem(name);
    },
  },
};
