import axios from 'axios';
import { getUrl } from '@/config/api.routes';
import { useTimeZone } from '@/libs';
import moment from 'moment-timezone';

const ROUTING_ALIAS = 'management.compliance';

export default {
  namespaced: true,
  state: {
    compliance: [],
    complianceLoaded: false,
    listPending: false,
    readPending: false,
  },
  getters: {},
  mutations: {
    GET_COMPLIANCE_LIST(state) {
      state.listPending = true;
    },
    GET_COMPLIANCE_LIST_SUCCESS(state, items) {
      state.compliance = items;
      state.listPending = false;
    },
    COMPLIANCE_LOADED(state, value) {
      state.complianceLoaded = value;
    },
    GET_COMPLIANCE(state) {
      state.readPending = true;
    },
    GET_COMPLIANCE_SUCCESS(state) {
      state.readPending = false;
    },
  },
  actions: {
    getComplianceList({ commit }) {
      commit('GET_COMPLIANCE_LIST');
      return axios.get(getUrl(ROUTING_ALIAS))
        .then(res => {
          const items = res.data;
          if (items.length === 0) {
            commit('COMPLIANCE_LOADED', true);
          }
          commit('GET_COMPLIANCE_LIST_SUCCESS', items);
          return items;
        });
    },
    markComplianceAsLoaded({ commit }, value) {
      commit('COMPLIANCE_LOADED', value);
    },
    async getCompliance({ commit, rootState }, params) {
      commit('GET_COMPLIANCE');
      const timezone = await useTimeZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        this.dispatch,
        rootState.configs);
      params = { ...params, timeZone: timezone };
      const startDate = moment.unix(params.startDate).format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment.unix(params.endDate).format('YYYY-MM-DD HH:mm:ss');

      //apply timezone to unix start and end time
      params.startDate = moment.tz(startDate, timezone).unix();
      params.endDate = moment.tz(endDate, timezone).unix();

      return axios.get(`${getUrl(ROUTING_ALIAS)}/${params.type}`, { responseType: 'blob', params });
    },
  },
};
