<template lang="pug">
.saml-binding
  .binding-configuration
    .bound-list(v-for="item in modelValue" key="item.value")
      aiq-col(:span="5")
        aiq-input(:value="bindingToName(item.Binding)" size="small" disabled)
      aiq-col(:span="15")
        aiq-input(:value="item.Location" size="small" disabled)
      aiq-col(:span="2")
        aiq-button(size="small" @click="onDelete(item)" type="danger") Delete
  .new-binding
    aiq-col(:span="5")
      aiq-select(v-model="newBinding"
                size="small")
        aiq-option(v-for="binding in filteredBindings"
                  :key="binding.value"
                  :label="binding.name"
                  :value="binding.value")
    aiq-col(:span="15")
      aiq-input(v-model="newLocation" size="small")
    aiq-col(:span="2")
      aiq-button(size="small" @click="onAdd") Add
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'SamlBindingEditor',
  props: {
    modelValue: {
      type: Array,
      default: [],
    },
  },
  computed: {
    bindings: function() {
      return [
        {
          name: 'Redirect',
          value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
        },
        {
          name: 'Post',
          value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
        },
        {
          name: 'Simple Sign',
          value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST-SimpleSign',
        },
        {
          name: 'Artifact',
          value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Artifact',
        },
      ];
    },
    filteredBindings: function() {
      return this.bindings.filter(b => !this.modelValue.find(mb => mb.Binding === b.value));
    },
  },
  data() {
    return {
      newBinding: '',
      newLocation: '',
    };
  },
  methods: {
    onDelete(item) {
      const filtered = cloneDeep(this.modelValue).filter(b => b.Binding !== item.Binding);
      this.$emit('update:modelValue', filtered);
    },
    onAdd() {
      const newList = cloneDeep(this.modelValue);
      newList.push({ Binding: this.newBinding, Location: this.newLocation });
      this.$emit('update:modelValue', newList);

      // reset
      this.newBinding = '';
      this.newLocation = '';
    },
    bindingToName(binding) {
      const found = this.bindings.find(b => b.value === binding);
      return found ? found.name : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";

.bound-list, .new-binding {
  display: flex;
  justify-content: space-evenly;
}

.new-binding {
  margin-top: 10px
}
</style>
