<template lang="pug">
.field-mapping-dialog
  aiq-dialog(:title="title" :model-value="visible", @close="$emit('close')")
    .info-text-container
      .info-text Add mapping between AIQ objects and CRM objects.


    .selections
      aiq-select.select-box(v-model="aiqSelected" placeholder="Select")
        aiq-option(v-for="item in aiqSupportingFields"
                  :key="item.path"
                  :label="convertLabel(item)"
                  :value="item.path")

      span =

      aiq-select.select-box(v-model="crmSelected" placeholder="Select")
        aiq-option(v-for="item in crmSupportingFields"
                  :key="item.path"
                  :label="convertLabel(item)"
                  :value="item.path")
      .select-box
        aiq-button(@click="onAdd") Add


    .added-mapping
      .selections(v-for="(mapping, index) in field_mappings")
        aiq-input.select-box(:model-value="mapping.source_path" disabled)
        span =
        aiq-input.select-box(:model-value="mapping.target_name" disabled)
        .select-box.remove
          a(@click="onRemove(index)") Remove

    template(v-slot:footer)
      aiq-button(@click="$emit('close')") Cancel
      aiq-button(type="primary" @click="onSave") Save

</template>

<script>

function extractSupportingFields(object, fieldMappings) {
  const items = (object && object.supporting_fields)
    ? object.supporting_fields : [];
  return items.filter(item => !fieldMappings.includes(item.path));
}

export default {
  name: 'field-mapping-dialog',
  props: {
    title: {
      type: String,
      default: '',
    },

    visible: {
      type: Boolean,
      default: false,
    },

    aiqObject: {
      type: Object,
      default: null,
    },

    crmObject: {
      type: Object,
      default: null,
    },

    field_mappings: {
      type: Array,
      default: [],
    },
  },
  emits: ['add', 'remove', 'save'],

  data() {
    return {
      aiqSelected: '',
      crmSelected: '',
    };
  },

  computed: {
    aiqPathFieldMappings() {
      return this.field_mappings.map(item => item.source_path);
    },

    crmPathFieldMappings() {
      return this.field_mappings.map(item => item.target_name);
    },

    aiqSupportingFields() {
      return extractSupportingFields(this.aiqObject, this.aiqPathFieldMappings);
    },

    crmSupportingFields() {
      return extractSupportingFields(this.crmObject, this.crmPathFieldMappings);
    },
  },

  methods: {
    reset() {
      this.aiqSelected = '';
      this.crmSelected = '';
    },
    onAdd() {
      if (!this.aiqSelected || !this.crmSelected) {
        return;
      }

      this.$emit('add', { source_path: this.aiqSelected, target_name: this.crmSelected });
      this.reset();
    },
    onRemove(index) {
      this.$emit('remove', { index });
    },
    convertLabel(item) {
      return `${item.name}(${item.path})`;
    },

    onSave() {
      this.$emit('save');
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../styles/aiq-variables.scss";

.selections {
  display: flex;
  .select-box {
    margin-left: 10px;
    margin-right: 10px;

  }

  .remove {
    padding-top: 5px;
    cursor: pointer;
  }

  span {
    padding: 5px;
  }
}

.added-mapping{
  margin-top: 50px;
}
</style>
